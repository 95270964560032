import React, { useEffect, useState } from "react";
import { motion, cubicBezier } from "framer-motion";
import Slider from "react-slick";
import { Container, Row, Col } from "react-bootstrap";
import { Anchor, SectionTitle, Text } from "../../components/styledComponent";
import {
  // bannerSliderImg,
  homeSliderSettings,
  homePromoSliderSettings,
  aboutSliderImg,
} from "../../utils/constants";
import { DownloadAppSection, FindCancoSection } from "../../components/parts";
import CancoCardImg from "../../assets/cancoCard.png";
import OneStopLogo from "../../assets/oneStopLogo.png";
// import RTLogo from "../../assets/rtLogoVar.png";
import styles from "./style.module.scss";
import { routes } from "../../utils/routes";
import { RegisterButton } from "../../components/ui";
import { useAuth } from "../../context/AuthProvider";
import { GET_API_URLS } from "../../utils/apiConstant";
import { commonGetApiHandler } from "../../api/commonApiCall";

const animationTransitionValues = [0.95, 0.05, 0.795, 0.035];

const Home = () => {
  const { user } = useAuth();
  const [promotionData, setPromotionData] = useState(false);
  const [activePromotionData, setActivePromotionData] = useState(null);
  const [promotionList, setPromotionList] = useState(null);
  const [bannerSliderData, setBannerSliderData] = useState(null);

  // console.log(user);

  // Getting list of promotions
  useEffect(() => {
    async function promotionFetcher() {
      try {
        const repsonse = await commonGetApiHandler(GET_API_URLS.GET_PROMOTIONS);
        repsonse && setPromotionData(repsonse.data);
      } catch (error) {
        console.log("web promotion api error", error);
      }
    }
    promotionFetcher();
  }, []);

  // Getting list of banner

  useEffect(() => {
    async function bannerFetcher() {
      try {
        const repsonse = await commonGetApiHandler(
          GET_API_URLS.GET_BANNER + "?module=DASHBOARD"
        );
        repsonse && setBannerSliderData(repsonse.data);
      } catch (error) {
        console.log("banner api error", error);
      }
    }
    bannerFetcher();
  }, []);

  // Dividing promotion data into categories
  useEffect(() => {
    if (promotionData) {
      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let currentDate = new Date(`${year}/${month}/${day}`);
      setActivePromotionData(
        promotionData.filter((val) => {
          let effectiveDate = new Date(val.effective_date.replace(/-/g, "/"));
          let expirationDate = new Date(val.expiration_date.replace(/-/g, "/"));
          // console.log("date", { effectiveDate, expirationDate, currentDate });
          // console.log("exp date", expirationDate >= currentDate);
          return effectiveDate <= currentDate && expirationDate >= currentDate;
        })
      );
    }
  }, [promotionData]);

  useEffect(() => {
    if (activePromotionData) {
      setPromotionList(
        activePromotionData
          ?.sort((a, b) => a.sequence_number - b.sequence_number)
          .filter((val) => val.category.id === 3)
      );
    }
  }, [activePromotionData]);

  return (
    <div className={styles.pageContainer}>
      {/* Banner section start */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          // delay: 0.3,
          duration: 3,
          easings: cubicBezier({ ...animationTransitionValues }),
        }}
        className={styles.bannerContainer}
      >
        {bannerSliderData && (
          <Slider {...homeSliderSettings}>
            {bannerSliderData
              .sort((a, b) => a.sequence - b.sequence)
              .map((data, i) => {
                return (
                  <div key={i} className={styles.promoImgContainer}>
                    <img src={data.banner} alt="Promotion Slider" />
                  </div>
                );
              })}
          </Slider>
        )}
      </motion.div>
      {/* <div className={`${styles.roadTripSection}`}>
        <Container>
          <Row className={styles.thirdSectionRow}>
            <Col xs={12} md={6} lg={6}>
              <img src={RTLogo} alt="Canco Road Trip" />
            </Col>
            <Col
              lg={6}
              xs={12}
              md={6}
              className={styles.thirdDescription}
              as={motion.div}
              initial={{ y: 200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                // delay: 0.2,
                duration: 1,
                easings: cubicBezier({ ...animationTransitionValues }),
              }}
            >
              <SectionTitle>
                It's that time of year where the more you drive, the more you
                get
              </SectionTitle>
              <Text $fontWeight={600} style={{ marginTop: "20px" }}>
                Starting from June 1st to August 31st
              </Text>
              <Text $fontWeight={700} $color={"red"}>
                Download Canco Cash App Latest Version to Play.
              </Text>
              <Anchor href={routes.ROAD_TRIP_RULES}>
                LEARN MORE ABOUT ROAD TRIP
              </Anchor>
            </Col>
          </Row>
        </Container>
      </div> */}

      {/* Second section start */}
      <FindCancoSection />
      <Container className={`sectionContainer ${styles.secondSection}`}>
        <Row className={styles.secondInnerTwo}>
          <Col
            lg={6}
            className={styles.aboutSliderContainer}
            as={motion.div}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              // delay: 0.4,
              duration: 3,
              easings: cubicBezier({ ...animationTransitionValues }),
            }}
          >
            <Slider {...homeSliderSettings} className="aboutSlider">
              {aboutSliderImg.map((imgUrl, i) => {
                return (
                  <div key={i} className={styles.aboutImgContainer}>
                    <img src={imgUrl} alt="Promotion Slider" />
                  </div>
                );
              })}
            </Slider>
          </Col>
          <Col lg={6} className={styles.aboutDescription}>
            <SectionTitle>
              Canadian
              <br />
              Born and Raised
            </SectionTitle>
            <Text>
              Canco is an independent chain of gas stations that launched in
              2016.
              <br />
              <br />A bold alternative to big-box retailers, Canco was born from
              a vision to become a leading gas and retail brand. We purchase
              high quality fuels from Canadian refineries who meet or exceed our
              high standards of quality.
              <br />
              <br />
              Backed by more than 15 years of combined experience; Canco has a
              dedicated team to support every step of the way! We are there to
              provide the quality and support in growing your business and
              community.
            </Text>
            <Anchor href={routes.ABOUT}>LEARN MORE</Anchor>
          </Col>
        </Row>
      </Container>

      {/* Third section start */}
      <div className={`${styles.thirdSection}`}>
        <Container>
          <Row className={styles.thirdSectionRow}>
            <Col
              lg={6}
              className={styles.thirdDescription}
              as={motion.div}
              initial={{ y: 200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                // delay: 0.2,
                duration: 1,
                easings: cubicBezier({ ...animationTransitionValues }),
              }}
            >
              <SectionTitle $color={"var(--whiteColor)"}>
                Earn 2¢ per litre on fuel.
              </SectionTitle>
              <SectionTitle $color={"var(--whiteColor)"}>
                +2% Cashback on in-store purchases.*
              </SectionTitle>
              <Text $fontWeight={600} $color={"var(--whiteColor)"}>
                *Some restrictions may apply.
              </Text>
              <Anchor href={routes.CANCO_CASH_LOYALTY}>LEARN MORE</Anchor>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Fourth section start */}

      <Container className={`sectionContainer ${styles.fourthSection}`}>
        <Row>
          <Col lg={6} className={styles.fourthDescription}>
            <SectionTitle>
              It pays to be loyal,
              <br />
              the card that spends like real cash
            </SectionTitle>
            <Text>
              We appreciate every person that walks into Canco Gas + One Stop
              Convenience and we want to help you get the best bang for your
              buck. Canco Cash is our loyalty program where customers earn and
              redeem.
            </Text>
            {!user.isLoggedIn && <RegisterButton />}
          </Col>
          <Col lg={6} className={styles.fourthImg}>
            <motion.img
              src={CancoCardImg}
              alt="Canco Card"
              initial={{ x: 200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.5,
                duration: 2,
                easings: cubicBezier({ ...animationTransitionValues }),
                type: "spring",
                stiffness: 100,
              }}
            />
          </Col>
        </Row>
      </Container>

      {/* Fifth section start */}

      <div className={`${styles.fifthSection}`}>
        <div className={styles.sectionImg}>
          <img src={OneStopLogo} alt="Canco One Stop" />
        </div>
        <SectionTitle $textAlign="center">
          Check out our latest promotions in-store
        </SectionTitle>
        {promotionList && promotionList.length > 0 ? (
          <Slider {...homePromoSliderSettings} className="promotionSlider">
            {/* {food2GoSliderImg.map((imgUrl, i) => {
                return (
                  <div key={i} className={styles.promoImgContainer}>
                    <img src={imgUrl} alt="Promotion Slider" />
                  </div>
                );
              })} */}
            {promotionList.map((data, i) => {
              return (
                <div key={i} className={styles.promoImgContainer}>
                  <img src={data.image} alt="Promotion Slider" />
                </div>
              );
            })}
          </Slider>
        ) : (
          <Text $textAlign="center" style={{ margin: "0", fontSize: "2rem" }}>
            No Promotions Available!
          </Text>
        )}
      </div>

      {/* Sixth section start */}

      <DownloadAppSection />
    </div>
  );
};

export default Home;
