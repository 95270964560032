import React from "react";
import { Anchor } from "../../styledComponent";
import { routes } from "../../../utils/routes";
import styles from "./style.module.scss";

const RegisterButton = () => {
  return (
    <Anchor href={routes.REGISTER} style={{ maxWidth: "fit-content" }} className={styles.registerGlobalBtn}>
      REGISTER NOW
    </Anchor>
  );
};

export default RegisterButton;
