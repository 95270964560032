import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { commonGetApiHandler } from "../../api/commonApiCall";
import { GET_API_URLS } from "../../utils/apiConstant";
import styles from "./style.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Loader } from "../../components/ui";
import { SectionTitle } from "../../components/styledComponent";

const BlogInner = () => {
  const { blogId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);
  const [dataError, setDataError] = useState(false);

  useEffect(() => {
    async function singleBlogFetcher() {
      setIsLoading(true);
      try {
        const repsonse = await commonGetApiHandler(
          GET_API_URLS.GET_BLOG + `${blogId}`
        );
        repsonse && setBlogData(repsonse.data);
        setIsLoading(false);
      } catch (error) {
        setDataError(true);
        setIsLoading(false);
      }
    }
    singleBlogFetcher();
  }, [blogId]);
  return (
    <div className={styles.blogInnerPage}>
      <Container className={`sectionContainer`}>
        {isLoading ? (
          <Row>
            <Col style={{ textAlign: "center" }}>
              <Loader />
            </Col>
          </Row>
        ) : dataError ? (
          <Row>
            <Col style={{ textAlign: "center" }}>
              Opps! Problem while fetching the blog data.
            </Col>
          </Row>
        ) : blogData ? (
          <>
            <Row>
              <Col lg={4}>
                <div className={styles.singleBlogImg}>
                  <img src={blogData.image} alt="Canco Blog" />
                </div>
              </Col>
              <Col lg={8}>
                <div className={styles.singleBlogContent}>
                  <SectionTitle>{blogData.title}</SectionTitle>
                  <div className={styles.blogInnerContent} dangerouslySetInnerHTML={{__html: blogData.content}} />
                </div>
              </Col>
            </Row>
          </>
        ) : null}
      </Container>
    </div>
  );
};

export default BlogInner;
