import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import {
  Button,
  Form,
  Input,
  Label,
  ErrorText,
} from "../../../styledComponent";
import { realtorApplicationInitialValues } from "../../../../utils/formik/initialvalue";
import { realtorFormValidationSchema } from "../../../../utils/formik/validationSchema";
import { normalizeInput } from "../../../../utils/helpers";
import styles from "./style.module.scss";
import { UploadFile } from "../../../ui";

const BecomeRealtorForm = (props) =>  {
  const [phoneNumber, setPhoneNumber] = useState("");

  const formikRef = useRef(null);

  const formik = useFormik({
    initialValues: realtorApplicationInitialValues,
    validationSchema: realtorFormValidationSchema,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("phone", phoneNumber);
    }, 1000);

    return () => clearTimeout(timer);
  }, [phoneNumber, formik]);

  const handleSubmit = (e) => {
    props.loader(true);
    e.preventDefault();
    fetch("/", {
      body: new FormData(e.target),
      method: "POST",
    })
      .then(() => {
        props.loader(false);
        props.success(true);
      })
      .catch((error) => {
        props.loader(false);
        // console.log(error);
      });
  };

  return (
    <Form
      method="POST"
      name="realtorForm"
      data-netlify="true"
      enctype="multipart/form-data"
      onSubmit={handleSubmit}
      className={styles.becomeRealtorForm}
    >
      <input type="hidden" name="form-name" value="realtorForm" />
      <div>
        <div>
          <Label htmlFor="firstName">First Name*</Label>
          <Input
            type="text"
            id="firstName"
            {...formik.getFieldProps("firstName")}
            style={
              formik.touched.firstName &&
              formik.errors.firstName && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <ErrorText className={styles.errorText}>
              {formik.errors.firstName}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="lastName">Last Name*</Label>
          <Input
            type="text"
            id="lastName"
            {...formik.getFieldProps("lastName")}
            style={
              formik.touched.lastName &&
              formik.errors.lastName && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <ErrorText className={styles.errorText}>
              {formik.errors.lastName}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="company">Company*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("company")}
            style={
              formik.touched.company &&
              formik.errors.company && { boxShadow: "0px 0px 0px 2px red" }
            }
          />
          {formik.touched.company && formik.errors.company && (
            <ErrorText className={styles.errorText}>
              {formik.errors.company}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="email">Email*</Label>
          <Input
            type="email"
            id="email"
            {...formik.getFieldProps("email")}
            style={
              formik.touched.email &&
              formik.errors.email && { boxShadow: "0px 0px 0px 2px red" }
            }
          />
          {formik.touched.email && formik.errors.email && (
            <ErrorText className={styles.errorText}>
              {formik.errors.email}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="lastName">Phone Number*</Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            placeholder="(xxx) xxx-xxxx"
            onBlur={formik.handleBlur("phone")}
            onChange={(e) => {
              setPhoneNumber((prev) => normalizeInput(e.target.value, prev));
            }}
            value={phoneNumber}
            style={
              formik.touched.phone &&
              formik.errors.phone && { boxShadow: "0px 0px 0px 2px red" }
            }
          />
          {formik.touched.phone && formik.errors.phone && (
            <ErrorText className={styles.errorText}>
              {formik.errors.phone}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="leasePurchase">
            Are you offering a lease or purchase?*
          </Label>
          <Input
            type="text"
            {...formik.getFieldProps("leasePurchase")}
            style={
              formik.touched.leasePurchase &&
              formik.errors.leasePurchase && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.leasePurchase && formik.errors.leasePurchase && (
            <ErrorText className={styles.errorText}>
              {formik.errors.leasePurchase}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="address">Address*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("address")}
            style={
              formik.touched.address &&
              formik.errors.address && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.address && formik.errors.address && (
            <ErrorText className={styles.errorText}>
              {formik.errors.address}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="sizeOfProperty">Size of property*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("sizeOfProperty")}
            style={
              formik.touched.sizeOfProperty &&
              formik.errors.sizeOfProperty && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.sizeOfProperty && formik.errors.sizeOfProperty && (
            <ErrorText className={styles.errorText}>
              {formik.errors.sizeOfProperty}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="zoning">What zoning does it have?*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("zoning")}
            style={
              formik.touched.zoning &&
              formik.errors.zoning && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.zoning && formik.errors.zoning && (
            <ErrorText className={styles.errorText}>
              {formik.errors.zoning}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <UploadFile
            onChange={(e) =>
              formik.setFieldValue("document", e.currentTarget.files[0])
            }
            onBlur={formik.handleBlur}
            value={formik.values.document}
          />
          {formik.touched.document && formik.errors.document && (
            <ErrorText className={styles.errorText}>
              {formik.errors.document}
            </ErrorText>
          )}
        </div>
      </div>
      <Button
        $width="100%"
        className={
          !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
        }
      >
        Submit
      </Button>
    </Form>
  );
};

export default BecomeRealtorForm;
