import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import { PlusLg } from "react-bootstrap-icons";
import { Checkbox } from "../../../ui";
import { Button, Text, ErrorText } from "../../../styledComponent";
import { balanceTransferInitialValues } from "../../../../utils/formik/initialvalue";
import { balanceTransferValidationSchema } from "../../../../utils/formik/validationSchema";
import { POST_API_URLS } from "../../../../utils/apiConstant";
import { postApiHandler } from "../../../../api/commonApiCall";
import { SecondaryLoader } from "../../../ui";
import styles from "./style.module.scss";

const BalanceTransfer = (props) => {
  const [loader, setLoader] = useState(false);
  const [sourceCard, setSourceCard] = useState(null);
  const [sourceAckrooID, setSourceAckrooID] = useState("");
  const [cardData, setCardData] = useState([]);
  const formik = useFormik({
    initialValues: balanceTransferInitialValues,
    validationSchema: balanceTransferValidationSchema,
    onSubmit: (values) => {
      // console.log("balance transfer", {...values, access_code: sourceAckrooID});
      balanceTransferHandler({ ...values, access_code: sourceAckrooID });
    },
  });

  const balanceTransferHandler = async (values) => {
    setLoader(true);
    const res = await postApiHandler(POST_API_URLS.BALANCE_TRANSFER, values);

    // Trigger toast when entered data is incorrect
    res && !res.response.success
      ? toast.error(res.response.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          style: { fontSize: "1.4rem", fontWeight: 500 },
        })
      : toast.success(res.response.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: { fontSize: "1.4rem", fontWeight: 500 },
        });

    res &&
      res.response.success &&
      setTimeout(() => window.location.reload(), 1500);
    setLoader(false);
  };

  useEffect(() => {
    if (sourceCard) {
      const sourceCardData = cardData.filter((data) =>
        sourceCard === data.cardnumber ? true : false
      );

      setSourceAckrooID(sourceCardData[0].ackroo_id);
    }
  }, [sourceCard, cardData]);

  useEffect(() => {
    if (props.cards) {
      const loyaltyCard = props.cards.filter(
        (data) => data.cardnumber.slice(0, 6) !== "604958"
      );
      // console.log("card data", loyaltyCard);
      setCardData(loyaltyCard);
    }
  }, [props.cards]);

  return (
    <div className={styles.balanceContainer}>
      <div className={styles.balanceInner}>
        <div className={styles.balanceHeader}>
          <Text>Transfer Balance</Text>
          <PlusLg size={20} onClick={props.onClose} />
        </div>
        <div className={styles.balanceBody}>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <label htmlFor="from_card_number">Source Card*</label>
              <select
                // defaultValue={"select"}
                name="from_card_number"
                onChange={(e) => {
                  setSourceCard(e.target.value);
                  formik.setFieldValue("from_card_number", e.target.value);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.from_card_number}
                // {...formik.getFieldProps("from_card_number")}
                style={
                  formik.touched.from_card_number &&
                  formik.errors.from_card_number && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
              >
                <option value={"select"}>Select Card</option>
                {cardData &&
                  cardData.map((val, i) => (
                    <option key={i} value={val.cardnumber}>
                      {val.cardnumber}
                    </option>
                  ))}
              </select>
              {formik.touched.from_card_number &&
                formik.errors.from_card_number && (
                  <ErrorText className={styles.errorText}>
                    {formik.errors.from_card_number}
                  </ErrorText>
                )}
            </div>
            <div>
              <label htmlFor="access_code">Ackroo ID / Access code</label>
              <input
                type="text"
                name="access_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={sourceAckrooID}
                // {...formik.getFieldProps("access_code")}
                style={
                  formik.touched.access_code &&
                  formik.errors.access_code && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
                disabled
              />
              {formik.touched.access_code && formik.errors.access_code && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.access_code}
                </ErrorText>
              )}
            </div>
            <div>
              <label htmlFor="to_card_number">To Card Number*</label>
              <select
                // defaultValue={"select"}
                name="to_card_number"
                {...formik.getFieldProps("to_card_number")}
                style={
                  formik.touched.to_card_number &&
                  formik.errors.to_card_number && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
                disabled={formik.values.from_card_number === "" ? true : false}
              >
                <option value={"select"}>Select Card</option>
                {props.cards &&
                  props.cards
                    .filter((val) => val.cardnumber !== sourceCard)
                    .map((val, i) => (
                      <option key={i} value={val.cardnumber}>
                        {val.cardnumber}
                      </option>
                    ))}
              </select>
              {formik.touched.to_card_number &&
                formik.errors.to_card_number && (
                  <ErrorText className={styles.errorText}>
                    {formik.errors.to_card_number}
                  </ErrorText>
                )}
            </div>
            <div>
              <Checkbox
                name={"deactivate_source_code"}
                label={"Deactivate source card"}
                onChange={formik.handleChange}
                value={formik.values.deactivate_source_code}
              />
            </div>
            <Button
              type="submit"
              className={
                !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
              }
            >
              {loader ? (
                <SecondaryLoader borderColor={"var(--whiteColor)"} />
              ) : (
                "Transfer My Balance"
              )}
            </Button>
          </form>
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            theme="colored"
          />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </div>
      </div>
    </div>
  );
};

export default BalanceTransfer;
