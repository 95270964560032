import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SectionTitle, Text } from "../../components/styledComponent";
import styles from "./style.module.scss";
import { AccountDeletionForm } from "../../components/parts/forms";
import { useAuth } from "../../context/AuthProvider";
import { routes } from "../../utils/routes";

const RequestAccountDeactivation = () => {
  const { userData, user } = useAuth();

  return (
    <div className={styles.termsConditionPage}>
      <Container className={`sectionContainer`}>
        <Row className={styles.pageTitle}>
          <Col>
            <SectionTitle $textAlign="center">
              Account Deletion and Data Removal Policy
            </SectionTitle>
          </Col>
        </Row>
        <Row className={styles.personalInfo}>
          <Col>
            <h3>Canco Petroleum Account Deletion and Data Removal Policy</h3>
            <Text>
              At Canco Petroleum, we value the privacy and data protection
              rights of our users. We understand that you may want to delete
              your account and associated data for various reasons. Therefore,
              we have implemented a process to facilitate the deletion of
              accounts and the removal of associated data upon user request.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Account Deletion and Data Removal Process:</h3>
            <ol>
              <li>
                <b>Request Submission:</b> Users who wish to delete their
                account and associated data can do so by submitting a request
                through our designated account deletion page.
              </li>
              <li>
                <b>Verification:</b> Upon receiving a deletion request, we will
                verify the identity of the user to ensure the security of the
                process and prevent unauthorized access. Verification may
                involve confirming certain account details or utilizing other
                appropriate authentication methods.
              </li>
              <li>
                <b>Confirmation:</b> Once the request is verified, we will send
                a confirmation message to the user, acknowledging receipt of the
                deletion request and providing an estimated timeframe for
                completion.
              </li>
              <li>
                <b>Data Removal:</b> Following confirmation, we will proceed to
                permanently delete the user's account and associated data from
                our systems. This includes any personal information,
                user-generated content, or other data associated with the
                account.
              </li>
              <li>
                <b>Confirmation of Deletion:</b> Upon completion of the deletion
                process, we will send a final confirmation message to the user,
                confirming the successful deletion of their account and
                associated data from our systems.
              </li>
              <li>
                <b>Canco Cash and Points:</b> All the data related to your Canco
                Cash and Points will be lost or unrecoverable, once your account
                is deleted.
              </li>
            </ol>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Additional Information</h3>
            <ul>
              <li>
                <b>Backup Data:</b> Please note that any backup copies of your
                data may exist in our system for a limited period due to
                technical constraints. However, such backup data will not be
                accessible or used for any purpose other than system
                restoration.
              </li>

              <li>
                <b>Data Retention:</b> We adhere to applicable laws and
                regulations regarding data retention. Certain data may be
                retained for a specific period to comply with legal obligations
                or for legitimate business purposes.
              </li>

              <li>
                <b>Reactivation:</b> If you wish to reactivate your account
                after deletion, you will need to create a new account with Canco
                Petroleum. Your previous data will not be recoverable after
                deletion.
              </li>

              <li>
                <b>Contact Us:</b> If you have any questions or concerns
                regarding the deletion of your account and associated data,
                please contact our support team using this form{" "}
                <a
                  href={routes.CONTACT}
                  style={{
                    borderBottom: "1px solid var(--primaryColor)",
                    fontWeight: 600,
                  }}
                >
                  https://cancopetroleum.ca/contact
                </a>{" "}
                (select "Other Technical Issues" for the subject).
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Policy Updates</h3>
            <Text>
              We reserve the right to update or modify this account deletion and
              data removal policy at any time. Users will be notified of any
              significant changes to the policy through appropriate channels. By
              using our services, you agree to abide by the terms outlined in
              this policy regarding the deletion of your account and associated
              data.
              <br />
              <br />
              Last updated: <b>26/04/2024</b>
              <br />
              <br />
              Canco Petroleum strives to maintain transparency and
              accountability in our data handling practices. Thank you for
              entrusting us with your privacy and for using our services.
            </Text>
          </Col>
        </Row>
        <Row className={styles.accountDeletionBlock}>
          <Col lg={5}>
            {user.isLoggedIn ? (
              <AccountDeletionForm
                userData={userData}
                isLoggedIn={user.isLoggedIn}
              />
            ) : (
              <Text>
                Please{" "}
                <a
                  href={routes.LOGIN}
                  style={{
                    fontWeight: "600",
                  }}
                >
                  Log In
                </a>
                , to continue with account deletion process.
              </Text>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RequestAccountDeactivation;
