import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { resetPasswordApiHandler } from "../../api/commonApiCall";
import { Button, SectionTitle } from "../../components/styledComponent";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./style.module.scss";
import SecondaryLoader from "../../components/ui/secondaryLoader";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";

const ForgotPassword = () => {
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const emailHandler = async () => {
    setLoading(true);
    const res = await resetPasswordApiHandler({ email: email });
    res.response && res.response.status && setSuccess(true);
    res &&
      res.error?.errorData &&
      Object.keys(res.error.errorData).map((k) =>
        res.error.errorData[k].map((val) =>
          toast.error(val, {
            position: "bottom-left",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            style: { fontSize: "1.6rem", fontWeight: 500 },
          })
        )
      );
    // res.response?.status
    // console.log("Email submit", response);
    setLoading(false);
  };

  return (
    <div className={styles.forgotPasswordPage}>
      <div className={styles.emailBox}>
        {success ? (
          <div>
            <SectionTitle $textAlign="center">
              Email sent successfully.
              <br />
              Please check your email for new password link.
            </SectionTitle>
          </div>
        ) : (
          <>
            <div className={styles.emailHeader}>
              <ArrowLeft
                color="var(--primaryColor)"
                size={30}
                onClick={() => navigate(routes.LOGIN)}
                style={{ cursor: "pointer" }}
              />
              <SectionTitle>Please enter your email address</SectionTitle>
            </div>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button onClick={() => emailHandler()}>
              {loading ? <SecondaryLoader /> : "Submit"}
            </Button>
          </>
        )}
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
    </div>
  );
};

export default ForgotPassword;
