export const citiesList = [
    {
      label: "Toronto",
      value: "Toronto",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.7417,
      lng: -79.3733
    },
    {
      label: "Montréal",
      value: "Montreal",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5089,
      lng: -73.5617,
      population: 3519595,
      density: 3889,
      timezone: "America/Montreal",
      ranking: 1,
      postal: "H1X H1Y H1Z H1P H1R H1S H1T H1V H1W H1H H1J H1K H1L H1M H1N H1A H1B H1C H1E H1G H2Y H2X H2Z H2T H2W H2V H2P H2S H2R H2M H2L H2N H2H H2K H2J H2E H2G H2A H2C H2B H3B H3C H3A H3G H3E H3J H3K H3H H3N H3L H3M H3R H3S H3V H3W H3T H3X H4G H4E H4C H4B H4A H4N H4M H4L H4K H4J H4H H4V H4S H4R H4P H8N H8S H8R H8P H8T H8Z H8Y H9A H9C H9E H9H H9J H9K",
      id: 1124586170
    },
    {
      label: "Vancouver",
      value: "Vancouver",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.25,
      lng: -123.1,
      population: 2264823,
      density: 5492.6,
      timezone: "America/Vancouver",
      ranking: 1,
      postal: "V6Z V6S V6R V6P V6N V6M V6L V6K V6J V6H V6G V6E V6C V6B V6A V5S V5P V5Z V5N V5L V5M V5K V5V V5W V5T V5R V5X V5Y",
      id: 1124825478
    },
    {
      label: "Calgary",
      value: "Calgary",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.05,
      lng: -114.0667,
      population: 1239220,
      density: 1501.1,
      timezone: "America/Edmonton",
      ranking: 1,
      postal: "T1Y T2H T2K T2J T2L T2N T2A T2C T2B T2E T2G T2Y T2X T2Z T2S T2R T2T T2V T3N T3L T3M T3J T3K T3H T3G T3E T3B T3C T3A T3R T3S T3P",
      id: 1124690423
    },
    {
      label: "Edmonton",
      value: "Edmonton",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.5344,
      lng: -113.4903,
      population: 1062643,
      density: 1360.9,
      timezone: "America/Edmonton",
      ranking: 1,
      postal: "T5X T5Y T5Z T5P T5R T5S T5T T5V T5W T5H T5J T5K T5L T5M T5N T5A T5B T5C T5E T5G T6X T6T T6W T6V T6P T6S T6R T6M T6L T6H T6K T6J T6E T6G T6A T6C T6B",
      id: 1124290735
    },
    {
      label: "Ottawa",
      value: "Ottawa",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.4247,
      lng: -75.695,
      population: 989567,
      density: 334,
      timezone: "America/Montreal",
      ranking: 1,
      postal: "K4P K4M K4A K4B K4C K7S K1S K1R K1P K1W K1V K1T K1Z K1Y K1X K1C K1B K1G K1E K1K K1J K1H K1N K1M K1L K0A K2R K2S K2P K2V K2W K2T K2J K2K K2H K2L K2M K2B K2C K2A K2G K2E",
      id: 1124399363
    },
    {
      label: "Mississauga",
      value: "Mississauga",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.6,
      lng: -79.65,
      population: 721599,
      density: 2467.6,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L4W L4V L4T L4Z L4Y L4X L5R L5V L5W L5A L5B L5C L5E L5G L5H L5J L5K L5L L5M L5N",
      id: 1124112672
    },
    {
      label: "Winnipeg",
      value: "Winnipeg",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.8844,
      lng: -97.1464,
      population: 705244,
      density: 1430,
      timezone: "America/Winnipeg",
      ranking: 1,
      postal: "R2N R2M R2L R2K R2J R2H R2G R2C R2Y R2X R2W R2V R2R R2P R3L R3M R3N R3H R3J R3K R3E R3G R3A R3B R3X R3Y R3T R3V R3W R3P R3R R3S",
      id: 1124224963
    },
    {
      label: "Quebec label",
      value: "Quebec label",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8139,
      lng: -71.2081,
      population: 705103,
      density: 1173.2,
      timezone: "America/Montreal",
      ranking: 1,
      postal: "G1N G1M G1L G1K G1J G1H G1G G1E G1C G1B G1Y G1X G1W G1V G1T G1S G1R G1P G3E G3G G3K G3J G2G G2E G2B G2C G2A G2N G2L G2M G2J G2K",
      id: 1124823933
    },
    {
      label: "Hamilton",
      value: "Hamilton",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.2567,
      lng: -79.8692,
      population: 693645,
      density: 480.6,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L0R L0P L8W L8V L8T L8S L8R L8P L8G L8E L8N L8M L8L L8K L8J L8H L9G L9A L9B L9C L9H L9K N1R",
      id: 1124567288
    },
    {
      label: "Brampton",
      value: "Brampton",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.6833,
      lng: -79.7667,
      population: 593638,
      density: 2228.7,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L7A L6T L6W L6V L6P L6S L6R L6Y L6X L6Z",
      id: 1124625989
    },
    {
      label: "Surrey",
      value: "Surrey",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.19,
      lng: -122.8489,
      population: 517887,
      density: 1636.8,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V4A V4N V4P V3R V3S V3T V3V V3W V3X V3Z",
      id: 1124001454
    },
    {
      label: "Kitchener",
      value: "Kitchener",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.4186,
      lng: -80.4728,
      population: 470015,
      density: 3433.5,
      timezone: "America/Toronto",
      ranking: 1,
      postal: "N2K N2H N2N N2M N2C N2B N2A N2G N2E N2R N2P",
      id: 1124158530
    },
    {
      label: "Laval",
      value: "Laval",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5833,
      lng: -73.75,
      population: 422993,
      density: 1710.9,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "H7N H7L H7M H7J H7K H7H H7G H7E H7B H7C H7A H7X H7Y H7V H7W H7T H7R H7S H7P",
      id: 1124922301
    },
    {
      label: "Halifax",
      value: "Halifax",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 44.6475,
      lng: -63.5906,
      population: 403131,
      density: 73.4,
      timezone: "America/Halifax",
      ranking: 1,
      postal: "B2Z B2Y B2X B2W B2V B2T B2S B2R B3T B3V B3P B3R B3L B3M B3N B3H B3J B3K B3E B3G B3A B3B B0J B3Z B3S B4E B4G B4A B4C B4B B0N",
      id: 1124130981
    },
    {
      label: "London",
      value: "London",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.9836,
      lng: -81.2497,
      population: 383822,
      density: 913.1,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "N5Z N5X N5Y N5V N5W N6A N6P N6G N6E N6C N6N N6L N6J N6H N6B N6M N6K",
      id: 1124469960
    },
    {
      label: "Victoria",
      value: "Victoria",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.4283,
      lng: -123.3647,
      population: 335696,
      density: 4406.3,
      timezone: "America/Vancouver",
      ranking: 1,
      postal: "V8T V8W V8S V8R V9A V8V",
      id: 1124147219
    },
    {
      label: "Markham",
      value: "Markham",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.8767,
      lng: -79.2633,
      population: 328966,
      density: 1549.2,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L3T L3R L3P L3S L6E L6G L6C L6B",
      id: 1124272698
    },
    {
      label: "St. Catharines",
      value: "St. Catharines",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.1833,
      lng: -79.2333,
      population: 309319,
      density: 1384.8,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L2M L2N L2P L2S L2R L2T L2W",
      id: 1124140229
    },
    {
      label: "Niagara Falls",
      value: "Niagara Falls",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.06,
      lng: -79.1067,
      population: 308596,
      density: 419.9,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L2E L2G L2H L2J L3B",
      id: 1124704011
    },
    {
      label: "Vaughan",
      value: "Vaughan",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.8333,
      lng: -79.5,
      population: 306233,
      density: 1119.4,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L0J L4K L4J L4H L4L L6A",
      id: 1124000141
    },
    {
      label: "Gatineau",
      value: "Gatineau",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4833,
      lng: -75.65,
      population: 276245,
      density: 773.7,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "J8P J8R J8T J8Y J8X J8Z J8M J9J J9H J9A",
      id: 1124722129
    },
    {
      label: "Windsor",
      value: "Windsor",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.2833,
      lng: -83,
      population: 276165,
      density: 1484.3,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "N8T N8W N8P N8S N8R N8Y N8X N9J N9B N9C N9A N9G N9E N0R",
      id: 1124261024
    },
    {
      label: "Saskatoon",
      value: "Saskatoon",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.1333,
      lng: -106.6833,
      population: 246376,
      density: 1080,
      timezone: "America/Regina",
      ranking: 2,
      postal: "S7H S7K S7J S7M S7L S7N S7S S7R S7W S7V",
      id: 1124612546
    },
    {
      label: "Longueuil",
      value: "Longueuil",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5333,
      lng: -73.5167,
      population: 239700,
      density: 2002,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "J4T J4V J4P J4R J4M J4L J4N J4H J4K J4J J4G J3Y J3Z",
      id: 1124122753
    },
    {
      label: "Burnaby",
      value: "Burnaby",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.2667,
      lng: -122.9667,
      population: 232755,
      density: 2568.7,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V5B V5G V5E V5C V5J V5H V5A V3J V3N",
      id: 1124817304
    },
    {
      label: "Regina",
      value: "Regina",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.4547,
      lng: -104.6067,
      population: 215106,
      density: 1195.2,
      timezone: "America/Regina",
      ranking: 2,
      postal: "S4T S4V S4W S4R S4S S4X S4Y S4Z",
      id: 1124342541
    },
    {
      label: "Richmond",
      value: "Richmond",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.1667,
      lng: -123.1333,
      population: 198309,
      density: 1534.1,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V6Y V6X V6W V6V V7E V7A V7B V7C",
      id: 1124121940
    },
    {
      label: "Richmond Hill",
      value: "Richmond Hill",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.8667,
      lng: -79.4333,
      population: 195022,
      density: 1928.8,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L4S L4C L4B L4E",
      id: 1124364273
    },
    {
      label: "Oakville",
      value: "Oakville",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.45,
      lng: -79.6833,
      population: 193832,
      density: 1314.2,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L6M L6L L6H L6K L6J",
      id: 1124080468
    },
    {
      label: "Burlington",
      value: "Burlington",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.3167,
      lng: -79.8,
      population: 183314,
      density: 946.8,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L7R L7S L7P L7T L7N L7L L7M",
      id: 1124955083
    },
    {
      label: "Barrie",
      value: "Barrie",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.3711,
      lng: -79.6769,
      population: 172657,
      density: 1428,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L9J L4N L4M",
      id: 1124340223
    },
    {
      label: "Oshawa",
      value: "Oshawa",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.9,
      lng: -78.85,
      population: 166000,
      density: 1027,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L1L L1H L1J L1K L1G",
      id: 1124541904
    },
    {
      label: "Sherbrooke",
      value: "Sherbrooke",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4,
      lng: -71.9,
      population: 161323,
      density: 456,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "J1N J1L J1M J1J J1K J1H J1G J1E J1C J1R",
      id: 1124559506
    },
    {
      label: "Saguenay",
      value: "Saguenay",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.4167,
      lng: -71.0667,
      population: 144746,
      density: 128.5,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "G8A G7N G7H G7K G7T G7Z G7G G7B G7J G7P G7S G7Y G7X",
      id: 1124001930
    },
    {
      label: "Lévis",
      value: "Levis",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8,
      lng: -71.1833,
      population: 143414,
      density: 319.4,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "G7A G6J G6K G6C G6Z G6X G6Y G6V G6W",
      id: 1124958950
    },
    {
      label: "Kelowna",
      value: "Kelowna",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.8881,
      lng: -119.4956,
      population: 142146,
      density: 601.3,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V1X V1Y V1P V1W V1V",
      id: 1124080626
    },
    {
      label: "Abbotsford",
      value: "Abbotsford",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.05,
      lng: -122.3167,
      population: 141397,
      density: 376.5,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V4X V2S V2T V3G",
      id: 1124808029
    },
    {
      label: "Coquitlam",
      value: "Coquitlam",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.2839,
      lng: -122.7919,
      population: 139284,
      density: 1138.9,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V3B V3C V3E V3J V3K",
      id: 1124000500
    },
    {
      label: "Trois-Rivières",
      value: "Trois-Rivieres",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.35,
      lng: -72.55,
      population: 134413,
      density: 1581.2,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "G9C G9B G9A G8T G8V G8W G8Y G8Z",
      id: 1124407487
    },
    {
      label: "Guelph",
      value: "Guelph",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.55,
      lng: -80.25,
      population: 131794,
      density: 1511.1,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "N1C N1G N1E N1K N1H N1L",
      id: 1124968815
    },
    {
      label: "Cambridge",
      value: "Cambridge",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.3972,
      lng: -80.3114,
      population: 129920,
      density: 1149.6,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "N3H N3C N3E N1R N1S N1P N1T",
      id: 1124113576
    },
    {
      label: "Whitby",
      value: "Whitby",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.8833,
      lng: -78.9417,
      population: 128377,
      density: 876.1,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L0B L1P L1R L1M L1N",
      id: 1124112077
    },
    {
      label: "Ajax",
      value: "Ajax",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.8583,
      lng: -79.0364,
      population: 119677,
      density: 1634.2,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L1Z L1T L1S",
      id: 1124382916
    },
    {
      label: "Langley",
      value: "Langley",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.1044,
      lng: -122.5827,
      population: 117285,
      density: 380.8,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V1M V4W V2Z V2Y V3A",
      id: 1124000480
    },
    {
      label: "Saanich",
      value: "Saanich",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.484,
      lng: -123.381,
      population: 114148,
      density: 1099.9,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V8N V8X V8Z V8P V8R V9A V9E V8Y",
      id: 1124000949
    },
    {
      label: "Terrebonne",
      value: "Terrebonne",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7,
      lng: -73.6333,
      population: 111575,
      density: 687.1,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "J6Y J6V J7M J6X J6W",
      id: 1124993674
    },
    {
      label: "Milton",
      value: "Milton",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.5083,
      lng: -79.8833,
      population: 110128,
      density: 230.11,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L7J L0P L9T",
      id: 1124001426
    },
    {
      label: "St. John's",
      value: "St. John's",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.4817,
      lng: -52.7971,
      population: 108860,
      density: 244.1,
      timezone: "America/St_Johns",
      ranking: 2,
      postal: "A1H A1S A1E A1G A1A A1C A1B",
      id: 1124741456
    },
    {
      label: "Moncton",
      value: "Moncton",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.1328,
      lng: -64.7714,
      population: 108620,
      density: 506,
      timezone: "America/Moncton",
      ranking: 2,
      postal: "E1H E1A E1C E1E E1G",
      id: 1124521303
    },
    {
      label: "Thunder Bay",
      value: "Thunder Bay",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.3822,
      lng: -89.2461,
      population: 107909,
      density: 330.1,
      timezone: "America/Thunder_Bay",
      ranking: 2,
      postal: "P7G P7E P7B P7C P7J P7K",
      id: 1124398712
    },
    {
      label: "Dieppe",
      value: "Dieppe",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.0989,
      lng: -64.7242,
      population: 107068,
      density: 469.6,
      timezone: "America/Moncton",
      ranking: 2,
      postal: "E1A",
      id: 1124195431
    },
    {
      label: "Waterloo",
      value: "Waterloo",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.4667,
      lng: -80.5167,
      population: 104986,
      density: 1520.7,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "N2K N2J N2L N2V N2T",
      id: 1124321390
    },
    {
      label: "Delta",
      value: "Delta",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.0847,
      lng: -123.0586,
      population: 102238,
      density: 567.4,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V4C V4E V4G V4K V4M V4L",
      id: 1124001200
    },
    {
      label: "Chatham",
      value: "Chatham",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.4229,
      lng: -82.1324,
      population: 101647,
      density: 41.4,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "N8A N0P N7L N7M",
      id: 1124393373
    },
    {
      label: "Red Deer",
      value: "Red Deer",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.2681,
      lng: -113.8111,
      population: 100418,
      density: 958.8,
      timezone: "America/Edmonton",
      ranking: 2,
      postal: "T4R T4P T4N",
      id: 1124404130
    },
    {
      label: "Kamloops",
      value: "Kamloops",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.6761,
      lng: -120.3408,
      population: 100046,
      density: 301.7,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V1S V2C V2B V2E",
      id: 1124735582
    },
    {
      label: "Brantford",
      value: "Brantford",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.1667,
      lng: -80.25,
      population: 97496,
      density: 1345.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N3P N3R N3S N3T N3V",
      id: 1124737006
    },
    {
      label: "Cape Breton",
      value: "Cape Breton",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 46.1389,
      lng: -60.1931,
      population: 94285,
      density: 38.8,
      timezone: "America/Glace_Bay",
      ranking: 3,
      postal: "B2A B1S B1V B1G B1E B1B B1C B1A B1N B1L B1M B1J B1H B1T B1R B1P B1Y B1K",
      id: 1124000383
    },
    {
      label: "Lethbridge",
      value: "Lethbridge",
      province_id: "AB",
      province_name: "Alberta",
      lat: 49.6942,
      lng: -112.8328,
      population: 92729,
      density: 759.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T1H T1J T1K",
      id: 1124321200
    },
    {
      label: "Saint-Jean-sur-Richelieu",
      value: "Saint-Jean-sur-Richelieu",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3167,
      lng: -73.2667,
      population: 92394,
      density: 419.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J2W J2Y J2X J3A J3B",
      id: 1124278447
    },
    {
      label: "Clarington",
      value: "Clarington",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.935,
      lng: -78.6083,
      population: 92013,
      density: 138.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0B L0A L1E L1B L1C",
      id: 1124000882
    },
    {
      label: "Pickering",
      value: "Pickering",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.8354,
      lng: -79.089,
      population: 91771,
      density: 383.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0H L0B L1X L1Y L1V L1W",
      id: 1124781814
    },
    {
      label: "Nanaimo",
      value: "Nanaimo",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.1642,
      lng: -123.9364,
      population: 90504,
      density: 918,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V9R V9S V9V V9T",
      id: 1124623893
    },
    {
      label: "Sudbury",
      value: "Sudbury",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.49,
      lng: -81.01,
      population: 88054,
      density: 49.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0M P3N P3L P3B P3C P3A P3G P3Y P3P",
      id: 1124539524
    },
    {
      label: "North Vancouver",
      value: "North Vancouver",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.3641,
      lng: -123.0066,
      population: 85935,
      density: 534.6,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V7P V7R V7L V7N V7H V7J V7K V7G",
      id: 1124000146
    },
    {
      label: "Brossard",
      value: "Brossard",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4667,
      lng: -73.45,
      population: 85721,
      density: 1896,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J4Y J4X J4Z J4W",
      id: 1124655166
    },
    {
      label: "Repentigny",
      value: "Repentigny",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7333,
      lng: -73.4667,
      population: 84965,
      density: 1395.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J6A J5Z J5Y",
      id: 1124379778
    },
    {
      label: "Newmarket",
      value: "Newmarket",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.05,
      lng: -79.4667,
      population: 84224,
      density: 2190.5,
      timezone: "America/Toronto",
      ranking: 2,
      postal: "L3X L3Y",
      id: 1124400266
    },
    {
      label: "Chilliwack",
      value: "Chilliwack",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.1577,
      lng: -121.9509,
      population: 83788,
      density: 320.2,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V4Z V2R V2P",
      id: 1124531262
    },
    {
      label: "White Rock",
      value: "White Rock",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.025,
      lng: -122.8028,
      population: 82368,
      density: 3893.1,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V4B",
      id: 1124260555
    },
    {
      label: "Maple Ridge",
      value: "Maple Ridge",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.2167,
      lng: -122.6,
      population: 82256,
      density: 308.3,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V4R V2W V2X",
      id: 1124001699
    },
    {
      label: "Peterborough",
      value: "Peterborough",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.3,
      lng: -78.3167,
      population: 82094,
      density: 1261.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K9K K9J K9H K9L",
      id: 1124440356
    },
    {
      label: "Kawartha Lakes",
      value: "Kawartha Lakes",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.35,
      lng: -78.75,
      population: 75423,
      density: 24.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0K L0B L0A K9V K0M",
      id: 1124000852
    },
    {
      label: "Prince George",
      value: "Prince George",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 53.9169,
      lng: -122.7494,
      population: 74003,
      density: 232.5,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V2K V2N V2M V2L",
      id: 1124733292
    },
    {
      label: "Sault Ste. Marie",
      value: "Sault Ste. Marie",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.5333,
      lng: -84.35,
      population: 73368,
      density: 328.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P6A P6C P6B",
      id: 1124810690
    },
    {
      label: "Sarnia",
      value: "Sarnia",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.9994,
      lng: -82.3089,
      population: 71594,
      density: 434.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N7V N7W N7S N7T N7X",
      id: 1124509835
    },
    {
      label: "Wood Buffalo",
      value: "Wood Buffalo",
      province_id: "AB",
      province_name: "Alberta",
      lat: 57.6042,
      lng: -111.3284,
      population: 71589,
      density: 1.2,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T9H T9J T9K T0P",
      id: 1124001123
    },
    {
      label: "New Westminster",
      value: "New Westminster",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.2069,
      lng: -122.9111,
      population: 70996,
      density: 4543.4,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V3L V3M",
      id: 1124196524
    },
    {
      label: "Châteauguay",
      value: "Chateauguay",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.38,
      lng: -73.75,
      population: 70812,
      density: 1278.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J6K J6J",
      id: 1124437897
    },
    {
      label: "Saint-Jérôme",
      value: "Saint-Jerome",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7833,
      lng: -74,
      population: 69598,
      density: 756.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7Y J7Z J5L",
      id: 1124268324
    },
    {
      label: "Drummondville",
      value: "Drummondville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8833,
      lng: -72.4833,
      population: 68601,
      density: 1315.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J2E J2C J2B J2A J1Z",
      id: 1124624283
    },
    {
      label: "Saint John",
      value: "Saint John",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.2806,
      lng: -66.0761,
      population: 67575,
      density: 213.8,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E2P E2L E2M E2N E2H E2J E2K",
      id: 1124631364
    },
    {
      label: "Caledon",
      value: "Caledon",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.8667,
      lng: -79.8667,
      population: 66502,
      density: 96.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L7K L7C L7E",
      id: 1124070007
    },
    {
      label: "St. Albert",
      value: "St. Albert",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.6303,
      lng: -113.6258,
      population: 65589,
      density: 1353.9,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T8N",
      id: 1124850754
    },
    {
      label: "Granby",
      value: "Granby",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4,
      lng: -72.7333,
      population: 63433,
      density: 415.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J2G J2J J2H",
      id: 1124502071
    },
    {
      label: "Medicine Hat",
      value: "Medicine Hat",
      province_id: "AB",
      province_name: "Alberta",
      lat: 50.0417,
      lng: -110.6775,
      population: 63260,
      density: 564.6,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T1A T1B T1C",
      id: 1124303972
    },
    {
      label: "Grande Prairie",
      value: "Grande Prairie",
      province_id: "AB",
      province_name: "Alberta",
      lat: 55.1708,
      lng: -118.7947,
      population: 63166,
      density: 475.9,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T8V T8X T8W",
      id: 1124505481
    },
    {
      label: "St. Thomas",
      value: "St. Thomas",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.775,
      lng: -81.1833,
      population: 61707,
      density: 1067.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N5R N5P",
      id: 1124790209
    },
    {
      label: "Airdrie",
      value: "Airdrie",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.2917,
      lng: -114.0144,
      population: 61581,
      density: 728.2,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T4B T4A",
      id: 1124990202
    },
    {
      label: "Halton Hills",
      value: "Halton Hills",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.63,
      lng: -79.95,
      population: 61161,
      density: 221.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L7J L7G L0P L9T",
      id: 1124000788
    },
    {
      label: "Saint-Hyacinthe",
      value: "Saint-Hyacinthe",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6167,
      lng: -72.95,
      population: 59614,
      density: 294.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J2T J2S J2R",
      id: 1124010116
    },
    {
      label: "Lac-Brome",
      value: "Lac-Brome",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2167,
      lng: -72.5167,
      population: 58889,
      density: 27.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0E",
      id: 1124000579
    },
    {
      label: "Port Coquitlam",
      value: "Port Coquitlam",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.2625,
      lng: -122.7811,
      population: 58612,
      density: 2009.4,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V3B V3C",
      id: 1124473757
    },
    {
      label: "Fredericton",
      value: "Fredericton",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.9636,
      lng: -66.6431,
      population: 58220,
      density: 439.2,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E3G E3C E3B E3A",
      id: 1124061289
    },
    {
      label: "Blainville",
      value: "Blainville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.67,
      lng: -73.88,
      population: 56363,
      density: 1030.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7B J7C J7E",
      id: 1124000623
    },
    {
      label: "Aurora",
      value: "Aurora",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44,
      lng: -79.4667,
      population: 55445,
      density: 1112.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L4G",
      id: 1124085034
    },
    {
      label: "Welland",
      value: "Welland",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.9833,
      lng: -79.2333,
      population: 52293,
      density: 645.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L3B L3C",
      id: 1124745616
    },
    {
      label: "North Bay",
      value: "North Bay",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.3,
      lng: -79.45,
      population: 51553,
      density: 161.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P1A P1B P1C",
      id: 1124058496
    },
    {
      label: "Beloeil",
      value: "Beloeil",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5667,
      lng: -73.2,
      population: 50796,
      density: 862.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3G",
      id: 1124469084
    },
    {
      label: "Belleville",
      value: "Belleville",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.1667,
      lng: -77.3833,
      population: 50716,
      density: 205.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K8N K8P K0K",
      id: 1124786959
    },
    {
      label: "Mirabel",
      value: "Mirabel",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.65,
      lng: -74.0833,
      population: 50513,
      density: 104.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7J J7N",
      id: 1124182375
    },
    {
      label: "Shawinigan",
      value: "Shawinigan",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5667,
      lng: -72.75,
      population: 50060,
      density: 68.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G9N G9T G9R G9P G0X",
      id: 1124441118
    },
    {
      label: "Dollard-des-Ormeaux",
      value: "Dollard-des-Ormeaux",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4833,
      lng: -73.8167,
      population: 49637,
      density: 3286.7,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "H9A H9B H9G",
      id: 1124902278
    },
    {
      label: "Brandon",
      value: "Brandon",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.8483,
      lng: -99.95,
      population: 48859,
      density: 599.1,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R7A R7B R7C",
      id: 1124239939
    },
    {
      label: "Rimouski",
      value: "Rimouski",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.45,
      lng: -68.53,
      population: 48664,
      density: 143.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G5N G5M G5L G0L",
      id: 1124433645
    },
    {
      label: "Cornwall",
      value: "Cornwall",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.0275,
      lng: -74.74,
      population: 46589,
      density: 756.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K6J K6K K6H",
      id: 1124938303
    },
    {
      label: "Stouffville",
      value: "Stouffville",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.9667,
      lng: -79.25,
      population: 45837,
      density: 222.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0H L3Y L4A",
      id: 1124207594
    },
    {
      label: "Georgina",
      value: "Georgina",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.3,
      lng: -79.4333,
      population: 45418,
      density: 157.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0E L4P",
      id: 1124000048
    },
    {
      label: "Victoriaville",
      value: "Victoriaville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.05,
      lng: -71.9667,
      population: 45309,
      density: 516.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G6R G6P G6T",
      id: 1124149787
    },
    {
      label: "Vernon",
      value: "Vernon",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.267,
      lng: -119.272,
      population: 44600,
      density: 417.7,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V1T V1H",
      id: 1124553338
    },
    {
      label: "Duncan",
      value: "Duncan",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.7787,
      lng: -123.7079,
      population: 44451,
      density: 2387.1,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V9L",
      id: 1124316061
    },
    {
      label: "Saint-Eustache",
      value: "Saint-Eustache",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.57,
      lng: -73.9,
      population: 44154,
      density: 634.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7P J7R",
      id: 1124758162
    },
    {
      label: "Quinte West",
      value: "Quinte West",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.1833,
      lng: -77.5667,
      population: 43577,
      density: 88.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K8N K8R K8V K0K",
      id: 1124001037
    },
    {
      label: "Charlottetown",
      value: "Charlottetown",
      province_id: "PE",
      province_name: "Prince Edward Island",
      lat: 46.2403,
      lng: -63.1347,
      population: 42602,
      density: 779.7,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "C1C C1A C1E",
      id: 1124897699
    },
    {
      label: "Mascouche",
      value: "Mascouche",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.75,
      lng: -73.6,
      population: 42491,
      density: 398.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7K J7L",
      id: 1124001580
    },
    {
      label: "West Vancouver",
      value: "West Vancouver",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.3667,
      lng: -123.1667,
      population: 42473,
      density: 486.8,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V7T V7V V7S",
      id: 1124001824
    },
    {
      label: "Salaberry-de-Valleyfield",
      value: "Salaberry-de-Valleyfield",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.25,
      lng: -74.13,
      population: 42410,
      density: 395.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J6T J6S",
      id: 1124638758
    },
    {
      label: "Rouyn-Noranda",
      value: "Rouyn-Noranda",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.2333,
      lng: -79.0167,
      population: 42334,
      density: 7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J9X J9Y J0Y J0Z",
      id: 1124267752
    },
    {
      label: "Timmins",
      value: "Timmins",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.4667,
      lng: -81.3333,
      population: 41788,
      density: 14,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0N P4N P4R P4P",
      id: 1124760634
    },
    {
      label: "Sorel-Tracy",
      value: "Sorel-Tracy",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0333,
      lng: -73.1167,
      population: 41629,
      density: 241.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3P J3R",
      id: 1124000182
    },
    {
      label: "New Tecumseth",
      value: "New Tecumseth",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.0833,
      lng: -79.75,
      population: 41439,
      density: 151.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0L L0G L9R",
      id: 1124001571
    },
    {
      label: "Woodstock",
      value: "Woodstock",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.1306,
      lng: -80.7467,
      population: 40902,
      density: 835.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4S N4T N4V",
      id: 1124758374
    },
    {
      label: "Boucherville",
      value: "Boucherville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6,
      lng: -73.45,
      population: 40753,
      density: 575.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J4B",
      id: 1124000296
    },
    {
      label: "Mission",
      value: "Mission",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.1337,
      lng: -122.3112,
      population: 38833,
      density: 170.6,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V4S V2V",
      id: 1124502601
    },
    {
      label: "Vaudreuil-Dorion",
      value: "Vaudreuil-Dorion",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4,
      lng: -74.0333,
      population: 38117,
      density: 524.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7V",
      id: 1124618618
    },
    {
      label: "Brant",
      value: "Brant",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.1167,
      lng: -80.3667,
      population: 36707,
      density: 43.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N3L N3T N3W N0E",
      id: 1124000682
    },
    {
      label: "Lakeshore",
      value: "Lakeshore",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.2399,
      lng: -82.6511,
      population: 36611,
      density: 69,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N8M N8N N9K N0P N0R",
      id: 1124001501
    },
    {
      label: "Innisfil",
      value: "Innisfil",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.3,
      lng: -79.5833,
      population: 36566,
      density: 139.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0L L9S",
      id: 1124001408
    },
    {
      label: "Prince Albert",
      value: "Prince Albert",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.2,
      lng: -105.75,
      population: 35926,
      density: 534.4,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S6X S6V S6W",
      id: 1124158154
    },
    {
      label: "Langford Station",
      value: "Langford Station",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.4506,
      lng: -123.5058,
      population: 35342,
      density: 885,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V9B V9C",
      id: 1124095065
    },
    {
      label: "Bradford West Gwillimbury",
      value: "Bradford West Gwillimbury",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.1333,
      lng: -79.6333,
      population: 35325,
      density: 175.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0G L3Z",
      id: 1124001093
    },
    {
      label: "Campbell River",
      value: "Campbell River",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.0244,
      lng: -125.2475,
      population: 35138,
      density: 1143.9,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V9H V9W",
      id: 1124851971
    },
    {
      label: "Spruce Grove",
      value: "Spruce Grove",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.545,
      lng: -113.9008,
      population: 34066,
      density: 1057.9,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T7X",
      id: 1124943146
    },
    {
      label: "Moose Jaw",
      value: "Moose Jaw",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.3933,
      lng: -105.5519,
      population: 33890,
      density: 710.7,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S6K S6H",
      id: 1124806868
    },
    {
      label: "Penticton",
      value: "Penticton",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.4911,
      lng: -119.5886,
      population: 33761,
      density: 801.8,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V2A",
      id: 1124613898
    },
    {
      label: "Port Moody",
      value: "Port Moody",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.2831,
      lng: -122.8317,
      population: 33551,
      density: 1295.9,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V3H",
      id: 1124252668
    },
    {
      label: "Leamington",
      value: "Leamington",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.0667,
      lng: -82.5833,
      population: 32991,
      density: 105.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N8H N0P",
      id: 1124258797
    },
    {
      label: "East Kelowna",
      value: "East Kelowna",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.8625,
      lng: -119.5833,
      population: 32655,
      density: 264.4,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V1Z V4T",
      id: 1124070905
    },
    {
      label: "Côte-Saint-Luc",
      value: "Cote-Saint-Luc",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4687,
      lng: -73.6673,
      population: 32448,
      density: 4662.5,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "H4W H4V H4X",
      id: 1124563603
    },
    {
      label: "Val-d’Or",
      value: "Val-d'Or",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.1,
      lng: -77.7833,
      population: 31862,
      density: 9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J9P",
      id: 1124239138
    },
    {
      label: "Owen Sound",
      value: "Owen Sound",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.5667,
      lng: -80.9333,
      population: 31820,
      density: 1311.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4K",
      id: 1124623613
    },
    {
      label: "Stratford",
      value: "Stratford",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.3708,
      lng: -80.9819,
      population: 31465,
      density: 1167.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4Z N5A",
      id: 1124676255
    },
    {
      label: "Lloydminster",
      value: "Lloydminster",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.2783,
      lng: -110.005,
      population: 31410,
      density: 742.2,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "S9V",
      id: 1124051728
    },
    {
      label: "Pointe-Claire",
      value: "Pointe-Claire",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.45,
      lng: -73.8167,
      population: 31380,
      density: 1662,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "H9R H9S",
      id: 1124470650
    },
    {
      label: "Orillia",
      value: "Orillia",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.6,
      lng: -79.4167,
      population: 31166,
      density: 1090.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L3V",
      id: 1124049830
    },
    {
      label: "Alma",
      value: "Alma",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.55,
      lng: -71.65,
      population: 30904,
      density: 158,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G8E G8B G8C",
      id: 1124138438
    },
    {
      label: "Orangeville",
      value: "Orangeville",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.9167,
      lng: -80.1167,
      population: 30734,
      density: 69.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L9W",
      id: 1124566061
    },
    {
      label: "Fort Erie",
      value: "Fort Erie",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.9167,
      lng: -79.0167,
      population: 30710,
      density: 184.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0S L2A",
      id: 1124516852
    },
    {
      label: "LaSalle",
      value: "LaSalle",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.2167,
      lng: -83.0667,
      population: 30180,
      density: 461.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N9J N9H N9A",
      id: 1124000988
    },
    {
      label: "Sainte-Julie",
      value: "Sainte-Julie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5833,
      lng: -73.3333,
      population: 30104,
      density: 607.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3E",
      id: 1124000521
    },
    {
      label: "Leduc",
      value: "Leduc",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.2594,
      lng: -113.5492,
      population: 29993,
      density: 706.7,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T9E",
      id: 1124170853
    },
    {
      label: "North Cowichan",
      value: "North Cowichan",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.8236,
      lng: -123.7192,
      population: 29676,
      density: 147.3,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0R V9L",
      id: 1124000052
    },
    {
      label: "Chambly",
      value: "Chambly",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4311,
      lng: -73.2873,
      population: 29120,
      density: 1158.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3L",
      id: 1124345124
    },
    {
      label: "Okotoks",
      value: "Okotoks",
      province_id: "AB",
      province_name: "Alberta",
      lat: 50.725,
      lng: -113.975,
      population: 28881,
      density: 1471,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T1S",
      id: 1124521470
    },
    {
      label: "Sept-Îles",
      value: "Sept-Iles",
      province_id: "QC",
      province_name: "Quebec",
      lat: 50.2167,
      lng: -66.3833,
      population: 28534,
      density: 16.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G4S G0G",
      id: 1124406431
    },
    {
      label: "Centre Wellington",
      value: "Centre Wellington",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.7,
      lng: -80.3667,
      population: 28191,
      density: 69.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0B N1M",
      id: 1124000849
    },
    {
      label: "Saint-Constant",
      value: "Saint-Constant",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.37,
      lng: -73.57,
      population: 27359,
      density: 478.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J5A",
      id: 1124000054
    },
    {
      label: "Grimsby",
      value: "Grimsby",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.2,
      lng: -79.55,
      population: 27314,
      density: 396.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L3M",
      id: 1124989517
    },
    {
      label: "Boisbriand",
      value: "Boisbriand",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.62,
      lng: -73.83,
      population: 26884,
      density: 966.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7H J7E J7G",
      id: 1124001940
    },
    {
      label: "Conception Bay South",
      value: "Conception Bay South",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.5167,
      lng: -52.9833,
      population: 26199,
      density: 443.3,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A1W A1X",
      id: 1124000563
    },
    {
      label: "Saint-Bruno-de-Montarville",
      value: "Saint-Bruno-de-Montarville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5333,
      lng: -73.35,
      population: 26107,
      density: 603.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3V",
      id: 1124286783
    },
    {
      label: "Sainte-Thérèse",
      value: "Sainte-Therese",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6333,
      lng: -73.85,
      population: 26025,
      density: 2716.1,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "J7E",
      id: 1124190411
    },
    {
      label: "Cochrane",
      value: "Cochrane",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.189,
      lng: -114.467,
      population: 25853,
      density: 866.7,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T4C",
      id: 1124952542
    },
    {
      label: "Thetford Mines",
      value: "Thetford Mines",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1,
      lng: -71.3,
      population: 25709,
      density: 113.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G6H G6G",
      id: 1124032181
    },
    {
      label: "Courtenay",
      value: "Courtenay",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.6878,
      lng: -124.9944,
      population: 25599,
      density: 789.9,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V9N",
      id: 1124324905
    },
    {
      label: "Magog",
      value: "Magog",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2667,
      lng: -72.15,
      population: 25358,
      density: 175.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J1X",
      id: 1124404849
    },
    {
      label: "Whitehorse",
      value: "Whitehorse",
      province_id: "YT",
      province_name: "Yukon",
      lat: 60.7029,
      lng: -135.0691,
      population: 25085,
      density: 60.2,
      timezone: "America/Whitehorse",
      ranking: 3,
      postal: "Y1A",
      id: 1124348186
    },
    {
      label: "Woolwich",
      value: "Woolwich",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.5667,
      lng: -80.4833,
      population: 25006,
      density: 76.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N2J N3B N0B",
      id: 1124000096
    },
    {
      label: "Clarence-Rockland",
      value: "Clarence-Rockland",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.4833,
      lng: -75.2,
      population: 24512,
      density: 82.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K4K K0A",
      id: 1124000639
    },
    {
      label: "Fort Saskatchewan",
      value: "Fort Saskatchewan",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.7128,
      lng: -113.2133,
      population: 24149,
      density: 501.3,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T8L",
      id: 1124769097
    },
    {
      label: "East Gwillimbury",
      value: "East Gwillimbury",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.1333,
      lng: -79.4167,
      population: 23991,
      density: 97.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0G L9N",
      id: 1124001370
    },
    {
      label: "Lincoln",
      value: "Lincoln",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.13,
      lng: -79.43,
      population: 23787,
      density: 146.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0R L2R",
      id: 1124001767
    },
    {
      label: "La Prairie",
      value: "La Prairie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.42,
      lng: -73.5,
      population: 23357,
      density: 539.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J5R",
      id: 1124956496
    },
    {
      label: "Tecumseh",
      value: "Tecumseh",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.2431,
      lng: -82.9256,
      population: 23229,
      density: 245.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N8N N9K N0R",
      id: 1124720869
    },
    {
      label: "Mount Pearl Park",
      value: "Mount Pearl Park",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.5189,
      lng: -52.8058,
      population: 22957,
      density: 1456.8,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A1N",
      id: 1124869949
    },
    {
      label: "Amherstburg",
      value: "Amherstburg",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.1,
      lng: -83.0833,
      population: 21936,
      density: 118.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N9V N0R",
      id: 1124696938
    },
    {
      label: "Saint-Lambert",
      value: "Saint-Lambert",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5,
      lng: -73.5167,
      population: 21861,
      density: 2880.6,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "J4P J4S J4R",
      id: 1124174363
    },
    {
      label: "Brockville",
      value: "Brockville",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.5833,
      lng: -75.6833,
      population: 21854,
      density: 1167.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K6V",
      id: 1124286131
    },
    {
      label: "Collingwood",
      value: "Collingwood",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.5,
      lng: -80.2167,
      population: 21793,
      density: 645.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L9Y",
      id: 1124219884
    },
    {
      label: "Scugog",
      value: "Scugog",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.09,
      lng: -78.936,
      population: 21617,
      density: 45.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0C L0B L9L",
      id: 1124000741
    },
    {
      label: "Kingsville",
      value: "Kingsville",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.1,
      lng: -82.7167,
      population: 21552,
      density: 87.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N8M N9Y N0P N0R",
      id: 1124616034
    },
    {
      label: "Baie-Comeau",
      value: "Baie-Comeau",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.2167,
      lng: -68.15,
      population: 21536,
      density: 64,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G5C G4Z",
      id: 1124859576
    },
    {
      label: "Paradise",
      value: "Paradise",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.5333,
      lng: -52.8667,
      population: 21389,
      density: 731.5,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A1L",
      id: 1124001159
    },
    {
      label: "Uxbridge",
      value: "Uxbridge",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.1167,
      lng: -79.1333,
      population: 21176,
      density: 45.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0E L0C L9P L4A",
      id: 1124829638
    },
    {
      label: "Essa",
      value: "Essa",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.25,
      lng: -79.7833,
      population: 21083,
      density: 75.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0M L0L L9R",
      id: 1124001569
    },
    {
      label: "Candiac",
      value: "Candiac",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.38,
      lng: -73.52,
      population: 21047,
      density: 1215.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J5R",
      id: 1124457982
    },
    {
      label: "Oro-Medonte",
      value: "Oro-Medonte",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.5667,
      lng: -79.5833,
      population: 21036,
      density: 35.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0L L0K L3V L4R L4M",
      id: 1124001350
    },
    {
      label: "Varennes",
      value: "Varennes",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6833,
      lng: -73.4333,
      population: 20994,
      density: 226.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3X",
      id: 1124232101
    },
    {
      label: "Strathroy-Caradoc",
      value: "Strathroy-Caradoc",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.9575,
      lng: -81.6167,
      population: 20867,
      density: 77.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0L N7G",
      id: 1124000831
    },
    {
      label: "Wasaga Beach",
      value: "Wasaga Beach",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.5206,
      lng: -80.0167,
      population: 20675,
      density: 352.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L9Z",
      id: 1124001919
    },
    {
      label: "New Glasgow",
      value: "New Glasgow",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.5926,
      lng: -62.6455,
      population: 20609,
      density: 911.6,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B2H",
      id: 1124760188
    },
    {
      label: "Wilmot",
      value: "Wilmot",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.4,
      lng: -80.65,
      population: 20545,
      density: 77.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N3A N0B",
      id: 1124001797
    },
    {
      label: "Essex",
      value: "Essex",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.0833,
      lng: -82.9,
      population: 20427,
      density: 73.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N8M N0R",
      id: 1124628052
    },
    {
      label: "Fort St. John",
      value: "Fort St. John",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 56.2465,
      lng: -120.8476,
      population: 20155,
      density: 820.2,
      timezone: "America/Dawson_Creek",
      ranking: 3,
      postal: "V1J",
      id: 1124517495
    },
    {
      label: "Kirkland",
      value: "Kirkland",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.45,
      lng: -73.8667,
      population: 20151,
      density: 2204.4,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "H9H H9J",
      id: 1124000941
    },
    {
      label: "L’Assomption",
      value: "L'Assomption",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8333,
      lng: -73.4167,
      population: 20065,
      density: 202.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J5W",
      id: 1124500862
    },
    {
      label: "Westmount",
      value: "Westmount",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4833,
      lng: -73.6,
      population: 19931,
      density: 4952.8,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "H3Z H3Y",
      id: 1124878078
    },
    {
      label: "Saint-Lazare",
      value: "Saint-Lazare",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4,
      lng: -74.1333,
      population: 19889,
      density: 289.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7T",
      id: 1124000613
    },
    {
      label: "Chestermere",
      value: "Chestermere",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.05,
      lng: -113.8225,
      population: 19887,
      density: 603.8,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T1X",
      id: 1124000371
    },
    {
      label: "Huntsville",
      value: "Huntsville",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.3333,
      lng: -79.2167,
      population: 19816,
      density: 27.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0B P1H",
      id: 1124961145
    },
    {
      label: "Corner Brook",
      value: "Corner Brook",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.9287,
      lng: -57.926,
      population: 19806,
      density: 133.6,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A2H",
      id: 1124244792
    },
    {
      label: "Riverview",
      value: "Riverview",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.0613,
      lng: -64.8052,
      population: 19667,
      density: 554.8,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E1B",
      id: 1124000112
    },
    {
      label: "Lloydminster",
      value: "Lloydminster",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.2807,
      lng: -110.035,
      population: 19645,
      density: 817.3,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T9V",
      id: 1124000858
    },
    {
      label: "Joliette",
      value: "Joliette",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0167,
      lng: -73.45,
      population: 19621,
      density: 860.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J6E",
      id: 1124841554
    },
    {
      label: "Yellowknife",
      value: "Yellowknife",
      province_id: "NT",
      province_name: "Northwest Territories",
      lat: 62.4709,
      lng: -114.4053,
      population: 19569,
      density: 185.5,
      timezone: "America/Yellowknife",
      ranking: 3,
      postal: "X1A",
      id: 1124208917
    },
    {
      label: "Squamish",
      value: "Squamish",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.7017,
      lng: -123.1589,
      population: 19512,
      density: 186.1,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0N V8B",
      id: 1124005958
    },
    {
      label: "Mont-Royal",
      value: "Mont-Royal",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5161,
      lng: -73.6431,
      population: 19503,
      density: 2545.3,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "H3R H3P H4P",
      id: 1124001920
    },
    {
      label: "Rivière-du-Loup",
      value: "Riviere-du-Loup",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.8333,
      lng: -69.5333,
      population: 19447,
      density: 230.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G5R",
      id: 1124662123
    },
    {
      label: "Cobourg",
      value: "Cobourg",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.9667,
      lng: -78.1667,
      population: 19440,
      density: 869.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K9A",
      id: 1124831257
    },
    {
      label: "Cranbrook",
      value: "Cranbrook",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.5097,
      lng: -115.7667,
      population: 19259,
      density: 604.7,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "V1C",
      id: 1124937794
    },
    {
      label: "Beaconsfield",
      value: "Beaconsfield",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4333,
      lng: -73.8667,
      population: 19115,
      density: 1752.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "H9W",
      id: 1124755118
    },
    {
      label: "Springwater",
      value: "Springwater",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.4333,
      lng: -79.7333,
      population: 19059,
      density: 35.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0L L4M",
      id: 1124001298
    },
    {
      label: "Dorval",
      value: "Dorval",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.45,
      lng: -73.75,
      population: 18980,
      density: 839.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "H9P H9S",
      id: 1124933556
    },
    {
      label: "Thorold",
      value: "Thorold",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.1167,
      lng: -79.2,
      population: 18801,
      density: 226.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0S L2V L3B",
      id: 1124718251
    },
    {
      label: "Camrose",
      value: "Camrose",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.0167,
      lng: -112.8333,
      population: 18742,
      density: 439.8,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T4V",
      id: 1124351657
    },
    {
      label: "South Frontenac",
      value: "South Frontenac",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.5081,
      lng: -76.4939,
      population: 18646,
      density: 19.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0H",
      id: 1124000063
    },
    {
      label: "Pitt Meadows",
      value: "Pitt Meadows",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.2333,
      lng: -122.6833,
      population: 18573,
      density: 214.7,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V3Y",
      id: 1124786902
    },
    {
      label: "Port Colborne",
      value: "Port Colborne",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.8833,
      lng: -79.25,
      population: 18306,
      density: 150.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0S L3K",
      id: 1124274319
    },
    {
      label: "Quispamsis",
      value: "Quispamsis",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.4322,
      lng: -65.9462,
      population: 18245,
      density: 318.9,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E2S E2E E2G",
      id: 1124000379
    },
    {
      label: "Mont-Saint-Hilaire",
      value: "Mont-Saint-Hilaire",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5622,
      lng: -73.1917,
      population: 18200,
      density: 410.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3H",
      id: 1124333461
    },
    {
      label: "Bathurst",
      value: "Bathurst",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.62,
      lng: -65.65,
      population: 18154,
      density: 129.3,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E2A",
      id: 1124816720
    },
    {
      label: "Saint-Augustin-de-Desmaures",
      value: "Saint-Augustin-de-Desmaures",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.7333,
      lng: -71.4667,
      population: 18141,
      density: 211.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G3A",
      id: 1124000358
    },
    {
      label: "Oak Bay",
      value: "Oak Bay",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.4264,
      lng: -123.3228,
      population: 18094,
      density: 1717.7,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V8P V8S V8R",
      id: 1124761730
    },
    {
      label: "Sainte-Marthe-sur-le-Lac",
      value: "Sainte-Marthe-sur-le-Lac",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.53,
      lng: -73.93,
      population: 18074,
      density: 2066.7,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "J0N",
      id: 1124001153
    },
    {
      label: "Salmon Arm",
      value: "Salmon Arm",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.7022,
      lng: -119.2722,
      population: 17706,
      density: 144,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0E V1E",
      id: 1124478865
    },
    {
      label: "Port Alberni",
      value: "Port Alberni",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.2339,
      lng: -124.805,
      population: 17678,
      density: 894.6,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V9Y",
      id: 1124952808
    },
    {
      label: "Esquimalt",
      value: "Esquimalt",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.4306,
      lng: -123.4147,
      population: 17655,
      density: 2494.7,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V9A",
      id: 1124990218
    },
    {
      label: "Deux-Montagnes",
      value: "Deux-Montagnes",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5333,
      lng: -73.8833,
      population: 17553,
      density: 2850.1,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "J7R",
      id: 1124980214
    },
    {
      label: "Miramichi",
      value: "Miramichi",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.0196,
      lng: -65.5072,
      population: 17537,
      density: 97.7,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E1V E1N",
      id: 1124714190
    },
    {
      label: "Niagara-on-the-Lake",
      value: "Niagara-on-the-Lake",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.2553,
      lng: -79.0717,
      population: 17511,
      density: 131.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0S",
      id: 1124366228
    },
    {
      label: "Saint-Lin--Laurentides",
      value: "Saint-Lin--Laurentides",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.85,
      lng: -73.7667,
      population: 17463,
      density: 147.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J5M",
      id: 1124906585
    },
    {
      label: "Beaumont",
      value: "Beaumont",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.3572,
      lng: -113.4147,
      population: 17396,
      density: 1661.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T4X",
      id: 1124001210
    },
    {
      label: "Middlesex Centre",
      value: "Middlesex Centre",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.05,
      lng: -81.45,
      population: 17262,
      density: 29.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N6P N6H N0M N0L",
      id: 1124001841
    },
    {
      label: "Inverness",
      value: "Inverness",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 46.2,
      lng: -61.1,
      population: 17235,
      density: 4.5,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0E",
      id: 1124840965
    },
    {
      label: "Stony Plain",
      value: "Stony Plain",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.5264,
      lng: -114.0069,
      population: 17189,
      density: 481.2,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T7Z",
      id: 1124982081
    },
    {
      label: "Petawawa",
      value: "Petawawa",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.9,
      lng: -77.2833,
      population: 17187,
      density: 103.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K8H",
      id: 1124206291
    },
    {
      label: "Pelham",
      value: "Pelham",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.0333,
      lng: -79.3333,
      population: 17110,
      density: 135.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0S",
      id: 1124000042
    },
    {
      label: "Selwyn",
      value: "Selwyn",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.4167,
      lng: -78.3333,
      population: 17060,
      density: 54,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K9J K9L K0L",
      id: 1124000937
    },
    {
      label: "Loyalist",
      value: "Loyalist",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.25,
      lng: -76.75,
      population: 16971,
      density: 49.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7N K7R K0H",
      id: 1124001145
    },
    {
      label: "Midland",
      value: "Midland",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.75,
      lng: -79.8833,
      population: 16864,
      density: 477.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L4R",
      id: 1124104490
    },
    {
      label: "Colwood",
      value: "Colwood",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.4236,
      lng: -123.4958,
      population: 16859,
      density: 954.2,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V9B V9C",
      id: 1124000395
    },
    {
      label: "Central Saanich",
      value: "Central Saanich",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.5142,
      lng: -123.3839,
      population: 16814,
      density: 406.8,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V8M",
      id: 1124000519
    },
    {
      label: "Sainte-Catherine",
      value: "Sainte-Catherine",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4,
      lng: -73.58,
      population: 16762,
      density: 1764.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J5C",
      id: 1124941451
    },
    {
      label: "Port Hope",
      value: "Port Hope",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.95,
      lng: -78.3,
      population: 16753,
      density: 60.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0A L1A",
      id: 1124105292
    },
    {
      label: "L’Ancienne-Lorette",
      value: "L'Ancienne-Lorette",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8,
      lng: -71.35,
      population: 16745,
      density: 2193.6,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "G2E",
      id: 1124580674
    },
    {
      label: "Saint-Basile-le-Grand",
      value: "Saint-Basile-le-Grand",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5333,
      lng: -73.2833,
      population: 16736,
      density: 463.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3N",
      id: 1124000968
    },
    {
      label: "Swift Current",
      value: "Swift Current",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.2881,
      lng: -107.7939,
      population: 16604,
      density: 566.5,
      timezone: "America/Swift_Current",
      ranking: 3,
      postal: "S9H",
      id: 1124460875
    },
    {
      label: "Edmundston",
      value: "Edmundston",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.3765,
      lng: -68.3253,
      population: 16580,
      density: 155.2,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E7C E7B E3V",
      id: 1124274233
    },
    {
      label: "Russell",
      value: "Russell",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.2569,
      lng: -75.3583,
      population: 16520,
      density: 83,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K4R K0A",
      id: 1124982538
    },
    {
      label: "North Grenville",
      value: "North Grenville",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.9667,
      lng: -75.65,
      population: 16451,
      density: 46.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0G",
      id: 1124000746
    },
    {
      label: "Yorkton",
      value: "Yorkton",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.2139,
      lng: -102.4628,
      population: 16343,
      density: 449.9,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S3N",
      id: 1124108820
    },
    {
      label: "Tracadie",
      value: "Tracadie",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.5124,
      lng: -64.9101,
      population: 16114,
      density: 31.2,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E1X E9H",
      id: 1124362021
    },
    {
      label: "Bracebridge",
      value: "Bracebridge",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.0333,
      lng: -79.3,
      population: 16010,
      density: 25.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P1L P1P",
      id: 1124793645
    },
    {
      label: "Greater Napanee",
      value: "Greater Napanee",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.25,
      lng: -76.95,
      population: 15892,
      density: 34.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7R K0H K0K",
      id: 1124001319
    },
    {
      label: "Tillsonburg",
      value: "Tillsonburg",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.8667,
      lng: -80.7333,
      population: 15872,
      density: 710.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4G",
      id: 1124817746
    },
    {
      label: "Steinbach",
      value: "Steinbach",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.5258,
      lng: -96.6839,
      population: 15829,
      density: 618.6,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R5G",
      id: 1124152692
    },
    {
      label: "Hanover",
      value: "Hanover",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.4433,
      lng: -96.8492,
      population: 15733,
      density: 21.2,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R5G R0A",
      id: 1124001704
    },
    {
      label: "Terrace",
      value: "Terrace",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 54.5164,
      lng: -128.5997,
      population: 15723,
      density: 212.7,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V8G",
      id: 1124878479
    },
    {
      label: "Springfield",
      value: "Springfield",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.9292,
      lng: -96.6939,
      population: 15342,
      density: 13.9,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R2C R0E",
      id: 1124000696
    },
    {
      label: "Gaspé",
      value: "Gaspe",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.8333,
      lng: -64.4833,
      population: 15163,
      density: 13.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G4X",
      id: 1124212754
    },
    {
      label: "Kenora",
      value: "Kenora",
      province_id: "ON",
      province_name: "Ontario",
      lat: 49.7667,
      lng: -94.4833,
      population: 15096,
      density: 71.3,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "P9N",
      id: 1124844807
    },
    {
      label: "Cold Lake",
      value: "Cold Lake",
      province_id: "AB",
      province_name: "Alberta",
      lat: 54.4642,
      lng: -110.1825,
      population: 14961,
      density: 249.7,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T9M",
      id: 1124089461
    },
    {
      label: "Summerside",
      value: "Summerside",
      province_id: "PE",
      province_name: "Prince Edward Island",
      lat: 46.4,
      lng: -63.7833,
      population: 14829,
      density: 520.5,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "C1N C0B",
      id: 1124487102
    },
    {
      label: "Comox",
      value: "Comox",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.6733,
      lng: -124.9022,
      population: 14828,
      density: 838.2,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0R V9M",
      id: 1124788300
    },
    {
      label: "Sylvan Lake",
      value: "Sylvan Lake",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.3083,
      lng: -114.0964,
      population: 14816,
      density: 634.2,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T4S",
      id: 1124397940
    },
    {
      label: "Pincourt",
      value: "Pincourt",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3833,
      lng: -73.9833,
      population: 14558,
      density: 2048.1,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "J7W",
      id: 1124637966
    },
    {
      label: "West Lincoln",
      value: "West Lincoln",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.0667,
      lng: -79.5667,
      population: 14500,
      density: 37.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0R",
      id: 1124001460
    },
    {
      label: "Matane",
      value: "Matane",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.85,
      lng: -67.5333,
      population: 14462,
      density: 74,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G4W",
      id: 1124528318
    },
    {
      label: "Brooks",
      value: "Brooks",
      province_id: "AB",
      province_name: "Alberta",
      lat: 50.5642,
      lng: -111.8989,
      population: 14451,
      density: 777.3,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T1R",
      id: 1124093123
    },
    {
      label: "Sainte-Anne-des-Plaines",
      value: "Sainte-Anne-des-Plaines",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7617,
      lng: -73.8204,
      population: 14421,
      density: 154.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0N",
      id: 1124304532
    },
    {
      label: "West Nipissing / Nipissing Ouest",
      value: "West Nipissing / Nipissing Ouest",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.3667,
      lng: -79.9167,
      population: 14364,
      density: 7.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0H P1B P2B",
      id: 1124000026
    },
    {
      label: "Rosemère",
      value: "Rosemere",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6369,
      lng: -73.8,
      population: 14294,
      density: 1326.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7A",
      id: 1124741055
    },
    {
      label: "Mistassini",
      value: "Mistassini",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.8229,
      lng: -72.2154,
      population: 14250,
      density: 48.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G8L",
      id: 1124980171
    },
    {
      label: "Grand Falls",
      value: "Grand Falls",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.9578,
      lng: -55.6633,
      population: 14171,
      density: 259.2,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A2A A2B",
      id: 1124068277
    },
    {
      label: "Clearview",
      value: "Clearview",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.3981,
      lng: -80.0742,
      population: 14151,
      density: 25.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0M L9Y",
      id: 1124000053
    },
    {
      label: "St. Clair",
      value: "St. Clair",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.7833,
      lng: -82.35,
      population: 14086,
      density: 22.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N8A N0N N0P",
      id: 1124000228
    },
    {
      label: "Canmore",
      value: "Canmore",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.089,
      lng: -115.359,
      population: 13992,
      density: 201.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T1W",
      id: 1124688642
    },
    {
      label: "North Battleford",
      value: "North Battleford",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.7575,
      lng: -108.2861,
      population: 13888,
      density: 414,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S9A",
      id: 1124789635
    },
    {
      label: "Pembroke",
      value: "Pembroke",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.8167,
      lng: -77.1,
      population: 13882,
      density: 953.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K8A",
      id: 1124877940
    },
    {
      label: "Mont-Laurier",
      value: "Mont-Laurier",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.55,
      lng: -75.5,
      population: 13779,
      density: 23.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J9L",
      id: 1124355399
    },
    {
      label: "Strathmore",
      value: "Strathmore",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.0378,
      lng: -113.4003,
      population: 13756,
      density: 502,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T1P",
      id: 1124000881
    },
    {
      label: "Saugeen Shores",
      value: "Saugeen Shores",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.4333,
      lng: -81.3667,
      population: 13715,
      density: 80.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0H",
      id: 1124000488
    },
    {
      label: "Thompson",
      value: "Thompson",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 55.7433,
      lng: -97.8553,
      population: 13678,
      density: 657.91,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R8N",
      id: 1124110693
    },
    {
      label: "Lavaltrie",
      value: "Lavaltrie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8833,
      lng: -73.2833,
      population: 13657,
      density: 199.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J5T",
      id: 1124818327
    },
    {
      label: "High River",
      value: "High River",
      province_id: "AB",
      province_name: "Alberta",
      lat: 50.5808,
      lng: -113.8744,
      population: 13584,
      density: 635.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T1V",
      id: 1124607825
    },
    {
      label: "Severn",
      value: "Severn",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.75,
      lng: -79.5167,
      population: 13477,
      density: 24.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0K L3V",
      id: 1124489890
    },
    {
      label: "Sainte-Sophie",
      value: "Sainte-Sophie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.82,
      lng: -73.9,
      population: 13375,
      density: 120.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J5J",
      id: 1124001574
    },
    {
      label: "Saint-Charles-Borromée",
      value: "Saint-Charles-Borromee",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.05,
      lng: -73.4667,
      population: 13321,
      density: 715,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J6E",
      id: 1124000877
    },
    {
      label: "Portage La Prairie",
      value: "Portage La Prairie",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.9728,
      lng: -98.2919,
      population: 13304,
      density: 539.1,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R1N",
      id: 1124282782
    },
    {
      label: "Thames Centre",
      value: "Thames Centre",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.03,
      lng: -81.08,
      population: 13191,
      density: 30.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0M N0L N6M",
      id: 1124000993
    },
    {
      label: "Mississippi Mills",
      value: "Mississippi Mills",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.2167,
      lng: -76.2,
      population: 13163,
      density: 25.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7C K0A",
      id: 1124001617
    },
    {
      label: "Powell River",
      value: "Powell River",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.8353,
      lng: -124.5247,
      population: 13157,
      density: 444.1,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V8A",
      id: 1124154376
    },
    {
      label: "South Glengarry",
      value: "South Glengarry",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.2,
      lng: -74.5833,
      population: 13150,
      density: 21.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K6H K0C",
      id: 1124001506
    },
    {
      label: "North Perth",
      value: "North Perth",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.73,
      lng: -80.95,
      population: 13130,
      density: 26.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4W N0K N0G",
      id: 1124000749
    },
    {
      label: "Mercier",
      value: "Mercier",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.32,
      lng: -73.75,
      population: 13115,
      density: 284.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J6R",
      id: 1124186621
    },
    {
      label: "South Stormont",
      value: "South Stormont",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.0833,
      lng: -74.9667,
      population: 13110,
      density: 28.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0C",
      id: 1124001793
    },
    {
      label: "Saint-Colomban",
      value: "Saint-Colomban",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.73,
      lng: -74.13,
      population: 13080,
      density: 139.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J5K",
      id: 1124001676
    },
    {
      label: "Lacombe",
      value: "Lacombe",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.4683,
      lng: -113.7369,
      population: 13057,
      density: 627.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T4L",
      id: 1124057569
    },
    {
      label: "Sooke",
      value: "Sooke",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.3761,
      lng: -123.7378,
      population: 13001,
      density: 229.6,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V9Z",
      id: 1124034713
    },
    {
      label: "Dawson Creek",
      value: "Dawson Creek",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 55.7606,
      lng: -120.2356,
      population: 12978,
      density: 475.4,
      timezone: "America/Dawson_Creek",
      ranking: 3,
      postal: "V1G",
      id: 1124081402
    },
    {
      label: "Lake Country",
      value: "Lake Country",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.0833,
      lng: -119.4142,
      population: 12922,
      density: 105.8,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V4V",
      id: 1124001544
    },
    {
      label: "Trent Hills",
      value: "Trent Hills",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.3142,
      lng: -77.8514,
      population: 12900,
      density: 25.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0K K0L",
      id: 1124001755
    },
    {
      label: "Sainte-Marie",
      value: "Sainte-Marie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.45,
      lng: -71.0333,
      population: 12889,
      density: 120.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G6E",
      id: 1124650507
    },
    {
      label: "Guelph/Eramosa",
      value: "Guelph/Eramosa",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.63,
      lng: -80.22,
      population: 12854,
      density: 44.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0B N1H",
      id: 1124001707
    },
    {
      label: "Truro",
      value: "Truro",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.3647,
      lng: -63.28,
      population: 12826,
      density: 355.5,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B2N",
      id: 1124952899
    },
    {
      label: "Amos",
      value: "Amos",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.5667,
      lng: -78.1167,
      population: 12823,
      density: 29.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J9T",
      id: 1124939649
    },
    {
      label: "The Nation / La Nation",
      value: "The Nation / La Nation",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.35,
      lng: -75.0333,
      population: 12808,
      density: 19.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0A K0B K0C",
      id: 1124001243
    },
    {
      label: "Ingersoll",
      value: "Ingersoll",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.0392,
      lng: -80.8836,
      population: 12757,
      density: 1000.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N5C",
      id: 1124716784
    },
    {
      label: "Winkler",
      value: "Winkler",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1817,
      lng: -97.9397,
      population: 12660,
      density: 740.5,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R6W",
      id: 1124205424
    },
    {
      label: "Wetaskiwin",
      value: "Wetaskiwin",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.9694,
      lng: -113.3769,
      population: 12655,
      density: 691.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T9A",
      id: 1124492484
    },
    {
      label: "Central Elgin",
      value: "Central Elgin",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.7667,
      lng: -81.1,
      population: 12607,
      density: 45,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N5R N5P N5L N0L",
      id: 1124000475
    },
    {
      label: "Lachute",
      value: "Lachute",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.65,
      lng: -74.3333,
      population: 12551,
      density: 114.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J8H",
      id: 1124217062
    },
    {
      label: "West Grey",
      value: "West Grey",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.1833,
      lng: -80.8167,
      population: 12518,
      density: 14.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4N N0G N0C",
      id: 1124000272
    },
    {
      label: "Parksville",
      value: "Parksville",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.315,
      lng: -124.312,
      population: 12514,
      density: 854.6,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V9P",
      id: 1124698963
    },
    {
      label: "Cowansville",
      value: "Cowansville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2,
      lng: -72.75,
      population: 12489,
      density: 271,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J2K",
      id: 1124357421
    },
    {
      label: "Bécancour",
      value: "Becancour",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3333,
      lng: -72.4333,
      population: 12438,
      density: 28.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G9H",
      id: 1124242297
    },
    {
      label: "Gravenhurst",
      value: "Gravenhurst",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.9167,
      lng: -79.3667,
      population: 12311,
      density: 23.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0E P1P",
      id: 1124842372
    },
    {
      label: "Perth East",
      value: "Perth East",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.47,
      lng: -80.95,
      population: 12261,
      density: 17.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4W N5A N3A N0K N0B",
      id: 1124001760
    },
    {
      label: "Prince Rupert",
      value: "Prince Rupert",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 54.3122,
      lng: -130.3271,
      population: 12220,
      density: 227.7,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V8J",
      id: 1124847707
    },
    {
      label: "Prévost",
      value: "Prevost",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.87,
      lng: -74.08,
      population: 12171,
      density: 347.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0R",
      id: 1124001584
    },
    {
      label: "Sainte-Adèle",
      value: "Sainte-Adele",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.95,
      lng: -74.13,
      population: 12137,
      density: 100.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J8B",
      id: 1124439200
    },
    {
      label: "Kentville",
      value: "Kentville",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.0775,
      lng: -64.4958,
      population: 12088,
      density: 363.3,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B4N",
      id: 1124530137
    },
    {
      label: "Beauharnois",
      value: "Beauharnois",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.32,
      lng: -73.87,
      population: 12011,
      density: 173.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J6N",
      id: 1124880971
    },
    {
      label: "Les Îles-de-la-Madeleine",
      value: "Les Iles-de-la-Madeleine",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.3833,
      lng: -61.8667,
      population: 12010,
      density: 69.5,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "G4T",
      id: 1124000721
    },
    {
      label: "Wellington North",
      value: "Wellington North",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.9,
      lng: -80.57,
      population: 11914,
      density: 22.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0G",
      id: 1124001997
    },
    {
      label: "St. Andrews",
      value: "St. Andrews",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.27,
      lng: -96.9747,
      population: 11913,
      density: 15.8,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0C R1A",
      id: 1124001672
    },
    {
      label: "Carleton Place",
      value: "Carleton Place",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.1333,
      lng: -76.1333,
      population: 11901,
      density: 1176.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7C",
      id: 1124676010
    },
    {
      label: "Whistler",
      value: "Whistler",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.1208,
      lng: -122.9544,
      population: 11854,
      density: 49.3,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0N",
      id: 1124001562
    },
    {
      label: "Brighton",
      value: "Brighton",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.1222,
      lng: -77.7642,
      population: 11844,
      density: 43.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0K",
      id: 1124672085
    },
    {
      label: "Tiny",
      value: "Tiny",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.6833,
      lng: -79.95,
      population: 11787,
      density: 35,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0L L9M",
      id: 1124000103
    },
    {
      label: "Gander",
      value: "Gander",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.9569,
      lng: -54.6089,
      population: 11688,
      density: 112.1,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A1V",
      id: 1124310517
    },
    {
      label: "Sidney",
      value: "Sidney",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.6506,
      lng: -123.3986,
      population: 11672,
      density: 2290.7,
      timezone: "America/Vancouver",
      ranking: 2,
      postal: "V8L",
      id: 1124421362
    },
    {
      label: "Rothesay",
      value: "Rothesay",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.3831,
      lng: -65.9969,
      population: 11659,
      density: 335.8,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E2S E2E E2H",
      id: 1124211328
    },
    {
      label: "Brock",
      value: "Brock",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.3167,
      lng: -79.0833,
      population: 11642,
      density: 27.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0K L0E L0C",
      id: 1124001106
    },
    {
      label: "Summerland",
      value: "Summerland",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.6006,
      lng: -119.6778,
      population: 11615,
      density: 156.8,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0H",
      id: 1124400731
    },
    {
      label: "Val-des-Monts",
      value: "Val-des-Monts",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.65,
      lng: -75.6667,
      population: 11582,
      density: 26.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J8N",
      id: 1124001051
    },
    {
      label: "Taché",
      value: "Tache",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.7081,
      lng: -96.6736,
      population: 11568,
      density: 19.9,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R5H R0A R0E",
      id: 1124000169
    },
    {
      label: "Montmagny",
      value: "Montmagny",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.9833,
      lng: -70.55,
      population: 11491,
      density: 91.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G5V",
      id: 1124025705
    },
    {
      label: "Erin",
      value: "Erin",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.7667,
      lng: -80.0667,
      population: 11439,
      density: 38.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L7J L0N N0B",
      id: 1124418313
    },
    {
      label: "Kincardine",
      value: "Kincardine",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.1667,
      lng: -81.6333,
      population: 11389,
      density: 21.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N2Z N0G",
      id: 1124781881
    },
    {
      label: "North Dundas",
      value: "North Dundas",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.0833,
      lng: -75.35,
      population: 11278,
      density: 22.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0C K0E",
      id: 1124000474
    },
    {
      label: "Wellesley",
      value: "Wellesley",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.55,
      lng: -80.7167,
      population: 11260,
      density: 40.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0B",
      id: 1124590159
    },
    {
      label: "Estevan",
      value: "Estevan",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 49.1392,
      lng: -102.9861,
      population: 11258,
      density: 586.6,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S4A",
      id: 1124416742
    },
    {
      label: "North Saanich",
      value: "North Saanich",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.6142,
      lng: -123.42,
      population: 11249,
      density: 301.8,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V8L",
      id: 1124000779
    },
    {
      label: "Warman",
      value: "Warman",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.3219,
      lng: -106.5842,
      population: 11020,
      density: 844.6,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0K",
      id: 1124688931
    },
    {
      label: "La Tuque",
      value: "La Tuque",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.0652,
      lng: -74.0528,
      population: 11001,
      density: 0.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G9X G0X",
      id: 1124000430
    },
    {
      label: "Norwich",
      value: "Norwich",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.9833,
      lng: -80.6,
      population: 11001,
      density: 25.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4S N4G N0J",
      id: 1124219807
    },
    {
      label: "Meaford",
      value: "Meaford",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.58,
      lng: -80.73,
      population: 10991,
      density: 18.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4K N4L N0H",
      id: 1124445257
    },
    {
      label: "Adjala-Tosorontio",
      value: "Adjala-Tosorontio",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.1333,
      lng: -79.9333,
      population: 10975,
      density: 29.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0N L0M L0G L9R",
      id: 1124000498
    },
    {
      label: "Hamilton Township",
      value: "Hamilton Township",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.054,
      lng: -78.2164,
      population: 10942,
      density: 42.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K9A K0K K0L",
      id: 1124000994
    },
    {
      label: "St. Clements",
      value: "St. Clements",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.2689,
      lng: -96.6742,
      population: 10876,
      density: 14.9,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0E R1B R1C R1A",
      id: 1124000566
    },
    {
      label: "Saint-Amable",
      value: "Saint-Amable",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.65,
      lng: -73.3,
      population: 10870,
      density: 296.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124000904
    },
    {
      label: "Weyburn",
      value: "Weyburn",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 49.6611,
      lng: -103.8525,
      population: 10870,
      density: 688.8,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S4H",
      id: 1124618383
    },
    {
      label: "South Dundas",
      value: "South Dundas",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.9167,
      lng: -75.2667,
      population: 10833,
      density: 20.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0C K0E",
      id: 1124001404
    },
    {
      label: "L’Île-Perrot",
      value: "L'Ile-Perrot",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3833,
      lng: -73.95,
      population: 10756,
      density: 1955.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7V",
      id: 1124063001
    },
    {
      label: "Notre-Dame-de-l'Île-Perrot",
      value: "Notre-Dame-de-l'Ile-Perrot",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3667,
      lng: -73.9333,
      population: 10756,
      density: 386.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7V",
      id: 1124001191
    },
    {
      label: "Williams Lake",
      value: "Williams Lake",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 52.1294,
      lng: -122.1383,
      population: 10753,
      density: 327,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V2G",
      id: 1124821980
    },
    {
      label: "Elliot Lake",
      value: "Elliot Lake",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.3833,
      lng: -82.65,
      population: 10741,
      density: 15.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P5A",
      id: 1124793448
    },
    {
      label: "Cantley",
      value: "Cantley",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5667,
      lng: -75.7833,
      population: 10699,
      density: 83.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J8V",
      id: 1124000263
    },
    {
      label: "Nelson",
      value: "Nelson",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.5,
      lng: -117.2833,
      population: 10664,
      density: 1552.3,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V1L",
      id: 1124361295
    },
    {
      label: "Lambton Shores",
      value: "Lambton Shores",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.1833,
      lng: -81.9,
      population: 10631,
      density: 32.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0M N0N",
      id: 1124001891
    },
    {
      label: "Mapleton",
      value: "Mapleton",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.7358,
      lng: -80.6681,
      population: 10527,
      density: 19.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4W N0G N0B",
      id: 1124000835
    },
    {
      label: "Georgian Bluffs",
      value: "Georgian Bluffs",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.65,
      lng: -81.0333,
      population: 10479,
      density: 17.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4K N0H",
      id: 1124001470
    },
    {
      label: "Rawdon",
      value: "Rawdon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.05,
      lng: -73.7167,
      population: 10416,
      density: 55.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124084263
    },
    {
      label: "Campbellton",
      value: "Campbellton",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 48.005,
      lng: -66.6731,
      population: 10411,
      density: 370.5,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E3N",
      id: 1124336512
    },
    {
      label: "View Royal",
      value: "View Royal",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.4517,
      lng: -123.4339,
      population: 10408,
      density: 724.8,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V9B",
      id: 1124001985
    },
    {
      label: "Coldstream",
      value: "Coldstream",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.22,
      lng: -119.2481,
      population: 10314,
      density: 155.6,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V1B",
      id: 1124000216
    },
    {
      label: "Chester",
      value: "Chester",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 44.65,
      lng: -64.3,
      population: 10310,
      density: 9.2,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0J",
      id: 1124772236
    },
    {
      label: "Queens",
      value: "Queens",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 44.0333,
      lng: -64.7167,
      population: 10307,
      density: 4.3,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0T",
      id: 1124001652
    },
    {
      label: "Selkirk",
      value: "Selkirk",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.1436,
      lng: -96.8839,
      population: 10278,
      density: 413.4,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R1A",
      id: 1124499880
    },
    {
      label: "Saint-Félicien",
      value: "Saint-Felicien",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.65,
      lng: -72.45,
      population: 10278,
      density: 28.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G8K",
      id: 1124555496
    },
    {
      label: "Hawkesbury",
      value: "Hawkesbury",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.6,
      lng: -74.6,
      population: 10263,
      density: 1067.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "K6A",
      id: 1124065659
    },
    {
      label: "Roberval",
      value: "Roberval",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.52,
      lng: -72.23,
      population: 10227,
      density: 66.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G8H",
      id: 1124395055
    },
    {
      label: "Sainte-Agathe-des-Monts",
      value: "Sainte-Agathe-des-Monts",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.05,
      lng: -74.28,
      population: 10223,
      density: 78.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J8C",
      id: 1124041166
    },
    {
      label: "North Dumfries",
      value: "North Dumfries",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.32,
      lng: -80.38,
      population: 10215,
      density: 54.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0B N1R",
      id: 1124000802
    },
    {
      label: "Rideau Lakes",
      value: "Rideau Lakes",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.6667,
      lng: -76.2167,
      population: 10207,
      density: 14,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0E K0G",
      id: 1124000369
    },
    {
      label: "Sechelt",
      value: "Sechelt",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.4742,
      lng: -123.7542,
      population: 10200,
      density: 212.9,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0N",
      id: 1124845591
    },
    {
      label: "North Glengarry",
      value: "North Glengarry",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.3333,
      lng: -74.7333,
      population: 10109,
      density: 15.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0B K0C",
      id: 1124000836
    },
    {
      label: "South Huron",
      value: "South Huron",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.32,
      lng: -81.5,
      population: 10096,
      density: 23.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0M",
      id: 1124000910
    },
    {
      label: "Marieville",
      value: "Marieville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4333,
      lng: -73.1667,
      population: 10094,
      density: 160.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3M",
      id: 1124834229
    },
    {
      label: "Tay",
      value: "Tay",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.7167,
      lng: -79.7667,
      population: 10033,
      density: 72.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0L L0K L4R",
      id: 1124001057
    },
    {
      label: "Temiskaming Shores",
      value: "Temiskaming Shores",
      province_id: "ON",
      province_name: "Ontario",
      lat: 47.5167,
      lng: -79.6833,
      population: 9920,
      density: 55.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0J",
      id: 1124001880
    },
    {
      label: "Hinton",
      value: "Hinton",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.4114,
      lng: -117.5639,
      population: 9882,
      density: 294.8,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T7V",
      id: 1124131074
    },
    {
      label: "Saint-Sauveur",
      value: "Saint-Sauveur",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9,
      lng: -74.17,
      population: 9881,
      density: 206.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0R",
      id: 1124720935
    },
    {
      label: "Quesnel",
      value: "Quesnel",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 52.9784,
      lng: -122.4927,
      population: 9879,
      density: 279.2,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V2J",
      id: 1124028015
    },
    {
      label: "Elizabethtown-Kitley",
      value: "Elizabethtown-Kitley",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.7,
      lng: -75.8833,
      population: 9854,
      density: 17.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K6V K6T K0E K0G",
      id: 1124001450
    },
    {
      label: "Morinville",
      value: "Morinville",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.8022,
      lng: -113.6497,
      population: 9848,
      density: 882.8,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T8R",
      id: 1124322535
    },
    {
      label: "Grey Highlands",
      value: "Grey Highlands",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.3333,
      lng: -80.5,
      population: 9804,
      density: 11.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4L N0C",
      id: 1124000119
    },
    {
      label: "Stratford",
      value: "Stratford",
      province_id: "PE",
      province_name: "Prince Edward Island",
      lat: 46.2167,
      lng: -63.0893,
      population: 9706,
      density: 430.8,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "C1B",
      id: 1124001331
    },
    {
      label: "Alfred and Plantagenet",
      value: "Alfred and Plantagenet",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.5667,
      lng: -74.9167,
      population: 9680,
      density: 24.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0B",
      id: 1124001813
    },
    {
      label: "Mont-Tremblant",
      value: "Mont-Tremblant",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1167,
      lng: -74.6,
      population: 9646,
      density: 40.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J8E",
      id: 1124041173
    },
    {
      label: "Martensville",
      value: "Martensville",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.2897,
      lng: -106.6667,
      population: 9645,
      density: 1421.2,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0K",
      id: 1124000654
    },
    {
      label: "Saint-Raymond",
      value: "Saint-Raymond",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.9,
      lng: -71.8333,
      population: 9615,
      density: 14.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G3L",
      id: 1124162305
    },
    {
      label: "Amherst",
      value: "Amherst",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.8167,
      lng: -64.2167,
      population: 9550,
      density: 779.7,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B4H",
      id: 1124895094
    },
    {
      label: "Ramara",
      value: "Ramara",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.6333,
      lng: -79.2167,
      population: 9488,
      density: 22.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0K L3V",
      id: 1124000641
    },
    {
      label: "Bois-des-Filion",
      value: "Bois-des-Filion",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6667,
      lng: -73.75,
      population: 9485,
      density: 2216.2,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "J6Z",
      id: 1124978470
    },
    {
      label: "Leeds and the Thousand Islands",
      value: "Leeds and the Thousand Islands",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.45,
      lng: -76.08,
      population: 9465,
      density: 15.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7G K0E K0H",
      id: 1124000531
    },
    {
      label: "Carignan",
      value: "Carignan",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.45,
      lng: -73.3,
      population: 9462,
      density: 151.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3L",
      id: 1124001655
    },
    {
      label: "Brockton",
      value: "Brockton",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.1667,
      lng: -81.2167,
      population: 9461,
      density: 16.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0G",
      id: 1124000713
    },
    {
      label: "Laurentian Valley",
      value: "Laurentian Valley",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.7681,
      lng: -77.2239,
      population: 9387,
      density: 17,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K8A K8B",
      id: 1124000736
    },
    {
      label: "East St. Paul",
      value: "East St. Paul",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.9772,
      lng: -97.0103,
      population: 9372,
      density: 222.6,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R2E",
      id: 1124000695
    },
    {
      label: "Lorraine",
      value: "Lorraine",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6833,
      lng: -73.7833,
      population: 9352,
      density: 1570,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J6Z",
      id: 1124001859
    },
    {
      label: "Sainte-Julienne",
      value: "Sainte-Julienne",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.97,
      lng: -73.72,
      population: 9331,
      density: 94,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124086540
    },
    {
      label: "Blackfalds",
      value: "Blackfalds",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.3833,
      lng: -113.8,
      population: 9328,
      density: 567.3,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0M T4M",
      id: 1124056144
    },
    {
      label: "Malahide",
      value: "Malahide",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.7928,
      lng: -80.9361,
      population: 9292,
      density: 23.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N5H N0L",
      id: 1124001777
    },
    {
      label: "Oromocto",
      value: "Oromocto",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.8488,
      lng: -66.4788,
      population: 9223,
      density: 411,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E2V",
      id: 1124928183
    },
    {
      label: "Olds",
      value: "Olds",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.7928,
      lng: -114.1067,
      population: 9184,
      density: 615.3,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T4H",
      id: 1124330412
    },
    {
      label: "Huron East",
      value: "Huron East",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.63,
      lng: -81.28,
      population: 9138,
      density: 13.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4W N0M N0K N0G",
      id: 1124000724
    },
    {
      label: "Stanley",
      value: "Stanley",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1331,
      lng: -98.0656,
      population: 9038,
      density: 10.8,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R6M R6W",
      id: 1124001503
    },
    {
      label: "Penetanguishene",
      value: "Penetanguishene",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.7667,
      lng: -79.9333,
      population: 8962,
      density: 350.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L9M",
      id: 1124304117
    },
    {
      label: "Qualicum Beach",
      value: "Qualicum Beach",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.35,
      lng: -124.4333,
      population: 8943,
      density: 497.4,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V9K",
      id: 1124822520
    },
    {
      label: "Notre-Dame-des-Prairies",
      value: "Notre-Dame-des-Prairies",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.05,
      lng: -73.4333,
      population: 8868,
      density: 487.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J6E",
      id: 1124001393
    },
    {
      label: "West Perth",
      value: "West Perth",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.47,
      lng: -81.2,
      population: 8865,
      density: 15.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0K",
      id: 1124001056
    },
    {
      label: "Cavan Monaghan",
      value: "Cavan Monaghan",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.2,
      lng: -78.4667,
      population: 8829,
      density: 28.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0A K0L",
      id: 1124001281
    },
    {
      label: "Arnprior",
      value: "Arnprior",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.4333,
      lng: -76.35,
      population: 8795,
      density: 672.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7S",
      id: 1124700031
    },
    {
      label: "Smiths Falls",
      value: "Smiths Falls",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.9,
      lng: -76.0167,
      population: 8780,
      density: 909.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7A",
      id: 1124233827
    },
    {
      label: "Pont-Rouge",
      value: "Pont-Rouge",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.75,
      lng: -71.7,
      population: 8723,
      density: 72,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G3H",
      id: 1124608325
    },
    {
      label: "Champlain",
      value: "Champlain",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.5333,
      lng: -74.65,
      population: 8706,
      density: 42,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K6A K0B",
      id: 1124000537
    },
    {
      label: "Coaticook",
      value: "Coaticook",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.1333,
      lng: -71.8,
      population: 8698,
      density: 39.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J1A",
      id: 1124454176
    },
    {
      label: "Minto",
      value: "Minto",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.9167,
      lng: -80.8667,
      population: 8671,
      density: 28.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0G",
      id: 1124000198
    },
    {
      label: "Morden",
      value: "Morden",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1919,
      lng: -98.1006,
      population: 8668,
      density: 401,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R6M",
      id: 1124327817
    },
    {
      label: "Mono",
      value: "Mono",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.0167,
      lng: -80.0667,
      population: 8609,
      density: 31,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L9V L9W",
      id: 1124001904
    },
    {
      label: "Corman Park No. 344",
      value: "Corman Park No. 344",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.2291,
      lng: -106.8002,
      population: 8568,
      density: 4.5,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0K S7K S7P S7T",
      id: 1124000077
    },
    {
      label: "Ladysmith",
      value: "Ladysmith",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.9975,
      lng: -123.8203,
      population: 8537,
      density: 711.9,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V9G",
      id: 1124872385
    },
    {
      label: "Bridgewater",
      value: "Bridgewater",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 44.37,
      lng: -64.52,
      population: 8532,
      density: 625.9,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B4V",
      id: 1124736310
    },
    {
      label: "Dauphin",
      value: "Dauphin",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 51.1992,
      lng: -100.0633,
      population: 8457,
      density: 670.7,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R7N",
      id: 1124144510
    },
    {
      label: "Otterburn Park",
      value: "Otterburn Park",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5333,
      lng: -73.2167,
      population: 8450,
      density: 1580.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3H",
      id: 1124899409
    },
    {
      label: "Taber",
      value: "Taber",
      province_id: "AB",
      province_name: "Alberta",
      lat: 49.7847,
      lng: -112.1508,
      population: 8428,
      density: 537.9,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T1G",
      id: 1124113583
    },
    {
      label: "South Bruce Peninsula",
      value: "South Bruce Peninsula",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.7333,
      lng: -81.2,
      population: 8416,
      density: 15.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0H",
      id: 1124000114
    },
    {
      label: "Edson",
      value: "Edson",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.5817,
      lng: -116.4344,
      population: 8414,
      density: 283.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T7E",
      id: 1124553562
    },
    {
      label: "Farnham",
      value: "Farnham",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2833,
      lng: -72.9833,
      population: 8330,
      density: 90.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J2N",
      id: 1124553013
    },
    {
      label: "Kapuskasing",
      value: "Kapuskasing",
      province_id: "ON",
      province_name: "Ontario",
      lat: 49.4167,
      lng: -82.4333,
      population: 8292,
      density: 98.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P5N",
      id: 1124764245
    },
    {
      label: "La Malbaie",
      value: "La Malbaie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.65,
      lng: -70.15,
      population: 8271,
      density: 18,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G5A",
      id: 1124466004
    },
    {
      label: "Renfrew",
      value: "Renfrew",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.4717,
      lng: -76.6831,
      population: 8223,
      density: 643.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7V",
      id: 1124652971
    },
    {
      label: "Coaldale",
      value: "Coaldale",
      province_id: "AB",
      province_name: "Alberta",
      lat: 49.7333,
      lng: -112.6167,
      population: 8215,
      density: 1028.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T1M",
      id: 1124989507
    },
    {
      label: "Portugal Cove-St. Philip's",
      value: "Portugal Cove-St. Philip's",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.6272,
      lng: -52.8506,
      population: 8147,
      density: 128.4,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A1M",
      id: 1124001559
    },
    {
      label: "Zorra",
      value: "Zorra",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.15,
      lng: -80.95,
      population: 8138,
      density: 15.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N5C N0M N0J",
      id: 1124000608
    },
    {
      label: "Kitimat",
      value: "Kitimat",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 54,
      lng: -128.7,
      population: 8131,
      density: 34.7,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V8C",
      id: 1124198272
    },
    {
      label: "Shelburne",
      value: "Shelburne",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.0833,
      lng: -80.2,
      population: 8126,
      density: 907.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L9V",
      id: 1124470888
    },
    {
      label: "Happy Valley",
      value: "Happy Valley",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 53.3396,
      lng: -60.4467,
      population: 8109,
      density: 26.5,
      timezone: "America/Goose_Bay",
      ranking: 4,
      postal: "A0P",
      id: 1124879731
    },
    {
      label: "Saint-Hippolyte",
      value: "Saint-Hippolyte",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.93,
      lng: -74.02,
      population: 8083,
      density: 67,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J8A",
      id: 1124001758
    },
    {
      label: "Castlegar",
      value: "Castlegar",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.3256,
      lng: -117.6661,
      population: 8039,
      density: 408.6,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V1N",
      id: 1124379972
    },
    {
      label: "Church Point",
      value: "Church Point",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 44.3333,
      lng: -66.1167,
      population: 8018,
      density: 9.4,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0W",
      id: 1124316445
    },
    {
      label: "Drumheller",
      value: "Drumheller",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.4636,
      lng: -112.7194,
      population: 7982,
      density: 73.9,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0J",
      id: 1124745292
    },
    {
      label: "Kirkland Lake",
      value: "Kirkland Lake",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.15,
      lng: -80.0333,
      population: 7981,
      density: 30.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0K P2N",
      id: 1124683504
    },
    {
      label: "Argyle",
      value: "Argyle",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 43.8,
      lng: -65.85,
      population: 7899,
      density: 5.2,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0W",
      id: 1124503052
    },
    {
      label: "Torbay",
      value: "Torbay",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.65,
      lng: -52.7333,
      population: 7899,
      density: 212.1,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A1K",
      id: 1124406642
    },
    {
      label: "La Pêche",
      value: "La Peche",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6833,
      lng: -75.9833,
      population: 7863,
      density: 13.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0X",
      id: 1124001249
    },
    {
      label: "Banff",
      value: "Banff",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.1781,
      lng: -115.5719,
      population: 7847,
      density: 1646,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T1L",
      id: 1124351648
    },
    {
      label: "Innisfail",
      value: "Innisfail",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.0333,
      lng: -113.95,
      population: 7847,
      density: 404.6,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T4G",
      id: 1124612670
    },
    {
      label: "Nicolet",
      value: "Nicolet",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2167,
      lng: -72.6167,
      population: 7828,
      density: 81.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3T",
      id: 1124746363
    },
    {
      label: "Rockwood",
      value: "Rockwood",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.2856,
      lng: -97.2869,
      population: 7823,
      density: 6.5,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0C",
      id: 1124000435
    },
    {
      label: "Drummond/North Elmsley",
      value: "Drummond/North Elmsley",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.9667,
      lng: -76.2,
      population: 7773,
      density: 21.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7A K7C K7H K0G",
      id: 1124001787
    },
    {
      label: "Dryden",
      value: "Dryden",
      province_id: "ON",
      province_name: "Ontario",
      lat: 49.7833,
      lng: -92.8333,
      population: 7749,
      density: 117.1,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "P8N",
      id: 1124295097
    },
    {
      label: "Iqaluit",
      value: "Iqaluit",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 63.7598,
      lng: -68.5107,
      population: 7740,
      density: 147.4,
      timezone: "America/Iqaluit",
      ranking: 4,
      postal: "X0A",
      id: 1124379539
    },
    {
      label: "Fort Frances",
      value: "Fort Frances",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.6167,
      lng: -93.4,
      population: 7739,
      density: 303.4,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "P9A",
      id: 1124939714
    },
    {
      label: "La Sarre",
      value: "La Sarre",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.8,
      lng: -79.2,
      population: 7719,
      density: 51.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J9Z",
      id: 1124902252
    },
    {
      label: "Trail",
      value: "Trail",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.095,
      lng: -117.71,
      population: 7709,
      density: 220.7,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V1R",
      id: 1124817036
    },
    {
      label: "Chandler",
      value: "Chandler",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.35,
      lng: -64.6833,
      population: 7703,
      density: 18.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0C",
      id: 1124111932
    },
    {
      label: "Stone Mills",
      value: "Stone Mills",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.45,
      lng: -76.9167,
      population: 7702,
      density: 10.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0K",
      id: 1124000075
    },
    {
      label: "Hanover",
      value: "Hanover",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.15,
      lng: -81.0333,
      population: 7688,
      density: 763.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4N",
      id: 1124868817
    },
    {
      label: "South-West Oxford",
      value: "South-West Oxford",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.95,
      lng: -80.8,
      population: 7664,
      density: 20.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4S N4G N5C N0L N0J",
      id: 1124000210
    },
    {
      label: "Acton Vale",
      value: "Acton Vale",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.65,
      lng: -72.5667,
      population: 7664,
      density: 84.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124864792
    },
    {
      label: "Bromont",
      value: "Bromont",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3167,
      lng: -72.65,
      population: 7649,
      density: 66.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J2L",
      id: 1124286457
    },
    {
      label: "Beckwith",
      value: "Beckwith",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.0833,
      lng: -76.0667,
      population: 7644,
      density: 31.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7A K7C K0A",
      id: 1124000163
    },
    {
      label: "Goderich",
      value: "Goderich",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.7333,
      lng: -81.7,
      population: 7628,
      density: 882.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N7A",
      id: 1124989247
    },
    {
      label: "Plympton-Wyoming",
      value: "Plympton-Wyoming",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.0167,
      lng: -82.0833,
      population: 7576,
      density: 23.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0N",
      id: 1124001273
    },
    {
      label: "Central Huron",
      value: "Central Huron",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.63,
      lng: -81.57,
      population: 7576,
      density: 16.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0M N7A",
      id: 1124001983
    },
    {
      label: "Rigaud",
      value: "Rigaud",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4833,
      lng: -74.3,
      population: 7566,
      density: 74.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0P",
      id: 1124176101
    },
    {
      label: "Louiseville",
      value: "Louiseville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.25,
      lng: -72.95,
      population: 7517,
      density: 120.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J5V",
      id: 1124866425
    },
    {
      label: "Chibougamau",
      value: "Chibougamau",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.9167,
      lng: -74.3667,
      population: 7504,
      density: 10.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G8P",
      id: 1124650514
    },
    {
      label: "Aylmer",
      value: "Aylmer",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.7667,
      lng: -80.9833,
      population: 7492,
      density: 1197.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N5H",
      id: 1124964102
    },
    {
      label: "Delson",
      value: "Delson",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.37,
      lng: -73.55,
      population: 7462,
      density: 982.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J5B",
      id: 1124405717
    },
    {
      label: "Kimberley",
      value: "Kimberley",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.6697,
      lng: -115.9775,
      population: 7425,
      density: 122.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "V1A",
      id: 1124170837
    },
    {
      label: "Blandford-Blenheim",
      value: "Blandford-Blenheim",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.2333,
      lng: -80.6,
      population: 7399,
      density: 19.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0J",
      id: 1124001001
    },
    {
      label: "Bayham",
      value: "Bayham",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.7333,
      lng: -80.7833,
      population: 7396,
      density: 30.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N5H N0J",
      id: 1124000461
    },
    {
      label: "Augusta",
      value: "Augusta",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.7511,
      lng: -75.6003,
      population: 7353,
      density: 23.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K6V K0E K0G",
      id: 1124000619
    },
    {
      label: "Puslinch",
      value: "Puslinch",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.45,
      lng: -80.1667,
      population: 7336,
      density: 34.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N3C N0B N1H",
      id: 1124129947
    },
    {
      label: "Beauport",
      value: "Beauport",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.9667,
      lng: -71.3,
      population: 7281,
      density: 118.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G3B",
      id: 1124715267
    },
    {
      label: "Saint-Rémi",
      value: "Saint-Remi",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2667,
      lng: -73.6167,
      population: 7265,
      density: 92.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124638080
    },
    {
      label: "St. Marys",
      value: "St. Marys",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.2583,
      lng: -81.1333,
      population: 7265,
      density: 583.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4X",
      id: 1124438866
    },
    {
      label: "Drayton Valley",
      value: "Drayton Valley",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.2222,
      lng: -114.9769,
      population: 7235,
      density: 235.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T7A",
      id: 1124814220
    },
    {
      label: "Ponoka",
      value: "Ponoka",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.6833,
      lng: -113.5667,
      population: 7229,
      density: 417.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T4J",
      id: 1124308190
    },
    {
      label: "Labrador label",
      value: "Labrador label",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 52.95,
      lng: -66.9167,
      population: 7220,
      density: 186,
      timezone: "America/Goose_Bay",
      ranking: 3,
      postal: "A2V",
      id: 1124000773
    },
    {
      label: "Donnacona",
      value: "Donnacona",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.6747,
      lng: -71.7294,
      population: 7200,
      density: 357.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G3M",
      id: 1124002794
    },
    {
      label: "Southgate",
      value: "Southgate",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.1,
      lng: -80.5833,
      population: 7190,
      density: 11.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0G N0C",
      id: 1124000656
    },
    {
      label: "McNab/Braeside",
      value: "McNab/Braeside",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.45,
      lng: -76.5,
      population: 7178,
      density: 28.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7S K7V K0A",
      id: 1124001458
    },
    {
      label: "Macdonald",
      value: "Macdonald",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.6725,
      lng: -97.4472,
      population: 7162,
      density: 6.2,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R4G R0G",
      id: 1124000633
    },
    {
      label: "Hampstead",
      value: "Hampstead",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4833,
      lng: -73.6333,
      population: 7153,
      density: 3996.5,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "H3X",
      id: 1124000763
    },
    {
      label: "Baie-Saint-Paul",
      value: "Baie-Saint-Paul",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.45,
      lng: -70.5,
      population: 7146,
      density: 13.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G3Z",
      id: 1124415452
    },
    {
      label: "Merritt",
      value: "Merritt",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.1128,
      lng: -120.7897,
      population: 7139,
      density: 273.9,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V1K",
      id: 1124550302
    },
    {
      label: "Bluewater",
      value: "Bluewater",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.45,
      lng: -81.6,
      population: 7136,
      density: 17.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0M",
      id: 1124000066
    },
    {
      label: "East Zorra-Tavistock",
      value: "East Zorra-Tavistock",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.2333,
      lng: -80.7833,
      population: 7129,
      density: 29.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4S N0J N0B",
      id: 1124000189
    },
    {
      label: "Brownsburg",
      value: "Brownsburg",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6703,
      lng: -74.4467,
      population: 7122,
      density: 28.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J8G",
      id: 1124023263
    },
    {
      label: "Stoneham-et-Tewkesbury",
      value: "Stoneham-et-Tewkesbury",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.1667,
      lng: -71.4333,
      population: 7106,
      density: 10.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G3C",
      id: 1124000439
    },
    {
      label: "Asbestos",
      value: "Asbestos",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7667,
      lng: -71.9333,
      population: 7096,
      density: 239.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J1T",
      id: 1124583779
    },
    {
      label: "Huron-Kinloss",
      value: "Huron-Kinloss",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.05,
      lng: -81.5333,
      population: 7069,
      density: 16,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N2Z N0G",
      id: 1124000614
    },
    {
      label: "Coteau-du-Lac",
      value: "Coteau-du-Lac",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3,
      lng: -74.18,
      population: 7044,
      density: 150.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0P",
      id: 1124000308
    },
    {
      label: "The Blue Mountains",
      value: "The Blue Mountains",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.4833,
      lng: -80.3833,
      population: 7025,
      density: 24.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L9Y N0H",
      id: 1124000370
    },
    {
      label: "Whitewater Region",
      value: "Whitewater Region",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.7167,
      lng: -76.8333,
      population: 7009,
      density: 13,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0J",
      id: 1124001363
    },
    {
      label: "Edwardsburgh/Cardinal",
      value: "Edwardsburgh/Cardinal",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.8333,
      lng: -75.5,
      population: 6959,
      density: 22.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0E",
      id: 1124001736
    },
    {
      label: "Sainte-Anne-des-Monts",
      value: "Sainte-Anne-des-Monts",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.1333,
      lng: -66.5,
      population: 6933,
      density: 26.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G4V",
      id: 1124183859
    },
    {
      label: "Old Chelsea",
      value: "Old Chelsea",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5,
      lng: -75.7833,
      population: 6909,
      density: 60.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J9B",
      id: 1124835028
    },
    {
      label: "North Stormont",
      value: "North Stormont",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.2167,
      lng: -75,
      population: 6873,
      density: 13.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0A K0C",
      id: 1124000261
    },
    {
      label: "Alnwick/Haldimand",
      value: "Alnwick/Haldimand",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.0833,
      lng: -78.0333,
      population: 6869,
      density: 17.25,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K9A K0K",
      id: 1124000698
    },
    {
      label: "Peace River",
      value: "Peace River",
      province_id: "AB",
      province_name: "Alberta",
      lat: 56.2339,
      lng: -117.2897,
      population: 6842,
      density: 260.6,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T8S",
      id: 1124941936
    },
    {
      label: "Arran-Elderslie",
      value: "Arran-Elderslie",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.4,
      lng: -81.2,
      population: 6803,
      density: 14.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0H N0G",
      id: 1124001766
    },
    {
      label: "Saint-Zotique",
      value: "Saint-Zotique",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.25,
      lng: -74.25,
      population: 6773,
      density: 268.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0P",
      id: 1124170824
    },
    {
      label: "Val-Shefford",
      value: "Val-Shefford",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.35,
      lng: -72.5667,
      population: 6711,
      density: 56.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J2M",
      id: 1124787548
    },
    {
      label: "Douro-Dummer",
      value: "Douro-Dummer",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.45,
      lng: -78.1,
      population: 6709,
      density: 14.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K9J K9L K0L",
      id: 1124001679
    },
    {
      label: "Plessisville",
      value: "Plessisville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2167,
      lng: -71.7833,
      population: 6688,
      density: 1546,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G6L",
      id: 1124223899
    },
    {
      label: "Ritchot",
      value: "Ritchot",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.6647,
      lng: -97.1167,
      population: 6679,
      density: 20,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R5A R0A R0G",
      id: 1124001990
    },
    {
      label: "Otonabee-South Monaghan",
      value: "Otonabee-South Monaghan",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.2333,
      lng: -78.2333,
      population: 6670,
      density: 19.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K9J K0L",
      id: 1124000517
    },
    {
      label: "Shediac",
      value: "Shediac",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.2167,
      lng: -64.5333,
      population: 6664,
      density: 123.5,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E4P",
      id: 1124770042
    },
    {
      label: "Slave Lake",
      value: "Slave Lake",
      province_id: "AB",
      province_name: "Alberta",
      lat: 55.2853,
      lng: -114.7706,
      population: 6651,
      density: 460.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0G",
      id: 1124106662
    },
    {
      label: "Port-Cartier",
      value: "Port-Cartier",
      province_id: "QC",
      province_name: "Quebec",
      lat: 50.0333,
      lng: -66.8667,
      population: 6651,
      density: 6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G5B G0H",
      id: 1124795368
    },
    {
      label: "Saint-Lambert-de-Lauzon",
      value: "Saint-Lambert-de-Lauzon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5865,
      lng: -71.2271,
      population: 6647,
      density: 62.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124610423
    },
    {
      label: "Barrington",
      value: "Barrington",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 43.5646,
      lng: -65.5639,
      population: 6646,
      density: 10.5,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0W",
      id: 1124548310
    },
    {
      label: "Rocky Mountain House",
      value: "Rocky Mountain House",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.3753,
      lng: -114.9217,
      population: 6635,
      density: 521.8,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T4T",
      id: 1124203206
    },
    {
      label: "Chatsworth",
      value: "Chatsworth",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.38,
      lng: -80.87,
      population: 6630,
      density: 11.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0H",
      id: 1124525225
    },
    {
      label: "Stephenville",
      value: "Stephenville",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.55,
      lng: -58.5667,
      population: 6623,
      density: 185.6,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A2N",
      id: 1124000201
    },
    {
      label: "Muskoka Falls",
      value: "Muskoka Falls",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.1264,
      lng: -79.558,
      population: 6588,
      density: 8.3,
      timezone: "America/Toronto",
      ranking: 4,
      postal: "P0C P0B P1L P1P",
      id: 1124955753
    },
    {
      label: "Devon",
      value: "Devon",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.3633,
      lng: -113.7322,
      population: 6578,
      density: 460.2,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T9G",
      id: 1124268366
    },
    {
      label: "Yarmouth",
      value: "Yarmouth",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 43.8361,
      lng: -66.1175,
      population: 6518,
      density: 616.9,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B5A",
      id: 1124983867
    },
    {
      label: "Boischatel",
      value: "Boischatel",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.9,
      lng: -71.15,
      population: 6465,
      density: 308.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124332563
    },
    {
      label: "Parry Sound",
      value: "Parry Sound",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.3333,
      lng: -80.0333,
      population: 6408,
      density: 478.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P2A",
      id: 1124245809
    },
    {
      label: "Pointe-Calumet",
      value: "Pointe-Calumet",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5,
      lng: -73.97,
      population: 6396,
      density: 1382.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0N",
      id: 1124629762
    },
    {
      label: "Beaubassin East / Beaubassin-est",
      value: "Beaubassin East / Beaubassin-est",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.1726,
      lng: -64.3122,
      population: 6376,
      density: 21.9,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E4P E4N",
      id: 1124000427
    },
    {
      label: "Wainfleet",
      value: "Wainfleet",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.925,
      lng: -79.375,
      population: 6372,
      density: 29.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0S L0R L3B L3K",
      id: 1124538125
    },
    {
      label: "Cramahe",
      value: "Cramahe",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.0833,
      lng: -77.8833,
      population: 6355,
      density: 31.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0K",
      id: 1124000879
    },
    {
      label: "Beauceville",
      value: "Beauceville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2,
      lng: -70.7833,
      population: 6354,
      density: 37.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G5X",
      id: 1124575286
    },
    {
      label: "North Middlesex",
      value: "North Middlesex",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.15,
      lng: -81.6333,
      population: 6352,
      density: 10.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0M",
      id: 1124001914
    },
    {
      label: "Amqui",
      value: "Amqui",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.4667,
      lng: -67.4333,
      population: 6322,
      density: 52.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G5J",
      id: 1124681333
    },
    {
      label: "Sainte-Catherine-de-la-Jacques-Cartier",
      value: "Sainte-Catherine-de-la-Jacques-Cartier",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.85,
      lng: -71.6167,
      population: 6319,
      density: 52.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G3N",
      id: 1124001417
    },
    {
      label: "Clarenville",
      value: "Clarenville",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.1566,
      lng: -53.965,
      population: 6291,
      density: 44.7,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A5A",
      id: 1124924217
    },
    {
      label: "Mont-Joli",
      value: "Mont-Joli",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.58,
      lng: -68.18,
      population: 6281,
      density: 272.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G5H",
      id: 1124642037
    },
    {
      label: "Dysart et al",
      value: "Dysart et al",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.2042,
      lng: -78.4047,
      population: 6280,
      density: 4.2,
      timezone: "America/Toronto",
      ranking: 4,
      postal: "K0L K0M",
      id: 1124000824
    },
    {
      label: "Wainwright",
      value: "Wainwright",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.8333,
      lng: -110.8667,
      population: 6270,
      density: 688.7,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T9W",
      id: 1124385336
    },
    {
      label: "Contrecoeur",
      value: "Contrecoeur",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.85,
      lng: -73.2333,
      population: 6252,
      density: 102.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124384220
    },
    {
      label: "Beresford",
      value: "Beresford",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.7181,
      lng: -65.8794,
      population: 6248,
      density: 13.7,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E8J E8K",
      id: 1124000299
    },
    {
      label: "Saint-Joseph-du-Lac",
      value: "Saint-Joseph-du-Lac",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5333,
      lng: -74,
      population: 6195,
      density: 149.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0N",
      id: 1124001195
    },
    {
      label: "Hope",
      value: "Hope",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.3858,
      lng: -121.4419,
      population: 6181,
      density: 151,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0X",
      id: 1124662863
    },
    {
      label: "Gimli",
      value: "Gimli",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.6619,
      lng: -97.0297,
      population: 6181,
      density: 19.5,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0C",
      id: 1124472413
    },
    {
      label: "Douglas",
      value: "Douglas",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.2819,
      lng: -66.942,
      population: 6154,
      density: 4.3,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E6L E6B E3G",
      id: 1124000768
    },
    {
      label: "Saint-Apollinaire",
      value: "Saint-Apollinaire",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.6167,
      lng: -71.5167,
      population: 6110,
      density: 63,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0S",
      id: 1124951601
    },
    {
      label: "Hindon Hill",
      value: "Hindon Hill",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.9333,
      lng: -78.7333,
      population: 6088,
      density: 6.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0M",
      id: 1124076260
    },
    {
      label: "Les Cèdres",
      value: "Les Cedres",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3,
      lng: -74.05,
      population: 6079,
      density: 78.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7T",
      id: 1124051098
    },
    {
      label: "La Broquerie",
      value: "La Broquerie",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.3994,
      lng: -96.5103,
      population: 6076,
      density: 10.5,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0A",
      id: 1124000582
    },
    {
      label: "Kent",
      value: "Kent",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.2833,
      lng: -121.75,
      population: 6067,
      density: 35.9,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0M",
      id: 1124001999
    },
    {
      label: "Tweed",
      value: "Tweed",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.6,
      lng: -77.3333,
      population: 6044,
      density: 6.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0K",
      id: 1124220211
    },
    {
      label: "Saint-Félix-de-Valois",
      value: "Saint-Felix-de-Valois",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.17,
      lng: -73.43,
      population: 6029,
      density: 68.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124578689
    },
    {
      label: "Bay Roberts",
      value: "Bay Roberts",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.5847,
      lng: -53.2783,
      population: 6012,
      density: 249.9,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0A",
      id: 1124372298
    },
    {
      label: "Melfort",
      value: "Melfort",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.8564,
      lng: -104.61,
      population: 5992,
      density: 405.4,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0E",
      id: 1124817334
    },
    {
      label: "Bonnyville",
      value: "Bonnyville",
      province_id: "AB",
      province_name: "Alberta",
      lat: 54.2667,
      lng: -110.75,
      population: 5975,
      density: 421.4,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T9N",
      id: 1124166469
    },
    {
      label: "Stettler",
      value: "Stettler",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.3236,
      lng: -112.7192,
      population: 5952,
      density: 453,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0C",
      id: 1124010388
    },
    {
      label: "Saint-Calixte",
      value: "Saint-Calixte",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.95,
      lng: -73.85,
      population: 5934,
      density: 41.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124001462
    },
    {
      label: "Lac-Mégantic",
      value: "Lac-Megantic",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5833,
      lng: -70.8833,
      population: 5932,
      density: 272.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G6B",
      id: 1124329615
    },
    {
      label: "Perth",
      value: "Perth",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.9,
      lng: -76.25,
      population: 5930,
      density: 484.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7H",
      id: 1124732094
    },
    {
      label: "Oliver Paipoonge",
      value: "Oliver Paipoonge",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.39,
      lng: -89.52,
      population: 5922,
      density: 16.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0T P7G P7J P7K",
      id: 1124000729
    },
    {
      label: "Humboldt",
      value: "Humboldt",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.2019,
      lng: -105.1231,
      population: 5869,
      density: 1783.4,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0K",
      id: 1124147660
    },
    {
      label: "Charlemagne",
      value: "Charlemagne",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7167,
      lng: -73.4833,
      population: 5853,
      density: 2704,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "J5Z",
      id: 1124185024
    },
    {
      label: "Pontiac",
      value: "Pontiac",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5833,
      lng: -76.1333,
      population: 5850,
      density: 13.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0X",
      id: 1124000248
    },
    {
      label: "St. Paul",
      value: "St. Paul",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.9928,
      lng: -111.2972,
      population: 5827,
      density: 674.2,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0A",
      id: 1124528022
    },
    {
      label: "Petrolia",
      value: "Petrolia",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.8833,
      lng: -82.1417,
      population: 5742,
      density: 452.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0N",
      id: 1124479624
    },
    {
      label: "Southwest Middlesex",
      value: "Southwest Middlesex",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.75,
      lng: -81.7,
      population: 5723,
      density: 13.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0L",
      id: 1124000520
    },
    {
      label: "Front of Yonge",
      value: "Front of Yonge",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.5333,
      lng: -75.8667,
      population: 5710,
      density: 20.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0E",
      id: 1124001901
    },
    {
      label: "Vegreville",
      value: "Vegreville",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.4928,
      lng: -112.0522,
      population: 5708,
      density: 405.4,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T9C",
      id: 1124534321
    },
    {
      label: "Sainte-Brigitte-de-Laval",
      value: "Sainte-Brigitte-de-Laval",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47,
      lng: -71.2,
      population: 5696,
      density: 52.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124647754
    },
    {
      label: "Princeville",
      value: "Princeville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1667,
      lng: -71.8833,
      population: 5693,
      density: 29.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G6L",
      id: 1124715340
    },
    {
      label: "Verchères",
      value: "Vercheres",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7833,
      lng: -73.35,
      population: 5692,
      density: 77.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124549666
    },
    {
      label: "The Pas",
      value: "The Pas",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 53.825,
      lng: -101.2533,
      population: 5689,
      density: 115.3,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R9A R0B",
      id: 1124755168
    },
    {
      label: "Saint-Césaire",
      value: "Saint-Cesaire",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4167,
      lng: -73,
      population: 5686,
      density: 68.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124948389
    },
    {
      label: "La Ronge",
      value: "La Ronge",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 55.1,
      lng: -105.3,
      population: 5671,
      density: 163.9,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0J",
      id: 1124763455
    },
    {
      label: "Tay Valley",
      value: "Tay Valley",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.8667,
      lng: -76.3833,
      population: 5665,
      density: 10.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7H K0G K0H",
      id: 1124000734
    },
    {
      label: "South Bruce",
      value: "South Bruce",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.0333,
      lng: -81.2,
      population: 5639,
      density: 11.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0G",
      id: 1124001457
    },
    {
      label: "McMasterville",
      value: "McMasterville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.55,
      lng: -73.2333,
      population: 5615,
      density: 1810.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3G",
      id: 1124000115
    },
    {
      label: "Redcliff",
      value: "Redcliff",
      province_id: "AB",
      province_name: "Alberta",
      lat: 50.0792,
      lng: -110.7783,
      population: 5600,
      density: 344.6,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0J",
      id: 1124603057
    },
    {
      label: "Crowsnest Pass",
      value: "Crowsnest Pass",
      province_id: "AB",
      province_name: "Alberta",
      lat: 49.5955,
      lng: -114.5136,
      population: 5589,
      density: 15,
      timezone: "America/Edmonton",
      ranking: 4,
      postal: "T0K",
      id: 1124000595
    },
    {
      label: "Saint-Philippe",
      value: "Saint-Philippe",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.35,
      lng: -73.47,
      population: 5495,
      density: 88.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124461923
    },
    {
      label: "Richelieu",
      value: "Richelieu",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.45,
      lng: -73.25,
      population: 5467,
      density: 176.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3L",
      id: 1124000387
    },
    {
      label: "Notre-Dame-du-Mont-Carmel",
      value: "Notre-Dame-du-Mont-Carmel",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4833,
      lng: -72.65,
      population: 5467,
      density: 42.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124893320
    },
    {
      label: "L'Ange-Gardien",
      value: "L'Ange-Gardien",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5833,
      lng: -75.45,
      population: 5464,
      density: 25,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J8L",
      id: 1124001197
    },
    {
      label: "Sainte-Martine",
      value: "Sainte-Martine",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.25,
      lng: -73.8,
      population: 5461,
      density: 86.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0S",
      id: 1124000017
    },
    {
      label: "Saint-Pie",
      value: "Saint-Pie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5,
      lng: -72.9,
      population: 5438,
      density: 49.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124508787
    },
    {
      label: "Peachland",
      value: "Peachland",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.7736,
      lng: -119.7369,
      population: 5428,
      density: 340.1,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0H",
      id: 1124440160
    },
    {
      label: "Ashfield-Colborne-Wawanosh",
      value: "Ashfield-Colborne-Wawanosh",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.8667,
      lng: -81.6,
      population: 5422,
      density: 9.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0M N0G N7A",
      id: 1124000025
    },
    {
      label: "Trent Lakes",
      value: "Trent Lakes",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.6667,
      lng: -78.4333,
      population: 5397,
      density: 6.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0L K0M",
      id: 1124001268
    },
    {
      label: "Northern Rockies",
      value: "Northern Rockies",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 59,
      lng: -123.75,
      population: 5393,
      density: 0.063,
      timezone: "America/Fort_Nelson",
      ranking: 3,
      postal: "V0C",
      id: 1124001362
    },
    {
      label: "Cookshire",
      value: "Cookshire",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3729,
      lng: -71.672,
      population: 5393,
      density: 18.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0B",
      id: 1124895156
    },
    {
      label: "West St. Paul",
      value: "West St. Paul",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.0119,
      lng: -97.115,
      population: 5368,
      density: 61.1,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R4A R2V",
      id: 1124001136
    },
    {
      label: "Windsor",
      value: "Windsor",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5667,
      lng: -72,
      population: 5367,
      density: 375.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J1S",
      id: 1124798166
    },
    {
      label: "L’Epiphanie",
      value: "L'Epiphanie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.85,
      lng: -73.4833,
      population: 5353,
      density: 2367.9,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "J5X",
      id: 1124599436
    },
    {
      label: "Creston",
      value: "Creston",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.09,
      lng: -116.51,
      population: 5351,
      density: 626.8,
      timezone: "America/Creston",
      ranking: 3,
      postal: "V0B",
      id: 1124204302
    },
    {
      label: "Smithers",
      value: "Smithers",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 54.7819,
      lng: -127.1681,
      population: 5351,
      density: 514.9,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0J",
      id: 1124191574
    },
    {
      label: "Cornwall",
      value: "Cornwall",
      province_id: "PE",
      province_name: "Prince Edward Island",
      lat: 46.2407,
      lng: -63.2009,
      population: 5348,
      density: 189.7,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "C0A",
      id: 1124001245
    },
    {
      label: "Meadow Lake",
      value: "Meadow Lake",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 54.1242,
      lng: -108.4358,
      population: 5344,
      density: 433.6,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S9X",
      id: 1124434578
    },
    {
      label: "Lanark Highlands",
      value: "Lanark Highlands",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.088,
      lng: -76.517,
      population: 5338,
      density: 5.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0A K0G",
      id: 1124000887
    },
    {
      label: "Sackville",
      value: "Sackville",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.9,
      lng: -64.3667,
      population: 5331,
      density: 71.9,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E4L",
      id: 1124877244
    },
    {
      label: "Grand Falls",
      value: "Grand Falls",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.0344,
      lng: -67.7394,
      population: 5326,
      density: 294.4,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E3Z",
      id: 1124904843
    },
    {
      label: "Cochrane",
      value: "Cochrane",
      province_id: "ON",
      province_name: "Ontario",
      lat: 49.0667,
      lng: -81.0167,
      population: 5321,
      density: 9.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0L",
      id: 1124296729
    },
    {
      label: "Marystown",
      value: "Marystown",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.1667,
      lng: -55.1667,
      population: 5316,
      density: 85.8,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0E",
      id: 1124408131
    },
    {
      label: "Sioux Lookout",
      value: "Sioux Lookout",
      province_id: "ON",
      province_name: "Ontario",
      lat: 50.1,
      lng: -91.9167,
      population: 5272,
      density: 13.9,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "P0V P8T",
      id: 1124804545
    },
    {
      label: "Didsbury",
      value: "Didsbury",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.6658,
      lng: -114.1311,
      population: 5268,
      density: 321.7,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0M",
      id: 1124574154
    },
    {
      label: "Saint-Honoré",
      value: "Saint-Honore",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.5333,
      lng: -71.0833,
      population: 5257,
      density: 27.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0V",
      id: 1124504668
    },
    {
      label: "Fernie",
      value: "Fernie",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.5042,
      lng: -115.0628,
      population: 5249,
      density: 388.8,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "V0B",
      id: 1124927114
    },
    {
      label: "Deer Lake",
      value: "Deer Lake",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 49.1744,
      lng: -57.4269,
      population: 5249,
      density: 71.7,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A8A",
      id: 1124841556
    },
    {
      label: "Woodstock",
      value: "Woodstock",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.1522,
      lng: -67.5983,
      population: 5228,
      density: 356.7,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E7M",
      id: 1124035280
    },
    {
      label: "Val-David",
      value: "Val-David",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.03,
      lng: -74.22,
      population: 5209,
      density: 103.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0T",
      id: 1124707666
    },
    {
      label: "Flin Flon",
      value: "Flin Flon",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 54.7667,
      lng: -101.8778,
      population: 5185,
      density: 448.9,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "S0P",
      id: 1124500458
    },
    {
      label: "Hudson",
      value: "Hudson",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.45,
      lng: -74.15,
      population: 5165,
      density: 234.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0P",
      id: 1124590540
    },
    {
      label: "Gananoque",
      value: "Gananoque",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.33,
      lng: -76.17,
      population: 5159,
      density: 733.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7G",
      id: 1124349596
    },
    {
      label: "Brokenhead",
      value: "Brokenhead",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.1428,
      lng: -96.5319,
      population: 5122,
      density: 6.8,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0E",
      id: 1124000321
    },
    {
      label: "Saint-Paul",
      value: "Saint-Paul",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9833,
      lng: -73.45,
      population: 5122,
      density: 103.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124001817
    },
    {
      label: "Burton",
      value: "Burton",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.8009,
      lng: -66.4066,
      population: 5119,
      density: 19.8,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E2V",
      id: 1124000544
    },
    {
      label: "Spallumcheen",
      value: "Spallumcheen",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.4462,
      lng: -119.2121,
      population: 5106,
      density: 20,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V0E",
      id: 1124001340
    },
    {
      label: "Westlock",
      value: "Westlock",
      province_id: "AB",
      province_name: "Alberta",
      lat: 54.1522,
      lng: -113.8511,
      population: 5101,
      density: 381.4,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T7P",
      id: 1124037311
    },
    {
      label: "Témiscouata-sur-le-Lac",
      value: "Temiscouata-sur-le-Lac",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.68,
      lng: -68.88,
      population: 5096,
      density: 23.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0L",
      id: 1124001776
    },
    {
      label: "Shannon",
      value: "Shannon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8833,
      lng: -71.5167,
      population: 5086,
      density: 79.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124440867
    },
    {
      label: "Osoyoos",
      value: "Osoyoos",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.0325,
      lng: -119.4661,
      population: 5085,
      density: 598.2,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0H",
      id: 1124713973
    },
    {
      label: "Montréal-Ouest",
      value: "Montreal-Ouest",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4536,
      lng: -73.6472,
      population: 5085,
      density: 3614.6,
      timezone: "America/Montreal",
      ranking: 2,
      postal: "H4X",
      id: 1124001742
    },
    {
      label: "Hearst",
      value: "Hearst",
      province_id: "ON",
      province_name: "Ontario",
      lat: 49.6869,
      lng: -83.6544,
      population: 5070,
      density: 51.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0L",
      id: 1124376843
    },
    {
      label: "Saint-Henri",
      value: "Saint-Henri",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.7,
      lng: -71.0667,
      population: 5023,
      density: 41.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0R",
      id: 1124057702
    },
    {
      label: "Ste. Anne",
      value: "Ste. Anne",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.6186,
      lng: -96.5708,
      population: 5003,
      density: 10.5,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R5H R5G R0A R0E",
      id: 1124000501
    },
    {
      label: "Antigonish",
      value: "Antigonish",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.6167,
      lng: -61.9833,
      population: 5002,
      density: 871.7,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B2G",
      id: 1124839247
    },
    {
      label: "Espanola",
      value: "Espanola",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.25,
      lng: -81.7667,
      population: 4996,
      density: 60.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P5E",
      id: 1124133485
    },
    {
      label: "West Elgin",
      value: "West Elgin",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.5833,
      lng: -81.6667,
      population: 4995,
      density: 15.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0L",
      id: 1124000948
    },
    {
      label: "Flin Flon (Part)",
      value: "Flin Flon (Part)",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 54.7712,
      lng: -101.8419,
      population: 4982,
      density: 359.2,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R8A",
      id: 1124000122
    },
    {
      label: "Grand Bay-Westfield",
      value: "Grand Bay-Westfield",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.3608,
      lng: -66.2415,
      population: 4964,
      density: 83,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E5K",
      id: 1124001504
    },
    {
      label: "Sainte-Anne-de-Bellevue",
      value: "Sainte-Anne-de-Bellevue",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4039,
      lng: -73.9525,
      population: 4958,
      density: 473,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "H9X",
      id: 1124418135
    },
    {
      label: "North Huron",
      value: "North Huron",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.83,
      lng: -81.42,
      population: 4932,
      density: 27.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0G",
      id: 1124001142
    },
    {
      label: "Oliver",
      value: "Oliver",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.1844,
      lng: -119.55,
      population: 4928,
      density: 896,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0H",
      id: 1124145879
    },
    {
      label: "Saint-Roch-de-l'Achigan",
      value: "Saint-Roch-de-l'Achigan",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.85,
      lng: -73.6,
      population: 4892,
      density: 60.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124000365
    },
    {
      label: "Stirling-Rawdon",
      value: "Stirling-Rawdon",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.3667,
      lng: -77.5917,
      population: 4882,
      density: 17.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0K",
      id: 1124001948
    },
    {
      label: "Chisasibi",
      value: "Chisasibi",
      province_id: "QC",
      province_name: "Quebec",
      lat: 53.6645,
      lng: -78.7938,
      population: 4872,
      density: 5.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0M",
      id: 1124000072
    },
    {
      label: "Carbonear",
      value: "Carbonear",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.7375,
      lng: -53.2294,
      population: 4838,
      density: 411.5,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A1Y",
      id: 1124121214
    },
    {
      label: "Saint Marys",
      value: "Saint Marys",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.1748,
      lng: -66.4897,
      population: 4837,
      density: 6.4,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E6C E3A",
      id: 1124000221
    },
    {
      label: "Chertsey",
      value: "Chertsey",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.17,
      lng: -73.92,
      population: 4836,
      density: 16.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124001234
    },
    {
      label: "Armstrong",
      value: "Armstrong",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.4483,
      lng: -119.1961,
      population: 4830,
      density: 927.7,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0E",
      id: 1124201947
    },
    {
      label: "Stonewall",
      value: "Stonewall",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.1344,
      lng: -97.3261,
      population: 4809,
      density: 802.8,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0C",
      id: 1124829875
    },
    {
      label: "Shippagan",
      value: "Shippagan",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.8557,
      lng: -64.6012,
      population: 4800,
      density: 23.1,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E8T E8S",
      id: 1124001772
    },
    {
      label: "Lanoraie",
      value: "Lanoraie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9667,
      lng: -73.2167,
      population: 4787,
      density: 46.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124453107
    },
    {
      label: "Memramcook",
      value: "Memramcook",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46,
      lng: -64.55,
      population: 4778,
      density: 25.5,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E4K",
      id: 1124833147
    },
    {
      label: "Centre Hastings",
      value: "Centre Hastings",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.4167,
      lng: -77.4417,
      population: 4774,
      density: 20.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0K",
      id: 1124000705
    },
    {
      label: "Warwick",
      value: "Warwick",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.95,
      lng: -71.9833,
      population: 4766,
      density: 43.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0A",
      id: 1124510688
    },
    {
      label: "East Ferris",
      value: "East Ferris",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.2667,
      lng: -79.3,
      population: 4750,
      density: 30.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0H",
      id: 1124000523
    },
    {
      label: "Hanwell",
      value: "Hanwell",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.8681,
      lng: -66.7947,
      population: 4750,
      density: 31.4,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E3E E3B",
      id: 1124001405
    },
    {
      label: "Saint-Joseph-de-Beauce",
      value: "Saint-Joseph-de-Beauce",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3,
      lng: -70.8833,
      population: 4722,
      density: 42.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0S",
      id: 1124865207
    },
    {
      label: "Metchosin",
      value: "Metchosin",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.3819,
      lng: -123.5378,
      population: 4708,
      density: 66.2,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V9C",
      id: 1124625175
    },
    {
      label: "Lucan Biddulph",
      value: "Lucan Biddulph",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.2,
      lng: -81.3833,
      population: 4700,
      density: 27.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0M",
      id: 1124000469
    },
    {
      label: "Rivière-Rouge",
      value: "Riviere-Rouge",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4167,
      lng: -74.8667,
      population: 4645,
      density: 10.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0T",
      id: 1124001720
    },
    {
      label: "Greenstone",
      value: "Greenstone",
      province_id: "ON",
      province_name: "Ontario",
      lat: 50,
      lng: -86.7333,
      population: 4636,
      density: 1.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0T",
      id: 1124000068
    },
    {
      label: "Saint-Mathias-sur-Richelieu",
      value: "Saint-Mathias-sur-Richelieu",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4667,
      lng: -73.2667,
      population: 4618,
      density: 97.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3L",
      id: 1124000576
    },
    {
      label: "Neepawa",
      value: "Neepawa",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.2289,
      lng: -99.4656,
      population: 4609,
      density: 206.5,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0J",
      id: 1124375380
    },
    {
      label: "Gibsons",
      value: "Gibsons",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.4028,
      lng: -123.5036,
      population: 4605,
      density: 1066,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0N",
      id: 1124342069
    },
    {
      label: "Kindersley",
      value: "Kindersley",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.4678,
      lng: -109.1567,
      population: 4597,
      density: 347.5,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0L",
      id: 1124343190
    },
    {
      label: "Jasper",
      value: "Jasper",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.9013,
      lng: -118.1312,
      population: 4590,
      density: 5,
      timezone: "America/Edmonton",
      ranking: 4,
      postal: "T0E",
      id: 1124533812
    },
    {
      label: "Barrhead",
      value: "Barrhead",
      province_id: "AB",
      province_name: "Alberta",
      lat: 54.1167,
      lng: -114.4,
      population: 4579,
      density: 560.4,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T7N",
      id: 1124181687
    },
    {
      label: "Les Coteaux",
      value: "Les Coteaux",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.28,
      lng: -74.23,
      population: 4568,
      density: 393.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7X",
      id: 1124001989
    },
    {
      label: "Melville",
      value: "Melville",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.9306,
      lng: -102.8078,
      population: 4562,
      density: 307.8,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0A",
      id: 1124823140
    },
    {
      label: "Saint-Germain-de-Grantham",
      value: "Saint-Germain-de-Grantham",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8333,
      lng: -72.5667,
      population: 4551,
      density: 51.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0C",
      id: 1124972184
    },
    {
      label: "Iroquois Falls",
      value: "Iroquois Falls",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.7667,
      lng: -80.6667,
      population: 4537,
      density: 7.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0K",
      id: 1124652927
    },
    {
      label: "Havelock-Belmont-Methuen",
      value: "Havelock-Belmont-Methuen",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.5667,
      lng: -77.9,
      population: 4530,
      density: 8.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0L",
      id: 1124001644
    },
    {
      label: "Cornwallis",
      value: "Cornwallis",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.7981,
      lng: -99.8481,
      population: 4520,
      density: 9,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R7A",
      id: 1124000766
    },
    {
      label: "Saint-Boniface",
      value: "Saint-Boniface",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5,
      lng: -72.8167,
      population: 4511,
      density: 41.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124000235
    },
    {
      label: "Edenwold No. 158",
      value: "Edenwold No. 158",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.5166,
      lng: -104.3451,
      population: 4490,
      density: 5.3,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0G S4L",
      id: 1124001180
    },
    {
      label: "Coverdale",
      value: "Coverdale",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.0003,
      lng: -64.8859,
      population: 4466,
      density: 18.9,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E1J E4J",
      id: 1124001531
    },
    {
      label: "Vanderhoof",
      value: "Vanderhoof",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 54.0143,
      lng: -124.0089,
      population: 4439,
      density: 81,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0J",
      id: 1124100075
    },
    {
      label: "Southwold",
      value: "Southwold",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.75,
      lng: -81.3167,
      population: 4421,
      density: 14.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N5P N5L N0L",
      id: 1124001461
    },
    {
      label: "Goulds",
      value: "Goulds",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.4517,
      lng: -52.7647,
      population: 4418,
      density: 721.3,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A1S",
      id: 1124000955
    },
    {
      label: "Saint Stephen",
      value: "Saint Stephen",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.2,
      lng: -67.2833,
      population: 4415,
      density: 326.6,
      timezone: "America/New_York",
      ranking: 3,
      postal: "E3L",
      id: 1124128038
    },
    {
      label: "Waterloo",
      value: "Waterloo",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.35,
      lng: -72.5167,
      population: 4410,
      density: 360.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0E",
      id: 1124389471
    },
    {
      label: "Nipawin",
      value: "Nipawin",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.3572,
      lng: -104.0192,
      population: 4401,
      density: 505,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0E",
      id: 1124567955
    },
    {
      label: "Neuville",
      value: "Neuville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.7,
      lng: -71.5833,
      population: 4392,
      density: 61,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124862301
    },
    {
      label: "Saint-Cyrille-de-Wendover",
      value: "Saint-Cyrille-de-Wendover",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9333,
      lng: -72.4333,
      population: 4389,
      density: 39.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J1Z",
      id: 1124582350
    },
    {
      label: "Central Frontenac",
      value: "Central Frontenac",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.7167,
      lng: -76.8,
      population: 4373,
      density: 4.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0H",
      id: 1124000254
    },
    {
      label: "Mont-Orford",
      value: "Mont-Orford",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3661,
      lng: -72.1838,
      population: 4337,
      density: 31.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J1X",
      id: 1124618048
    },
    {
      label: "Saint-Jean-de-Matha",
      value: "Saint-Jean-de-Matha",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.23,
      lng: -73.53,
      population: 4335,
      density: 39.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124833822
    },
    {
      label: "Seguin",
      value: "Seguin",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.2882,
      lng: -79.8116,
      population: 4304,
      density: 7.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0C P2A",
      id: 1124001464
    },
    {
      label: "Tyendinaga",
      value: "Tyendinaga",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.3,
      lng: -77.2,
      population: 4297,
      density: 13.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0K",
      id: 1124000109
    },
    {
      label: "Hampton",
      value: "Hampton",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.533,
      lng: -65.833,
      population: 4289,
      density: 203.4,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E5N",
      id: 1124175945
    },
    {
      label: "Sussex",
      value: "Sussex",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.7167,
      lng: -65.5167,
      population: 4282,
      density: 478.3,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E5P E4E",
      id: 1124362993
    },
    {
      label: "Grand Forks",
      value: "Grand Forks",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.0333,
      lng: -118.44,
      population: 4274,
      density: 388.1,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0H",
      id: 1124547325
    },
    {
      label: "La Pocatière",
      value: "La Pocatiere",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.3667,
      lng: -70.0333,
      population: 4266,
      density: 196.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124845219
    },
    {
      label: "Caraquet",
      value: "Caraquet",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.7853,
      lng: -64.9592,
      population: 4248,
      density: 62.1,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E1W",
      id: 1124593896
    },
    {
      label: "Saint-Étienne-des-Grès",
      value: "Saint-Etienne-des-Gres",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4333,
      lng: -72.7667,
      population: 4217,
      density: 40.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124635032
    },
    {
      label: "Altona",
      value: "Altona",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1044,
      lng: -97.5625,
      population: 4212,
      density: 445.2,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0G",
      id: 1124628560
    },
    {
      label: "Stellarton",
      value: "Stellarton",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.5567,
      lng: -62.66,
      population: 4208,
      density: 468.1,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0K",
      id: 1124388660
    },
    {
      label: "Wolfville",
      value: "Wolfville",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.0833,
      lng: -64.3667,
      population: 4195,
      density: 649.8,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B4P",
      id: 1124909280
    },
    {
      label: "New Maryland",
      value: "New Maryland",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.8911,
      lng: -66.6847,
      population: 4174,
      density: 195.7,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E3C",
      id: 1124001875
    },
    {
      label: "Port Hardy",
      value: "Port Hardy",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.7225,
      lng: -127.4928,
      population: 4132,
      density: 106.7,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0N",
      id: 1124937351
    },
    {
      label: "Saint-Donat",
      value: "Saint-Donat",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3167,
      lng: -74.2167,
      population: 4130,
      density: 11.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0T",
      id: 1124430126
    },
    {
      label: "Château-Richer",
      value: "Chateau-Richer",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.9667,
      lng: -71.0167,
      population: 4126,
      density: 18,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124518769
    },
    {
      label: "Madawaska Valley",
      value: "Madawaska Valley",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.5,
      lng: -77.6667,
      population: 4123,
      density: 6.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0J",
      id: 1124000653
    },
    {
      label: "Deep River",
      value: "Deep River",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.1,
      lng: -77.4917,
      population: 4109,
      density: 82,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "K0J",
      id: 1124309248
    },
    {
      label: "Asphodel-Norwood",
      value: "Asphodel-Norwood",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.3531,
      lng: -78.0183,
      population: 4109,
      density: 25.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0L",
      id: 1124001973
    },
    {
      label: "Red Lake",
      value: "Red Lake",
      province_id: "ON",
      province_name: "Ontario",
      lat: 51.0333,
      lng: -93.8333,
      population: 4107,
      density: 6.7,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "P0V",
      id: 1124856215
    },
    {
      label: "Métabetchouan-Lac-à-la-Croix",
      value: "Metabetchouan-Lac-a-la-Croix",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.4333,
      lng: -71.8667,
      population: 4097,
      density: 21.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G8G",
      id: 1124093309
    },
    {
      label: "Berthierville",
      value: "Berthierville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0833,
      lng: -73.1833,
      population: 4091,
      density: 596.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124734495
    },
    {
      label: "Vermilion",
      value: "Vermilion",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.3542,
      lng: -110.8528,
      population: 4084,
      density: 315.8,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T9X",
      id: 1124995979
    },
    {
      label: "Niverville",
      value: "Niverville",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.6056,
      lng: -97.0417,
      population: 4083,
      density: 1767.5,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0A",
      id: 1124001529
    },
    {
      label: "Hastings Highlands",
      value: "Hastings Highlands",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.2333,
      lng: -77.9333,
      population: 4078,
      density: 4.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0J K0L",
      id: 1124000285
    },
    {
      label: "Carstairs",
      value: "Carstairs",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.5619,
      lng: -114.0953,
      population: 4077,
      density: 342.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0M",
      id: 1124621475
    },
    {
      label: "Danville",
      value: "Danville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7833,
      lng: -72.0167,
      population: 4070,
      density: 26.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0A J1T",
      id: 1124290094
    },
    {
      label: "Channel-Port aux Basques",
      value: "Channel-Port aux Basques",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.5694,
      lng: -59.1361,
      population: 4067,
      density: 104.9,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0M",
      id: 1124993496
    },
    {
      label: "Battleford",
      value: "Battleford",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.7383,
      lng: -108.3153,
      population: 4065,
      density: 174.2,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0M",
      id: 1124885955
    },
    {
      label: "Lac-Etchemin",
      value: "Lac-Etchemin",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4,
      lng: -70.5,
      population: 4061,
      density: 25.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124000895
    },
    {
      label: "Saint-Antonin",
      value: "Saint-Antonin",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.7667,
      lng: -69.4833,
      population: 4027,
      density: 22.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0L",
      id: 1124990343
    },
    {
      label: "Saint-Jacques",
      value: "Saint-Jacques",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.95,
      lng: -73.5667,
      population: 4021,
      density: 59.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124472694
    },
    {
      label: "Swan River",
      value: "Swan River",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 52.1058,
      lng: -101.2667,
      population: 4014,
      density: 560.4,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0L",
      id: 1124210942
    },
    {
      label: "Sutton",
      value: "Sutton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.091,
      lng: -72.5792,
      population: 4012,
      density: 16.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0E",
      id: 1124001526
    },
    {
      label: "Northern Bruce Peninsula",
      value: "Northern Bruce Peninsula",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.08,
      lng: -81.38,
      population: 3999,
      density: 5.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0H",
      id: 1124000606
    },
    {
      label: "L’Islet-sur-Mer",
      value: "L'Islet-sur-Mer",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.1,
      lng: -70.35,
      population: 3999,
      density: 33.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124309185
    },
    {
      label: "Carleton-sur-Mer",
      value: "Carleton-sur-Mer",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.1,
      lng: -66.1333,
      population: 3991,
      density: 18,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0C",
      id: 1124001943
    },
    {
      label: "Oka",
      value: "Oka",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.47,
      lng: -74.08,
      population: 3969,
      density: 69.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0N",
      id: 1124446142
    },
    {
      label: "Prescott",
      value: "Prescott",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.7167,
      lng: -75.5167,
      population: 3965,
      density: 1273.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0E",
      id: 1124461297
    },
    {
      label: "Amaranth",
      value: "Amaranth",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.9833,
      lng: -80.2333,
      population: 3963,
      density: 15,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L9W",
      id: 1124001154
    },
    {
      label: "Marmora and Lake",
      value: "Marmora and Lake",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.6425,
      lng: -77.7372,
      population: 3953,
      density: 7.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0K K0L",
      id: 1124000420
    },
    {
      label: "Maniwaki",
      value: "Maniwaki",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.375,
      lng: -75.9667,
      population: 3930,
      density: 677.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J9E",
      id: 1124137130
    },
    {
      label: "Morin-Heights",
      value: "Morin-Heights",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9,
      lng: -74.25,
      population: 3925,
      density: 69.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0R",
      id: 1124001231
    },
    {
      label: "Dundas",
      value: "Dundas",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.3155,
      lng: -64.6947,
      population: 3914,
      density: 22.4,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4R E4V",
      id: 1124001475
    },
    {
      label: "Napierville",
      value: "Napierville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.1833,
      lng: -73.4,
      population: 3899,
      density: 796.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124015883
    },
    {
      label: "Crabtree",
      value: "Crabtree",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9667,
      lng: -73.4667,
      population: 3887,
      density: 155,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124136084
    },
    {
      label: "Bancroft",
      value: "Bancroft",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.05,
      lng: -77.85,
      population: 3881,
      density: 16.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0L",
      id: 1124451822
    },
    {
      label: "Saint-Tite",
      value: "Saint-Tite",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.7333,
      lng: -72.5667,
      population: 3880,
      density: 41.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124821328
    },
    {
      label: "Howick",
      value: "Howick",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.9,
      lng: -81.07,
      population: 3873,
      density: 13.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0G",
      id: 1124000394
    },
    {
      label: "Dutton/Dunwich",
      value: "Dutton/Dunwich",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.6667,
      lng: -81.5,
      population: 3866,
      density: 13.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0L",
      id: 1124000540
    },
    {
      label: "Callander",
      value: "Callander",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.1781,
      lng: -79.4125,
      population: 3863,
      density: 36.4,
      timezone: "America/Toronto",
      ranking: 4,
      postal: "P0H",
      id: 1124853285
    },
    {
      label: "Simonds",
      value: "Simonds",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.3145,
      lng: -65.803,
      population: 3843,
      density: 13.7,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E2S",
      id: 1124001671
    },
    {
      label: "Baie-d’Urfé",
      value: "Baie-d'Urfe",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4167,
      lng: -73.9167,
      population: 3823,
      density: 633.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "H9X",
      id: 1124534130
    },
    {
      label: "New Richmond",
      value: "New Richmond",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.1667,
      lng: -65.8667,
      population: 3810,
      density: 22.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0C",
      id: 1124960222
    },
    {
      label: "Perth South",
      value: "Perth South",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.3,
      lng: -81.15,
      population: 3810,
      density: 9.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N4X N5A N0K",
      id: 1124000996
    },
    {
      label: "Roxton Pond",
      value: "Roxton Pond",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4833,
      lng: -72.6667,
      population: 3786,
      density: 38.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0E",
      id: 1124356503
    },
    {
      label: "Sparwood",
      value: "Sparwood",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.7331,
      lng: -114.8853,
      population: 3784,
      density: 19.4,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "V0B",
      id: 1124001718
    },
    {
      label: "Claresholm",
      value: "Claresholm",
      province_id: "AB",
      province_name: "Alberta",
      lat: 50.0194,
      lng: -113.5783,
      population: 3780,
      density: 465.9,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0L",
      id: 1124380878
    },
    {
      label: "Breslau",
      value: "Breslau",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.4816,
      lng: -80.408,
      population: 3778,
      density: 691.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0B",
      id: 1124001083
    },
    {
      label: "Montague",
      value: "Montague",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.9667,
      lng: -75.9667,
      population: 3761,
      density: 13.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7A",
      id: 1124001810
    },
    {
      label: "Cumberland",
      value: "Cumberland",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.6206,
      lng: -125.0261,
      population: 3753,
      density: 128.9,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0R",
      id: 1124658693
    },
    {
      label: "Beaupré",
      value: "Beaupre",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.05,
      lng: -70.9,
      population: 3752,
      density: 163.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124125524
    },
    {
      label: "Saint-André-Avellin",
      value: "Saint-Andre-Avellin",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7167,
      lng: -75.0667,
      population: 3749,
      density: 27.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0V",
      id: 1124494033
    },
    {
      label: "Saint-Ambroise-de-Kildare",
      value: "Saint-Ambroise-de-Kildare",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0833,
      lng: -73.55,
      population: 3747,
      density: 55.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124306240
    },
    {
      label: "East Angus",
      value: "East Angus",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4833,
      lng: -71.6667,
      population: 3741,
      density: 472.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0B",
      id: 1124456321
    },
    {
      label: "Rossland",
      value: "Rossland",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.0786,
      lng: -117.7992,
      population: 3729,
      density: 62.4,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0G",
      id: 1124850810
    },
    {
      label: "Mackenzie",
      value: "Mackenzie",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 55.3381,
      lng: -123.0944,
      population: 3714,
      density: 22.6,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0J",
      id: 1124001437
    },
    {
      label: "Golden",
      value: "Golden",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 51.3019,
      lng: -116.9667,
      population: 3708,
      density: 325,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "V0A",
      id: 1124428625
    },
    {
      label: "Raymond",
      value: "Raymond",
      province_id: "AB",
      province_name: "Alberta",
      lat: 49.4658,
      lng: -112.6508,
      population: 3708,
      density: 557.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0K",
      id: 1124125903
    },
    {
      label: "Saint-Adolphe-d'Howard",
      value: "Saint-Adolphe-d'Howard",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.97,
      lng: -74.33,
      population: 3702,
      density: 26.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0T",
      id: 1124001188
    },
    {
      label: "Warwick",
      value: "Warwick",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43,
      lng: -81.8917,
      population: 3692,
      density: 12.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0M N0N",
      id: 1124864287
    },
    {
      label: "Bowen Island",
      value: "Bowen Island",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.3833,
      lng: -123.3833,
      population: 3680,
      density: 73.4,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0N",
      id: 1124000418
    },
    {
      label: "Bonnechere Valley",
      value: "Bonnechere Valley",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.5333,
      lng: -77.1,
      population: 3674,
      density: 6.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0J",
      id: 1124000398
    },
    {
      label: "Windsor",
      value: "Windsor",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 44.9803,
      lng: -64.1292,
      population: 3648,
      density: 400.6,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0N",
      id: 1124800737
    },
    {
      label: "Pincher Creek",
      value: "Pincher Creek",
      province_id: "AB",
      province_name: "Alberta",
      lat: 49.4861,
      lng: -113.95,
      population: 3642,
      density: 361.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0K",
      id: 1124252125
    },
    {
      label: "Alnwick",
      value: "Alnwick",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.2656,
      lng: -65.2292,
      population: 3640,
      density: 5.4,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E1V E9G E9H",
      id: 1124000627
    },
    {
      label: "Westville",
      value: "Westville",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.55,
      lng: -62.7,
      population: 3628,
      density: 254.9,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0K",
      id: 1124476279
    },
    {
      label: "Fruitvale",
      value: "Fruitvale",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.1161,
      lng: -117.5414,
      population: 3627,
      density: 1363.5,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0G",
      id: 1124854890
    },
    {
      label: "Pasadena",
      value: "Pasadena",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 49.0161,
      lng: -57.605,
      population: 3620,
      density: 73.6,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0L",
      id: 1124001778
    },
    {
      label: "Saint-Prosper",
      value: "Saint-Prosper",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2167,
      lng: -70.4833,
      population: 3605,
      density: 27,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0M",
      id: 1124232575
    },
    {
      label: "Ormstown",
      value: "Ormstown",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.13,
      lng: -74,
      population: 3595,
      density: 25.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0S",
      id: 1124670346
    },
    {
      label: "Cardston",
      value: "Cardston",
      province_id: "AB",
      province_name: "Alberta",
      lat: 49.2025,
      lng: -113.3019,
      population: 3585,
      density: 417.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0K",
      id: 1124479644
    },
    {
      label: "Westbank",
      value: "Westbank",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.8423,
      lng: -119.6743,
      population: 3581,
      density: 80.6,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V4T",
      id: 1124001101
    },
    {
      label: "De Salaberry",
      value: "De Salaberry",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.4403,
      lng: -96.9844,
      population: 3580,
      density: 5.3,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0A",
      id: 1124001664
    },
    {
      label: "Headingley",
      value: "Headingley",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.8681,
      lng: -97.3908,
      population: 3579,
      density: 33.4,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R4H R4J",
      id: 1124000273
    },
    {
      label: "Grande Cache",
      value: "Grande Cache",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.8773,
      lng: -119.1199,
      population: 3571,
      density: 102.1,
      timezone: "America/Edmonton",
      ranking: 4,
      postal: "T0E",
      id: 1124001952
    },
    {
      label: "Atholville",
      value: "Atholville",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.9894,
      lng: -66.7125,
      population: 3570,
      density: 29.8,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E3N",
      id: 1124001302
    },
    {
      label: "Saint-Agapit",
      value: "Saint-Agapit",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5667,
      lng: -71.4333,
      population: 3567,
      density: 54.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124119699
    },
    {
      label: "Prince Albert No. 461",
      value: "Prince Albert No. 461",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.1089,
      lng: -105.6574,
      population: 3562,
      density: 3.5,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S6V",
      id: 1124001802
    },
    {
      label: "Casselman",
      value: "Casselman",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.3,
      lng: -75.0833,
      population: 3548,
      density: 693.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0A",
      id: 1124666499
    },
    {
      label: "Saint-Ambroise",
      value: "Saint-Ambroise",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.55,
      lng: -71.3333,
      population: 3546,
      density: 23.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G7P",
      id: 1124001342
    },
    {
      label: "Hay River",
      value: "Hay River",
      province_id: "NT",
      province_name: "Northwest Territories",
      lat: 60.7531,
      lng: -115.9004,
      population: 3528,
      density: 26.5,
      timezone: "America/Yellowknife",
      ranking: 4,
      postal: "X0E",
      id: 1124721803
    },
    {
      label: "Mistissini",
      value: "Mistissini",
      province_id: "QC",
      province_name: "Quebec",
      lat: 50.5707,
      lng: -73.6829,
      population: 3523,
      density: 4.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0W J0Y",
      id: 1124001942
    },
    {
      label: "Studholm",
      value: "Studholm",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.8133,
      lng: -65.5747,
      population: 3522,
      density: 7.8,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E5P E4G",
      id: 1124001373
    },
    {
      label: "Lumby",
      value: "Lumby",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.2494,
      lng: -118.9656,
      population: 3500,
      density: 379.5,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0E",
      id: 1124173367
    },
    {
      label: "Saint-Faustin--Lac-Carré",
      value: "Saint-Faustin--Lac-Carre",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0813,
      lng: -74.4668,
      population: 3499,
      density: 28.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0T",
      id: 1124677642
    },
    {
      label: "Morris-Turnberry",
      value: "Morris-Turnberry",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.85,
      lng: -81.25,
      population: 3496,
      density: 9.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0G",
      id: 1124001124
    },
    {
      label: "Placentia",
      value: "Placentia",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.2458,
      lng: -53.9611,
      population: 3496,
      density: 60.2,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0B",
      id: 1124471582
    },
    {
      label: "Saint-Pascal",
      value: "Saint-Pascal",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.5333,
      lng: -69.8,
      population: 3490,
      density: 58.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0L",
      id: 1124617986
    },
    {
      label: "Mulmur",
      value: "Mulmur",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.1917,
      lng: -80.1083,
      population: 3478,
      density: 12.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0M L9V",
      id: 1124001711
    },
    {
      label: "Blind River",
      value: "Blind River",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.1833,
      lng: -82.95,
      population: 3472,
      density: 6.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0R",
      id: 1124244510
    },
    {
      label: "Dunham",
      value: "Dunham",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.1333,
      lng: -72.8,
      population: 3471,
      density: 17.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0E",
      id: 1124514371
    },
    {
      label: "Havre-Saint-Pierre",
      value: "Havre-Saint-Pierre",
      province_id: "QC",
      province_name: "Quebec",
      lat: 50.2333,
      lng: -63.6,
      population: 3460,
      density: 1.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0G",
      id: 1124890113
    },
    {
      label: "Saint-Anselme",
      value: "Saint-Anselme",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.6333,
      lng: -70.9667,
      population: 3458,
      density: 46.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0R",
      id: 1124041118
    },
    {
      label: "Trois-Pistoles",
      value: "Trois-Pistoles",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.12,
      lng: -69.18,
      population: 3456,
      density: 450.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0L",
      id: 1124667916
    },
    {
      label: "Grande-Rivière",
      value: "Grande-Riviere",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.4,
      lng: -64.5,
      population: 3456,
      density: 39.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0C",
      id: 1124608500
    },
    {
      label: "Powassan",
      value: "Powassan",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.0825,
      lng: -79.3619,
      population: 3455,
      density: 15.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0H",
      id: 1124971329
    },
    {
      label: "Malartic",
      value: "Malartic",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.1333,
      lng: -78.1333,
      population: 3449,
      density: 23.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0Y",
      id: 1124600555
    },
    {
      label: "Bonavista",
      value: "Bonavista",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.6597,
      lng: -53.1208,
      population: 3448,
      density: 109.4,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0C",
      id: 1124990261
    },
    {
      label: "Killarney - Turtle Mountain",
      value: "Killarney - Turtle Mountain",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1775,
      lng: -99.6906,
      population: 3429,
      density: 3.7,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0K",
      id: 1124001432
    },
    {
      label: "Woodlands",
      value: "Woodlands",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.2408,
      lng: -97.7358,
      population: 3416,
      density: 2.9,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0C",
      id: 1124530756
    },
    {
      label: "Lewisporte",
      value: "Lewisporte",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 49.23,
      lng: -55.07,
      population: 3409,
      density: 92.4,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0G",
      id: 1124582594
    },
    {
      label: "Saint-Denis-de-Brompton",
      value: "Saint-Denis-de-Brompton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.45,
      lng: -72.0833,
      population: 3402,
      density: 48.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0B",
      id: 1124001970
    },
    {
      label: "Invermere",
      value: "Invermere",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.5083,
      lng: -116.0303,
      population: 3391,
      density: 315.9,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "V0A",
      id: 1124839399
    },
    {
      label: "Salisbury",
      value: "Salisbury",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.0776,
      lng: -65.1996,
      population: 3388,
      density: 3.9,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4Z E4J",
      id: 1124001982
    },
    {
      label: "Bifrost-Riverton",
      value: "Bifrost-Riverton",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 51.0603,
      lng: -97.1436,
      population: 3378,
      density: 2.1,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0C",
      id: 1124000047
    },
    {
      label: "Buckland No. 491",
      value: "Buckland No. 491",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.3276,
      lng: -105.7804,
      population: 3375,
      density: 4.3,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0J S6V",
      id: 1124001476
    },
    {
      label: "Cartier",
      value: "Cartier",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.9161,
      lng: -97.7,
      population: 3368,
      density: 6.1,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R4K R0G R0H",
      id: 1124001073
    },
    {
      label: "Sainte-Anne-des-Lacs",
      value: "Sainte-Anne-des-Lacs",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.85,
      lng: -74.1333,
      population: 3363,
      density: 135.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0R",
      id: 1124001507
    },
    {
      label: "Highlands East",
      value: "Highlands East",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.9667,
      lng: -78.25,
      population: 3343,
      density: 4.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0L K0M",
      id: 1124001598
    },
    {
      label: "Alexander",
      value: "Alexander",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.4222,
      lng: -96.075,
      population: 3333,
      density: 2.1,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0E",
      id: 1124001199
    },
    {
      label: "Sainte-Claire",
      value: "Sainte-Claire",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.6,
      lng: -70.8667,
      population: 3325,
      density: 37.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124401109
    },
    {
      label: "Percé",
      value: "Perce",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.5333,
      lng: -64.2167,
      population: 3312,
      density: 7.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0C",
      id: 1124000234
    },
    {
      label: "Saint-Jean-Port-Joli",
      value: "Saint-Jean-Port-Joli",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.2167,
      lng: -70.2667,
      population: 3304,
      density: 47.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124255737
    },
    {
      label: "East Hawkesbury",
      value: "East Hawkesbury",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.5167,
      lng: -74.4667,
      population: 3296,
      density: 14,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K6A K0B",
      id: 1124000222
    },
    {
      label: "Bright",
      value: "Bright",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.1205,
      lng: -67.0545,
      population: 3289,
      density: 8.1,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E6L",
      id: 1124001649
    },
    {
      label: "Penhold",
      value: "Penhold",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.1333,
      lng: -113.8667,
      population: 3277,
      density: 619.9,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0M",
      id: 1124360682
    },
    {
      label: "Saint-André-d'Argenteuil",
      value: "Saint-Andre-d'Argenteuil",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5667,
      lng: -74.3333,
      population: 3275,
      density: 33.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0V",
      id: 1124000962
    },
    {
      label: "Saint-Côme--Linière",
      value: "Saint-Come--Liniere",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0667,
      lng: -70.5167,
      population: 3274,
      density: 21.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0M",
      id: 1124151898
    },
    {
      label: "Saint-Sulpice",
      value: "Saint-Sulpice",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8333,
      lng: -73.35,
      population: 3273,
      density: 90,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J5W",
      id: 1124000703
    },
    {
      label: "Marathon",
      value: "Marathon",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.75,
      lng: -86.3667,
      population: 3273,
      density: 19.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0T",
      id: 1124974800
    },
    {
      label: "Forestville",
      value: "Forestville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.7333,
      lng: -69.0833,
      population: 3270,
      density: 16.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0T",
      id: 1124215354
    },
    {
      label: "Inuvik",
      value: "Inuvik",
      province_id: "NT",
      province_name: "Northwest Territories",
      lat: 68.3407,
      lng: -133.6096,
      population: 3243,
      density: 51.9,
      timezone: "America/Inuvik",
      ranking: 4,
      postal: "X0E",
      id: 1124116419
    },
    {
      label: "Richmond",
      value: "Richmond",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6667,
      lng: -72.15,
      population: 3232,
      density: 460.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0B",
      id: 1124924022
    },
    {
      label: "Lake Cowichan",
      value: "Lake Cowichan",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.8258,
      lng: -124.0542,
      population: 3226,
      density: 369.6,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0R",
      id: 1124082843
    },
    {
      label: "Sables-Spanish Rivers",
      value: "Sables-Spanish Rivers",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.2333,
      lng: -82,
      population: 3214,
      density: 3.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0P",
      id: 1124000330
    },
    {
      label: "Hillsburg-Roblin-Shell River",
      value: "Hillsburg-Roblin-Shell River",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 51.3343,
      lng: -101.2929,
      population: 3214,
      density: 1.9,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0L",
      id: 1124001467
    },
    {
      label: "Port Hawkesbury",
      value: "Port Hawkesbury",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.6153,
      lng: -61.3642,
      population: 3214,
      density: 396.6,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B9A",
      id: 1124913307
    },
    {
      label: "Three Hills",
      value: "Three Hills",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.7072,
      lng: -113.2647,
      population: 3212,
      density: 475.7,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0M",
      id: 1124247045
    },
    {
      label: "Lorette",
      value: "Lorette",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.7414,
      lng: -96.8761,
      population: 3208,
      density: 685,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0A",
      id: 1124000429
    },
    {
      label: "Paspebiac",
      value: "Paspebiac",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.0333,
      lng: -65.25,
      population: 3198,
      density: 33.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0C",
      id: 1124858850
    },
    {
      label: "Saint-Thomas",
      value: "Saint-Thomas",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0167,
      lng: -73.35,
      population: 3193,
      density: 33.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124176940
    },
    {
      label: "Saint-Jean-Baptiste",
      value: "Saint-Jean-Baptiste",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5167,
      lng: -73.1167,
      population: 3191,
      density: 44.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124000869
    },
    {
      label: "Portneuf",
      value: "Portneuf",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.7,
      lng: -71.8833,
      population: 3187,
      density: 29.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124993610
    },
    {
      label: "Pictou",
      value: "Pictou",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.6814,
      lng: -62.7119,
      population: 3186,
      density: 397.6,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0K",
      id: 1124595917
    },
    {
      label: "Tisdale",
      value: "Tisdale",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.85,
      lng: -104.05,
      population: 3180,
      density: 491.5,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0E",
      id: 1124001086
    },
    {
      label: "Lake of Bays",
      value: "Lake of Bays",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.3043,
      lng: -79.018,
      population: 3167,
      density: 4.7,
      timezone: "America/Toronto",
      ranking: 4,
      postal: "P0B P0A P1H",
      id: 1124000232
    },
    {
      label: "High Level",
      value: "High Level",
      province_id: "AB",
      province_name: "Alberta",
      lat: 58.5169,
      lng: -117.1361,
      population: 3159,
      density: 108.2,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0H",
      id: 1124099423
    },
    {
      label: "Gibbons",
      value: "Gibbons",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.8278,
      lng: -113.3228,
      population: 3159,
      density: 421.3,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0A",
      id: 1124001097
    },
    {
      label: "Bishops Falls",
      value: "Bishops Falls",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 49.0167,
      lng: -55.5167,
      population: 3156,
      density: 112.2,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0H",
      id: 1124735612
    },
    {
      label: "WestLake-Gladstone",
      value: "WestLake-Gladstone",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.2862,
      lng: -98.8415,
      population: 3154,
      density: 1.7,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0J R0H",
      id: 1124001087
    },
    {
      label: "Normandin",
      value: "Normandin",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.8333,
      lng: -72.5333,
      population: 3137,
      density: 14.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G8M",
      id: 1124410764
    },
    {
      label: "Saint-Alphonse-Rodriguez",
      value: "Saint-Alphonse-Rodriguez",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1833,
      lng: -73.7,
      population: 3134,
      density: 32,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124001435
    },
    {
      label: "Beauséjour",
      value: "Beausejour",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.0622,
      lng: -96.5161,
      population: 3126,
      density: 584.4,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0E",
      id: 1124260967
    },
    {
      label: "Dalhousie",
      value: "Dalhousie",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 48.1,
      lng: -66.6167,
      population: 3126,
      density: 205.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "E8C",
      id: 1124540945
    },
    {
      label: "Saint-Alphonse-de-Granby",
      value: "Saint-Alphonse-de-Granby",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3333,
      lng: -72.8167,
      population: 3125,
      density: 62.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0E",
      id: 1124000185
    },
    {
      label: "Lac du Bonnet",
      value: "Lac du Bonnet",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.2577,
      lng: -96.1209,
      population: 3121,
      density: 2.8,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0E",
      id: 1124000450
    },
    {
      label: "Clermont",
      value: "Clermont",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.6833,
      lng: -70.2333,
      population: 3118,
      density: 62.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G4A",
      id: 1124937298
    },
    {
      label: "Virden",
      value: "Virden",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.8508,
      lng: -100.9317,
      population: 3114,
      density: 370.2,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0M",
      id: 1124620072
    },
    {
      label: "Compton",
      value: "Compton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2333,
      lng: -71.8167,
      population: 3112,
      density: 15.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0B",
      id: 1124144541
    },
    {
      label: "White label",
      value: "White label",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.4353,
      lng: -104.3572,
      population: 3099,
      density: 7.5,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S4L",
      id: 1124001289
    },
    {
      label: "Ellison",
      value: "Ellison",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.9646,
      lng: -119.3178,
      population: 3094,
      density: 37.6,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V1X V1P",
      id: 1124000194
    },
    {
      label: "Mont-Saint-Grégoire",
      value: "Mont-Saint-Gregoire",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3333,
      lng: -73.1667,
      population: 3086,
      density: 38.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124094125
    },
    {
      label: "Wellington",
      value: "Wellington",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.4768,
      lng: -64.7478,
      population: 3079,
      density: 16.7,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4S E4V",
      id: 1124001391
    },
    {
      label: "Merrickville",
      value: "Merrickville",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.8539,
      lng: -75.8269,
      population: 3067,
      density: 14.3,
      timezone: "America/Toronto",
      ranking: 4,
      postal: "K0G",
      id: 1124846224
    },
    {
      label: "Saint-Liboire",
      value: "Saint-Liboire",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.65,
      lng: -72.7667,
      population: 3051,
      density: 41.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124016354
    },
    {
      label: "Dégelis",
      value: "Degelis",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.55,
      lng: -68.65,
      population: 3051,
      density: 5.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G5T",
      id: 1124001549
    },
    {
      label: "Morris",
      value: "Morris",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.3986,
      lng: -97.4592,
      population: 3047,
      density: 2.9,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0G",
      id: 1124001886
    },
    {
      label: "Saint-Alexis-des-Monts",
      value: "Saint-Alexis-des-Monts",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4667,
      lng: -73.1333,
      population: 3046,
      density: 2.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124120192
    },
    {
      label: "Cap-Saint-Ignace",
      value: "Cap-Saint-Ignace",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.0333,
      lng: -70.4667,
      population: 3045,
      density: 14.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124138813
    },
    {
      label: "Saint-Anaclet-de-Lessard",
      value: "Saint-Anaclet-de-Lessard",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.48,
      lng: -68.42,
      population: 3035,
      density: 23.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0K",
      id: 1124764523
    },
    {
      label: "Carman",
      value: "Carman",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.4992,
      lng: -98.0008,
      population: 3027,
      density: 702.4,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0G",
      id: 1124732787
    },
    {
      label: "Athens",
      value: "Athens",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.625,
      lng: -75.95,
      population: 3013,
      density: 23.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0E",
      id: 1124291343
    },
    {
      label: "Melancthon",
      value: "Melancthon",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.15,
      lng: -80.2667,
      population: 3008,
      density: 9.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0N",
      id: 1124736504
    },
    {
      label: "Cap Santé",
      value: "Cap Sante",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.6667,
      lng: -71.7833,
      population: 2996,
      density: 54.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124080648
    },
    {
      label: "Harbour Grace",
      value: "Harbour Grace",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.6917,
      lng: -53.2167,
      population: 2995,
      density: 88.8,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0A",
      id: 1124871661
    },
    {
      label: "Houston",
      value: "Houston",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 54.3975,
      lng: -126.6419,
      population: 2993,
      density: 41,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0J",
      id: 1124993327
    },
    {
      label: "Adelaide-Metcalfe",
      value: "Adelaide-Metcalfe",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.95,
      lng: -81.7,
      population: 2990,
      density: 9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0M N7G",
      id: 1124000926
    },
    {
      label: "Crossfield",
      value: "Crossfield",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.4333,
      lng: -114.0333,
      population: 2983,
      density: 249.3,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0M",
      id: 1124737275
    },
    {
      label: "Springdale",
      value: "Springdale",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 49.4974,
      lng: -56.0727,
      population: 2971,
      density: 168.8,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0J",
      id: 1124612197
    },
    {
      label: "Fort Macleod",
      value: "Fort Macleod",
      province_id: "AB",
      province_name: "Alberta",
      lat: 49.7256,
      lng: -113.3975,
      population: 2967,
      density: 126.8,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0L",
      id: 1124975838
    },
    {
      label: "Athabasca",
      value: "Athabasca",
      province_id: "AB",
      province_name: "Alberta",
      lat: 54.7197,
      lng: -113.2856,
      population: 2965,
      density: 168,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T9S",
      id: 1124006333
    },
    {
      label: "Enderby",
      value: "Enderby",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.5508,
      lng: -119.1397,
      population: 2964,
      density: 695.8,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0E",
      id: 1124312550
    },
    {
      label: "Saint-Ferréol-les-Neiges",
      value: "Saint-Ferreol-les-Neiges",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.1167,
      lng: -70.85,
      population: 2964,
      density: 35.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124255920
    },
    {
      label: "Laurentian Hills",
      value: "Laurentian Hills",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.1333,
      lng: -77.55,
      population: 2961,
      density: 4.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "K0J",
      id: 1124000976
    },
    {
      label: "Grand Valley",
      value: "Grand Valley",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.95,
      lng: -80.3667,
      population: 2956,
      density: 18.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L9W",
      id: 1124627074
    },
    {
      label: "Senneterre",
      value: "Senneterre",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.3833,
      lng: -77.2333,
      population: 2953,
      density: 0.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0Y",
      id: 1124548422
    },
    {
      label: "Sainte-Marie-Madeleine",
      value: "Sainte-Marie-Madeleine",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6,
      lng: -73.1,
      population: 2935,
      density: 57.7,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0H",
      id: 1124000666
    },
    {
      label: "Admaston/Bromley",
      value: "Admaston/Bromley",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.5292,
      lng: -76.8969,
      population: 2935,
      density: 5.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7V K0J",
      id: 1124001494
    },
    {
      label: "Saint-Gabriel-de-Valcartier",
      value: "Saint-Gabriel-de-Valcartier",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.9333,
      lng: -71.4667,
      population: 2933,
      density: 6.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124782327
    },
    {
      label: "North Algona Wilberforce",
      value: "North Algona Wilberforce",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.6167,
      lng: -77.2,
      population: 2915,
      density: 7.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K8A K0J",
      id: 1124001620
    },
    {
      label: "Kingston",
      value: "Kingston",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.4663,
      lng: -66.0217,
      population: 2913,
      density: 14.5,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E5N E5S",
      id: 1124000116
    },
    {
      label: "Wawa",
      value: "Wawa",
      province_id: "ON",
      province_name: "Ontario",
      lat: 47.9931,
      lng: -84.7736,
      population: 2905,
      density: 7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0S",
      id: 1124381797
    },
    {
      label: "Saint-Christophe-d'Arthabaska",
      value: "Saint-Christophe-d'Arthabaska",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0333,
      lng: -71.8833,
      population: 2892,
      density: 41.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G6R G6S",
      id: 1124000694
    },
    {
      label: "Sainte-Mélanie",
      value: "Sainte-Melanie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1333,
      lng: -73.5167,
      population: 2892,
      density: 38.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124173990
    },
    {
      label: "Ascot Corner",
      value: "Ascot Corner",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.45,
      lng: -71.7667,
      population: 2891,
      density: 35.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0B",
      id: 1124945733
    },
    {
      label: "Horton",
      value: "Horton",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.5,
      lng: -76.6667,
      population: 2887,
      density: 18.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7V",
      id: 1124001850
    },
    {
      label: "Saint-Michel",
      value: "Saint-Michel",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2333,
      lng: -73.5667,
      population: 2884,
      density: 48.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124926265
    },
    {
      label: "Botwood",
      value: "Botwood",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 49.15,
      lng: -55.3667,
      population: 2875,
      density: 191,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0H",
      id: 1124634384
    },
    {
      label: "Saint-Paul-d'Abbotsford",
      value: "Saint-Paul-d'Abbotsford",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4333,
      lng: -72.8833,
      population: 2870,
      density: 36.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0E",
      id: 1124492372
    },
    {
      label: "Saint-Marc-des-Carrières",
      value: "Saint-Marc-des-Carrieres",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.6833,
      lng: -72.05,
      population: 2862,
      density: 172,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124924445
    },
    {
      label: "Stanstead",
      value: "Stanstead",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.0167,
      lng: -72.1,
      population: 2857,
      density: 125.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0B",
      id: 1124000851
    },
    {
      label: "Sainte-Anne-de-Beaupré",
      value: "Sainte-Anne-de-Beaupre",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.0167,
      lng: -70.9333,
      population: 2854,
      density: 45.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124323389
    },
    {
      label: "Sainte-Luce",
      value: "Sainte-Luce",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.55,
      lng: -68.38,
      population: 2851,
      density: 39,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0K",
      id: 1124000034
    },
    {
      label: "Saint-Gabriel",
      value: "Saint-Gabriel",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3,
      lng: -73.3833,
      population: 2844,
      density: 1012.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124920056
    },
    {
      label: "Rankin Inlet",
      value: "Rankin Inlet",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 62.83,
      lng: -92.1321,
      population: 2842,
      density: 140.4,
      timezone: "America/Rankin_Inlet",
      ranking: 4,
      postal: "X0C",
      id: 1124057160
    },
    {
      label: "Vanscoy No. 345",
      value: "Vanscoy No. 345",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.0073,
      lng: -107.0552,
      population: 2840,
      density: 3.3,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0K S0L S7K",
      id: 1124001166
    },
    {
      label: "Cedar",
      value: "Cedar",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.0853,
      lng: -123.8259,
      population: 2836,
      density: 83.8,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V9X",
      id: 1124000187
    },
    {
      label: "Princeton",
      value: "Princeton",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.4589,
      lng: -120.506,
      population: 2828,
      density: 47.4,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0X",
      id: 1124790102
    },
    {
      label: "La Loche",
      value: "La Loche",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 56.4833,
      lng: -109.4333,
      population: 2827,
      density: 181.3,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0M",
      id: 1124048077
    },
    {
      label: "Kingsclear",
      value: "Kingsclear",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.8796,
      lng: -66.8695,
      population: 2822,
      density: 18.6,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E3E",
      id: 1124744497
    },
    {
      label: "Ferme-Neuve",
      value: "Ferme-Neuve",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.7,
      lng: -75.45,
      population: 2822,
      density: 3.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0W",
      id: 1124159065
    },
    {
      label: "Thurso",
      value: "Thurso",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5969,
      lng: -75.2433,
      population: 2818,
      density: 449,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0X",
      id: 1124913486
    },
    {
      label: "Adstock",
      value: "Adstock",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.05,
      lng: -71.08,
      population: 2806,
      density: 9.7,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0N",
      id: 1124001673
    },
    {
      label: "Shuniah",
      value: "Shuniah",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.5833,
      lng: -88.8333,
      population: 2798,
      density: 4.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0T P7A",
      id: 1124000092
    },
    {
      label: "Enniskillen",
      value: "Enniskillen",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.8167,
      lng: -82.125,
      population: 2796,
      density: 8.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0N",
      id: 1124001379
    },
    {
      label: "Yamachiche",
      value: "Yamachiche",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2667,
      lng: -72.8333,
      population: 2787,
      density: 26.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124138672
    },
    {
      label: "Saint-Maurice",
      value: "Saint-Maurice",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4667,
      lng: -72.5333,
      population: 2775,
      density: 30.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124381241
    },
    {
      label: "Bonaventure",
      value: "Bonaventure",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.05,
      lng: -65.4833,
      population: 2775,
      density: 26.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0C",
      id: 1124014798
    },
    {
      label: "Val-Morin",
      value: "Val-Morin",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46,
      lng: -74.18,
      population: 2772,
      density: 70.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0T",
      id: 1124001446
    },
    {
      label: "Pohénégamook",
      value: "Pohenegamook",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.4667,
      lng: -69.2167,
      population: 2770,
      density: 8.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0L",
      id: 1124000688
    },
    {
      label: "Wakefield",
      value: "Wakefield",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.2406,
      lng: -67.6248,
      population: 2767,
      density: 14.1,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E7M E7P",
      id: 1124000329
    },
    {
      label: "Stoke",
      value: "Stoke",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5333,
      lng: -71.8,
      population: 2765,
      density: 10.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0B",
      id: 1124001196
    },
    {
      label: "Sainte-Marguerite-du-Lac-Masson",
      value: "Sainte-Marguerite-du-Lac-Masson",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.056,
      lng: -74.0723,
      population: 2763,
      density: 30,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0T",
      id: 1124208615
    },
    {
      label: "Saint-Prime",
      value: "Saint-Prime",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.58,
      lng: -72.33,
      population: 2758,
      density: 18.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G8J",
      id: 1124389119
    },
    {
      label: "Kuujjuaq",
      value: "Kuujjuaq",
      province_id: "QC",
      province_name: "Quebec",
      lat: 58.1429,
      lng: -68.3742,
      population: 2754,
      density: 9.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0M",
      id: 1124705411
    },
    {
      label: "Atikokan",
      value: "Atikokan",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.75,
      lng: -91.6167,
      population: 2753,
      density: 8.6,
      timezone: "America/Atikokan",
      ranking: 3,
      postal: "P0T",
      id: 1124868159
    },
    {
      label: "Grenville-sur-la-Rouge",
      value: "Grenville-sur-la-Rouge",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.65,
      lng: -74.6333,
      population: 2746,
      density: 8.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0V",
      id: 1124001524
    },
    {
      label: "North Cypress-Langford",
      value: "North Cypress-Langford",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.9969,
      lng: -99.3982,
      population: 2745,
      density: 1.6,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0J R0K",
      id: 1124001785
    },
    {
      label: "Sainte-Anne-de-Sorel",
      value: "Sainte-Anne-de-Sorel",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.05,
      lng: -73.0667,
      population: 2742,
      density: 71.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J3P",
      id: 1124001977
    },
    {
      label: "Macamic",
      value: "Macamic",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.75,
      lng: -79,
      population: 2734,
      density: 13.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0Z",
      id: 1124965674
    },
    {
      label: "Sundre",
      value: "Sundre",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.7972,
      lng: -114.6406,
      population: 2729,
      density: 245.6,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0M",
      id: 1124001279
    },
    {
      label: "Rougemont",
      value: "Rougemont",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4333,
      lng: -73.05,
      population: 2723,
      density: 62,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124876872
    },
    {
      label: "Piedmont",
      value: "Piedmont",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9,
      lng: -74.13,
      population: 2721,
      density: 111.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0R",
      id: 1124001265
    },
    {
      label: "Grimshaw",
      value: "Grimshaw",
      province_id: "AB",
      province_name: "Alberta",
      lat: 56.1908,
      lng: -117.6117,
      population: 2718,
      density: 383.4,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0H",
      id: 1124339886
    },
    {
      label: "Lac-des-Écorces",
      value: "Lac-des-Ecorces",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.55,
      lng: -75.35,
      population: 2713,
      density: 18.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0W",
      id: 1124808962
    },
    {
      label: "Northeastern Manitoulin and the Islands",
      value: "Northeastern Manitoulin and the Islands",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.9667,
      lng: -81.9333,
      population: 2706,
      density: 5.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0P",
      id: 1124001541
    },
    {
      label: "Pelican Narrows",
      value: "Pelican Narrows",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 55.1883,
      lng: -102.9342,
      population: 2703,
      density: 295,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0P",
      id: 1124970223
    },
    {
      label: "McDougall",
      value: "McDougall",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.45,
      lng: -80.0167,
      population: 2702,
      density: 10.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P2A",
      id: 1124000643
    },
    {
      label: "Black Diamond",
      value: "Black Diamond",
      province_id: "AB",
      province_name: "Alberta",
      lat: 50.6881,
      lng: -114.2333,
      population: 2700,
      density: 702.7,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0L",
      id: 1124170822
    },
    {
      label: "Saint-Pamphile",
      value: "Saint-Pamphile",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.9667,
      lng: -69.7833,
      population: 2685,
      density: 19.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124993070
    },
    {
      label: "Bedford",
      value: "Bedford",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.1167,
      lng: -72.9833,
      population: 2684,
      density: 639.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124195813
    },
    {
      label: "Weedon-Centre",
      value: "Weedon-Centre",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7,
      lng: -71.4667,
      population: 2683,
      density: 12.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0B",
      id: 1124651516
    },
    {
      label: "Lacolle",
      value: "Lacolle",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.0833,
      lng: -73.3667,
      population: 2680,
      density: 54,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124270266
    },
    {
      label: "Saint-Gabriel-de-Brandon",
      value: "Saint-Gabriel-de-Brandon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2667,
      lng: -73.3833,
      population: 2679,
      density: 26.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124001827
    },
    {
      label: "Errington",
      value: "Errington",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.2874,
      lng: -124.3433,
      population: 2677,
      density: 97.4,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V0R V9P",
      id: 1124001138
    },
    {
      label: "Coalhurst",
      value: "Coalhurst",
      province_id: "AB",
      province_name: "Alberta",
      lat: 49.7457,
      lng: -112.9319,
      population: 2668,
      density: 857.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0L",
      id: 1124001548
    },
    {
      label: "French River / Rivière des Français",
      value: "French River / Riviere des Francais",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.1667,
      lng: -80.5,
      population: 2662,
      density: 3.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0M",
      id: 1124000556
    },
    {
      label: "Arviat",
      value: "Arviat",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 61.0996,
      lng: -94.1688,
      population: 2657,
      density: 20.1,
      timezone: "America/Rankin_Inlet",
      ranking: 4,
      postal: "X0C",
      id: 1124309634
    },
    {
      label: "Saint-David-de-Falardeau",
      value: "Saint-David-de-Falardeau",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.6167,
      lng: -71.1167,
      population: 2657,
      density: 6.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0V",
      id: 1124001854
    },
    {
      label: "Markstay",
      value: "Markstay",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.4912,
      lng: -80.4717,
      population: 2656,
      density: 5.2,
      timezone: "America/Toronto",
      ranking: 4,
      postal: "P0H P0M",
      id: 1124061532
    },
    {
      label: "Spaniards Bay",
      value: "Spaniards Bay",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.6181,
      lng: -53.3369,
      population: 2653,
      density: 40.4,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0A",
      id: 1124334544
    },
    {
      label: "Cocagne",
      value: "Cocagne",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.3406,
      lng: -64.62,
      population: 2649,
      density: 66.78,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E4R",
      id: 1124000195
    },
    {
      label: "Saint-Bruno",
      value: "Saint-Bruno",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.4667,
      lng: -71.65,
      population: 2636,
      density: 33.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0W",
      id: 1124000655
    },
    {
      label: "Chetwynd",
      value: "Chetwynd",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 55.6972,
      lng: -121.6333,
      population: 2635,
      density: 43.1,
      timezone: "America/Dawson_Creek",
      ranking: 3,
      postal: "V0C",
      id: 1124001005
    },
    {
      label: "Laurier-Station",
      value: "Laurier-Station",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5333,
      lng: -71.6333,
      population: 2634,
      density: 219.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0S",
      id: 1124029105
    },
    {
      label: "Saint-Anicet",
      value: "Saint-Anicet",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.12,
      lng: -74.35,
      population: 2626,
      density: 19.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0S",
      id: 1124287636
    },
    {
      label: "Saint-Mathieu-de-Beloeil",
      value: "Saint-Mathieu-de-Beloeil",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5667,
      lng: -73.2,
      population: 2624,
      density: 65.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3G",
      id: 1124000340
    },
    {
      label: "Cap-Chat",
      value: "Cap-Chat",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.1,
      lng: -66.6833,
      population: 2623,
      density: 14.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0J",
      id: 1124662875
    },
    {
      label: "Sexsmith",
      value: "Sexsmith",
      province_id: "AB",
      province_name: "Alberta",
      lat: 55.3508,
      lng: -118.7825,
      population: 2620,
      density: 197.9,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0H",
      id: 1124024490
    },
    {
      label: "Notre-Dame-de-Lourdes",
      value: "Notre-Dame-de-Lourdes",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1,
      lng: -73.4333,
      population: 2595,
      density: 72.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0K",
      id: 1124935751
    },
    {
      label: "Ville-Marie",
      value: "Ville-Marie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.3333,
      lng: -79.4333,
      population: 2595,
      density: 424.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J9V",
      id: 1124001938
    },
    {
      label: "Saint-Isidore",
      value: "Saint-Isidore",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3,
      lng: -73.68,
      population: 2581,
      density: 49.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124775572
    },
    {
      label: "Shippegan",
      value: "Shippegan",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.7439,
      lng: -64.7178,
      population: 2580,
      density: 257.6,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E8S",
      id: 1124198415
    },
    {
      label: "East Garafraxa",
      value: "East Garafraxa",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.85,
      lng: -80.25,
      population: 2579,
      density: 15.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L9W",
      id: 1124000753
    },
    {
      label: "Pemberton",
      value: "Pemberton",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.3203,
      lng: -122.8053,
      population: 2574,
      density: 217.5,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0N",
      id: 1124476252
    },
    {
      label: "Unity",
      value: "Unity",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.4333,
      lng: -109.1667,
      population: 2573,
      density: 244.6,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0K",
      id: 1124230227
    },
    {
      label: "Rimbey",
      value: "Rimbey",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.6333,
      lng: -114.2167,
      population: 2567,
      density: 225.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0C",
      id: 1124861733
    },
    {
      label: "High Prairie",
      value: "High Prairie",
      province_id: "AB",
      province_name: "Alberta",
      lat: 55.4325,
      lng: -116.4861,
      population: 2564,
      density: 355.3,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0G",
      id: 1124163323
    },
    {
      label: "Turner Valley",
      value: "Turner Valley",
      province_id: "AB",
      province_name: "Alberta",
      lat: 50.6739,
      lng: -114.2786,
      population: 2559,
      density: 442.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0L",
      id: 1124557397
    },
    {
      label: "Hanna",
      value: "Hanna",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.6383,
      lng: -111.9419,
      population: 2559,
      density: 290.6,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0J",
      id: 1124751402
    },
    {
      label: "Fort Smith",
      value: "Fort Smith",
      province_id: "NT",
      province_name: "Northwest Territories",
      lat: 60.026,
      lng: -112.0821,
      population: 2542,
      density: 27.4,
      timezone: "America/Yellowknife",
      ranking: 4,
      postal: "X0E",
      id: 1124491408
    },
    {
      label: "Maria",
      value: "Maria",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.1667,
      lng: -65.9833,
      population: 2536,
      density: 26.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0C",
      id: 1124002967
    },
    {
      label: "Saint-Chrysostome",
      value: "Saint-Chrysostome",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.1,
      lng: -73.7667,
      population: 2522,
      density: 25.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0S",
      id: 1124333805
    },
    {
      label: "Greater Madawaska",
      value: "Greater Madawaska",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.2722,
      lng: -76.8589,
      population: 2518,
      density: 2.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7V K0J",
      id: 1124001487
    },
    {
      label: "Berwick",
      value: "Berwick",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.0475,
      lng: -64.736,
      population: 2509,
      density: 381.3,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0P",
      id: 1124831957
    },
    {
      label: "Saint-Damase",
      value: "Saint-Damase",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5333,
      lng: -73,
      population: 2506,
      density: 31.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124245814
    },
    {
      label: "Lincoln",
      value: "Lincoln",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.8716,
      lng: -66.5437,
      population: 2504,
      density: 98.2,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E3B",
      id: 1124001266
    },
    {
      label: "Disraeli",
      value: "Disraeli",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9,
      lng: -71.35,
      population: 2502,
      density: 362.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0N",
      id: 1124115327
    },
    {
      label: "Sainte-Victoire-de-Sorel",
      value: "Sainte-Victoire-de-Sorel",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.95,
      lng: -73.0833,
      population: 2501,
      density: 33.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0G",
      id: 1124001523
    },
    {
      label: "Meadow Lake No. 588",
      value: "Meadow Lake No. 588",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 54.1213,
      lng: -108.2837,
      population: 2501,
      density: 0.4,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0M S9X",
      id: 1124001846
    },
    {
      label: "Elkford",
      value: "Elkford",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.0214,
      lng: -114.9158,
      population: 2499,
      density: 24.6,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "V0B",
      id: 1124000218
    },
    {
      label: "Georgian Bay",
      value: "Georgian Bay",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.9833,
      lng: -79.8167,
      population: 2499,
      density: 4.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L0K P0E",
      id: 1124001020
    },
    {
      label: "Saint-Alexandre",
      value: "Saint-Alexandre",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2333,
      lng: -73.1167,
      population: 2495,
      density: 32.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124016760
    },
    {
      label: "Hérbertville",
      value: "Herbertville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.3473,
      lng: -71.6784,
      population: 2491,
      density: 9.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G8N",
      id: 1124806332
    },
    {
      label: "Moosomin",
      value: "Moosomin",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.142,
      lng: -101.67,
      population: 2485,
      density: 327.5,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0G",
      id: 1124103159
    },
    {
      label: "North Kawartha",
      value: "North Kawartha",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.75,
      lng: -78.1,
      population: 2479,
      density: 3.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0L",
      id: 1124001184
    },
    {
      label: "Sainte-Thècle",
      value: "Sainte-Thecle",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8167,
      lng: -72.5,
      population: 2478,
      density: 11.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124387301
    },
    {
      label: "Trenton",
      value: "Trenton",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.6193,
      lng: -62.6332,
      population: 2474,
      density: 407.8,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0K",
      id: 1124776153
    },
    {
      label: "Fermont",
      value: "Fermont",
      province_id: "QC",
      province_name: "Quebec",
      lat: 52.7833,
      lng: -67.0833,
      population: 2474,
      density: 5.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0G",
      id: 1124001089
    },
    {
      label: "Esterhazy",
      value: "Esterhazy",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.65,
      lng: -102.0667,
      population: 2472,
      density: 520.9,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0A",
      id: 1124095034
    },
    {
      label: "Wickham",
      value: "Wickham",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.75,
      lng: -72.5,
      population: 2470,
      density: 25,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0C",
      id: 1124353605
    },
    {
      label: "La Présentation",
      value: "La Presentation",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6667,
      lng: -73.05,
      population: 2466,
      density: 26.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124865042
    },
    {
      label: "Beaverlodge",
      value: "Beaverlodge",
      province_id: "AB",
      province_name: "Alberta",
      lat: 55.2094,
      lng: -119.4292,
      population: 2465,
      density: 430.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0H",
      id: 1124894073
    },
    {
      label: "Sainte-Catherine-de-Hatley",
      value: "Sainte-Catherine-de-Hatley",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.25,
      lng: -72.05,
      population: 2464,
      density: 28.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0B",
      id: 1124000135
    },
    {
      label: "Saint-Basile",
      value: "Saint-Basile",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.75,
      lng: -71.8167,
      population: 2463,
      density: 25.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124369196
    },
    {
      label: "Saint-Raphaël",
      value: "Saint-Raphael",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8,
      lng: -70.75,
      population: 2463,
      density: 20.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0R",
      id: 1124630982
    },
    {
      label: "Holyrood",
      value: "Holyrood",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.3833,
      lng: -53.1333,
      population: 2463,
      density: 19.6,
      timezone: "America/St_Johns",
      ranking: 4,
      postal: "A0A",
      id: 1124289617
    },
    {
      label: "Gracefield",
      value: "Gracefield",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0926,
      lng: -75.9574,
      population: 2462,
      density: 6.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0X",
      id: 1124000820
    },
    {
      label: "Saint-Martin",
      value: "Saint-Martin",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9667,
      lng: -70.65,
      population: 2462,
      density: 20.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0M",
      id: 1124001971
    },
    {
      label: "Causapscal",
      value: "Causapscal",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.3667,
      lng: -67.2333,
      population: 2458,
      density: 15.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0J",
      id: 1124289460
    },
    {
      label: "Brigham",
      value: "Brigham",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.25,
      lng: -72.85,
      population: 2457,
      density: 28.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J2K",
      id: 1124336821
    },
    {
      label: "Perry",
      value: "Perry",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.5,
      lng: -79.2833,
      population: 2454,
      density: 13.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0A",
      id: 1124001994
    },
    {
      label: "Port-Daniel--Gascons",
      value: "Port-Daniel--Gascons",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.1833,
      lng: -64.9667,
      population: 2453,
      density: 8.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0C",
      id: 1124001024
    },
    {
      label: "Rosetown",
      value: "Rosetown",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.55,
      lng: -107.9833,
      population: 2451,
      density: 201.9,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0L",
      id: 1124742251
    },
    {
      label: "Minnedosa",
      value: "Minnedosa",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.2453,
      lng: -99.8428,
      population: 2449,
      density: 161.1,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0J",
      id: 1124860237
    },
    {
      label: "Labelle",
      value: "Labelle",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2833,
      lng: -74.7333,
      population: 2445,
      density: 12.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0T",
      id: 1124748931
    },
    {
      label: "Huntingdon",
      value: "Huntingdon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.08,
      lng: -74.17,
      population: 2444,
      density: 879.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0S",
      id: 1124322836
    },
    {
      label: "Hébertville",
      value: "Hebertville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.4,
      lng: -71.6833,
      population: 2441,
      density: 9.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0W",
      id: 1124293093
    },
    {
      label: "Black River-Matheson",
      value: "Black River-Matheson",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.5333,
      lng: -80.4667,
      population: 2438,
      density: 2.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0K",
      id: 1124000106
    },
    {
      label: "Saint-Michel-des-Saints",
      value: "Saint-Michel-des-Saints",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.6833,
      lng: -73.9167,
      population: 2436,
      density: 4.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124969050
    },
    {
      label: "Dufferin",
      value: "Dufferin",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.5319,
      lng: -98.07,
      population: 2435,
      density: 2.7,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0G",
      id: 1124001018
    },
    {
      label: "Saint-Victor",
      value: "Saint-Victor",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.15,
      lng: -70.9,
      population: 2430,
      density: 20.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0M",
      id: 1124899336
    },
    {
      label: "Sicamous",
      value: "Sicamous",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.8378,
      lng: -118.9703,
      population: 2429,
      density: 192,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0E",
      id: 1124519194
    },
    {
      label: "Cap Pele",
      value: "Cap Pele",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.2172,
      lng: -64.2822,
      population: 2425,
      density: 103.8,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4N",
      id: 1124541608
    },
    {
      label: "Kelsey",
      value: "Kelsey",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 53.7356,
      lng: -101.395,
      population: 2424,
      density: 2.8,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R9A R0B",
      id: 1124001840
    },
    {
      label: "Killaloe, Hagarty and Richards",
      value: "Killaloe, Hagarty and Richards",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.6,
      lng: -77.5,
      population: 2420,
      density: 6.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0J",
      id: 1124001779
    },
    {
      label: "Alvinston",
      value: "Alvinston",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.8489,
      lng: -81.9049,
      population: 2411,
      density: 7.7,
      timezone: "America/Toronto",
      ranking: 4,
      postal: "N0N",
      id: 1124753895
    },
    {
      label: "Dundurn No. 314",
      value: "Dundurn No. 314",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.8261,
      lng: -106.5416,
      population: 2404,
      density: 3,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0K",
      id: 1124000094
    },
    {
      label: "Saint-Éphrem-de-Beauce",
      value: "Saint-Ephrem-de-Beauce",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0516,
      lng: -70.9374,
      population: 2400,
      density: 20.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0M",
      id: 1124956973
    },
    {
      label: "Assiniboia",
      value: "Assiniboia",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 49.6167,
      lng: -105.9833,
      population: 2389,
      density: 630.3,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0H",
      id: 1124513932
    },
    {
      label: "Témiscaming",
      value: "Temiscaming",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.7167,
      lng: -79.1,
      population: 2385,
      density: 3.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "J0Z",
      id: 1124002169
    },
    {
      label: "Magrath",
      value: "Magrath",
      province_id: "AB",
      province_name: "Alberta",
      lat: 49.4239,
      lng: -112.8683,
      population: 2374,
      density: 396.4,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0K",
      id: 1124735480
    },
    {
      label: "Sainte-Geneviève-de-Berthier",
      value: "Sainte-Genevieve-de-Berthier",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0833,
      lng: -73.2167,
      population: 2365,
      density: 35.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0K",
      id: 1124001090
    },
    {
      label: "Buctouche",
      value: "Buctouche",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.4719,
      lng: -64.7249,
      population: 2361,
      density: 130.5,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E4S",
      id: 1124405790
    },
    {
      label: "Grand Manan",
      value: "Grand Manan",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 44.69,
      lng: -66.82,
      population: 2360,
      density: 15.8,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E5G",
      id: 1124000229
    },
    {
      label: "Sainte-Madeleine",
      value: "Sainte-Madeleine",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6,
      lng: -73.1,
      population: 2356,
      density: 439.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124000679
    },
    {
      label: "Boissevain",
      value: "Boissevain",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1779,
      lng: -100.0955,
      population: 2353,
      density: 2.2,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0K",
      id: 1124368869
    },
    {
      label: "Scott",
      value: "Scott",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.512,
      lng: -71.077,
      population: 2352,
      density: 75.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124001254
    },
    {
      label: "Sainte-Croix",
      value: "Sainte-Croix",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.62,
      lng: -71.73,
      population: 2352,
      density: 33.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0S",
      id: 1124208011
    },
    {
      label: "Algonquin Highlands",
      value: "Algonquin Highlands",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.4,
      lng: -78.75,
      population: 2351,
      density: 2.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0A",
      id: 1124001381
    },
    {
      label: "Valcourt",
      value: "Valcourt",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5,
      lng: -72.3167,
      population: 2349,
      density: 467.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0E",
      id: 1124334549
    },
    {
      label: "Saint George",
      value: "Saint George",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.2916,
      lng: -66.8501,
      population: 2341,
      density: 4.7,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E5C",
      id: 1124000156
    },
    {
      label: "Paquetville",
      value: "Paquetville",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.6334,
      lng: -65.1803,
      population: 2329,
      density: 10.6,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E8R",
      id: 1124000770
    },
    {
      label: "Saint-Dominique",
      value: "Saint-Dominique",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5667,
      lng: -72.85,
      population: 2327,
      density: 33.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124847475
    },
    {
      label: "Clearwater",
      value: "Clearwater",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 51.65,
      lng: -120.0333,
      population: 2324,
      density: 41.7,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0E",
      id: 1124911350
    },
    {
      label: "Addington Highlands",
      value: "Addington Highlands",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45,
      lng: -77.25,
      population: 2323,
      density: 1.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0H",
      id: 1124001921
    },
    {
      label: "Lillooet",
      value: "Lillooet",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.6864,
      lng: -121.9364,
      population: 2321,
      density: 84.4,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0K",
      id: 1124632130
    },
    {
      label: "Burin",
      value: "Burin",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.05,
      lng: -55.18,
      population: 2315,
      density: 67.8,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0E",
      id: 1124434509
    },
    {
      label: "Grand Bank",
      value: "Grand Bank",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.1,
      lng: -55.7833,
      population: 2310,
      density: 136.1,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0E",
      id: 1124257527
    },
    {
      label: "Léry",
      value: "Lery",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.35,
      lng: -73.8,
      population: 2307,
      density: 218.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J6N",
      id: 1124481204
    },
    {
      label: "Minto",
      value: "Minto",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.1497,
      lng: -66.1067,
      population: 2305,
      density: 72.8,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E4B",
      id: 1124754728
    },
    {
      label: "Rosthern No. 403",
      value: "Rosthern No. 403",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.6206,
      lng: -106.3967,
      population: 2300,
      density: 2.4,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0K",
      id: 1124001178
    },
    {
      label: "Chase",
      value: "Chase",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.8189,
      lng: -119.6844,
      population: 2286,
      density: 607,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0E",
      id: 1124452830
    },
    {
      label: "Mansfield-et-Pontefract",
      value: "Mansfield-et-Pontefract",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8611,
      lng: -76.7392,
      population: 2285,
      density: 4.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0X",
      id: 1124000865
    },
    {
      label: "Saint-Denis",
      value: "Saint-Denis",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7833,
      lng: -73.15,
      population: 2285,
      density: 26.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124298615
    },
    {
      label: "Outlook",
      value: "Outlook",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.5,
      lng: -107.05,
      population: 2279,
      density: 291,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0L",
      id: 1124721522
    },
    {
      label: "Mitchell",
      value: "Mitchell",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.5363,
      lng: -96.7634,
      population: 2279,
      density: 704.7,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R5G",
      id: 1124001295
    },
    {
      label: "Saint-Gédéon-de-Beauce",
      value: "Saint-Gedeon-de-Beauce",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.85,
      lng: -70.6333,
      population: 2277,
      density: 11.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0M",
      id: 1124765625
    },
    {
      label: "Saint-Léonard-d'Aston",
      value: "Saint-Leonard-d'Aston",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1,
      lng: -72.3667,
      population: 2271,
      density: 27.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0C",
      id: 1124836222
    },
    {
      label: "Lunenburg",
      value: "Lunenburg",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 44.3833,
      lng: -64.3167,
      population: 2263,
      density: 560.1,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0J",
      id: 1124214420
    },
    {
      label: "Northesk",
      value: "Northesk",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.2569,
      lng: -66.2613,
      population: 2263,
      density: 0.7,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E1V E9E",
      id: 1124000917
    },
    {
      label: "Albanel",
      value: "Albanel",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.8833,
      lng: -72.45,
      population: 2262,
      density: 11.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G8M",
      id: 1124386968
    },
    {
      label: "St. Anthony",
      value: "St. Anthony",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 51.3725,
      lng: -55.5947,
      population: 2258,
      density: 61,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0K",
      id: 1124808047
    },
    {
      label: "Pessamit",
      value: "Pessamit",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.0485,
      lng: -68.6814,
      population: 2256,
      density: 8.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0H",
      id: 1124000551
    },
    {
      label: "Maskinongé",
      value: "Maskinonge",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2333,
      lng: -73.0167,
      population: 2253,
      density: 30.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124944084
    },
    {
      label: "Saint-Charles-de-Bellechasse",
      value: "Saint-Charles-de-Bellechasse",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.7667,
      lng: -70.95,
      population: 2246,
      density: 24.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0R",
      id: 1124845287
    },
    {
      label: "Fogo Island",
      value: "Fogo Island",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 49.6667,
      lng: -54.1833,
      population: 2244,
      density: 9.4,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0G",
      id: 1124001746
    },
    {
      label: "East Broughton",
      value: "East Broughton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2167,
      lng: -71.0667,
      population: 2229,
      density: 255.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0N",
      id: 1124076092
    },
    {
      label: "Lantz",
      value: "Lantz",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 44.9894,
      lng: -63.4736,
      population: 2229,
      density: 749.9,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B2S",
      id: 1124980158
    },
    {
      label: "Calmar",
      value: "Calmar",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.25,
      lng: -113.7833,
      population: 2228,
      density: 476.2,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0C",
      id: 1124941943
    },
    {
      label: "Highlands",
      value: "Highlands",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.52,
      lng: -123.5,
      population: 2225,
      density: 58.5,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V9B V9E",
      id: 1124001752
    },
    {
      label: "Saint-Polycarpe",
      value: "Saint-Polycarpe",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3,
      lng: -74.3,
      population: 2224,
      density: 31.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0P",
      id: 1124227112
    },
    {
      label: "Logy Bay-Middle Cove-Outer Cove",
      value: "Logy Bay-Middle Cove-Outer Cove",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.63,
      lng: -52.68,
      population: 2221,
      density: 130.9,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A1K",
      id: 1124001213
    },
    {
      label: "Deschambault",
      value: "Deschambault",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.6436,
      lng: -72.0236,
      population: 2220,
      density: 17.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0A",
      id: 1124057933
    },
    {
      label: "Canora",
      value: "Canora",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.6339,
      lng: -102.4369,
      population: 2219,
      density: 303.7,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0A",
      id: 1124454845
    },
    {
      label: "Upper Miramichi",
      value: "Upper Miramichi",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.5254,
      lng: -66.2085,
      population: 2218,
      density: 1.2,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E9C E6A",
      id: 1124001963
    },
    {
      label: "Anmore",
      value: "Anmore",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.3144,
      lng: -122.8564,
      population: 2210,
      density: 80.2,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V3H",
      id: 1124001000
    },
    {
      label: "Hardwicke",
      value: "Hardwicke",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.0208,
      lng: -65.0302,
      population: 2201,
      density: 7.9,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E1N E9A",
      id: 1124081011
    },
    {
      label: "Saint-Côme",
      value: "Saint-Come",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.27,
      lng: -73.78,
      population: 2198,
      density: 13.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124183187
    },
    {
      label: "Waskaganish",
      value: "Waskaganish",
      province_id: "QC",
      province_name: "Quebec",
      lat: 51.3674,
      lng: -78.7069,
      population: 2196,
      density: 4.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0M J0Y",
      id: 1124626196
    },
    {
      label: "Twillingate",
      value: "Twillingate",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 49.6444,
      lng: -54.7436,
      population: 2196,
      density: 85.3,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0G",
      id: 1124836835
    },
    {
      label: "Saint-Quentin",
      value: "Saint-Quentin",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.5135,
      lng: -67.3921,
      population: 2194,
      density: 517.2,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E8A",
      id: 1124243371
    },
    {
      label: "Lebel-sur-Quévillon",
      value: "Lebel-sur-Quevillon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.05,
      lng: -76.9833,
      population: 2187,
      density: 53.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0Y",
      id: 1124000875
    },
    {
      label: "Pilot Butte",
      value: "Pilot Butte",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.4667,
      lng: -104.4167,
      population: 2183,
      density: 377.7,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0G",
      id: 1124343267
    },
    {
      label: "Nanton",
      value: "Nanton",
      province_id: "AB",
      province_name: "Alberta",
      lat: 50.3494,
      lng: -113.7717,
      population: 2181,
      density: 447.8,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0L",
      id: 1124418201
    },
    {
      label: "Pierreville",
      value: "Pierreville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0667,
      lng: -72.8167,
      population: 2176,
      density: 27.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0G",
      id: 1124888889
    },
    {
      label: "New-Wes-Valley",
      value: "New-Wes-Valley",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 49.15,
      lng: -53.5833,
      population: 2172,
      density: 16.3,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0G",
      id: 1124000397
    },
    {
      label: "Pennfield Ridge",
      value: "Pennfield Ridge",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.1924,
      lng: -66.6858,
      population: 2170,
      density: 6,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E5H",
      id: 1124474914
    },
    {
      label: "West Interlake",
      value: "West Interlake",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.9837,
      lng: -98.3572,
      population: 2162,
      density: 1.3,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0C",
      id: 1124001724
    },
    {
      label: "Biggar",
      value: "Biggar",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.059,
      lng: -107.979,
      population: 2161,
      density: 137.2,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0K",
      id: 1124897904
    },
    {
      label: "Britannia No. 502",
      value: "Britannia No. 502",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.4236,
      lng: -109.7772,
      population: 2153,
      density: 2.3,
      timezone: "America/Edmonton",
      ranking: 4,
      postal: "S9V",
      id: 1124000458
    },
    {
      label: "Kent",
      value: "Kent",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.6221,
      lng: -67.2953,
      population: 2153,
      density: 2.6,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E7J",
      id: 1124001214
    },
    {
      label: "Wabana",
      value: "Wabana",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.65,
      lng: -52.9333,
      population: 2146,
      density: 148,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0A",
      id: 1124180362
    },
    {
      label: "Saint-Gilles",
      value: "Saint-Gilles",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5167,
      lng: -71.3667,
      population: 2138,
      density: 12.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124239919
    },
    {
      label: "Wendake",
      value: "Wendake",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8693,
      lng: -71.3628,
      population: 2134,
      density: 1253.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124000757
    },
    {
      label: "Saint-Bernard",
      value: "Saint-Bernard",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5,
      lng: -71.1333,
      population: 2131,
      density: 23.7,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124594239
    },
    {
      label: "Sainte-Cécile-de-Milton",
      value: "Sainte-Cecile-de-Milton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4833,
      lng: -72.75,
      population: 2128,
      density: 29.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0E",
      id: 1124000630
    },
    {
      label: "Saint-Roch-de-Richelieu",
      value: "Saint-Roch-de-Richelieu",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8833,
      lng: -73.1667,
      population: 2122,
      density: 60.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0L",
      id: 1124796601
    },
    {
      label: "Saint-Nazaire",
      value: "Saint-Nazaire",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.5833,
      lng: -71.5333,
      population: 2114,
      density: 14.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0W",
      id: 1124583281
    },
    {
      label: "Saint-Elzéar",
      value: "Saint-Elzear",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4,
      lng: -71.0667,
      population: 2107,
      density: 24.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124069879
    },
    {
      label: "Hinchinbrooke",
      value: "Hinchinbrooke",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.05,
      lng: -74.1,
      population: 2103,
      density: 14.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0S",
      id: 1124000812
    },
    {
      label: "Saint-François-Xavier-de-Brompton",
      value: "Saint-Francois-Xavier-de-Brompton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5333,
      lng: -72.05,
      population: 2101,
      density: 21.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0B",
      id: 1124331796
    },
    {
      label: "Papineauville",
      value: "Papineauville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6167,
      lng: -75.0167,
      population: 2101,
      density: 34.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0V",
      id: 1124866604
    },
    {
      label: "Prairie View",
      value: "Prairie View",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.3304,
      lng: -100.9803,
      population: 2088,
      density: 1.2,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0J R0M",
      id: 1124001412
    },
    {
      label: "Cowichan Bay",
      value: "Cowichan Bay",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.7666,
      lng: -123.6743,
      population: 2086,
      density: 89.5,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V9L",
      id: 1124254242
    },
    {
      label: "Saint-Ignace-de-Loyola",
      value: "Saint-Ignace-de-Loyola",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0667,
      lng: -73.1333,
      population: 2086,
      density: 57.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124837640
    },
    {
      label: "Central Manitoulin",
      value: "Central Manitoulin",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.7167,
      lng: -82.2,
      population: 2084,
      density: 4.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0P",
      id: 1124001582
    },
    {
      label: "Maple Creek",
      value: "Maple Creek",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 49.9167,
      lng: -109.4667,
      population: 2084,
      density: 471.3,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0N",
      id: 1124706244
    },
    {
      label: "Glovertown",
      value: "Glovertown",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.6667,
      lng: -54.05,
      population: 2083,
      density: 29.6,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0G",
      id: 1124282877
    },
    {
      label: "Tofield",
      value: "Tofield",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.3703,
      lng: -112.6667,
      population: 2081,
      density: 253.4,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0B",
      id: 1124392098
    },
    {
      label: "Madoc",
      value: "Madoc",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.5833,
      lng: -77.5167,
      population: 2078,
      density: 7.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0K",
      id: 1124600331
    },
    {
      label: "Upton",
      value: "Upton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.65,
      lng: -72.6833,
      population: 2075,
      density: 37.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0H",
      id: 1124955599
    },
    {
      label: "Sainte-Anne-de-Sabrevois",
      value: "Sainte-Anne-de-Sabrevois",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2,
      lng: -73.2167,
      population: 2074,
      density: 46.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124001436
    },
    {
      label: "Logan Lake",
      value: "Logan Lake",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.4911,
      lng: -120.8153,
      population: 2073,
      density: 6.4,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0K",
      id: 1124000306
    },
    {
      label: "Sainte-Anne-de-la-Pérade",
      value: "Sainte-Anne-de-la-Perade",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5833,
      lng: -72.2,
      population: 2072,
      density: 18.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124630006
    },
    {
      label: "Saint-Damien-de-Buckland",
      value: "Saint-Damien-de-Buckland",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.6333,
      lng: -70.6667,
      population: 2071,
      density: 25.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0R",
      id: 1124710733
    },
    {
      label: "Baker Lake",
      value: "Baker Lake",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 64.3287,
      lng: -96.0308,
      population: 2069,
      density: 11.4,
      timezone: "America/Rankin_Inlet",
      ranking: 4,
      postal: "X0C",
      id: 1124826935
    },
    {
      label: "Saltair",
      value: "Saltair",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.9504,
      lng: -123.7637,
      population: 2069,
      density: 305.1,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0R V9G",
      id: 1124001512
    },
    {
      label: "Pouch Cove",
      value: "Pouch Cove",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.767,
      lng: -52.767,
      population: 2069,
      density: 35.5,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0A",
      id: 1124198879
    },
    {
      label: "Saint-Ferdinand",
      value: "Saint-Ferdinand",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1,
      lng: -71.5667,
      population: 2067,
      density: 15.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0N",
      id: 1124190438
    },
    {
      label: "Port McNeill",
      value: "Port McNeill",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.5903,
      lng: -127.0847,
      population: 2064,
      density: 149.9,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0N",
      id: 1124339378
    },
    {
      label: "Digby",
      value: "Digby",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 44.6222,
      lng: -65.7606,
      population: 2060,
      density: 654.6,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0V",
      id: 1124797865
    },
    {
      label: "Manouane",
      value: "Manouane",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.2091,
      lng: -74.3833,
      population: 2060,
      density: 258.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124764304
    },
    {
      label: "Saint-Gervais",
      value: "Saint-Gervais",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.7167,
      lng: -70.8833,
      population: 2058,
      density: 23.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0R",
      id: 1124488792
    },
    {
      label: "Neebing",
      value: "Neebing",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.1833,
      lng: -89.4667,
      population: 2055,
      density: 2.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P7L",
      id: 1124000953
    },
    {
      label: "Redwater",
      value: "Redwater",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.9489,
      lng: -113.1067,
      population: 2053,
      density: 102.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0A",
      id: 1124001733
    },
    {
      label: "Saint-Alexandre-de-Kamouraska",
      value: "Saint-Alexandre-de-Kamouraska",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.6817,
      lng: -69.625,
      population: 2050,
      density: 18.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0L",
      id: 1124090811
    },
    {
      label: "Saint-Marc-sur-Richelieu",
      value: "Saint-Marc-sur-Richelieu",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6833,
      lng: -73.2,
      population: 2050,
      density: 33.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0L",
      id: 1124009496
    },
    {
      label: "Mandeville",
      value: "Mandeville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3667,
      lng: -73.35,
      population: 2043,
      density: 6.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124912146
    },
    {
      label: "Caplan",
      value: "Caplan",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.1,
      lng: -65.6833,
      population: 2039,
      density: 23.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0C",
      id: 1124049436
    },
    {
      label: "Point Edward",
      value: "Point Edward",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.9931,
      lng: -82.4083,
      population: 2037,
      density: 620.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N7V N7T",
      id: 1124647566
    },
    {
      label: "Allardville",
      value: "Allardville",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.4321,
      lng: -65.4383,
      population: 2032,
      density: 3.1,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E8L",
      id: 1124442131
    },
    {
      label: "Waterville",
      value: "Waterville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2667,
      lng: -71.9,
      population: 2028,
      density: 45.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0B",
      id: 1124639721
    },
    {
      label: "Saint-Damien",
      value: "Saint-Damien",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.33,
      lng: -73.48,
      population: 2020,
      density: 7.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124745807
    },
    {
      label: "Lac-Nominingue",
      value: "Lac-Nominingue",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4,
      lng: -75.0333,
      population: 2019,
      density: 6.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0W",
      id: 1124245371
    },
    {
      label: "Obedjiwan",
      value: "Obedjiwan",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.6686,
      lng: -74.9289,
      population: 2019,
      density: 234.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0W",
      id: 1124832696
    },
    {
      label: "Rama",
      value: "Rama",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.7578,
      lng: -103.0008,
      population: 2016,
      density: 3087.3,
      timezone: "America/Regina",
      ranking: 2,
      postal: "S0A",
      id: 1124000936
    },
    {
      label: "McCreary",
      value: "McCreary",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.7494,
      lng: -99.485,
      population: 2011,
      density: 274.7,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0J",
      id: 1124852381
    },
    {
      label: "Deloraine-Winchester",
      value: "Deloraine-Winchester",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1775,
      lng: -100.4322,
      population: 2011,
      density: 2,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0M",
      id: 1124000085
    },
    {
      label: "Oakland-Wawanesa",
      value: "Oakland-Wawanesa",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.6208,
      lng: -99.8481,
      population: 2011,
      density: 2.9,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0K",
      id: 1124000206
    },
    {
      label: "Brenda-Waskada",
      value: "Brenda-Waskada",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1775,
      lng: -100.7019,
      population: 2011,
      density: 0.9,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0M",
      id: 1124000292
    },
    {
      label: "Russell-Binscarth",
      value: "Russell-Binscarth",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.7272,
      lng: -101.3689,
      population: 2011,
      density: 4.3,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0J",
      id: 1124000326
    },
    {
      label: "Ellice-Archie",
      value: "Ellice-Archie",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.3239,
      lng: -101.2729,
      population: 2011,
      density: 0.8,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0M",
      id: 1124000366
    },
    {
      label: "Souris-Glenwood",
      value: "Souris-Glenwood",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.6208,
      lng: -100.2581,
      population: 2011,
      density: 4.4,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0K",
      id: 1124000381
    },
    {
      label: "Riverdale",
      value: "Riverdale",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.975,
      lng: -100.2789,
      population: 2011,
      density: 3.7,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0K",
      id: 1124000402
    },
    {
      label: "Pembina",
      value: "Pembina",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1775,
      lng: -98.5408,
      population: 2011,
      density: 2.1,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0G",
      id: 1124000460
    },
    {
      label: "Wallace-Woodworth",
      value: "Wallace-Woodworth",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.9156,
      lng: -100.9389,
      population: 2011,
      density: 1.5,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0M",
      id: 1124000533
    },
    {
      label: "Lorne",
      value: "Lorne",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.4436,
      lng: -98.7494,
      population: 2011,
      density: 3.3,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0G R0K",
      id: 1124000596
    },
    {
      label: "Ethelbert",
      value: "Ethelbert",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 51.5364,
      lng: -100.4981,
      population: 2011,
      density: 104.3,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0L",
      id: 1124793190
    },
    {
      label: "Yellowhead",
      value: "Yellowhead",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.4847,
      lng: -100.4828,
      population: 2011,
      density: 1.8,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0J",
      id: 1124000631
    },
    {
      label: "Swan Valley West",
      value: "Swan Valley West",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 51.9978,
      lng: -101.3944,
      population: 2011,
      density: 1.6,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0L",
      id: 1124000683
    },
    {
      label: "Grey",
      value: "Grey",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.7094,
      lng: -98.0736,
      population: 2011,
      density: 2.8,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0G",
      id: 1124000699
    },
    {
      label: "Gilbert Plains",
      value: "Gilbert Plains",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 51.1547,
      lng: -100.4381,
      population: 2011,
      density: 1.4,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0L",
      id: 1124524030
    },
    {
      label: "Norfolk-Treherne",
      value: "Norfolk-Treherne",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.6653,
      lng: -98.5967,
      population: 2011,
      density: 2.4,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0G",
      id: 1124000889
    },
    {
      label: "Hamiota",
      value: "Hamiota",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.1964,
      lng: -100.6342,
      population: 2011,
      density: 248.3,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0M",
      id: 1124139264
    },
    {
      label: "Emerson-Franklin",
      value: "Emerson-Franklin",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1333,
      lng: -97.0331,
      population: 2011,
      density: 2.6,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0A",
      id: 1124000940
    },
    {
      label: "Sifton",
      value: "Sifton",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.6653,
      lng: -100.6678,
      population: 2011,
      density: 2.6,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0M",
      id: 1124001004
    },
    {
      label: "Rossburn",
      value: "Rossburn",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.7272,
      lng: -100.7408,
      population: 2011,
      density: 150.4,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0J",
      id: 1124628261
    },
    {
      label: "Grand View",
      value: "Grand View",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 51.155,
      lng: -100.7892,
      population: 2011,
      density: 310.3,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0L",
      id: 1124791401
    },
    {
      label: "Grassland",
      value: "Grassland",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.4306,
      lng: -100.3103,
      population: 2011,
      density: 1.2,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0K R0M",
      id: 1124001149
    },
    {
      label: "Louise",
      value: "Louise",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1772,
      lng: -98.8794,
      population: 2011,
      density: 2,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0G R0K",
      id: 1124001192
    },
    {
      label: "Ste. Rose",
      value: "Ste. Rose",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 51.0222,
      lng: -99.4306,
      population: 2011,
      density: 2.7,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0J R0L",
      id: 1124001220
    },
    {
      label: "Cartwright-Roblin",
      value: "Cartwright-Roblin",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1331,
      lng: -99.2797,
      population: 2011,
      density: 1.8,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0K",
      id: 1124001248
    },
    {
      label: "Mossey River",
      value: "Mossey River",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 51.755,
      lng: -99.9664,
      population: 2011,
      density: 1,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0L",
      id: 1124001378
    },
    {
      label: "Lakeshore",
      value: "Lakeshore",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 51.244,
      lng: -99.6562,
      population: 2011,
      density: 1.1,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0L",
      id: 1124001415
    },
    {
      label: "Riding Mountain West",
      value: "Riding Mountain West",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.8347,
      lng: -101.0961,
      population: 2011,
      density: 0.9,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0J",
      id: 1124001496
    },
    {
      label: "Clanwilliam-Erickson",
      value: "Clanwilliam-Erickson",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.5061,
      lng: -99.8156,
      population: 2011,
      density: 2.5,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0J",
      id: 1124001796
    },
    {
      label: "Glenboro-South Cypress",
      value: "Glenboro-South Cypress",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.665,
      lng: -99.3708,
      population: 2011,
      density: 1.4,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0K",
      id: 1124001832
    },
    {
      label: "North Norfolk",
      value: "North Norfolk",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.9308,
      lng: -98.8356,
      population: 2011,
      density: 3.3,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0H",
      id: 1124001856
    },
    {
      label: "Reinland",
      value: "Reinland",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1331,
      lng: -97.5942,
      population: 2011,
      density: 6.2,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0G",
      id: 1124389945
    },
    {
      label: "Minitonas-Bowsman",
      value: "Minitonas-Bowsman",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 52.1433,
      lng: -100.9772,
      population: 2011,
      density: 1.4,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0L",
      id: 1124001902
    },
    {
      label: "Kippens",
      value: "Kippens",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.5492,
      lng: -58.6236,
      population: 2008,
      density: 140.3,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A2N",
      id: 1124001147
    },
    {
      label: "Blucher",
      value: "Blucher",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.0134,
      lng: -106.2176,
      population: 2006,
      density: 2.5,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0K",
      id: 1124237296
    },
    {
      label: "Hatley",
      value: "Hatley",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.27,
      lng: -71.95,
      population: 2003,
      density: 28,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0B",
      id: 1124001440
    },
    {
      label: "Saint-Gédéon",
      value: "Saint-Gedeon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.5,
      lng: -71.7667,
      population: 2001,
      density: 31.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0W",
      id: 1124458135
    },
    {
      label: "Kingsey Falls",
      value: "Kingsey Falls",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.85,
      lng: -72.0667,
      population: 2000,
      density: 28.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0A",
      id: 1124274388
    },
    {
      label: "Provost",
      value: "Provost",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.3539,
      lng: -110.2686,
      population: 1998,
      density: 423,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0B",
      id: 1124659213
    },
    {
      label: "Saint-Charles",
      value: "Saint-Charles",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.6692,
      lng: -65.0184,
      population: 1997,
      density: 11.4,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4W",
      id: 1124000730
    },
    {
      label: "Mattawa",
      value: "Mattawa",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.3167,
      lng: -78.7,
      population: 1993,
      density: 544.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "P0H",
      id: 1124041230
    },
    {
      label: "Tumbler Ridge",
      value: "Tumbler Ridge",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 55.1333,
      lng: -121,
      population: 1987,
      density: 1.3,
      timezone: "America/Dawson_Creek",
      ranking: 3,
      postal: "V0C",
      id: 1124001642
    },
    {
      label: "Terrasse-Vaudreuil",
      value: "Terrasse-Vaudreuil",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4,
      lng: -73.9833,
      population: 1986,
      density: 1865.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7V",
      id: 1124001658
    },
    {
      label: "L'Ascension-de-Notre-Seigneur",
      value: "L'Ascension-de-Notre-Seigneur",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.7,
      lng: -71.6833,
      population: 1983,
      density: 15,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0W",
      id: 1124000873
    },
    {
      label: "Bow Island",
      value: "Bow Island",
      province_id: "AB",
      province_name: "Alberta",
      lat: 49.8667,
      lng: -111.3667,
      population: 1983,
      density: 341.6,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0K",
      id: 1124002843
    },
    {
      label: "Barraute",
      value: "Barraute",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.4333,
      lng: -77.6333,
      population: 1980,
      density: 4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0Y",
      id: 1124495319
    },
    {
      label: "One Hundred Mile House",
      value: "One Hundred Mile House",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 51.6413,
      lng: -121.3127,
      population: 1980,
      density: 37.2,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V0K",
      id: 1124920746
    },
    {
      label: "Kedgwick",
      value: "Kedgwick",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.6456,
      lng: -67.3431,
      population: 1979,
      density: 3,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E8B",
      id: 1124759374
    },
    {
      label: "Gambo",
      value: "Gambo",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.7833,
      lng: -54.2167,
      population: 1978,
      density: 21.5,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0G",
      id: 1124499854
    },
    {
      label: "Saint-Liguori",
      value: "Saint-Liguori",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0167,
      lng: -73.5667,
      population: 1976,
      density: 39.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0K",
      id: 1124672824
    },
    {
      label: "Bonfield",
      value: "Bonfield",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.2167,
      lng: -79.1333,
      population: 1975,
      density: 9.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0H",
      id: 1124001075
    },
    {
      label: "Pointe-Lebel",
      value: "Pointe-Lebel",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.1667,
      lng: -68.2,
      population: 1973,
      density: 23.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0H",
      id: 1124216164
    },
    {
      label: "Saint Mary",
      value: "Saint Mary",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.3987,
      lng: -64.8681,
      population: 1972,
      density: 8.3,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4S",
      id: 1124001508
    },
    {
      label: "Saint-Patrice-de-Sherrington",
      value: "Saint-Patrice-de-Sherrington",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.1667,
      lng: -73.5167,
      population: 1971,
      density: 21.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124878423
    },
    {
      label: "Fox Creek",
      value: "Fox Creek",
      province_id: "AB",
      province_name: "Alberta",
      lat: 54.395,
      lng: -116.8092,
      population: 1971,
      density: 159.4,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0H",
      id: 1124001709
    },
    {
      label: "Dawn-Euphemia",
      value: "Dawn-Euphemia",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.7,
      lng: -82.0167,
      population: 1967,
      density: 4.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0P",
      id: 1124000898
    },
    {
      label: "Chapleau",
      value: "Chapleau",
      province_id: "ON",
      province_name: "Ontario",
      lat: 47.8333,
      lng: -83.4,
      population: 1964,
      density: 138.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0M",
      id: 1124518288
    },
    {
      label: "Saint-Esprit",
      value: "Saint-Esprit",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9,
      lng: -73.6667,
      population: 1963,
      density: 36.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124001003
    },
    {
      label: "Westfield Beach",
      value: "Westfield Beach",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.3432,
      lng: -66.2868,
      population: 1962,
      density: 6.6,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E5K E5S",
      id: 1124204415
    },
    {
      label: "Montague",
      value: "Montague",
      province_id: "PE",
      province_name: "Prince Edward Island",
      lat: 46.1652,
      lng: -62.65,
      population: 1961,
      density: 620.8,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "C0A",
      id: 1124079838
    },
    {
      label: "Mashteuiatsh",
      value: "Mashteuiatsh",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.569,
      lng: -72.2495,
      population: 1957,
      density: 134.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0W",
      id: 1124000997
    },
    {
      label: "Saint-François-du-Lac",
      value: "Saint-Francois-du-Lac",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0667,
      lng: -72.8333,
      population: 1957,
      density: 30.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0G",
      id: 1124010410
    },
    {
      label: "Eel River Crossing",
      value: "Eel River Crossing",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 48.0125,
      lng: -66.4208,
      population: 1953,
      density: 29.8,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E8E",
      id: 1124000490
    },
    {
      label: "Saint-Fulgence",
      value: "Saint-Fulgence",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.45,
      lng: -70.9,
      population: 1949,
      density: 5.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0V",
      id: 1124969917
    },
    {
      label: "Millet",
      value: "Millet",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.0978,
      lng: -113.4728,
      population: 1945,
      density: 522.9,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0C",
      id: 1124327149
    },
    {
      label: "Vallée-Jonction",
      value: "Vallee-Jonction",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3667,
      lng: -70.9167,
      population: 1940,
      density: 76.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124672986
    },
    {
      label: "Saint-Georges-de-Cacouna",
      value: "Saint-Georges-de-Cacouna",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.9167,
      lng: -69.5,
      population: 1939,
      density: 30.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0L",
      id: 1124882699
    },
    {
      label: "Lumsden No. 189",
      value: "Lumsden No. 189",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.6734,
      lng: -104.7686,
      population: 1938,
      density: 2.4,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0G",
      id: 1124001061
    },
    {
      label: "Manitouwadge",
      value: "Manitouwadge",
      province_id: "ON",
      province_name: "Ontario",
      lat: 49.1333,
      lng: -85.8333,
      population: 1937,
      density: 5.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0T",
      id: 1124548320
    },
    {
      label: "Wellington",
      value: "Wellington",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.9579,
      lng: -77.3534,
      population: 1932,
      density: 275.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0K",
      id: 1124209670
    },
    {
      label: "Swift Current No. 137",
      value: "Swift Current No. 137",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.2211,
      lng: -107.8559,
      population: 1932,
      density: 1.8,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0N S9H",
      id: 1124000742
    },
    {
      label: "Tofino",
      value: "Tofino",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.1275,
      lng: -125.8852,
      population: 1932,
      density: 183.1,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V0R",
      id: 1124140302
    },
    {
      label: "Fort Qu’Appelle",
      value: "Fort Qu'Appelle",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.7667,
      lng: -103.7833,
      population: 1919,
      density: 363.2,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0G",
      id: 1124904751
    },
    {
      label: "Vulcan",
      value: "Vulcan",
      province_id: "AB",
      province_name: "Alberta",
      lat: 50.4,
      lng: -113.25,
      population: 1917,
      density: 302.3,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0L",
      id: 1124607765
    },
    {
      label: "Indian Head",
      value: "Indian Head",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.5333,
      lng: -103.6667,
      population: 1910,
      density: 602,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0G",
      id: 1124084038
    },
    {
      label: "Petit Rocher",
      value: "Petit Rocher",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.7839,
      lng: -65.7159,
      population: 1908,
      density: 425.2,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E8J",
      id: 1124808094
    },
    {
      label: "Wabush",
      value: "Wabush",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 52.9081,
      lng: -66.869,
      population: 1906,
      density: 41.2,
      timezone: "America/Goose_Bay",
      ranking: 3,
      postal: "A0R",
      id: 1124012604
    },
    {
      label: "Saint-Fabien",
      value: "Saint-Fabien",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.3,
      lng: -68.87,
      population: 1906,
      density: 15.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0L",
      id: 1124462554
    },
    {
      label: "Watrous",
      value: "Watrous",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.6841,
      lng: -105.4661,
      population: 1900,
      density: 170.1,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0K",
      id: 1124468381
    },
    {
      label: "North Frontenac",
      value: "North Frontenac",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.95,
      lng: -76.9,
      population: 1898,
      density: 1.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0H",
      id: 1124000803
    },
    {
      label: "Lac-Supérieur",
      value: "Lac-Superieur",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2,
      lng: -74.4667,
      population: 1892,
      density: 5.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0T",
      id: 1124000862
    },
    {
      label: "Les Escoumins",
      value: "Les Escoumins",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.35,
      lng: -69.4,
      population: 1891,
      density: 7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0T",
      id: 1124123618
    },
    {
      label: "Richibucto",
      value: "Richibucto",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.6189,
      lng: -64.8385,
      population: 1887,
      density: 7.6,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4W",
      id: 1124000569
    },
    {
      label: "Rivière-Beaudette",
      value: "Riviere-Beaudette",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2333,
      lng: -74.3333,
      population: 1885,
      density: 101.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0P",
      id: 1124687157
    },
    {
      label: "Saint-Barthélemy",
      value: "Saint-Barthelemy",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1833,
      lng: -73.1333,
      population: 1883,
      density: 17.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0K",
      id: 1124598418
    },
    {
      label: "Nisga'a",
      value: "Nisga'a",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 55.1078,
      lng: -129.4293,
      population: 1880,
      density: 0.9,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V0J",
      id: 1124000744
    },
    {
      label: "Austin",
      value: "Austin",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.1833,
      lng: -72.2833,
      population: 1880,
      density: 25.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0B",
      id: 1124000885
    },
    {
      label: "Saint-Mathieu",
      value: "Saint-Mathieu",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3167,
      lng: -73.5164,
      population: 1879,
      density: 59.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124978563
    },
    {
      label: "Saint-Paul-de-l'Île-aux-Noix",
      value: "Saint-Paul-de-l'Ile-aux-Noix",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.1333,
      lng: -73.2833,
      population: 1877,
      density: 63.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124000680
    },
    {
      label: "Orkney No. 244",
      value: "Orkney No. 244",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.2557,
      lng: -102.6469,
      population: 1875,
      density: 2.3,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S3N",
      id: 1124000139
    },
    {
      label: "Behchokò",
      value: "Behchoko",
      province_id: "NT",
      province_name: "Northwest Territories",
      lat: 62.8184,
      lng: -115.9933,
      population: 1874,
      density: 24.9,
      timezone: "America/Yellowknife",
      ranking: 4,
      postal: "X0E",
      id: 1124001992
    },
    {
      label: "Saint-Joseph-de-Coleraine",
      value: "Saint-Joseph-de-Coleraine",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.97,
      lng: -71.37,
      population: 1870,
      density: 14.7,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0N",
      id: 1124000088
    },
    {
      label: "Saint-Cyprien-de-Napierville",
      value: "Saint-Cyprien-de-Napierville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.1833,
      lng: -73.4167,
      population: 1869,
      density: 19.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124000843
    },
    {
      label: "Sayabec",
      value: "Sayabec",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.5667,
      lng: -67.6833,
      population: 1864,
      density: 14.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0J",
      id: 1124175872
    },
    {
      label: "Valleyview",
      value: "Valleyview",
      province_id: "AB",
      province_name: "Alberta",
      lat: 55.0686,
      lng: -117.2683,
      population: 1863,
      density: 199.9,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0H",
      id: 1124211786
    },
    {
      label: "Déléage",
      value: "Deleage",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3833,
      lng: -75.9167,
      population: 1856,
      density: 7.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J9E",
      id: 1124001615
    },
    {
      label: "Potton",
      value: "Potton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.0833,
      lng: -72.3667,
      population: 1849,
      density: 7.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0E",
      id: 1124000571
    },
    {
      label: "Sainte-Béatrix",
      value: "Sainte-Beatrix",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2,
      lng: -73.6167,
      population: 1849,
      density: 22,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124233714
    },
    {
      label: "Sainte-Justine",
      value: "Sainte-Justine",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4,
      lng: -70.35,
      population: 1845,
      density: 14.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124340970
    },
    {
      label: "Eastman",
      value: "Eastman",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3341,
      lng: -72.3041,
      population: 1843,
      density: 25,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0E",
      id: 1124001358
    },
    {
      label: "Saint-Valérien-de-Milton",
      value: "Saint-Valerien-de-Milton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5667,
      lng: -72.7167,
      population: 1840,
      density: 17.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124253518
    },
    {
      label: "Saint-Cuthbert",
      value: "Saint-Cuthbert",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.15,
      lng: -73.2333,
      population: 1839,
      density: 13.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124546028
    },
    {
      label: "Saint-Blaise-sur-Richelieu",
      value: "Saint-Blaise-sur-Richelieu",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2167,
      lng: -73.2833,
      population: 1837,
      density: 26.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124693015
    },
    {
      label: "Middleton",
      value: "Middleton",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 44.9418,
      lng: -65.0686,
      population: 1832,
      density: 329.1,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0S",
      id: 1124792393
    },
    {
      label: "Maugerville",
      value: "Maugerville",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.1301,
      lng: -66.2859,
      population: 1831,
      density: 2,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E3A",
      id: 1124001594
    },
    {
      label: "Dalmeny",
      value: "Dalmeny",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.3411,
      lng: -106.7733,
      population: 1826,
      density: 805.7,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0K",
      id: 1124962648
    },
    {
      label: "Kamsack",
      value: "Kamsack",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.565,
      lng: -101.8947,
      population: 1825,
      density: 311.8,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0A",
      id: 1124566093
    },
    {
      label: "Lumsden",
      value: "Lumsden",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.6463,
      lng: -104.8676,
      population: 1824,
      density: 402,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0G",
      id: 1124301488
    },
    {
      label: "Trinity Bay North",
      value: "Trinity Bay North",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.4978,
      lng: -53.086,
      population: 1819,
      density: 71.5,
      timezone: "America/St_Johns",
      ranking: 4,
      postal: "A0C",
      id: 1124001498
    },
    {
      label: "Saint-Michel-de-Bellechasse",
      value: "Saint-Michel-de-Bellechasse",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8667,
      lng: -70.9167,
      population: 1816,
      density: 41,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124972269
    },
    {
      label: "Sainte-Angèle-de-Monnoir",
      value: "Sainte-Angele-de-Monnoir",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3833,
      lng: -73.1,
      population: 1812,
      density: 39.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124001386
    },
    {
      label: "Picture Butte",
      value: "Picture Butte",
      province_id: "AB",
      province_name: "Alberta",
      lat: 49.8731,
      lng: -112.78,
      population: 1810,
      density: 635.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0K",
      id: 1124001015
    },
    {
      label: "Sacré-Coeur-Saguenay",
      value: "Sacre-Coeur-Saguenay",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.2479,
      lng: -69.854,
      population: 1803,
      density: 5.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0T",
      id: 1124365978
    },
    {
      label: "Saint-Louis",
      value: "Saint-Louis",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.7048,
      lng: -65.1046,
      population: 1802,
      density: 7,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4X",
      id: 1124001801
    },
    {
      label: "Victoria",
      value: "Victoria",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.7675,
      lng: -53.2411,
      population: 1800,
      density: 102,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0A",
      id: 1124985055
    },
    {
      label: "Saint-Robert",
      value: "Saint-Robert",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9667,
      lng: -73,
      population: 1794,
      density: 27.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0G",
      id: 1124000874
    },
    {
      label: "Armstrong",
      value: "Armstrong",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.64,
      lng: -97.495,
      population: 1792,
      density: 1,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0C",
      id: 1124000801
    },
    {
      label: "Saint-Pierre-de-l'Île-d'Orléans",
      value: "Saint-Pierre-de-l'Ile-d'Orleans",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8833,
      lng: -71.0667,
      population: 1789,
      density: 57.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124000266
    },
    {
      label: "La Guadeloupe",
      value: "La Guadeloupe",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.95,
      lng: -70.93,
      population: 1787,
      density: 54.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0M",
      id: 1124124557
    },
    {
      label: "Saint Andrews",
      value: "Saint Andrews",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.074,
      lng: -67.0521,
      population: 1786,
      density: 213.9,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E5B",
      id: 1124559218
    },
    {
      label: "Burns Lake",
      value: "Burns Lake",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 54.2292,
      lng: -125.7625,
      population: 1779,
      density: 269.8,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0J",
      id: 1124512812
    },
    {
      label: "Povungnituk",
      value: "Povungnituk",
      province_id: "QC",
      province_name: "Quebec",
      lat: 60.0477,
      lng: -77.2751,
      population: 1779,
      density: 20.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0M",
      id: 1124176799
    },
    {
      label: "Manners Sutton",
      value: "Manners Sutton",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.6417,
      lng: -67.0609,
      population: 1777,
      density: 3.4,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E6K",
      id: 1124811576
    },
    {
      label: "Gore",
      value: "Gore",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.77,
      lng: -74.25,
      population: 1775,
      density: 19.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0V",
      id: 1124000246
    },
    {
      label: "Deseronto",
      value: "Deseronto",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.2,
      lng: -77.05,
      population: 1774,
      density: 705.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0K",
      id: 1124824752
    },
    {
      label: "Lamont",
      value: "Lamont",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.7603,
      lng: -112.7778,
      population: 1774,
      density: 192.8,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0B",
      id: 1124567192
    },
    {
      label: "Chambord",
      value: "Chambord",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.4333,
      lng: -72.0667,
      population: 1773,
      density: 14.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0W",
      id: 1124404193
    },
    {
      label: "Dudswell",
      value: "Dudswell",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5833,
      lng: -71.5833,
      population: 1771,
      density: 8.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0B",
      id: 1124209090
    },
    {
      label: "Wynyard",
      value: "Wynyard",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.7667,
      lng: -104.1833,
      population: 1767,
      density: 334.1,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0A",
      id: 1124699826
    },
    {
      label: "Cambridge Bay",
      value: "Cambridge Bay",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 69.1528,
      lng: -105.1707,
      population: 1766,
      density: 8.7,
      timezone: "America/Cambridge_Bay",
      ranking: 4,
      postal: "X0B",
      id: 1124596377
    },
    {
      label: "Saint-Narcisse",
      value: "Saint-Narcisse",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5667,
      lng: -72.4667,
      population: 1762,
      density: 16.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124808791
    },
    {
      label: "Frontenac Islands",
      value: "Frontenac Islands",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.2,
      lng: -76.3833,
      population: 1760,
      density: 10.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K7G K0H",
      id: 1124000098
    },
    {
      label: "Waswanipi",
      value: "Waswanipi",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.7883,
      lng: -75.9544,
      population: 1759,
      density: 4.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0Y",
      id: 1124000056
    },
    {
      label: "Inukjuak",
      value: "Inukjuak",
      province_id: "QC",
      province_name: "Quebec",
      lat: 58.4824,
      lng: -78.1309,
      population: 1757,
      density: 31.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0M",
      id: 1124369757
    },
    {
      label: "Piney",
      value: "Piney",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.2069,
      lng: -95.8333,
      population: 1755,
      density: 0,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0A",
      id: 1124958787
    },
    {
      label: "Komoka",
      value: "Komoka",
      province_id: "ON",
      province_name: "Ontario",
      lat: 42.958,
      lng: -81.4001,
      population: 1754,
      density: 1576.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0L",
      id: 1124109518
    },
    {
      label: "Saint-Zacharie",
      value: "Saint-Zacharie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1333,
      lng: -70.3667,
      population: 1751,
      density: 9.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0M",
      id: 1124927704
    },
    {
      label: "Hemmingford",
      value: "Hemmingford",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.0833,
      lng: -73.5833,
      population: 1747,
      density: 11.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124000648
    },
    {
      label: "Shelburne",
      value: "Shelburne",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 43.7633,
      lng: -65.3236,
      population: 1743,
      density: 197.2,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0T",
      id: 1124659892
    },
    {
      label: "Saint-Clet",
      value: "Saint-Clet",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.35,
      lng: -74.22,
      population: 1738,
      density: 44.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0P",
      id: 1124001293
    },
    {
      label: "Carberry",
      value: "Carberry",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.8689,
      lng: -99.3594,
      population: 1738,
      density: 350.7,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0K",
      id: 1124314305
    },
    {
      label: "Brighton",
      value: "Brighton",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.3316,
      lng: -67.3585,
      population: 1735,
      density: 3.4,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E7P",
      id: 1124001133
    },
    {
      label: "Saint-Antoine",
      value: "Saint-Antoine",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.3629,
      lng: -64.753,
      population: 1733,
      density: 274.3,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E4V",
      id: 1124873921
    },
    {
      label: "Warfield",
      value: "Warfield",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.0953,
      lng: -117.7344,
      population: 1729,
      density: 910,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V1R",
      id: 1124000473
    },
    {
      label: "Northampton",
      value: "Northampton",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.1313,
      lng: -67.4713,
      population: 1724,
      density: 7.1,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E7N",
      id: 1124001603
    },
    {
      label: "Saint-Ours",
      value: "Saint-Ours",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8833,
      lng: -73.15,
      population: 1721,
      density: 29,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0G",
      id: 1124177651
    },
    {
      label: "Stephenville Crossing",
      value: "Stephenville Crossing",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.5167,
      lng: -58.4167,
      population: 1719,
      density: 55.1,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0N",
      id: 1124113007
    },
    {
      label: "Sainte-Anne-de-la-Pocatière",
      value: "Sainte-Anne-de-la-Pocatiere",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.35,
      lng: -70,
      population: 1717,
      density: 31.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124000130
    },
    {
      label: "Ucluelet",
      value: "Ucluelet",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.9358,
      lng: -125.5433,
      population: 1717,
      density: 264.5,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0R",
      id: 1124290800
    },
    {
      label: "Saint-Placide",
      value: "Saint-Placide",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5333,
      lng: -74.2,
      population: 1715,
      density: 39.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0V",
      id: 1124722091
    },
    {
      label: "Barrière",
      value: "Barriere",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 51.1803,
      lng: -120.1261,
      population: 1713,
      density: 164.7,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0E",
      id: 1124124556
    },
    {
      label: "Fisher",
      value: "Fisher",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 51.0825,
      lng: -97.6611,
      population: 1708,
      density: 1.2,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0C",
      id: 1124001091
    },
    {
      label: "Nipissing",
      value: "Nipissing",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.05,
      lng: -79.55,
      population: 1707,
      density: 4.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0H",
      id: 1124001066
    },
    {
      label: "Sainte-Clotilde",
      value: "Sainte-Clotilde",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.15,
      lng: -73.6833,
      population: 1704,
      density: 21.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124121082
    },
    {
      label: "Shaunavon",
      value: "Shaunavon",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 49.651,
      lng: -108.412,
      population: 1699,
      density: 344.2,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0N",
      id: 1124484836
    },
    {
      label: "Wicklow",
      value: "Wicklow",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.5017,
      lng: -67.7067,
      population: 1697,
      density: 8.7,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E7K E7L",
      id: 1124000344
    },
    {
      label: "Southesk",
      value: "Southesk",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.9901,
      lng: -66.4336,
      population: 1694,
      density: 0.7,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E9E",
      id: 1124001519
    },
    {
      label: "Nouvelle",
      value: "Nouvelle",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.1333,
      lng: -66.3167,
      population: 1689,
      density: 7.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0C",
      id: 1124064051
    },
    {
      label: "Rosthern",
      value: "Rosthern",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.65,
      lng: -106.3333,
      population: 1688,
      density: 392,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0K",
      id: 1124886826
    },
    {
      label: "Yamaska",
      value: "Yamaska",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0236,
      lng: -72.9391,
      population: 1687,
      density: 23.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0G",
      id: 1124001651
    },
    {
      label: "Neguac",
      value: "Neguac",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.2333,
      lng: -65.05,
      population: 1684,
      density: 62.9,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E9G",
      id: 1124936735
    },
    {
      label: "Flat Rock",
      value: "Flat Rock",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.7086,
      lng: -52.7144,
      population: 1683,
      density: 92.9,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A1K",
      id: 1124195076
    },
    {
      label: "Igloolik",
      value: "Igloolik",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 69.3817,
      lng: -81.6811,
      population: 1682,
      density: 16.3,
      timezone: "America/Iqaluit",
      ranking: 4,
      postal: "X0A",
      id: 1124253277
    },
    {
      label: "Grunthal",
      value: "Grunthal",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.4065,
      lng: -96.8603,
      population: 1680,
      density: 593.7,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0A",
      id: 1124001008
    },
    {
      label: "Naramata",
      value: "Naramata",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.5886,
      lng: -119.5838,
      population: 1676,
      density: 207.8,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0H",
      id: 1124000620
    },
    {
      label: "Saint-Élie-de-Caxton",
      value: "Saint-Elie-de-Caxton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4833,
      lng: -72.9667,
      population: 1676,
      density: 14.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124000951
    },
    {
      label: "Blumenort",
      value: "Blumenort",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.6033,
      lng: -96.7006,
      population: 1675,
      density: 526.3,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0A",
      id: 1124001566
    },
    {
      label: "Balmoral",
      value: "Balmoral",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.9667,
      lng: -66.45,
      population: 1674,
      density: 38.6,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E8E",
      id: 1124774000
    },
    {
      label: "Price",
      value: "Price",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.6017,
      lng: -68.1227,
      population: 1673,
      density: 646,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0J",
      id: 1124592512
    },
    {
      label: "Rosedale",
      value: "Rosedale",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.4397,
      lng: -99.5389,
      population: 1672,
      density: 1.9,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0J",
      id: 1124000230
    },
    {
      label: "Saint-Jacques-le-Mineur",
      value: "Saint-Jacques-le-Mineur",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2833,
      lng: -73.4167,
      population: 1672,
      density: 24.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124000320
    },
    {
      label: "Huron Shores",
      value: "Huron Shores",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.2833,
      lng: -83.2,
      population: 1664,
      density: 3.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0R",
      id: 1124000756
    },
    {
      label: "Champlain",
      value: "Champlain",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.45,
      lng: -72.35,
      population: 1664,
      density: 28.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124942363
    },
    {
      label: "Whitehead",
      value: "Whitehead",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.7981,
      lng: -100.2575,
      population: 1661,
      density: 2.9,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R7A R0K",
      id: 1124001853
    },
    {
      label: "Saint-Antoine-sur-Richelieu",
      value: "Saint-Antoine-sur-Richelieu",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7833,
      lng: -73.1833,
      population: 1659,
      density: 25.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0L",
      id: 1124151577
    },
    {
      label: "Saint-Pacôme",
      value: "Saint-Pacome",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.4,
      lng: -69.95,
      population: 1658,
      density: 57.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0L",
      id: 1124275513
    },
    {
      label: "Saint-Stanislas-de-Kostka",
      value: "Saint-Stanislas-de-Kostka",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.18,
      lng: -74.13,
      population: 1654,
      density: 28.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0S",
      id: 1124267249
    },
    {
      label: "Frontenac",
      value: "Frontenac",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.58,
      lng: -70.83,
      population: 1650,
      density: 7.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G6B",
      id: 1124001833
    },
    {
      label: "Stuartburn",
      value: "Stuartburn",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1331,
      lng: -96.5158,
      population: 1648,
      density: 1.4,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0A",
      id: 1124000992
    },
    {
      label: "Yamaska-Est",
      value: "Yamaska-Est",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46,
      lng: -72.92,
      population: 1644,
      density: 22.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0G",
      id: 1124187626
    },
    {
      label: "Sainte-Émélie-de-l'Énergie",
      value: "Sainte-Emelie-de-l'Energie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3167,
      lng: -73.65,
      population: 1644,
      density: 10.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124367776
    },
    {
      label: "Saint-Charles-sur-Richelieu",
      value: "Saint-Charles-sur-Richelieu",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6833,
      lng: -73.1833,
      population: 1643,
      density: 25.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124477144
    },
    {
      label: "Saint-Joseph-de-Sorel",
      value: "Saint-Joseph-de-Sorel",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0446,
      lng: -73.1308,
      population: 1642,
      density: 1192.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J3R",
      id: 1124557970
    },
    {
      label: "Nipigon",
      value: "Nipigon",
      province_id: "ON",
      province_name: "Ontario",
      lat: 49.0153,
      lng: -88.2683,
      population: 1642,
      density: 15,
      timezone: "America/Nipigon",
      ranking: 3,
      postal: "P0T",
      id: 1124361489
    },
    {
      label: "Rivière-Blanche",
      value: "Riviere-Blanche",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.7833,
      lng: -67.7,
      population: 1642,
      density: 13.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0J",
      id: 1124696529
    },
    {
      label: "Sainte-Hélène-de-Bagot",
      value: "Sainte-Helene-de-Bagot",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7333,
      lng: -72.7333,
      population: 1637,
      density: 22.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124837882
    },
    {
      label: "Franklin Centre",
      value: "Franklin Centre",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.0467,
      lng: -73.9005,
      population: 1636,
      density: 14.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0S",
      id: 1124556676
    },
    {
      label: "Harbour Breton",
      value: "Harbour Breton",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.4833,
      lng: -55.8333,
      population: 1634,
      density: 118.9,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0H",
      id: 1124833379
    },
    {
      label: "Massey Drive",
      value: "Massey Drive",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.9372,
      lng: -57.9,
      population: 1632,
      density: 658.7,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A2H",
      id: 1124000923
    },
    {
      label: "Mille-Isles",
      value: "Mille-Isles",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.82,
      lng: -74.22,
      population: 1629,
      density: 26.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0R",
      id: 1124001094
    },
    {
      label: "Wilton No. 472",
      value: "Wilton No. 472",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.124,
      lng: -109.7885,
      population: 1629,
      density: 1.6,
      timezone: "America/Edmonton",
      ranking: 4,
      postal: "S0M S9V",
      id: 1124001208
    },
    {
      label: "Lyster",
      value: "Lyster",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3667,
      lng: -71.6167,
      population: 1628,
      density: 9.7,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124296106
    },
    {
      label: "Oakview",
      value: "Oakview",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.1964,
      lng: -100.2167,
      population: 1626,
      density: 1.4,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0K",
      id: 1124000384
    },
    {
      label: "Balgonie",
      value: "Balgonie",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.488,
      lng: -104.269,
      population: 1625,
      density: 515.8,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0G",
      id: 1124001148
    },
    {
      label: "Harrison Park",
      value: "Harrison Park",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.5563,
      lng: -100.1674,
      population: 1622,
      density: 1.7,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0J",
      id: 1124000697
    },
    {
      label: "Kensington",
      value: "Kensington",
      province_id: "PE",
      province_name: "Prince Edward Island",
      lat: 46.4333,
      lng: -63.65,
      population: 1619,
      density: 537.9,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "C0B",
      id: 1124918690
    },
    {
      label: "Witless Bay",
      value: "Witless Bay",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.28,
      lng: -52.83,
      population: 1619,
      density: 92.6,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0A",
      id: 1124037826
    },
    {
      label: "Pond Inlet",
      value: "Pond Inlet",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 72.6808,
      lng: -77.7503,
      population: 1617,
      density: 9.3,
      timezone: "America/Iqaluit",
      ranking: 4,
      postal: "X0A",
      id: 1124788973
    },
    {
      label: "Royston",
      value: "Royston",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.6405,
      lng: -124.9406,
      population: 1616,
      density: 361.3,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0R V9N",
      id: 1124000692
    },
    {
      label: "Sainte-Clotilde-de-Horton",
      value: "Sainte-Clotilde-de-Horton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9833,
      lng: -72.2333,
      population: 1616,
      density: 14.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0A",
      id: 1124416351
    },
    {
      label: "Burford",
      value: "Burford",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.1036,
      lng: -80.424,
      population: 1615,
      density: 208.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0E",
      id: 1124578509
    },
    {
      label: "Fossambault-sur-le-Lac",
      value: "Fossambault-sur-le-Lac",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8667,
      lng: -71.6167,
      population: 1613,
      density: 141.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G3N",
      id: 1124001825
    },
    {
      label: "Saint-Benoît-Labre",
      value: "Saint-Benoit-Labre",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0667,
      lng: -70.8,
      population: 1612,
      density: 19,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0M",
      id: 1124381557
    },
    {
      label: "Coombs",
      value: "Coombs",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.3008,
      lng: -124.4049,
      population: 1612,
      density: 102.8,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V0R",
      id: 1124001663
    },
    {
      label: "Terrace Bay",
      value: "Terrace Bay",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.8,
      lng: -87.1,
      population: 1611,
      density: 10.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0T",
      id: 1124634789
    },
    {
      label: "Chapais",
      value: "Chapais",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.7819,
      lng: -74.8544,
      population: 1610,
      density: 25.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0W",
      id: 1124629095
    },
    {
      label: "Saint-Honoré-de-Shenley",
      value: "Saint-Honore-de-Shenley",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9667,
      lng: -70.8333,
      population: 1610,
      density: 12.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0M",
      id: 1124007745
    },
    {
      label: "Cleveland",
      value: "Cleveland",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.67,
      lng: -72.08,
      population: 1609,
      density: 12.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0B",
      id: 1124001081
    },
    {
      label: "Macdonald, Meredith and Aberdeen Additional",
      value: "Macdonald, Meredith and Aberdeen Additional",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.4833,
      lng: -84.0667,
      population: 1609,
      density: 9.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0S",
      id: 1124001485
    },
    {
      label: "Messines",
      value: "Messines",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2333,
      lng: -76.0167,
      population: 1608,
      density: 14.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0X",
      id: 1124295094
    },
    {
      label: "Saint-Jean-de-Dieu",
      value: "Saint-Jean-de-Dieu",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48,
      lng: -69.05,
      population: 1606,
      density: 10.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0L",
      id: 1124739032
    },
    {
      label: "Nakusp",
      value: "Nakusp",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.2434,
      lng: -117.8002,
      population: 1605,
      density: 195.2,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0G",
      id: 1124310238
    },
    {
      label: "Florenceville",
      value: "Florenceville",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.4435,
      lng: -67.6152,
      population: 1604,
      density: 102.7,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E7L",
      id: 1124518996
    },
    {
      label: "Saint-Antoine-de-Tilly",
      value: "Saint-Antoine-de-Tilly",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.6667,
      lng: -71.5833,
      population: 1604,
      density: 26.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124590960
    },
    {
      label: "Lakeview",
      value: "Lakeview",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.9026,
      lng: -119.5699,
      population: 1600,
      density: 63.5,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V1Z V4T",
      id: 1124001451
    },
    {
      label: "Humbermouth",
      value: "Humbermouth",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 49.0156,
      lng: -58.1678,
      population: 1599,
      density: 24.6,
      timezone: "America/St_Johns",
      ranking: 4,
      postal: "A0L",
      id: 1124416255
    },
    {
      label: "Fort St. James",
      value: "Fort St. James",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 54.4431,
      lng: -124.2542,
      population: 1598,
      density: 72,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0J",
      id: 1124865218
    },
    {
      label: "Saint-François-de-la-Rivière-du-Sud",
      value: "Saint-Francois-de-la-Riviere-du-Sud",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8833,
      lng: -70.7167,
      population: 1596,
      density: 17,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0R",
      id: 1124001406
    },
    {
      label: "Saint-Jacques",
      value: "Saint-Jacques",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.5634,
      lng: -68.3693,
      population: 1596,
      density: 5.3,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E7B",
      id: 1124840998
    },
    {
      label: "Uashat",
      value: "Uashat",
      province_id: "QC",
      province_name: "Quebec",
      lat: 50.233,
      lng: -66.3947,
      population: 1592,
      density: 681.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G4S G4R",
      id: 1124001483
    },
    {
      label: "Perth",
      value: "Perth",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.7393,
      lng: -67.6984,
      population: 1590,
      density: 177.3,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E7H",
      id: 1124468740
    },
    {
      label: "Eeyou Istchee Baie-James",
      value: "Eeyou Istchee Baie-James",
      province_id: "QC",
      province_name: "Quebec",
      lat: 52.3382,
      lng: -75.1977,
      population: 1589,
      density: 0,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0Y",
      id: 1124001722
    },
    {
      label: "Shellbrook No. 493",
      value: "Shellbrook No. 493",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.3545,
      lng: -106.2553,
      population: 1587,
      density: 1.3,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0J",
      id: 1124000896
    },
    {
      label: "Shawville",
      value: "Shawville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6,
      lng: -76.4833,
      population: 1587,
      density: 294.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0X",
      id: 1124868099
    },
    {
      label: "Saint-Lucien",
      value: "Saint-Lucien",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8667,
      lng: -72.2667,
      population: 1584,
      density: 14.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0C",
      id: 1124000172
    },
    {
      label: "Lambton",
      value: "Lambton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.83,
      lng: -71.08,
      population: 1584,
      density: 14.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0M",
      id: 1124236153
    },
    {
      label: "Saint-Laurent-de-l'Île-d'Orléans",
      value: "Saint-Laurent-de-l'Ile-d'Orleans",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8667,
      lng: -71.0167,
      population: 1580,
      density: 44.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124000659
    },
    {
      label: "Saint-Flavien",
      value: "Saint-Flavien",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5167,
      lng: -71.6,
      population: 1578,
      density: 23.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124326824
    },
    {
      label: "Grenville",
      value: "Grenville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6333,
      lng: -74.6,
      population: 1577,
      density: 560.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0V",
      id: 1124831011
    },
    {
      label: "Chute-aux-Outardes",
      value: "Chute-aux-Outardes",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.1167,
      lng: -68.4,
      population: 1577,
      density: 216,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0H",
      id: 1124968977
    },
    {
      label: "Sainte-Marcelline-de-Kildare",
      value: "Sainte-Marcelline-de-Kildare",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1167,
      lng: -73.6,
      population: 1567,
      density: 45.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0K",
      id: 1124028672
    },
    {
      label: "Saint-Félix-de-Kingsey",
      value: "Saint-Felix-de-Kingsey",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8,
      lng: -72.1833,
      population: 1563,
      density: 12.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0B",
      id: 1124052771
    },
    {
      label: "Upper Island Cove",
      value: "Upper Island Cove",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.6472,
      lng: -53.2233,
      population: 1561,
      density: 199,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0A",
      id: 1124254175
    },
    {
      label: "Glenelg",
      value: "Glenelg",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.9455,
      lng: -65.2893,
      population: 1560,
      density: 3.1,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E1N",
      id: 1124001212
    },
    {
      label: "Sainte-Élisabeth",
      value: "Sainte-Elisabeth",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0833,
      lng: -73.35,
      population: 1559,
      density: 18.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0K",
      id: 1124368135
    },
    {
      label: "Ashcroft",
      value: "Ashcroft",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.7256,
      lng: -121.2806,
      population: 1558,
      density: 32.3,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0K",
      id: 1124521001
    },
    {
      label: "Clarkes Beach",
      value: "Clarkes Beach",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.5447,
      lng: -53.2824,
      population: 1558,
      density: 122.6,
      timezone: "America/St_Johns",
      ranking: 4,
      postal: "A0A",
      id: 1124886112
    },
    {
      label: "Saint-Bernard-de-Lacolle",
      value: "Saint-Bernard-de-Lacolle",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.0833,
      lng: -73.4167,
      population: 1549,
      density: 13,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124000541
    },
    {
      label: "Belledune",
      value: "Belledune",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.9,
      lng: -65.8167,
      population: 1548,
      density: 8.2,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E8G",
      id: 1124444357
    },
    {
      label: "Saint-Guillaume",
      value: "Saint-Guillaume",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8833,
      lng: -72.7667,
      population: 1547,
      density: 17.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0C",
      id: 1124732782
    },
    {
      label: "Venise-en-Québec",
      value: "Venise-en-Quebec",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.0833,
      lng: -73.1333,
      population: 1547,
      density: 116.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124001019
    },
    {
      label: "Maliotenam",
      value: "Maliotenam",
      province_id: "QC",
      province_name: "Quebec",
      lat: 50.2114,
      lng: -66.1911,
      population: 1542,
      density: 288.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G4R",
      id: 1124000333
    },
    {
      label: "Ripon",
      value: "Ripon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7833,
      lng: -75.1,
      population: 1542,
      density: 11.7,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0V",
      id: 1124368199
    },
    {
      label: "Hilliers",
      value: "Hilliers",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.3022,
      lng: -124.4727,
      population: 1540,
      density: 53,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V0R V9K",
      id: 1124744995
    },
    {
      label: "Saint-Joseph",
      value: "Saint-Joseph",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.558,
      lng: -68.3082,
      population: 1538,
      density: 4.8,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E7B",
      id: 1124001284
    },
    {
      label: "Saint-Paulin",
      value: "Saint-Paulin",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4167,
      lng: -73.0333,
      population: 1534,
      density: 16,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0K",
      id: 1124571978
    },
    {
      label: "Bon Accord",
      value: "Bon Accord",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.8328,
      lng: -113.4189,
      population: 1529,
      density: 718.7,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0A",
      id: 1124764880
    },
    {
      label: "Saint David",
      value: "Saint David",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.2918,
      lng: -67.1983,
      population: 1529,
      density: 8,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E5A E3L",
      id: 1124001550
    },
    {
      label: "Saint-Albert",
      value: "Saint-Albert",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46,
      lng: -72.0833,
      population: 1526,
      density: 21.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0A",
      id: 1124484773
    },
    {
      label: "Matagami",
      value: "Matagami",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.75,
      lng: -77.6333,
      population: 1526,
      density: 22.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0Y",
      id: 1124686252
    },
    {
      label: "Springfield",
      value: "Springfield",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.7005,
      lng: -65.8079,
      population: 1525,
      density: 6.1,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E5T E5P",
      id: 1124760693
    },
    {
      label: "Amherst",
      value: "Amherst",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.05,
      lng: -74.7667,
      population: 1524,
      density: 6.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0T",
      id: 1124000118
    },
    {
      label: "Notre-Dame-du-Laus",
      value: "Notre-Dame-du-Laus",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0833,
      lng: -75.6167,
      population: 1518,
      density: 1.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0X",
      id: 1124131832
    },
    {
      label: "St. George",
      value: "St. George",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.1333,
      lng: -66.8167,
      population: 1517,
      density: 93.8,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E5C",
      id: 1124740743
    },
    {
      label: "Wembley",
      value: "Wembley",
      province_id: "AB",
      province_name: "Alberta",
      lat: 55.1572,
      lng: -119.1392,
      population: 1516,
      density: 318.9,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0H",
      id: 1124957462
    },
    {
      label: "Victoria",
      value: "Victoria",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.6644,
      lng: -98.9153,
      population: 1514,
      density: 2.2,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0G R0K",
      id: 1124000196
    },
    {
      label: "Springbrook",
      value: "Springbrook",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.1796,
      lng: -113.885,
      population: 1507,
      density: 286.3,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T4S",
      id: 1124001537
    },
    {
      label: "Saint-Tite-des-Caps",
      value: "Saint-Tite-des-Caps",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.1333,
      lng: -70.7667,
      population: 1506,
      density: 11.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124367873
    },
    {
      label: "Hudson Bay",
      value: "Hudson Bay",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.851,
      lng: -102.392,
      population: 1504,
      density: 86.7,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0E",
      id: 1124151446
    },
    {
      label: "Pinawa",
      value: "Pinawa",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.1707,
      lng: -95.9547,
      population: 1504,
      density: 11.7,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0E",
      id: 1124622420
    },
    {
      label: "Brudenell, Lyndoch and Raglan",
      value: "Brudenell, Lyndoch and Raglan",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.3167,
      lng: -77.4,
      population: 1503,
      density: 2.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0J",
      id: 1124001367
    },
    {
      label: "Carlyle",
      value: "Carlyle",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 49.6333,
      lng: -102.2667,
      population: 1503,
      density: 451.1,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0C",
      id: 1124830228
    },
    {
      label: "Keremeos",
      value: "Keremeos",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.2025,
      lng: -119.8294,
      population: 1502,
      density: 717.5,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0X",
      id: 1124920590
    },
    {
      label: "Val-Joli",
      value: "Val-Joli",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6,
      lng: -71.97,
      population: 1501,
      density: 16.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J1S",
      id: 1124000422
    },
    {
      label: "Gold River",
      value: "Gold River",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.7769,
      lng: -126.0514,
      population: 1500,
      density: 15,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0P",
      id: 1124000663
    },
    {
      label: "Saint-Casimir",
      value: "Saint-Casimir",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.65,
      lng: -72.1333,
      population: 1500,
      density: 22.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124445682
    },
    {
      label: "Bay Bulls",
      value: "Bay Bulls",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.3158,
      lng: -52.8103,
      population: 1500,
      density: 48.8,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0A",
      id: 1124701391
    },
    {
      label: "Langham",
      value: "Langham",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.3667,
      lng: -106.9667,
      population: 1496,
      density: 374.9,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0K",
      id: 1124101937
    },
    {
      label: "Frenchman Butte",
      value: "Frenchman Butte",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.6052,
      lng: -109.4298,
      population: 1494,
      density: 0.8,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0M",
      id: 1124729147
    },
    {
      label: "Gordon",
      value: "Gordon",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.8363,
      lng: -67.1913,
      population: 1493,
      density: 1,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E7G",
      id: 1124001029
    },
    {
      label: "Kugluktuk",
      value: "Kugluktuk",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 67.8055,
      lng: -115.3223,
      population: 1491,
      density: 2.7,
      timezone: "America/Cambridge_Bay",
      ranking: 4,
      postal: "X0B",
      id: 1124349489
    },
    {
      label: "Saint-Malachie",
      value: "Saint-Malachie",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5333,
      lng: -70.7667,
      population: 1489,
      density: 14.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124048620
    },
    {
      label: "Southampton",
      value: "Southampton",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.0789,
      lng: -67.3124,
      population: 1484,
      density: 3.3,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E6E E6G",
      id: 1124711539
    },
    {
      label: "Salluit",
      value: "Salluit",
      province_id: "QC",
      province_name: "Quebec",
      lat: 62.2013,
      lng: -75.6337,
      population: 1483,
      density: 101.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0M",
      id: 1124962070
    },
    {
      label: "Pangnirtung",
      value: "Pangnirtung",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 66.1436,
      lng: -65.6829,
      population: 1481,
      density: 190.6,
      timezone: "America/Pangnirtung",
      ranking: 4,
      postal: "X0A",
      id: 1124731886
    },
    {
      label: "Saint-Louis-de-Gonzague",
      value: "Saint-Louis-de-Gonzague",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2,
      lng: -73.98,
      population: 1481,
      density: 18.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0S",
      id: 1124000598
    },
    {
      label: "Moosonee",
      value: "Moosonee",
      province_id: "ON",
      province_name: "Ontario",
      lat: 51.2722,
      lng: -80.6431,
      population: 1481,
      density: 2.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0L",
      id: 1124592907
    },
    {
      label: "Englehart",
      value: "Englehart",
      province_id: "ON",
      province_name: "Ontario",
      lat: 47.8167,
      lng: -79.8667,
      population: 1479,
      density: 489.7,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0J",
      id: 1124297839
    },
    {
      label: "Saint-Urbain",
      value: "Saint-Urbain",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.55,
      lng: -70.5333,
      population: 1474,
      density: 4.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0A",
      id: 1124108877
    },
    {
      label: "Tring-Jonction",
      value: "Tring-Jonction",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2667,
      lng: -70.9833,
      population: 1473,
      density: 57.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0N",
      id: 1124821925
    },
    {
      label: "Nauwigewauk",
      value: "Nauwigewauk",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.4812,
      lng: -65.8738,
      population: 1472,
      density: 48.2,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E5N",
      id: 1124029649
    },
    {
      label: "Pointe-à-la-Croix",
      value: "Pointe-a-la-Croix",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.0167,
      lng: -66.6833,
      population: 1472,
      density: 3.7,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "G0C",
      id: 1124993506
    },
    {
      label: "Denmark",
      value: "Denmark",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.1155,
      lng: -67.4771,
      population: 1471,
      density: 2,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E7H E7G",
      id: 1124001349
    },
    {
      label: "Saint-Joachim",
      value: "Saint-Joachim",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.05,
      lng: -70.85,
      population: 1471,
      density: 34.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124056373
    },
    {
      label: "Torch River No. 488",
      value: "Torch River No. 488",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.5445,
      lng: -104.4619,
      population: 1471,
      density: 0.3,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0J",
      id: 1124001716
    },
    {
      label: "Saint-Théodore-d'Acton",
      value: "Saint-Theodore-d'Acton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6833,
      lng: -72.5833,
      population: 1471,
      density: 17.7,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0H",
      id: 1124207486
    },
    {
      label: "Grindrod",
      value: "Grindrod",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.63,
      lng: -119.1314,
      population: 1470,
      density: 34,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V0E V1E",
      id: 1124245869
    },
    {
      label: "L’ Îsle-Verte",
      value: "L' Isle-Verte",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.0167,
      lng: -69.3333,
      population: 1469,
      density: 12.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0L",
      id: 1124320524
    },
    {
      label: "Harrison Hot Springs",
      value: "Harrison Hot Springs",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.3,
      lng: -121.7819,
      population: 1468,
      density: 263.4,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0M",
      id: 1124001888
    },
    {
      label: "Palmarolle",
      value: "Palmarolle",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.6667,
      lng: -79.2,
      population: 1465,
      density: 12.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0Z",
      id: 1124693739
    },
    {
      label: "Henryville",
      value: "Henryville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.1333,
      lng: -73.1833,
      population: 1464,
      density: 22.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124175333
    },
    {
      label: "Sussex Corner",
      value: "Sussex Corner",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.7122,
      lng: -65.4719,
      population: 1461,
      density: 156.4,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4E",
      id: 1124001821
    },
    {
      label: "Saint-Odilon-de-Cranbourne",
      value: "Saint-Odilon-de-Cranbourne",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3667,
      lng: -70.6833,
      population: 1459,
      density: 11.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124001239
    },
    {
      label: "Pipestone",
      value: "Pipestone",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.6653,
      lng: -101.1444,
      population: 1458,
      density: 1.3,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0M",
      id: 1124293936
    },
    {
      label: "Laurierville",
      value: "Laurierville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3,
      lng: -71.65,
      population: 1454,
      density: 13.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124864029
    },
    {
      label: "La Doré",
      value: "La Dore",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.72,
      lng: -72.65,
      population: 1453,
      density: 5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G8J",
      id: 1124387334
    },
    {
      label: "Lac-au-Saumon",
      value: "Lac-au-Saumon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.4167,
      lng: -67.35,
      population: 1453,
      density: 17.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0J",
      id: 1124759496
    },
    {
      label: "Wotton",
      value: "Wotton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7333,
      lng: -71.8,
      population: 1453,
      density: 10.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0A",
      id: 1124174332
    },
    {
      label: "Prairie Lakes",
      value: "Prairie Lakes",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.4034,
      lng: -99.6298,
      population: 1453,
      density: 1.4,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0K",
      id: 1124001186
    },
    {
      label: "Elk Point",
      value: "Elk Point",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.8967,
      lng: -110.8972,
      population: 1452,
      density: 295.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0A",
      id: 1124622637
    },
    {
      label: "Shellbrook",
      value: "Shellbrook",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.2167,
      lng: -106.4,
      population: 1444,
      density: 390.3,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0J",
      id: 1124817725
    },
    {
      label: "Wemindji",
      value: "Wemindji",
      province_id: "QC",
      province_name: "Quebec",
      lat: 53.044,
      lng: -78.7384,
      population: 1444,
      density: 3.7,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0M",
      id: 1124079157
    },
    {
      label: "Cape Dorset",
      value: "Cape Dorset",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 64.2237,
      lng: -76.5405,
      population: 1441,
      density: 147.9,
      timezone: "America/Iqaluit",
      ranking: 4,
      postal: "X0A",
      id: 1124646146
    },
    {
      label: "Strong",
      value: "Strong",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.75,
      lng: -79.4,
      population: 1439,
      density: 9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0A",
      id: 1124000578
    },
    {
      label: "Lappe",
      value: "Lappe",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.5693,
      lng: -89.3573,
      population: 1436,
      density: 9.8,
      timezone: "America/Toronto",
      ranking: 4,
      postal: "P7G",
      id: 1124000934
    },
    {
      label: "Rivière-Héva",
      value: "Riviere-Heva",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.2333,
      lng: -78.2167,
      population: 1433,
      density: 3.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0Y",
      id: 1124000406
    },
    {
      label: "Fort-Coulonge",
      value: "Fort-Coulonge",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.85,
      lng: -76.7333,
      population: 1433,
      density: 462.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0X",
      id: 1124453309
    },
    {
      label: "Irishtown-Summerside",
      value: "Irishtown-Summerside",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.9833,
      lng: -57.95,
      population: 1418,
      density: 119.2,
      timezone: "America/St_Johns",
      ranking: 4,
      postal: "A2H",
      id: 1124000307
    },
    {
      label: "Godmanchester",
      value: "Godmanchester",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.08,
      lng: -74.25,
      population: 1417,
      density: 10.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0S",
      id: 1124000511
    },
    {
      label: "Macklin",
      value: "Macklin",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.33,
      lng: -109.94,
      population: 1415,
      density: 450.7,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0L",
      id: 1124573046
    },
    {
      label: "Armour",
      value: "Armour",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.6289,
      lng: -79.3436,
      population: 1414,
      density: 8.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0A",
      id: 1124000589
    },
    {
      label: "Saint-Simon",
      value: "Saint-Simon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.719,
      lng: -72.8463,
      population: 1413,
      density: 20.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0H",
      id: 1124669265
    },
    {
      label: "St. François Xavier",
      value: "St. Francois Xavier",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.9903,
      lng: -97.6722,
      population: 1411,
      density: 6.9,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R4L",
      id: 1124001915
    },
    {
      label: "Tingwick",
      value: "Tingwick",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8873,
      lng: -71.9244,
      population: 1410,
      density: 8.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0A",
      id: 1124969542
    },
    {
      label: "Saint-Aubert",
      value: "Saint-Aubert",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.1833,
      lng: -70.2167,
      population: 1409,
      density: 14,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124439130
    },
    {
      label: "Saint-Mathieu-du-Parc",
      value: "Saint-Mathieu-du-Parc",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5667,
      lng: -72.9167,
      population: 1407,
      density: 6.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0X",
      id: 1124001318
    },
    {
      label: "Wabasca",
      value: "Wabasca",
      province_id: "AB",
      province_name: "Alberta",
      lat: 55.9855,
      lng: -113.8566,
      population: 1406,
      density: 65.8,
      timezone: "America/Edmonton",
      ranking: 4,
      postal: "T0G",
      id: 1124001857
    },
    {
      label: "Ragueneau",
      value: "Ragueneau",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.0667,
      lng: -68.5333,
      population: 1405,
      density: 7.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0H",
      id: 1124000159
    },
    {
      label: "Notre-Dame-du-Bon-Conseil",
      value: "Notre-Dame-du-Bon-Conseil",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46,
      lng: -72.35,
      population: 1404,
      density: 331.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0C",
      id: 1124217511
    },
    {
      label: "Wasagamack",
      value: "Wasagamack",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 53.9056,
      lng: -94.9412,
      population: 1403,
      density: 17.3,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0B",
      id: 1124000650
    },
    {
      label: "Saint-Ubalde",
      value: "Saint-Ubalde",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.75,
      lng: -72.2667,
      population: 1403,
      density: 10,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124614507
    },
    {
      label: "Creighton",
      value: "Creighton",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 54.7561,
      lng: -101.8973,
      population: 1402,
      density: 97.4,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "S0P",
      id: 1124000828
    },
    {
      label: "Fortune",
      value: "Fortune",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.0733,
      lng: -55.8217,
      population: 1401,
      density: 25.5,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0E",
      id: 1124546267
    },
    {
      label: "Faraday",
      value: "Faraday",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45,
      lng: -77.9167,
      population: 1401,
      density: 6.4,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0L",
      id: 1124001991
    },
    {
      label: "Berthier-sur-Mer",
      value: "Berthier-sur-Mer",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.9167,
      lng: -70.7333,
      population: 1398,
      density: 52.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124625020
    },
    {
      label: "Frampton",
      value: "Frampton",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4667,
      lng: -70.8,
      population: 1393,
      density: 9.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124063273
    },
    {
      label: "Magnetawan",
      value: "Magnetawan",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.6667,
      lng: -79.6333,
      population: 1390,
      density: 2.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0A",
      id: 1124537839
    },
    {
      label: "New Carlisle",
      value: "New Carlisle",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.0167,
      lng: -65.3333,
      population: 1388,
      density: 20.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0C",
      id: 1124193848
    },
    {
      label: "Laird No. 404",
      value: "Laird No. 404",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.5696,
      lng: -106.7312,
      population: 1387,
      density: 1.9,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0K",
      id: 1124001811
    },
    {
      label: "Petitcodiac",
      value: "Petitcodiac",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.9333,
      lng: -65.1667,
      population: 1383,
      density: 80.4,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E4Z",
      id: 1124122911
    },
    {
      label: "Popkum",
      value: "Popkum",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.1911,
      lng: -121.7553,
      population: 1382,
      density: 216.7,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0X",
      id: 1124000726
    },
    {
      label: "Norton",
      value: "Norton",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.6387,
      lng: -65.6955,
      population: 1382,
      density: 18.3,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E5N",
      id: 1124362919
    },
    {
      label: "Canwood No. 494",
      value: "Canwood No. 494",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.4574,
      lng: -106.7768,
      population: 1381,
      density: 0.7,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0J",
      id: 1124001486
    },
    {
      label: "Wentworth-Nord",
      value: "Wentworth-Nord",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.85,
      lng: -74.45,
      population: 1381,
      density: 8.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0T",
      id: 1124001955
    },
    {
      label: "Bas Caraquet",
      value: "Bas Caraquet",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.8,
      lng: -64.8333,
      population: 1380,
      density: 44.5,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E1W",
      id: 1124124817
    },
    {
      label: "Sainte-Ursule",
      value: "Sainte-Ursule",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.2833,
      lng: -73.0333,
      population: 1375,
      density: 20.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0K",
      id: 1124000577
    },
    {
      label: "Dawson",
      value: "Dawson",
      province_id: "YT",
      province_name: "Yukon",
      lat: 64.0464,
      lng: -139.3893,
      population: 1375,
      density: 42.4,
      timezone: "America/Whitehorse",
      ranking: 4,
      postal: "Y0B",
      id: 1124075766
    },
    {
      label: "Nantes",
      value: "Nantes",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6333,
      lng: -71.0333,
      population: 1374,
      density: 11.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0Y",
      id: 1124802333
    },
    {
      label: "Lac-aux-Sables",
      value: "Lac-aux-Sables",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8667,
      lng: -72.4,
      population: 1373,
      density: 5.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124691735
    },
    {
      label: "Stewiacke",
      value: "Stewiacke",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.1422,
      lng: -63.3483,
      population: 1373,
      density: 77.9,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0N",
      id: 1124573534
    },
    {
      label: "Taylor",
      value: "Taylor",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 56.159,
      lng: -120.6878,
      population: 1373,
      density: 80.3,
      timezone: "America/Dawson_Creek",
      ranking: 3,
      postal: "V0C",
      id: 1124063816
    },
    {
      label: "Rosser",
      value: "Rosser",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.99,
      lng: -97.4592,
      population: 1372,
      density: 3.1,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R3C R0H",
      id: 1124001581
    },
    {
      label: "Estevan No. 5",
      value: "Estevan No. 5",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 49.1308,
      lng: -103.0126,
      population: 1370,
      density: 1.8,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S4A",
      id: 1124000725
    },
    {
      label: "Falmouth",
      value: "Falmouth",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 44.9967,
      lng: -64.1634,
      population: 1368,
      density: 262.3,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0P",
      id: 1124001382
    },
    {
      label: "Vaudreuil-sur-le-Lac",
      value: "Vaudreuil-sur-le-Lac",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4,
      lng: -74.0333,
      population: 1359,
      density: 989.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J7V",
      id: 1124001806
    },
    {
      label: "Grahamdale",
      value: "Grahamdale",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 51.42,
      lng: -98.3733,
      population: 1359,
      density: 0.6,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0C",
      id: 1124001918
    },
    {
      label: "Cardwell",
      value: "Cardwell",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.7848,
      lng: -65.3037,
      population: 1353,
      density: 4.3,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4Z E4G",
      id: 1124001428
    },
    {
      label: "Two Hills",
      value: "Two Hills",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.715,
      lng: -111.7461,
      population: 1352,
      density: 400,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0B",
      id: 1124512958
    },
    {
      label: "Spiritwood No. 496",
      value: "Spiritwood No. 496",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.4435,
      lng: -107.4495,
      population: 1347,
      density: 0.6,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0J",
      id: 1124001575
    },
    {
      label: "Legal",
      value: "Legal",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.9492,
      lng: -113.595,
      population: 1345,
      density: 423.4,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0G",
      id: 1124819805
    },
    {
      label: "Amulet",
      value: "Amulet",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.2938,
      lng: -79.0274,
      population: 1340,
      density: 724.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J9X J9Y",
      id: 1124000786
    },
    {
      label: "Hérouxville",
      value: "Herouxville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.6667,
      lng: -72.6167,
      population: 1340,
      density: 25.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124001698
    },
    {
      label: "Pointe-des-Cascades",
      value: "Pointe-des-Cascades",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3333,
      lng: -73.9667,
      population: 1340,
      density: 502.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0P",
      id: 1124001740
    },
    {
      label: "Weldford",
      value: "Weldford",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.5221,
      lng: -65.1114,
      population: 1338,
      density: 2.2,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4W E4T",
      id: 1124000165
    },
    {
      label: "Reynolds",
      value: "Reynolds",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.7678,
      lng: -95.8842,
      population: 1338,
      density: 0.4,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0E",
      id: 1124938750
    },
    {
      label: "St. Laurent",
      value: "St. Laurent",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.43,
      lng: -97.7933,
      population: 1338,
      density: 2.9,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0C",
      id: 1124303582
    },
    {
      label: "Lions Bay",
      value: "Lions Bay",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.4581,
      lng: -123.2369,
      population: 1334,
      density: 526.5,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0N",
      id: 1124001126
    },
    {
      label: "L'Isle-aux-Allumettes",
      value: "L'Isle-aux-Allumettes",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8667,
      lng: -77.0667,
      population: 1334,
      density: 7.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0X",
      id: 1124001726
    },
    {
      label: "Emo",
      value: "Emo",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.6333,
      lng: -93.8333,
      population: 1333,
      density: 6.6,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "P0W",
      id: 1124320866
    },
    {
      label: "Sainte-Brigide-d'Iberville",
      value: "Sainte-Brigide-d'Iberville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3167,
      lng: -73.0667,
      population: 1331,
      density: 18.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124015542
    },
    {
      label: "Les Éboulements",
      value: "Les Eboulements",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.4833,
      lng: -70.3167,
      population: 1331,
      density: 8.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124253187
    },
    {
      label: "Dunsmuir",
      value: "Dunsmuir",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.3696,
      lng: -124.5772,
      population: 1330,
      density: 62.3,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V9K",
      id: 1124000426
    },
    {
      label: "Pointe-aux-Outardes",
      value: "Pointe-aux-Outardes",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.05,
      lng: -68.4333,
      population: 1330,
      density: 17.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0H",
      id: 1124001253
    },
    {
      label: "Smooth Rock Falls",
      value: "Smooth Rock Falls",
      province_id: "ON",
      province_name: "Ontario",
      lat: 49.2833,
      lng: -81.6333,
      population: 1330,
      density: 6.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0L",
      id: 1124418972
    },
    {
      label: "Oxbow",
      value: "Oxbow",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 49.2333,
      lng: -102.1667,
      population: 1328,
      density: 412.6,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0C",
      id: 1124727899
    },
    {
      label: "Telkwa",
      value: "Telkwa",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 54.6972,
      lng: -127.05,
      population: 1327,
      density: 191.9,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0J",
      id: 1124000170
    },
    {
      label: "Gjoa Haven",
      value: "Gjoa Haven",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 68.6448,
      lng: -95.8912,
      population: 1324,
      density: 46.5,
      timezone: "America/Cambridge_Bay",
      ranking: 4,
      postal: "X0B",
      id: 1124942230
    },
    {
      label: "Sainte-Barbe",
      value: "Sainte-Barbe",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.1667,
      lng: -74.2,
      population: 1324,
      density: 33,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0S",
      id: 1124147367
    },
    {
      label: "Mayerthorpe",
      value: "Mayerthorpe",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.9503,
      lng: -115.1336,
      population: 1320,
      density: 302.2,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0E",
      id: 1124001053
    },
    {
      label: "Saint-Louis-du-Ha! Ha!",
      value: "Saint-Louis-du-Ha! Ha!",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.67,
      lng: -68.98,
      population: 1318,
      density: 11.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0L",
      id: 1124487645
    },
    {
      label: "Powerview-Pine Falls",
      value: "Powerview-Pine Falls",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.5661,
      lng: -96.1981,
      population: 1316,
      density: 262,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0E",
      id: 1124001400
    },
    {
      label: "Baie Verte",
      value: "Baie Verte",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 49.9167,
      lng: -56.1833,
      population: 1313,
      density: 3.5,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0K",
      id: 1124727368
    },
    {
      label: "Saint-Édouard",
      value: "Saint-Edouard",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2333,
      lng: -73.5167,
      population: 1312,
      density: 24.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0L",
      id: 1124689962
    },
    {
      label: "Charlo",
      value: "Charlo",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 48,
      lng: -66.32,
      population: 1310,
      density: 41.9,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E8E",
      id: 1124001583
    },
    {
      label: "Hillsborough",
      value: "Hillsborough",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.9052,
      lng: -64.7652,
      population: 1308,
      density: 4.3,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4H",
      id: 1124000107
    },
    {
      label: "Bruederheim",
      value: "Bruederheim",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.8042,
      lng: -112.9278,
      population: 1308,
      density: 183.8,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0B",
      id: 1124027946
    },
    {
      label: "Burgeo",
      value: "Burgeo",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.6,
      lng: -57.6333,
      population: 1307,
      density: 41.7,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0N",
      id: 1124034870
    },
    {
      label: "Wadena",
      value: "Wadena",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.9458,
      lng: -103.8014,
      population: 1306,
      density: 449.5,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0A",
      id: 1124604550
    },
    {
      label: "Richmond",
      value: "Richmond",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.0776,
      lng: -67.7248,
      population: 1303,
      density: 5,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E7N E7M",
      id: 1124000907
    },
    {
      label: "Swan Hills",
      value: "Swan Hills",
      province_id: "AB",
      province_name: "Alberta",
      lat: 54.7106,
      lng: -115.4133,
      population: 1301,
      density: 49.8,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0G",
      id: 1124000651
    },
    {
      label: "Wilkie",
      value: "Wilkie",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.4167,
      lng: -108.7,
      population: 1301,
      density: 137.3,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0K",
      id: 1124926813
    },
    {
      label: "Saint-Léonard",
      value: "Saint-Leonard",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.1625,
      lng: -67.925,
      population: 1300,
      density: 249.2,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E7E",
      id: 1124194436
    },
    {
      label: "Rivière-Bleue",
      value: "Riviere-Bleue",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.4333,
      lng: -69.05,
      population: 1299,
      density: 7.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0L",
      id: 1124592122
    },
    {
      label: "Noyan",
      value: "Noyan",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.0667,
      lng: -73.3,
      population: 1297,
      density: 29,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124781949
    },
    {
      label: "Ile-à-la-Crosse",
      value: "Ile-a-la-Crosse",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 55.45,
      lng: -107.8833,
      population: 1296,
      density: 54.4,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0J S0M",
      id: 1124359869
    },
    {
      label: "Landmark",
      value: "Landmark",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.6711,
      lng: -96.8179,
      population: 1292,
      density: 433.4,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0A",
      id: 1124000247
    },
    {
      label: "Saint-Hugues",
      value: "Saint-Hugues",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8,
      lng: -72.8667,
      population: 1292,
      density: 15.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124983381
    },
    {
      label: "Chisholm",
      value: "Chisholm",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.1,
      lng: -79.2333,
      population: 1291,
      density: 6.2,
      timezone: "America/Toronto",
      ranking: 4,
      postal: "P0H",
      id: 1124000894
    },
    {
      label: "Sainte-Anne-du-Sault",
      value: "Sainte-Anne-du-Sault",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1733,
      lng: -72.1415,
      population: 1290,
      density: 21.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0Z",
      id: 1124001314
    },
    {
      label: "La Conception",
      value: "La Conception",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.15,
      lng: -74.7,
      population: 1287,
      density: 9.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0T",
      id: 1124175484
    },
    {
      label: "Saint-Valère",
      value: "Saint-Valere",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0667,
      lng: -72.1,
      population: 1286,
      density: 11.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0P",
      id: 1124182292
    },
    {
      label: "Sorrento",
      value: "Sorrento",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.8832,
      lng: -119.4782,
      population: 1285,
      density: 108.3,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V0E",
      id: 1124978509
    },
    {
      label: "Lamèque",
      value: "Lameque",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.7925,
      lng: -64.6532,
      population: 1285,
      density: 102.8,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E8T",
      id: 1124209362
    },
    {
      label: "Thessalon",
      value: "Thessalon",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.25,
      lng: -83.55,
      population: 1279,
      density: 284.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0R",
      id: 1124087342
    },
    {
      label: "L'Isle-aux-Coudres",
      value: "L'Isle-aux-Coudres",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.4,
      lng: -70.3833,
      population: 1279,
      density: 42.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124001681
    },
    {
      label: "Nobleford",
      value: "Nobleford",
      province_id: "AB",
      province_name: "Alberta",
      lat: 49.8822,
      lng: -113.0531,
      population: 1278,
      density: 802.6,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0L",
      id: 1124281605
    },
    {
      label: "Larouche",
      value: "Larouche",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.45,
      lng: -71.5167,
      population: 1277,
      density: 15.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0W",
      id: 1124000827
    },
    {
      label: "South Qu'Appelle No. 157",
      value: "South Qu'Appelle No. 157",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.5389,
      lng: -104.0141,
      population: 1275,
      density: 1.4,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0G",
      id: 1124001385
    },
    {
      label: "Elton",
      value: "Elton",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.975,
      lng: -99.8658,
      population: 1273,
      density: 2.2,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0K",
      id: 1124000871
    },
    {
      label: "Lorrainville",
      value: "Lorrainville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.3613,
      lng: -79.3382,
      population: 1272,
      density: 14.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0Z",
      id: 1124001976
    },
    {
      label: "Conestogo",
      value: "Conestogo",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.5441,
      lng: -80.4997,
      population: 1270,
      density: 595.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0B",
      id: 1124566995
    },
    {
      label: "Upham",
      value: "Upham",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.5083,
      lng: -65.6618,
      population: 1269,
      density: 6.7,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E5N",
      id: 1124000748
    },
    {
      label: "St.-Charles",
      value: "St.-Charles",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.3422,
      lng: -80.4497,
      population: 1269,
      density: 3.9,
      timezone: "America/Toronto",
      ranking: 4,
      postal: "P0M",
      id: 1124428919
    },
    {
      label: "Sainte-Lucie-des-Laurentides",
      value: "Sainte-Lucie-des-Laurentides",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.13,
      lng: -74.18,
      population: 1269,
      density: 11.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0T",
      id: 1124001146
    },
    {
      label: "Saint-Alexis",
      value: "Saint-Alexis",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9333,
      lng: -73.6167,
      population: 1267,
      density: 29.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0K",
      id: 1124001453
    },
    {
      label: "Gillam",
      value: "Gillam",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 56.3472,
      lng: -94.7078,
      population: 1265,
      density: 0.6,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0B",
      id: 1124560722
    },
    {
      label: "Roxton Falls",
      value: "Roxton Falls",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5667,
      lng: -72.5167,
      population: 1265,
      density: 259.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124901453
    },
    {
      label: "Montcalm",
      value: "Montcalm",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.1775,
      lng: -97.3247,
      population: 1260,
      density: 2.7,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0G",
      id: 1124000100
    },
    {
      label: "Clarendon",
      value: "Clarendon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.65,
      lng: -76.5167,
      population: 1256,
      density: 3.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0X",
      id: 1124000922
    },
    {
      label: "Mervin No. 499",
      value: "Mervin No. 499",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.5455,
      lng: -108.8762,
      population: 1256,
      density: 0.8,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0M",
      id: 1124001677
    },
    {
      label: "Saint-Ludger",
      value: "Saint-Ludger",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.75,
      lng: -70.7,
      population: 1255,
      density: 9.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0M",
      id: 1124281144
    },
    {
      label: "Coldwell",
      value: "Coldwell",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.6389,
      lng: -98.0417,
      population: 1254,
      density: 1.4,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0C",
      id: 1124001845
    },
    {
      label: "Saint-Arsène",
      value: "Saint-Arsene",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.9167,
      lng: -69.4333,
      population: 1253,
      density: 17.7,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0L",
      id: 1124482227
    },
    {
      label: "Racine",
      value: "Racine",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5,
      lng: -72.25,
      population: 1252,
      density: 11.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0E",
      id: 1124253350
    },
    {
      label: "Saint-Majorique-de-Grantham",
      value: "Saint-Majorique-de-Grantham",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9333,
      lng: -72.5833,
      population: 1251,
      density: 21.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J2B",
      id: 1124000808
    },
    {
      label: "Saint-Zénon",
      value: "Saint-Zenon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.55,
      lng: -73.8167,
      population: 1250,
      density: 2.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124443019
    },
    {
      label: "Saint-Armand",
      value: "Saint-Armand",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.0333,
      lng: -73.05,
      population: 1248,
      density: 15.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124958164
    },
    {
      label: "Saint-Édouard-de-Lotbinière",
      value: "Saint-Edouard-de-Lotbiniere",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5667,
      lng: -71.8333,
      population: 1248,
      density: 12.7,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124001130
    },
    {
      label: "Alonsa",
      value: "Alonsa",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.9794,
      lng: -99.0796,
      population: 1247,
      density: 0.4,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0H R0L",
      id: 1124385753
    },
    {
      label: "Listuguj",
      value: "Listuguj",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.0609,
      lng: -66.7491,
      population: 1241,
      density: 28,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0C",
      id: 1124001828
    },
    {
      label: "Bowden",
      value: "Bowden",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.9306,
      lng: -114.0256,
      population: 1240,
      density: 442.8,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0M",
      id: 1124945470
    },
    {
      label: "St. Joseph",
      value: "St. Joseph",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.2667,
      lng: -84,
      population: 1240,
      density: 9.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0R",
      id: 1124001430
    },
    {
      label: "Osler",
      value: "Osler",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.37,
      lng: -106.54,
      population: 1237,
      density: 796.5,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0K",
      id: 1124000037
    },
    {
      label: "Saint-Hubert-de-Rivière-du-Loup",
      value: "Saint-Hubert-de-Riviere-du-Loup",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.8167,
      lng: -69.15,
      population: 1235,
      density: 6.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0L",
      id: 1124000191
    },
    {
      label: "Saint-Jude",
      value: "Saint-Jude",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7667,
      lng: -72.9833,
      population: 1235,
      density: 16,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0H",
      id: 1124510808
    },
    {
      label: "Dildo",
      value: "Dildo",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.5685,
      lng: -53.5471,
      population: 1234,
      density: 152,
      timezone: "America/St_Johns",
      ranking: 4,
      postal: "A0B",
      id: 1124396361
    },
    {
      label: "La Minerve",
      value: "La Minerve",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.25,
      lng: -74.9333,
      population: 1234,
      density: 4.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0T",
      id: 1124869065
    },
    {
      label: "Lanigan",
      value: "Lanigan",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.85,
      lng: -105.0333,
      population: 1233,
      density: 165.2,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0K",
      id: 1124052623
    },
    {
      label: "Lajord No. 128",
      value: "Lajord No. 128",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.1965,
      lng: -104.2507,
      population: 1232,
      density: 1.3,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0G",
      id: 1124000590
    },
    {
      label: "Moonbeam",
      value: "Moonbeam",
      province_id: "ON",
      province_name: "Ontario",
      lat: 49.35,
      lng: -82.15,
      population: 1231,
      density: 5.2,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0L",
      id: 1124775223
    },
    {
      label: "Notre-Dame-des-Pins",
      value: "Notre-Dame-des-Pins",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1833,
      lng: -70.7167,
      population: 1227,
      density: 51.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0M",
      id: 1124000253
    },
    {
      label: "Saint-Alban",
      value: "Saint-Alban",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.7167,
      lng: -72.0833,
      population: 1225,
      density: 8.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124839435
    },
    {
      label: "Saint-Pierre-les-Becquets",
      value: "Saint-Pierre-les-Becquets",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5,
      lng: -72.2,
      population: 1223,
      density: 25.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0X",
      id: 1124957722
    },
    {
      label: "Arborg",
      value: "Arborg",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.9075,
      lng: -97.2182,
      population: 1222,
      density: 611,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0C",
      id: 1124353392
    },
    {
      label: "Vauxhall",
      value: "Vauxhall",
      province_id: "AB",
      province_name: "Alberta",
      lat: 50.0689,
      lng: -112.0975,
      population: 1222,
      density: 449.4,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0K",
      id: 1124148360
    },
    {
      label: "Bayfield",
      value: "Bayfield",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.5615,
      lng: -81.6983,
      population: 1218,
      density: 439.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0M",
      id: 1124665510
    },
    {
      label: "Beaver River",
      value: "Beaver River",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 54.3531,
      lng: -109.5575,
      population: 1216,
      density: 0.5,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0M",
      id: 1124717040
    },
    {
      label: "Irricana",
      value: "Irricana",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.3189,
      lng: -113.6106,
      population: 1216,
      density: 376.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0M",
      id: 1124968867
    },
    {
      label: "Labrecque",
      value: "Labrecque",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.6667,
      lng: -71.5333,
      population: 1215,
      density: 7.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0W",
      id: 1124000362
    },
    {
      label: "New Bandon",
      value: "New Bandon",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.6912,
      lng: -65.29,
      population: 1214,
      density: 3.4,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E2A E8N",
      id: 1124001513
    },
    {
      label: "Wemotaci",
      value: "Wemotaci",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.9219,
      lng: -73.7872,
      population: 1213,
      density: 38.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0X",
      id: 1124001294
    },
    {
      label: "Sainte-Hénédine",
      value: "Sainte-Henedine",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.55,
      lng: -70.9833,
      population: 1212,
      density: 23.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124771909
    },
    {
      label: "L'Anse-Saint-Jean",
      value: "L'Anse-Saint-Jean",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.2333,
      lng: -70.2,
      population: 1208,
      density: 2.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0V",
      id: 1124155071
    },
    {
      label: "Bassano",
      value: "Bassano",
      province_id: "AB",
      province_name: "Alberta",
      lat: 50.7833,
      lng: -112.4667,
      population: 1206,
      density: 231.2,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0J",
      id: 1124776374
    },
    {
      label: "Parrsboro",
      value: "Parrsboro",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.3998,
      lng: -64.3312,
      population: 1205,
      density: 81.4,
      timezone: "America/Halifax",
      ranking: 4,
      postal: "B0M",
      id: 1124877589
    },
    {
      label: "Kaleden",
      value: "Kaleden",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.3926,
      lng: -119.5955,
      population: 1203,
      density: 278.7,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0H",
      id: 1124001071
    },
    {
      label: "St. George's",
      value: "St. George's",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.4275,
      lng: -58.4778,
      population: 1203,
      density: 46.6,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0N",
      id: 1124178262
    },
    {
      label: "Fort Simpson",
      value: "Fort Simpson",
      province_id: "NT",
      province_name: "Northwest Territories",
      lat: 61.8082,
      lng: -121.3199,
      population: 1202,
      density: 15.3,
      timezone: "America/Yellowknife",
      ranking: 4,
      postal: "X0E",
      id: 1124669512
    },
    {
      label: "Akwesasne",
      value: "Akwesasne",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.0155,
      lng: -74.5769,
      population: 1202,
      density: 48.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0S H0M",
      id: 1124000436
    },
    {
      label: "L’Avenir",
      value: "L'Avenir",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.7667,
      lng: -72.3,
      population: 1202,
      density: 12.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0C",
      id: 1124154040
    },
    {
      label: "Ignace",
      value: "Ignace",
      province_id: "ON",
      province_name: "Ontario",
      lat: 49.4167,
      lng: -91.6667,
      population: 1202,
      density: 16.5,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "P0T",
      id: 1124972211
    },
    {
      label: "Claremont",
      value: "Claremont",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.9741,
      lng: -79.1316,
      population: 1202,
      density: 567.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L1Y",
      id: 1124327632
    },
    {
      label: "Teulon",
      value: "Teulon",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.3858,
      lng: -97.2611,
      population: 1201,
      density: 372.3,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0C",
      id: 1124616630
    },
    {
      label: "Peel",
      value: "Peel",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.4058,
      lng: -67.5278,
      population: 1196,
      density: 10.6,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E7L",
      id: 1124771409
    },
    {
      label: "Musquash",
      value: "Musquash",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.1836,
      lng: -66.3514,
      population: 1194,
      density: 5.1,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E5J",
      id: 1124987756
    },
    {
      label: "Notre-Dame-du-Portage",
      value: "Notre-Dame-du-Portage",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.7667,
      lng: -69.6167,
      population: 1193,
      density: 29.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0L",
      id: 1124956445
    },
    {
      label: "St. Lawrence",
      value: "St. Lawrence",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 46.9244,
      lng: -55.3928,
      population: 1192,
      density: 33.6,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0E",
      id: 1124645666
    },
    {
      label: "Oxford",
      value: "Oxford",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.7306,
      lng: -63.8733,
      population: 1190,
      density: 110.6,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0M",
      id: 1124455847
    },
    {
      label: "Minto-Odanah",
      value: "Minto-Odanah",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.2406,
      lng: -99.8056,
      population: 1189,
      density: 1.6,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0J",
      id: 1124001517
    },
    {
      label: "St. Alban's",
      value: "St. Alban's",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.8753,
      lng: -55.8414,
      population: 1186,
      density: 56.9,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0H",
      id: 1124613667
    },
    {
      label: "Saint James",
      value: "Saint James",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.3822,
      lng: -67.3427,
      population: 1186,
      density: 2.1,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E5A E3L",
      id: 1124001675
    },
    {
      label: "Saint-Norbert-d'Arthabaska",
      value: "Saint-Norbert-d'Arthabaska",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1,
      lng: -71.8167,
      population: 1185,
      density: 11.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0P",
      id: 1124000467
    },
    {
      label: "Manning",
      value: "Manning",
      province_id: "AB",
      province_name: "Alberta",
      lat: 56.9142,
      lng: -117.6272,
      population: 1183,
      density: 291.9,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0H",
      id: 1124001357
    },
    {
      label: "Glenella-Lansdowne",
      value: "Glenella-Lansdowne",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.4163,
      lng: -99.2097,
      population: 1181,
      density: 0.9,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0J",
      id: 1124001185
    },
    {
      label: "Saint-Hilarion",
      value: "Saint-Hilarion",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.5667,
      lng: -70.4,
      population: 1181,
      density: 11.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0A",
      id: 1124375343
    },
    {
      label: "Saint-Siméon",
      value: "Saint-Simeon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.0667,
      lng: -65.5667,
      population: 1179,
      density: 20.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0C",
      id: 1124797465
    },
    {
      label: "Saint-Barnabé",
      value: "Saint-Barnabe",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4,
      lng: -72.8833,
      population: 1179,
      density: 20,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124760889
    },
    {
      label: "Sainte-Félicité",
      value: "Sainte-Felicite",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.9,
      lng: -67.3333,
      population: 1175,
      density: 12.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0J",
      id: 1124831574
    },
    {
      label: "Two Borders",
      value: "Two Borders",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.2668,
      lng: -101.1124,
      population: 1175,
      density: 0.5,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0M",
      id: 1124001678
    },
    {
      label: "Queensbury",
      value: "Queensbury",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.9918,
      lng: -67.0632,
      population: 1174,
      density: 3.9,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E6L E6E",
      id: 1124001691
    },
    {
      label: "Bury",
      value: "Bury",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4667,
      lng: -71.5,
      population: 1174,
      density: 4.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0B",
      id: 1124643055
    },
    {
      label: "Lac-Bouchette",
      value: "Lac-Bouchette",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.25,
      lng: -72.18,
      population: 1174,
      density: 1.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0W",
      id: 1124365485
    },
    {
      label: "Saint-Lazare-de-Bellechasse",
      value: "Saint-Lazare-de-Bellechasse",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.65,
      lng: -70.8,
      population: 1172,
      density: 13.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0R",
      id: 1124054719
    },
    {
      label: "Saint-Michel-du-Squatec",
      value: "Saint-Michel-du-Squatec",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.88,
      lng: -68.72,
      population: 1171,
      density: 3.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0L",
      id: 1124190334
    },
    {
      label: "Saint-Joachim-de-Shefford",
      value: "Saint-Joachim-de-Shefford",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.45,
      lng: -72.5333,
      population: 1171,
      density: 9.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0E",
      id: 1124777025
    },
    {
      label: "St-Pierre-Jolys",
      value: "St-Pierre-Jolys",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.4403,
      lng: -96.9844,
      population: 1170,
      density: 440.5,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0A",
      id: 1124001013
    },
    {
      label: "Grand-Remous",
      value: "Grand-Remous",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.6167,
      lng: -75.9,
      population: 1168,
      density: 3.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0W",
      id: 1124917091
    },
    {
      label: "Saint-Gabriel-de-Rimouski",
      value: "Saint-Gabriel-de-Rimouski",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.4209,
      lng: -68.1791,
      population: 1167,
      density: 9.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0K",
      id: 1124766556
    },
    {
      label: "Armstrong",
      value: "Armstrong",
      province_id: "ON",
      province_name: "Ontario",
      lat: 47.7083,
      lng: -79.825,
      population: 1166,
      density: 12.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0J",
      id: 1124000411
    },
    {
      label: "Rogersville",
      value: "Rogersville",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.7167,
      lng: -65.4167,
      population: 1166,
      density: 161.9,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E4Y",
      id: 1124369581
    },
    {
      label: "Langenburg",
      value: "Langenburg",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.8333,
      lng: -101.7,
      population: 1165,
      density: 337.1,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0A",
      id: 1124335442
    },
    {
      label: "Sainte-Marie-Salomé",
      value: "Sainte-Marie-Salome",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9333,
      lng: -73.5,
      population: 1164,
      density: 34.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124001034
    },
    {
      label: "Moose Jaw No. 161",
      value: "Moose Jaw No. 161",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.4433,
      lng: -105.5091,
      population: 1163,
      density: 1.5,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0H S6J",
      id: 1124000515
    },
    {
      label: "Saint-Cyprien",
      value: "Saint-Cyprien",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.9,
      lng: -69.0167,
      population: 1163,
      density: 8.4,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0L",
      id: 1124986836
    },
    {
      label: "Maidstone",
      value: "Maidstone",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.092,
      lng: -109.294,
      population: 1156,
      density: 233.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "S0M",
      id: 1124537085
    },
    {
      label: "Très-Saint-Sacrement",
      value: "Tres-Saint-Sacrement",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.1833,
      lng: -73.85,
      population: 1155,
      density: 11.7,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0S",
      id: 1124001118
    },
    {
      label: "Battle River No. 438",
      value: "Battle River No. 438",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.7343,
      lng: -108.4452,
      population: 1154,
      density: 1.1,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0M",
      id: 1124001521
    },
    {
      label: "Miltonvale Park",
      value: "Miltonvale Park",
      province_id: "PE",
      province_name: "Prince Edward Island",
      lat: 46.318,
      lng: -63.237,
      population: 1153,
      density: 32.4,
      timezone: "America/Halifax",
      ranking: 4,
      postal: "C1E",
      id: 1124001949
    },
    {
      label: "McAdam",
      value: "McAdam",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.5944,
      lng: -67.3258,
      population: 1151,
      density: 80.6,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E6J",
      id: 1124054455
    },
    {
      label: "Saints-Anges",
      value: "Saints-Anges",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4167,
      lng: -70.8833,
      population: 1149,
      density: 16.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124749056
    },
    {
      label: "Saint-Urbain-Premier",
      value: "Saint-Urbain-Premier",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.22,
      lng: -73.73,
      population: 1148,
      density: 21.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0S",
      id: 1124927145
    },
    {
      label: "Centreville-Wareham-Trinity",
      value: "Centreville-Wareham-Trinity",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 48.9879,
      lng: -53.9069,
      population: 1147,
      density: 30.8,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0G",
      id: 1124000332
    },
    {
      label: "Alberton",
      value: "Alberton",
      province_id: "PE",
      province_name: "Prince Edward Island",
      lat: 46.8167,
      lng: -64.0667,
      population: 1145,
      density: 253.5,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "C0B",
      id: 1124792801
    },
    {
      label: "Winnipeg Beach",
      value: "Winnipeg Beach",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 50.5058,
      lng: -96.9742,
      population: 1145,
      density: 295.9,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0C",
      id: 1124001121
    },
    {
      label: "Sainte-Agathe-de-Lotbinière",
      value: "Sainte-Agathe-de-Lotbiniere",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3833,
      lng: -71.4167,
      population: 1145,
      density: 6.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124003470
    },
    {
      label: "Salmo",
      value: "Salmo",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.1942,
      lng: -117.2778,
      population: 1141,
      density: 466.2,
      timezone: "America/Vancouver",
      ranking: 3,
      postal: "V0G",
      id: 1124411651
    },
    {
      label: "Kipling",
      value: "Kipling",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.1015,
      lng: -102.6324,
      population: 1140,
      density: 396.5,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0G",
      id: 1124000823
    },
    {
      label: "Sagamok",
      value: "Sagamok",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.1529,
      lng: -82.2072,
      population: 1140,
      density: 11.6,
      timezone: "America/Toronto",
      ranking: 4,
      postal: "P0P",
      id: 1124001822
    },
    {
      label: "Trécesson",
      value: "Trecesson",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.65,
      lng: -78.3167,
      population: 1138,
      density: 5.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0Y",
      id: 1124000493
    },
    {
      label: "Tara",
      value: "Tara",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.4793,
      lng: -81.1445,
      population: 1138,
      density: 470.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0H",
      id: 1124001864
    },
    {
      label: "Grande-Vallée",
      value: "Grande-Vallee",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.2167,
      lng: -65.1333,
      population: 1137,
      density: 7.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0E",
      id: 1124608975
    },
    {
      label: "Bertrand",
      value: "Bertrand",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.7622,
      lng: -65.0686,
      population: 1137,
      density: 24.5,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E1W",
      id: 1124001809
    },
    {
      label: "Newcastle",
      value: "Newcastle",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.1725,
      lng: -65.5551,
      population: 1136,
      density: 2,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E1V",
      id: 1124367015
    },
    {
      label: "Mont-Carmel",
      value: "Mont-Carmel",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.4397,
      lng: -69.8586,
      population: 1136,
      density: 2.7,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0L",
      id: 1124064864
    },
    {
      label: "Saint Martins",
      value: "Saint Martins",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.4563,
      lng: -65.4395,
      population: 1132,
      density: 1.8,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E5R",
      id: 1124001010
    },
    {
      label: "Saint-Eugène",
      value: "Saint-Eugene",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.8,
      lng: -72.7,
      population: 1131,
      density: 14.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0C",
      id: 1124834014
    },
    {
      label: "Notre-Dame-des-Neiges",
      value: "Notre-Dame-des-Neiges",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.1167,
      lng: -69.1667,
      population: 1129,
      density: 12,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0L",
      id: 1124000518
    },
    {
      label: "Saint-André",
      value: "Saint-Andre",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.1392,
      lng: -67.7444,
      population: 1129,
      density: 8.8,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E3Y",
      id: 1124000931
    },
    {
      label: "Centreville",
      value: "Centreville",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 45.13,
      lng: -64.5224,
      population: 1129,
      density: 477.5,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0P",
      id: 1124795742
    },
    {
      label: "Roland",
      value: "Roland",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.3547,
      lng: -97.8997,
      population: 1129,
      density: 2.3,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0G",
      id: 1124796797
    },
    {
      label: "Saint-Léon-de-Standon",
      value: "Saint-Leon-de-Standon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4833,
      lng: -70.6167,
      population: 1128,
      density: 8.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0R",
      id: 1124297826
    },
    {
      label: "Saint-Modeste",
      value: "Saint-Modeste",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.8333,
      lng: -69.4,
      population: 1128,
      density: 10.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0L",
      id: 1124591131
    },
    {
      label: "Carnduff",
      value: "Carnduff",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 49.167,
      lng: -101.783,
      population: 1126,
      density: 498.2,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0C",
      id: 1124238691
    },
    {
      label: "Carling",
      value: "Carling",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.4333,
      lng: -80.2167,
      population: 1125,
      density: 4.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0G",
      id: 1124000522
    },
    {
      label: "Eckville",
      value: "Eckville",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.3622,
      lng: -114.3614,
      population: 1125,
      density: 703.3,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0M",
      id: 1124000793
    },
    {
      label: "Nain",
      value: "Nain",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 56.5422,
      lng: -61.6928,
      population: 1125,
      density: 11.9,
      timezone: "America/Goose_Bay",
      ranking: 3,
      postal: "A0P",
      id: 1124719084
    },
    {
      label: "Hillsburgh",
      value: "Hillsburgh",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.7914,
      lng: -80.1354,
      population: 1124,
      density: 384.9,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0B",
      id: 1124258378
    },
    {
      label: "Foam Lake",
      value: "Foam Lake",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.65,
      lng: -103.5333,
      population: 1123,
      density: 189.4,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0A",
      id: 1124751136
    },
    {
      label: "Sainte-Sabine",
      value: "Sainte-Sabine",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.2333,
      lng: -73.0167,
      population: 1120,
      density: 20.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0J",
      id: 1124001836
    },
    {
      label: "Saint-Maxime-du-Mont-Louis",
      value: "Saint-Maxime-du-Mont-Louis",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49.2333,
      lng: -65.7333,
      population: 1118,
      density: 4.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0E",
      id: 1124000029
    },
    {
      label: "Blanc-Sablon",
      value: "Blanc-Sablon",
      province_id: "QC",
      province_name: "Quebec",
      lat: 51.4167,
      lng: -57.1333,
      population: 1118,
      density: 4.5,
      timezone: "America/Blanc-Sablon",
      ranking: 3,
      postal: "G0G",
      id: 1124785666
    },
    {
      label: "Cobalt",
      value: "Cobalt",
      province_id: "ON",
      province_name: "Ontario",
      lat: 47.4,
      lng: -79.6833,
      population: 1118,
      density: 776,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0J",
      id: 1124248298
    },
    {
      label: "Gravelbourg",
      value: "Gravelbourg",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 49.874,
      lng: -106.555,
      population: 1116,
      density: 346,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0H",
      id: 1124409900
    },
    {
      label: "South River",
      value: "South River",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.8417,
      lng: -79.375,
      population: 1114,
      density: 268.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0A",
      id: 1124154548
    },
    {
      label: "Hudson Bay No. 394",
      value: "Hudson Bay No. 394",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.0295,
      lng: -102.3122,
      population: 1114,
      density: 0.1,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0E",
      id: 1124001694
    },
    {
      label: "McKellar",
      value: "McKellar",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.4833,
      lng: -79.85,
      population: 1111,
      density: 6.1,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0G P2A",
      id: 1124000057
    },
    {
      label: "Frelighsburg",
      value: "Frelighsburg",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.0461,
      lng: -72.8106,
      population: 1111,
      density: 9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0J",
      id: 1124000101
    },
    {
      label: "Buffalo Narrows",
      value: "Buffalo Narrows",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 55.8769,
      lng: -108.5244,
      population: 1110,
      density: 16.2,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0M",
      id: 1124766743
    },
    {
      label: "Ayer’s Cliff",
      value: "Ayer's Cliff",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.1667,
      lng: -72.05,
      population: 1109,
      density: 197.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0B",
      id: 1124916439
    },
    {
      label: "Les Méchins",
      value: "Les Mechins",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49,
      lng: -66.9833,
      population: 1107,
      density: 2.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0J",
      id: 1124540316
    },
    {
      label: "Sainte-Marguerite",
      value: "Sainte-Marguerite",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5167,
      lng: -70.9333,
      population: 1107,
      density: 12.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124041972
    },
    {
      label: "Saint-Claude",
      value: "Saint-Claude",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6667,
      lng: -71.9833,
      population: 1106,
      density: 9.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J1S",
      id: 1124525083
    },
    {
      label: "Air Ronge",
      value: "Air Ronge",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 55.0872,
      lng: -105.3318,
      population: 1106,
      density: 184.3,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0J",
      id: 1124001996
    },
    {
      label: "Chipman",
      value: "Chipman",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.171,
      lng: -65.884,
      population: 1104,
      density: 58,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E4A",
      id: 1124551016
    },
    {
      label: "Girardville",
      value: "Girardville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 49,
      lng: -72.55,
      population: 1100,
      density: 8.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0W",
      id: 1124315247
    },
    {
      label: "Saint-Bruno-de-Guigues",
      value: "Saint-Bruno-de-Guigues",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.4667,
      lng: -79.4333,
      population: 1100,
      density: 8.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0Z",
      id: 1124052468
    },
    {
      label: "Grenfell",
      value: "Grenfell",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.4167,
      lng: -102.9167,
      population: 1099,
      density: 347,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0G",
      id: 1124603747
    },
    {
      label: "Dorchester",
      value: "Dorchester",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.9016,
      lng: -64.5161,
      population: 1096,
      density: 189.3,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E4K",
      id: 1124001021
    },
    {
      label: "South Algonquin",
      value: "South Algonquin",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.4967,
      lng: -78.0239,
      population: 1096,
      density: 1.3,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0J",
      id: 1124001032
    },
    {
      label: "Windermere",
      value: "Windermere",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 50.4856,
      lng: -115.9948,
      population: 1092,
      density: 104,
      timezone: "America/Edmonton",
      ranking: 4,
      postal: "V0A V0B",
      id: 1124519589
    },
    {
      label: "Saint-Narcisse-de-Beaurivage",
      value: "Saint-Narcisse-de-Beaurivage",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4833,
      lng: -71.2333,
      population: 1091,
      density: 17.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124000086
    },
    {
      label: "Saint-René-de-Matane",
      value: "Saint-Rene-de-Matane",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.7,
      lng: -67.3833,
      population: 1089,
      density: 4.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0J",
      id: 1124000167
    },
    {
      label: "Sainte-Jeanne-d'Arc",
      value: "Sainte-Jeanne-d'Arc",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.8575,
      lng: -72.0939,
      population: 1089,
      density: 4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0W",
      id: 1124001392
    },
    {
      label: "Plaisance",
      value: "Plaisance",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6167,
      lng: -75.1167,
      population: 1088,
      density: 30.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0V",
      id: 1124858477
    },
    {
      label: "Roxton-Sud",
      value: "Roxton-Sud",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5521,
      lng: -72.5265,
      population: 1086,
      density: 7.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0H",
      id: 1124174410
    },
    {
      label: "St. Louis No. 431",
      value: "St. Louis No. 431",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.8277,
      lng: -105.7873,
      population: 1086,
      density: 1.4,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0J S0K",
      id: 1124000136
    },
    {
      label: "Youbou",
      value: "Youbou",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 48.8562,
      lng: -124.1731,
      population: 1086,
      density: 122.8,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V0R",
      id: 1124809081
    },
    {
      label: "Duchess",
      value: "Duchess",
      province_id: "AB",
      province_name: "Alberta",
      lat: 50.7333,
      lng: -111.9,
      population: 1085,
      density: 553.4,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0J",
      id: 1124156956
    },
    {
      label: "Saint-Frédéric",
      value: "Saint-Frederic",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3,
      lng: -70.9667,
      population: 1085,
      density: 14.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0N",
      id: 1124436339
    },
    {
      label: "Viking",
      value: "Viking",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.0953,
      lng: -111.7769,
      population: 1083,
      density: 292.5,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0B",
      id: 1124502081
    },
    {
      label: "Sioux Narrows-Nestor Falls",
      value: "Sioux Narrows-Nestor Falls",
      province_id: "ON",
      province_name: "Ontario",
      lat: 49.4,
      lng: -94.08,
      population: 1082,
      density: 0.5,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "P0X",
      id: 1124000587
    },
    {
      label: "Whitecourt",
      value: "Whitecourt",
      province_id: "AB",
      province_name: "Alberta",
      lat: 54.1417,
      lng: -115.6833,
      population: 1082,
      density: 386,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T7S",
      id: 1124641551
    },
    {
      label: "Repulse Bay",
      value: "Repulse Bay",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 66.5628,
      lng: -86.3186,
      population: 1082,
      density: 2.6,
      timezone: "America/Rankin_Inlet",
      ranking: 4,
      postal: "X0C",
      id: 1124398936
    },
    {
      label: "Montréal-Est",
      value: "Montreal-Est",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.63,
      lng: -73.52,
      population: 1082,
      density: 299.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "H1L H1B",
      id: 1124000990
    },
    {
      label: "King",
      value: "King",
      province_id: "ON",
      province_name: "Ontario",
      lat: 44.0463,
      lng: -79.6044,
      population: 1082,
      density: 73.6,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "L7B L0G L3Y",
      id: 1124001693
    },
    {
      label: "Regina Beach",
      value: "Regina Beach",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.79,
      lng: -104.99,
      population: 1081,
      density: 462.4,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0G",
      id: 1124000991
    },
    {
      label: "Saint-Patrice-de-Beaurivage",
      value: "Saint-Patrice-de-Beaurivage",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.4167,
      lng: -71.2333,
      population: 1080,
      density: 12.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0S",
      id: 1124097931
    },
    {
      label: "Ootischenia",
      value: "Ootischenia",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 49.2916,
      lng: -117.6323,
      population: 1080,
      density: 135.4,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V1N",
      id: 1124935527
    },
    {
      label: "Hensall",
      value: "Hensall",
      province_id: "ON",
      province_name: "Ontario",
      lat: 43.4345,
      lng: -81.504,
      population: 1078,
      density: 569,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "N0M",
      id: 1124762629
    },
    {
      label: "Bentley",
      value: "Bentley",
      province_id: "AB",
      province_name: "Alberta",
      lat: 52.4667,
      lng: -114.05,
      population: 1078,
      density: 482.2,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0C",
      id: 1124340912
    },
    {
      label: "Durham",
      value: "Durham",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 47.7631,
      lng: -66.0849,
      population: 1076,
      density: 2.6,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E8G",
      id: 1124000804
    },
    {
      label: "Sainte-Marthe",
      value: "Sainte-Marthe",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.4,
      lng: -74.3,
      population: 1075,
      density: 13.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0P",
      id: 1124191744
    },
    {
      label: "Notre-Dame-du-Nord",
      value: "Notre-Dame-du-Nord",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.6,
      lng: -79.4833,
      population: 1075,
      density: 14.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0Z",
      id: 1124408692
    },
    {
      label: "Pinehouse",
      value: "Pinehouse",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 55.5136,
      lng: -106.5986,
      population: 1074,
      density: 142.9,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0J",
      id: 1124001604
    },
    {
      label: "Saint-Aimé-des-Lacs",
      value: "Saint-Aime-des-Lacs",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.6833,
      lng: -70.3,
      population: 1073,
      density: 11.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0T",
      id: 1124001325
    },
    {
      label: "Lac-Drolet",
      value: "Lac-Drolet",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.72,
      lng: -70.85,
      population: 1071,
      density: 8.6,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0Y",
      id: 1124120357
    },
    {
      label: "Preeceville",
      value: "Preeceville",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.958,
      lng: -102.6673,
      population: 1070,
      density: 0.7,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0A",
      id: 1124064523
    },
    {
      label: "Maple Creek No. 111",
      value: "Maple Creek No. 111",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 49.8044,
      lng: -109.6508,
      population: 1068,
      density: 0.3,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0N",
      id: 1124000783
    },
    {
      label: "Harbour Main-Chapel's Cove-Lakeview",
      value: "Harbour Main-Chapel's Cove-Lakeview",
      province_id: "NL",
      province_name: "Newfoundland and Labrador",
      lat: 47.4337,
      lng: -53.1458,
      population: 1067,
      density: 50.6,
      timezone: "America/St_Johns",
      ranking: 3,
      postal: "A0A",
      id: 1124000035
    },
    {
      label: "Saint-Wenceslas",
      value: "Saint-Wenceslas",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1667,
      lng: -72.3333,
      population: 1064,
      density: 13.3,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0Z",
      id: 1124947290
    },
    {
      label: "Weyburn No. 67",
      value: "Weyburn No. 67",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 49.6535,
      lng: -103.8348,
      population: 1064,
      density: 1.3,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0C",
      id: 1124000632
    },
    {
      label: "Birch Hills",
      value: "Birch Hills",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.9833,
      lng: -105.4333,
      population: 1064,
      density: 468.4,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0J",
      id: 1124520497
    },
    {
      label: "Wedgeport",
      value: "Wedgeport",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 43.7323,
      lng: -65.9797,
      population: 1061,
      density: 109.3,
      timezone: "America/Halifax",
      ranking: 4,
      postal: "B0W",
      id: 1124599537
    },
    {
      label: "Kerrobert",
      value: "Kerrobert",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.92,
      lng: -109.1272,
      population: 1061,
      density: 141.7,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0L",
      id: 1124941446
    },
    {
      label: "Havelock",
      value: "Havelock",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.9523,
      lng: -65.3885,
      population: 1061,
      density: 3,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4Z",
      id: 1124740292
    },
    {
      label: "Eston",
      value: "Eston",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.15,
      lng: -108.75,
      population: 1061,
      density: 390.3,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0L",
      id: 1124212993
    },
    {
      label: "Sainte-Geneviève-de-Batiscan",
      value: "Sainte-Genevieve-de-Batiscan",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.5333,
      lng: -72.3333,
      population: 1060,
      density: 10.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124685530
    },
    {
      label: "Saint-Justin",
      value: "Saint-Justin",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.25,
      lng: -73.0833,
      population: 1060,
      density: 13.5,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0K",
      id: 1124449723
    },
    {
      label: "Saint-Norbert",
      value: "Saint-Norbert",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.1667,
      lng: -73.3167,
      population: 1059,
      density: 14.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0K",
      id: 1124000928
    },
    {
      label: "Schreiber",
      value: "Schreiber",
      province_id: "ON",
      province_name: "Ontario",
      lat: 48.8167,
      lng: -87.2667,
      population: 1059,
      density: 28.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0T",
      id: 1124663303
    },
    {
      label: "Trochu",
      value: "Trochu",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.8236,
      lng: -113.2328,
      population: 1058,
      density: 381.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0M",
      id: 1124642144
    },
    {
      label: "Botsford",
      value: "Botsford",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.1145,
      lng: -63.9804,
      population: 1058,
      density: 3.5,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E4M",
      id: 1124000452
    },
    {
      label: "Riviere-Ouelle",
      value: "Riviere-Ouelle",
      province_id: "QC",
      province_name: "Quebec",
      lat: 47.4333,
      lng: -70.0167,
      population: 1058,
      density: 18.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0L",
      id: 1124401890
    },
    {
      label: "Greenwich",
      value: "Greenwich",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.5112,
      lng: -66.1229,
      population: 1058,
      density: 9.2,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E5M",
      id: 1124001377
    },
    {
      label: "Stukely-Sud",
      value: "Stukely-Sud",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.3167,
      lng: -72.4167,
      population: 1058,
      density: 15.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0E",
      id: 1124476796
    },
    {
      label: "Saint-Georges-de-Clarenceville",
      value: "Saint-Georges-de-Clarenceville",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.0667,
      lng: -73.25,
      population: 1056,
      density: 16.6,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0J",
      id: 1124963246
    },
    {
      label: "Sainte-Thérèse-de-Gaspé",
      value: "Sainte-Therese-de-Gaspe",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.4167,
      lng: -64.4167,
      population: 1055,
      density: 31.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0C",
      id: 1124000271
    },
    {
      label: "Beachburg",
      value: "Beachburg",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.7303,
      lng: -76.8559,
      population: 1054,
      density: 242.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0J",
      id: 1124185620
    },
    {
      label: "Desbiens",
      value: "Desbiens",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.4167,
      lng: -71.95,
      population: 1053,
      density: 101.1,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0W",
      id: 1124443927
    },
    {
      label: "Clyde River",
      value: "Clyde River",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 70.4632,
      lng: -68.4822,
      population: 1053,
      density: 9.9,
      timezone: "America/Iqaluit",
      ranking: 4,
      postal: "X0A",
      id: 1124801172
    },
    {
      label: "La Macaza",
      value: "La Macaza",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3667,
      lng: -74.7667,
      population: 1053,
      density: 6.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0T",
      id: 1124760668
    },
    {
      label: "Souris",
      value: "Souris",
      province_id: "PE",
      province_name: "Prince Edward Island",
      lat: 46.3554,
      lng: -62.2542,
      population: 1053,
      density: 303.7,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "C0A",
      id: 1124838959
    },
    {
      label: "Kindersley No. 290",
      value: "Kindersley No. 290",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.4894,
      lng: -109.0979,
      population: 1049,
      density: 0.5,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0L",
      id: 1124000416
    },
    {
      label: "Laird",
      value: "Laird",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.3833,
      lng: -84.0667,
      population: 1047,
      density: 10.2,
      timezone: "America/Detroit",
      ranking: 4,
      postal: "P0S",
      id: 1124000662
    },
    {
      label: "Falher",
      value: "Falher",
      province_id: "AB",
      province_name: "Alberta",
      lat: 55.7372,
      lng: -117.2014,
      population: 1047,
      density: 376.4,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0H",
      id: 1124001263
    },
    {
      label: "Saint-Vallier",
      value: "Saint-Vallier",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8833,
      lng: -70.8167,
      population: 1046,
      density: 23.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0R",
      id: 1124440624
    },
    {
      label: "Coleraine",
      value: "Coleraine",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9649,
      lng: -71.3734,
      population: 1043,
      density: 249.9,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0N",
      id: 1124793029
    },
    {
      label: "Melita",
      value: "Melita",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.2681,
      lng: -100.9958,
      population: 1042,
      density: 342.7,
      timezone: "America/Winnipeg",
      ranking: 3,
      postal: "R0M",
      id: 1124113199
    },
    {
      label: "Noonan",
      value: "Noonan",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 45.9544,
      lng: -66.4868,
      population: 1042,
      density: 13.7,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E3A",
      id: 1124001109
    },
    {
      label: "Sainte-Pétronille",
      value: "Sainte-Petronille",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.85,
      lng: -71.1333,
      population: 1041,
      density: 227.2,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0A",
      id: 1124000628
    },
    {
      label: "Delisle",
      value: "Delisle",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.9254,
      lng: -107.1333,
      population: 1038,
      density: 310.2,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0L",
      id: 1124184784
    },
    {
      label: "Bristol",
      value: "Bristol",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.5333,
      lng: -76.4667,
      population: 1036,
      density: 5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0X",
      id: 1124215672
    },
    {
      label: "Mahone Bay",
      value: "Mahone Bay",
      province_id: "NS",
      province_name: "Nova Scotia",
      lat: 44.4489,
      lng: -64.3819,
      population: 1036,
      density: 332.1,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "B0J",
      id: 1124406380
    },
    {
      label: "Waldheim",
      value: "Waldheim",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.65,
      lng: -106.6167,
      population: 1035,
      density: 525.5,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0K",
      id: 1124273730
    },
    {
      label: "Saint-Sylvestre",
      value: "Saint-Sylvestre",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.3667,
      lng: -71.2333,
      population: 1035,
      density: 7.1,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0S",
      id: 1124754541
    },
    {
      label: "Taloyoak",
      value: "Taloyoak",
      province_id: "NU",
      province_name: "Nunavut",
      lat: 69.5554,
      lng: -93.4972,
      population: 1029,
      density: 27.3,
      timezone: "America/Cambridge_Bay",
      ranking: 4,
      postal: "X0B",
      id: 1124099415
    },
    {
      label: "Onoway",
      value: "Onoway",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.7011,
      lng: -114.1981,
      population: 1029,
      density: 310.3,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0E",
      id: 1124983122
    },
    {
      label: "Saint-Stanislas",
      value: "Saint-Stanislas",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.6167,
      lng: -72.4,
      population: 1029,
      density: 11.5,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0X",
      id: 1124711165
    },
    {
      label: "Malpeque",
      value: "Malpeque",
      province_id: "PE",
      province_name: "Prince Edward Island",
      lat: 46.5,
      lng: -63.6667,
      population: 1029,
      density: 8.1,
      timezone: "America/Halifax",
      ranking: 3,
      postal: "C0B",
      id: 1124663926
    },
    {
      label: "Plantagenet",
      value: "Plantagenet",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.5321,
      lng: -74.9956,
      population: 1027,
      density: 260.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "K0B",
      id: 1124496473
    },
    {
      label: "Longue-Rive",
      value: "Longue-Rive",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.55,
      lng: -69.25,
      population: 1026,
      density: 3.3,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "G0T",
      id: 1124001270
    },
    {
      label: "Argyle",
      value: "Argyle",
      province_id: "MB",
      province_name: "Manitoba",
      lat: 49.3697,
      lng: -99.1506,
      population: 1025,
      density: 1.3,
      timezone: "America/Winnipeg",
      ranking: 4,
      postal: "R0K",
      id: 1124001193
    },
    {
      label: "Davidson",
      value: "Davidson",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 51.2667,
      lng: -105.9667,
      population: 1025,
      density: 228.4,
      timezone: "America/Regina",
      ranking: 3,
      postal: "S0G",
      id: 1124057902
    },
    {
      label: "Plaster Rock",
      value: "Plaster Rock",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.8833,
      lng: -67.3833,
      population: 1023,
      density: 336.1,
      timezone: "America/Moncton",
      ranking: 3,
      postal: "E7G",
      id: 1124983558
    },
    {
      label: "Wilmot",
      value: "Wilmot",
      province_id: "NB",
      province_name: "New Brunswick",
      lat: 46.3463,
      lng: -67.7099,
      population: 1022,
      density: 5.3,
      timezone: "America/Moncton",
      ranking: 4,
      postal: "E7K",
      id: 1124000939
    },
    {
      label: "Valemount",
      value: "Valemount",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 52.8284,
      lng: -119.2659,
      population: 1021,
      density: 197.6,
      timezone: "America/Vancouver",
      ranking: 4,
      postal: "V0E",
      id: 1124899599
    },
    {
      label: "Saint-Léonard-de-Portneuf",
      value: "Saint-Leonard-de-Portneuf",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.8833,
      lng: -71.9167,
      population: 1019,
      density: 7.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0A",
      id: 1124001565
    },
    {
      label: "Alberta Beach",
      value: "Alberta Beach",
      province_id: "AB",
      province_name: "Alberta",
      lat: 53.6767,
      lng: -114.35,
      population: 1018,
      density: 507.1,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0E",
      id: 1124000690
    },
    {
      label: "Saint-Narcisse-de-Rimouski",
      value: "Saint-Narcisse-de-Rimouski",
      province_id: "QC",
      province_name: "Quebec",
      lat: 48.28,
      lng: -68.43,
      population: 1017,
      density: 6.2,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "G0K",
      id: 1124000363
    },
    {
      label: "Saint-Bonaventure",
      value: "Saint-Bonaventure",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.9667,
      lng: -72.6833,
      population: 1017,
      density: 12.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0C",
      id: 1124324069
    },
    {
      label: "Longlaketon No. 219",
      value: "Longlaketon No. 219",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 50.9386,
      lng: -104.6913,
      population: 1016,
      density: 1,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0G",
      id: 1124000772
    },
    {
      label: "Papineau-Cameron",
      value: "Papineau-Cameron",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.3,
      lng: -78.7333,
      population: 1016,
      density: 1.8,
      timezone: "America/Toronto",
      ranking: 4,
      postal: "P0H",
      id: 1124000867
    },
    {
      label: "Assiginack",
      value: "Assiginack",
      province_id: "ON",
      province_name: "Ontario",
      lat: 45.7333,
      lng: -81.8,
      population: 1013,
      density: 4.5,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P0P",
      id: 1124000091
    },
    {
      label: "Brébeuf",
      value: "Brebeuf",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.0667,
      lng: -74.6667,
      population: 1012,
      density: 27.9,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0T",
      id: 1124001084
    },
    {
      label: "Hudson Hope",
      value: "Hudson Hope",
      province_id: "BC",
      province_name: "British Columbia",
      lat: 56.0316,
      lng: -121.9057,
      population: 1012,
      density: 1.1,
      timezone: "America/Dawson_Creek",
      ranking: 3,
      postal: "V0C",
      id: 1124260692
    },
    {
      label: "Prince",
      value: "Prince",
      province_id: "ON",
      province_name: "Ontario",
      lat: 46.5333,
      lng: -84.5,
      population: 1010,
      density: 11.8,
      timezone: "America/Toronto",
      ranking: 3,
      postal: "P6A",
      id: 1124000733
    },
    {
      label: "Baie-du-Febvre",
      value: "Baie-du-Febvre",
      province_id: "QC",
      province_name: "Quebec",
      lat: 46.13,
      lng: -72.72,
      population: 1010,
      density: 10.4,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0G",
      id: 1124218916
    },
    {
      label: "Durham-Sud",
      value: "Durham-Sud",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.6667,
      lng: -72.3333,
      population: 1008,
      density: 10.8,
      timezone: "America/Montreal",
      ranking: 4,
      postal: "J0H",
      id: 1124105436
    },
    {
      label: "Melbourne",
      value: "Melbourne",
      province_id: "QC",
      province_name: "Quebec",
      lat: 45.58,
      lng: -72.17,
      population: 1004,
      density: 5.8,
      timezone: "America/Montreal",
      ranking: 3,
      postal: "J0B",
      id: 1124850489
    },
    {
      label: "Nipawin No. 487",
      value: "Nipawin No. 487",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 53.2881,
      lng: -104.0544,
      population: 1004,
      density: 1.1,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0E",
      id: 1124001339
    },
    {
      label: "Duck Lake No. 463",
      value: "Duck Lake No. 463",
      province_id: "SK",
      province_name: "Saskatchewan",
      lat: 52.9596,
      lng: -106.2089,
      population: 1004,
      density: 1,
      timezone: "America/Regina",
      ranking: 4,
      postal: "S0K S6V",
      id: 1124001661
    },
    {
      label: "Oyen",
      value: "Oyen",
      province_id: "AB",
      province_name: "Alberta",
      lat: 51.3522,
      lng: -110.4739,
      population: 1001,
      density: 189.6,
      timezone: "America/Edmonton",
      ranking: 3,
      postal: "T0J",
      id: 1124000494
    }
  ]