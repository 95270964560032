import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SectionTitle, Text } from "../../components/styledComponent";
import styles from "./style.module.scss";

const TermsAndCondition = () => {
  return (
    <div className={styles.termsConditionPage}>
      <Container className={`sectionContainer`}>
        <Row className={styles.pageTitle}>
          <Col>
            <SectionTitle $textAlign="center">Terms and Condition</SectionTitle>
          </Col>
        </Row>
        <Row className={styles.personalInfo}>
          <Col>
            <h3>Personal Information Protection Policy</h3>
            <Text>
              At Canco Petroleum Ltd. we are committed to providing our
              customers with exceptional service. One of our services is
              providing a customer loyalty program. As providing this service
              involves the collection, use and disclosure of some personal
              information about our customers, protecting their personal
              information is one of our highest priorities.
              <br />
              <br />
              While we have always respected our customers privacy and
              safeguarded their personal information, we have strengthened our
              commitment to protecting personal information as a result of BC
              and Alberta’s Personal Information Protection Act (PIPA) and
              Canada’s Personal Information Protection and Electronic Documents
              Act (PIPEDA), which set out the ground rules for how businesses
              may collect, use and disclose personal information in the
              provinces we do business including BC, Alberta, Saskatchewan and
              Ontario.
              <br />
              <br />
              We will inform our customers of why and how we collect, use and
              disclose their personal information, obtain their consent where
              required, and only handle their personal information in a manner
              that a reasonable person would consider appropriate in the
              circumstances.
              <br />
              <br />
              This Personal Information Protection Policy, in compliance with
              the privacy laws, outlines the principles and practices we will
              follow in protecting customers’ personal information. Our privacy
              commitment includes ensuring the accuracy, confidentiality, and
              security of our customers’ personal information and allowing our
              customers to request access to, and correction of, their personal
              information.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Scope of this Policy</h3>
            <Text>
              This Personal Information Protection Policy applies to Canco as
              well as to any service providers collecting, using or disclosing
              personal information on behalf of Canco.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Definitions</h3>
            <Text>
              Personal Information – means information about an identifiable
              individual including name, home address, phone number, email
              address and date of birth. Personal information does not include
              contact information (described below).
              <br />
              <br />
              Contact information – means information that would enable an
              individual to be contacted at a place of business and includes
              name, position name or title, business telephone number, business
              address, business email or business fax number. Contact
              information is not covered by this policy or PIPA / PIPEDA.
              <br />
              <br />
              Privacy Officer – means the individual designated responsibility
              for ensuring that Canco complies with this policy and PIPA /
              PIPEDA.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Policy 1 – Collecting Personal Information </h3>
            <Text>
              <b>1.1</b> Unless the purposes for collecting personal information
              are obvious and the customer voluntarily provides his or her
              personal information for those purposes, we will communicate the
              purposes for which personal information is being collected, in
              writing, before or at the time of collection.
              <br />
              <br />
              <b>1.2</b> We will only collect customers information that is
              necessary to fulfill the following purposes:
              <ul className={styles.infoList}>
                <li>To Enrol The Customer In A Loyalty Program;</li>
                <li>To Verify Identity;</li>
                <li>To Send Out Membership Information;</li>
                <li>To Provide Promotional Information;</li>
                <li>
                  To Provide Information To Companies That Provide Support
                  Services To Us (such As Our Loyal Program Vendor, Ackroo
                  Canada Inc.) And That Help Us Communicate To You. These
                  Companies May Use Information About You To Perform Their
                  Functions On Our Behalf. Customers Can Review Ackroo’s
                  Personal Information Privacy Policy Here;
                </li>
                <li>
                  Details About Your Internet Service Provider, Your Operating
                  System, Browser Type, Domain Name, Internet Protocol (IP)
                  Address, The Website That Referred You To Us, The Web Pages
                  You Request, And The Date And Time Of Those Requests;
                </li>
                <li>
                  Our Collection Of Website Usage Information May Involve The
                  Use Of Cookies.
                </li>
              </ul>
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Policy 2 – Consent</h3>
            <Text>
              <b>2.1</b> We will obtain customers’ consent to collect, use or
              disclose personal information (except where, as noted below, we
              are authorized to do so without consent).
              <br />
              <br />
              <b>2.2</b> Consent can be provided in writing, electronically,
              through an authorized representative or it can be implied where
              the purpose for collecting using or disclosing the personal
              information would be considered obvious and the customer
              voluntarily provides personal information for that purpose.
              <br />
              <br />
              <b>2.3</b> Consent may also be implied where a customer is given
              notice and a reasonable opportunity to opt-out of his or her
              personal information being used for the marketing of new services
              or products, and the customer does not opt-out.
              <br />
              <br />
              <b>2.4</b> Subject to certain exceptions (e.g., the personal
              information is necessary to provide the service or product, or the
              withdrawal of consent would frustrate the performance of a legal
              obligation) customers can withhold or withdraw their consent for
              Canco to use their personal information in certain ways. A
              customer’s decision to withhold or withdraw their consent to
              certain uses of personal information may restrict our ability to
              provide a particular service or product. If so, we will explain
              the situation to assist the customer in making the decision.
              <br />
              <br />
              <b>2.5</b> We may collect, use or disclose personal information
              without the customer’s knowledge or consent in the following
              limited circumstances: When the collection, use or disclosure of
              personal information is permitted or required by law.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Policy 3 – Using and Disclosing Personal Information</h3>
            <Text>
              <b>3.1</b> We will only use or disclose customer personal
              information where necessary to fulfill the purposes identified at
              the time of collection or for a purpose reasonably related to
              those purposes such as:
              <ul className={styles.infoList}>
                <li>
                  In The Event Of A Merger, Acquisition, Financing, Or Sale Of
                  Assets Or Any Other Situation Involving The Transfer Of Some
                  Or All Of Our Business Assets, Canco May Disclose Personal
                  Information To Those Involved In The Negotiation Or Transfer;
                </li>
                <li>
                  To Share Aggregated Or Anonymized Information That Does Not
                  Directly Identify You For Third-party Support Services
                  Purposes;
                </li>
                <li>
                  To Provide Information To Companies That Provide Support
                  Services To Us (such As Our Loyal Program Vendor, Ackroo
                  Canada Inc.) And That Help Us Communicate To You. These
                  Companies May Use Information About You To Perform Their
                  Functions On Our Behalf. Customers Can Review Ackroo’s
                  Personal Information Privacy Policy Here.
                </li>
              </ul>
              <br />
              <br />
              <b>3.2</b> Cookies – When an individual visitor accesses our
              website to signup for our membership program, we may use a browser
              feature called a ‘cookie’ to collect information such as the type
              of Internet browser and operating system the visitor uses, the
              domain name of the website from which the visitor came, date and
              duration of the visit, number of visits, average time spent on our
              website, pages viewed and number of cookies accumulated. A cookie
              is a small text file containing a unique identification number
              that identifies the visitor’s browser, but not necessarily the
              visitor, to our computers each time our website is visited. Unless
              a visitor specifically informs us, we will not know who the
              individual visitors are. In addition to the identified purposes
              described in this policy, we may use this website information and
              share it with other organizations with whom we have a commercial
              relationship to measure the use of our website, to improve the
              functionality and content of the website and to facilitate usage
              by a visitor. Visitors can reset their browsers either to notify
              them when they have received a cookie or refuse to accept cookies.
              However, if a visitor refuses to accept cookies, he or she may not
              be able to use some of the features available on our website.
              <br />
              <br />
              <b>3.3</b> We will not use or disclose customer personal
              information for any additional purpose unless we obtain consent to
              do so.
              <br />
              <br />
              <b>3.4</b> We will not sell customer lists or personal information
              to other parties.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Policy 4 – Retaining Personal Information</h3>
            <Text>
              <b>4.1</b> If we use customer personal information to make a
              decision that directly affects the customer we will retain that
              personal information for at least one year so that the customer
              has a reasonable opportunity to request access to it.
              <br />
              <br />
              <b>4.2</b> Subject to policy 4.1, we will retain customer personal
              information only as long as necessary to fulfill the identified
              purposes or a legal or business purpose.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Policy 5 – Access Personal Information</h3>
            <Text>
              <b>5.1</b> We will make reasonable efforts to ensure that customer
              personal information is accurate and complete where it may be used
              to make a decision about the customer or disclosed to another
              organization.
              <br />
              <br />
              <b>5.2</b> Customers may request correction to their personal
              information in order to ensure its accuracy and completeness. A
              request to correct personal information must be made in writing
              and provide sufficient detail to identify the personal information
              and the correction being sought. A request to correct personal
              information should be forwarded to the Privacy Officer.
              <br />
              <br />
              <b>5.3</b> If the personal information is demonstrated to be
              inaccurate or incomplete, we will correct the information as
              required and send the corrected information to any organization to
              which we disclosed the personal information in the previous year.
              If the correction is not made, we will note the customers’
              correction request in the file.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Policy 6 – Securing Personal Information</h3>
            <Text>
              <b>6.1</b> We are committed to ensuring the security of customer
              personal information in order to protect it from unauthorized
              access, collection, use, disclosure, copying, modification or
              disposal or similar risks.
              <br />
              <br />
              <b>6.2</b> The following security measures will be followed to
              ensure that customer personal information is appropriately
              protected such as:
              <ul className={styles.infoList}>
                <li>
                  Physically Securing Offices Where Personal Information Is
                  Held;
                </li>
                <li>The Use Of User IDs, Passwords, Encryption, Firewalls;</li>
                <li>
                  Restricting Employee Access To Personal Information As
                  Appropriate (i.e., Only Those That Need To Know Will Have
                  Access); And
                </li>
                <li>
                  Contractually Requiring Any Service Providers To Provide
                  Comparable Security Measures.
                </li>
              </ul>
              <br />
              <br />
              <b>6.3</b> We will use appropriate security measures when
              destroying customer’s personal information such as:
              <ul className={styles.infoList}>
                <li>Shredding Documents; And</li>
                <li>Deleting Electronically Stored Information.</li>
              </ul>
              <br />
              <br />
              <b>6.4</b> We will continually review and update our security
              policies and controls as technology changes to ensure ongoing
              personal information security.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>
              Policy 7 – Providing Customers Access to Personal Information
            </h3>
            <Text>
              <b>7.1</b> Customers have a right to access their personal
              information, subject to limited exceptions.
              <br />
              <br />
              <b>7.2</b> A request to access personal information must be made
              in writing and provide sufficient detail to identify the personal
              information being sought. A request to access personal information
              should be forwarded to the Privacy Officer.
              <br />
              <br />
              <b>7.3</b> Upon request, we will also tell customers how we use
              their personal information and to whom it has been disclosed if
              applicable.
              <br />
              <br />
              <b>7.4</b> We will make the requested information available within
              30 business days, or provide written notice of an extension where
              additional time is required to fulfill the request.
              <br />
              <br />
              <b>7.5</b> A minimal fee may be charged for providing access to
              personal information. Where a fee may apply, we will inform the
              customer of the cost and request further direction from the
              customer on whether or not we should proceed with the request.
              <br />
              <br />
              <b>7.6</b> If a request is refused in full or in part, we will
              notify the customer in writing, providing the reasons for refusal
              and the recourse available to the customer.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>
              Policy 8 – Questions and Complaints: The Role of the Privacy
              Officer or designated individual
            </h3>
            <Text>
              <b>8.1</b> The Privacy Officer is responsible for ensuring Canco’s
              compliance with this policy and the applicable privacy acts.
              <br />
              <br />
              <b>8.2</b> Customers should direct any complaints, concerns or
              questions regarding Canco’s compliance in writing to the Privacy
              Officer. If the Privacy Officer is unable to resolve the concern,
              the customer may also write to the provincial or federal
              Information and Privacy Commissioners.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Contact information for Canco’s Privacy Officer:</h3>
            <Text>
              Satvir Panesar
              <br />
              <a href="mailto:inquiry@cancopetroleum.ca">
                inquiry@cancopetroleum.ca
              </a>
            </Text>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TermsAndCondition;
