import React, { useState } from "react";
import { FuelPump, ChevronRight } from "react-bootstrap-icons";
import styles from "./style.module.scss";
import { SectionTitle, Text } from "../../styledComponent";
import { ScheduledBox } from "../../ui";

const TableAccordion = ({
  storeName,
  address,
  amenities,
  timings,
  distance,
  lat,
  lng,
}) => {
  const [active, setActive] = useState(false);

  return (
    <div
      className={`${styles.accordionBlock} ${active ? styles.active : null}`}
    >
      <button
        className={styles.accordionHeader}
        onClick={() => active ? setActive(false) : setActive(true)}
      >
        <div>
          <FuelPump size={20} />
          <Text>{storeName}</Text>
        </div>
        <div>
          <ChevronRight size={20} />
        </div>
      </button>
      <div className={styles.accordionBody}>
        <SectionTitle>Address:</SectionTitle>
        <Text>{address}</Text>
        <SectionTitle>Amenities:</SectionTitle>
        <Text $size="1.4rem">
          {amenities &&
            amenities.map((item, i) => (
              <span key={i}>
                {item.name}, {""}
              </span>
            ))}
        </Text>
        <SectionTitle>Distance:</SectionTitle>
        <Text>{distance}</Text>
        <SectionTitle>Store Hours:</SectionTitle>
        <div className={styles.hoursBlock}>
        <ScheduledBox timings={timings}/>
        </div>
        <a href={`http://maps.google.com/maps?q=${lat},${lng}&ll=${lat},${lng}&z=17`}>Directions</a>
      </div>
    </div>
  );
};

export default TableAccordion;
