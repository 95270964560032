import React from "react";
import Select from "react-select";
import { createFilter } from "react-select";
import { citiesList } from "../../../utils/canadacities";

const CitySelect = (props) => {
  const citiesData = citiesList.map((val) => {
    return {
      value: val.value,
      label: val.label,
      lat: val.lat,
      lng: val.lng,
    };
  });

  const styles = {
    control: () => ({
      background: "transparent",
    }),
    dropdownIndicator: (base) => ({ ...base, display: "none" }),
    menuList: (base) => ({
      ...base,
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "none",
        borderRadius: "0",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "var(--primaryLightColor)",
        borderRadius: "0.7rem",
      },
    }),
  };

  return (
    <div>
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder="Search City"
        isSearchable={true}
        name="city"
        options={citiesData}
        components={{ IndicatorSeparator: () => null }}
        styles={styles}
        onChange={(e) => {
          props.onChange(e);
          // const selectedCity = ci
          // props.selectedCity()
        }}
        filterOption={createFilter({ ignoreAccents: false })}
      />
    </div>
  );
};

export default CitySelect;
