import React from "react";
import styles from "./style.module.scss";
import { Text } from "../../styledComponent";

const MapInfoBox = (props) => {
  return (
    <div className={styles.mapInfoBox}>
      <div>
        <h5>Name:</h5>
        <Text>{props.data.name}</Text>
      </div>
      <div>
        <h5>Address:</h5>
        <Text>
          {props.data.address},<br />
          {props.data.city}, {props.data.province}
        </Text>
      </div>
    </div>
  );
};

export default MapInfoBox;
