import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { signupForm } from "../../../../utils/formik/initialvalue";
import {
  signupFormValidationSchema,
  signupCardValidationSchema,
} from "../../../../utils/formik/validationSchema";
import { Button, ErrorText, SectionTitle } from "../../../styledComponent";
import { SecondaryLoader, Checkbox } from "../../../ui";
import { normalizeInput } from "../../../../utils/helpers";
import styles from "./style.module.scss";
import { routes } from "../../../../utils/routes";

const SignupForm = (props) => {
  const [isCancoCard, setIsCancoCard] = useState(null);
  const formik = useFormik({
    initialValues: isCancoCard
      ? { ...signupForm, cardnumber: "", access_code: "" }
      : signupForm,
    validationSchema: isCancoCard
      ? signupCardValidationSchema
      : signupFormValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log("register values", values);
      props.formData({
        ...values,
        phone: `${values.phone.replace(/[^\d]/g, "")}`,
        dob: values.dob === "" ? null : values.dob,
      });
    },
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const formikRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("phone", phoneNumber);
    }, 1000);

    return () => clearTimeout(timer);
  }, [phoneNumber, formik]);

  return (
    <>
      {isCancoCard === null ? (
        <div className={`${styles.initialQues}`}>
          <SectionTitle>Do you have Canco Cash Card?</SectionTitle>
          <div>
            <Button
              onClick={() => {
                setIsCancoCard(true);
              }}
            >
              Yes, I do
            </Button>
            <Button
              onClick={() => {
                setIsCancoCard(false);
              }}
            >
              No, I don't
            </Button>
          </div>
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit} className={styles.signupForm}>
          {isCancoCard && (
            <div>
              <div>
                <label htmlFor="cardnumber">Card #*</label>
                <input
                  type="text"
                  name="cardnumber"
                  id="cardnumber"
                  placeholder="Card Number"
                  {...formik.getFieldProps("cardnumber")}
                  style={
                    formik.touched.cardnumber &&
                    formik.errors.cardnumber && {
                      boxShadow: "0px 0px 0px 2px red",
                    }
                  }
                />
                {formik.touched.cardnumber && formik.errors.cardnumber && (
                  <ErrorText className={styles.errorText}>
                    {formik.errors.cardnumber}
                  </ErrorText>
                )}
              </div>
              <div>
                <label htmlFor="access_code">Access Code*</label>
                <input
                  type="text"
                  name="access_code"
                  id="access_code"
                  placeholder="XXXX"
                  {...formik.getFieldProps("access_code")}
                  style={
                    formik.touched.access_code &&
                    formik.errors.access_code && {
                      boxShadow: "0px 0px 0px 2px red",
                    }
                  }
                />
                {formik.touched.access_code && formik.errors.access_code && (
                  <ErrorText className={styles.errorText}>
                    {formik.errors.access_code}
                  </ErrorText>
                )}
              </div>
            </div>
          )}
          <div>
            <div>
              <label htmlFor="first_name">First Name*</label>
              <input
                type="text"
                name="first_name"
                id="first_name"
                placeholder="First Name"
                {...formik.getFieldProps("first_name")}
                style={
                  formik.touched.first_name &&
                  formik.errors.first_name && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.first_name}
                </ErrorText>
              )}
            </div>
            <div>
              <label htmlFor="last_name">Last Name*</label>
              <input
                type="text"
                name="last_name"
                id="last_name"
                placeholder="Last Name"
                {...formik.getFieldProps("last_name")}
                style={
                  formik.touched.last_name &&
                  formik.errors.last_name && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
              />
              {formik.touched.last_name && formik.errors.last_name && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.last_name}
                </ErrorText>
              )}
            </div>
          </div>
          <div>
            <div>
              <label>Email Address*</label>
              <input
                type="email"
                name="email"
                id="emailAddress"
                placeholder="Email Address"
                {...formik.getFieldProps("email")}
                style={
                  formik.touched.email &&
                  formik.errors.email && { boxShadow: "0px 0px 0px 2px red" }
                }
              />
              {formik.touched.email && formik.errors.email && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.email}
                </ErrorText>
              )}
            </div>
          </div>
          <div>
            <div>
              <label>Phone Number*</label>
              <input
                type="text"
                name="phone"
                id="phoneNumber"
                placeholder="(xxx) xxx-xxxx"
                onBlur={formik.handleBlur("phone")}
                onChange={(e) => {
                  setPhoneNumber((prev) =>
                    normalizeInput(e.target.value, prev)
                  );
                }}
                value={phoneNumber}
                style={
                  formik.touched.phone &&
                  formik.errors.phone && { boxShadow: "0px 0px 0px 2px red" }
                }
              />
              {formik.touched.phone && formik.errors.phone && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.phone}
                </ErrorText>
              )}
            </div>
          </div>
          <div>
            <div>
              <label>DOB</label>
              <input
                type="date"
                name="dob"
                id="dob"
                // {...formik.getFieldProps("dob")}
                onChange={(e) => formik.setFieldValue("dob", e.target.value)}
                style={
                  formik.touched.dob &&
                  formik.errors.dob && { boxShadow: "0px 0px 0px 2px red" }
                }
              />
              {formik.touched.dob && formik.errors.dob && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.dob}
                </ErrorText>
              )}
            </div>
            <div>
              <label>Address</label>
              <input
                type="text"
                name="address"
                id="address"
                placeholder="Address"
                {...formik.getFieldProps("address")}
                style={
                  formik.touched.address &&
                  formik.errors.address && { boxShadow: "0px 0px 0px 2px red" }
                }
              />
              {formik.touched.address && formik.errors.address && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.address}
                </ErrorText>
              )}
            </div>
          </div>
          <div>
            <div>
              <label>Password*</label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                {...formik.getFieldProps("password")}
                style={
                  formik.touched.password &&
                  formik.errors.password && { boxShadow: "0px 0px 0px 2px red" }
                }
              />
              {formik.touched.password && formik.errors.password && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.password}
                </ErrorText>
              )}
            </div>
            <div>
              <label>Confirm Password*</label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Password"
                {...formik.getFieldProps("confirmPassword")}
                style={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
              />
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <ErrorText className={styles.errorText}>
                    {formik.errors.confirmPassword}
                  </ErrorText>
                )}
            </div>
          </div>
          <div>
            <div>
              {/* <Checkbox
                name={"t_and_c_accepted"}
                label={"I accept the Terms and Conditions*"}
                onChange={formik.handleChange("t_and_c_accepted")}
                onBlur={formik.handleBlur("t_and_c_accepted")}
              /> */}
              <label className="material-checkbox">
                <input
                  type="checkbox"
                  name={"t_and_c_accepted"}
                  id={"t_and_c_accepted"}
                  onChange={formik.handleChange("t_and_c_accepted")}
                  onBlur={formik.handleBlur("t_and_c_accepted")}
                  // defaultChecked={true}
                />
                <span className="checkmark"></span>I accept the{" "}
                <a
                  href={routes.TERMS_CONDITION}
                  className={styles.innerLabelLink}
                >
                  Terms and Conditions*
                </a>
              </label>
              {formik.touched.t_and_c_accepted &&
                formik.errors.t_and_c_accepted && (
                  <ErrorText className={styles.errorText}>
                    {formik.errors.t_and_c_accepted}
                  </ErrorText>
                )}
            </div>
            <div>
              <Checkbox
                name={"receive_promotions"}
                label={
                  "I agree to receive newsletter containing news, updates, promotions, and relevant links about Canco Petroleum and content generated by Canco Petroleum. You can withdraw consent at any time."
                }
                onChange={formik.handleChange("receive_promotions")}
                onBlur={formik.handleBlur("receive_promotions")}
                defaultChecked={true}
              />
            </div>
          </div>
          <Button
            type="submit"
            className={
              !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
            }
          >
            {props.loader ? (
              <SecondaryLoader borderColor={"var(--whiteColor)"} />
            ) : (
              <span>Register</span>
            )}
          </Button>
        </form>
      )}
    </>
  );
};

export default SignupForm;
