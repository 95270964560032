import React from "react";
import { SectionTitle } from "../../components/styledComponent";
import emailGif from "../../assets/emailConfirm.png";
import styles from "./style.module.scss";

const EmailConfirmation = () => {
  return (
    <div className={`${styles.emailConfirmPage} sectionContainer`}>
      <div className={styles.emailConfirmInner}>
        <SectionTitle>Registration Successful</SectionTitle>
        <div className={styles.successBox}>
          <img src={emailGif} alt="Success" />
          <h3>Please confirm your email address</h3>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
