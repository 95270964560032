import { createContext, useContext, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { advGetApiHandler } from "../api/commonApiCall";
import { GET_API_URLS } from "../utils/apiConstant";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    if (localStorage.getItem("tokens")) {
      const tokens = JSON.parse(localStorage.getItem("tokens"));
      const userData = jwtDecode(tokens.access);
      return {
        id: userData.user_id,
        email: userData.email,
        isLoggedIn: true,
      };
    }
    return {
      id: null,
      isLoggedIn: false,
      email: null,
    };
  });
  const [userName, setUserName] = useState("");
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetcher = async () => {
      const res = await advGetApiHandler(
        GET_API_URLS.GET_USER_DETAIL + `${user.id}/`
      );
      // console.log("user data", res.response);
      // res.response && res.response.phone.replace("+1", "");
      res.response &&
        setUserName(res.response.first_name);
      res.response &&
        setUserData({
          first_name: res.response.first_name,
          last_name: res.response.last_name,
          address: res.response.address,
          dob: res.response.dob,
          email: res.response.email,
          phone: res.response.phone?.replace("+1", ""),
        });
    };
    fetcher();
  }, [user]);

  async function latestUserDetailHandler() {
    const res = await advGetApiHandler(
      GET_API_URLS.GET_USER_DETAIL + `${user.id}/`
    );

    res.response &&
      setUserName(res.response.first_name);
    res.response &&
      setUserData({
        first_name: res.response.first_name,
        last_name: res.response.last_name,
        address: res.response.address,
        dob: res.response.dob,
        email: res.response.email,
        phone: res.response.phone?.replace("+1", ""),
      });
  }

  // console.log("user detail", userData);

  return (
    <AuthContext.Provider
      value={{ userName, user, setUser, setUserName, userData, latestUserDetailHandler }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
