import React, { useState } from "react";
import { BecomeRealtorForm } from "../../components/parts/forms";
import { SectionTitle } from "../../components/styledComponent";
import { Loader } from "../../components/ui";
import successGif from "../../assets/successGif.gif";
import styles from "./style.module.scss";

const BecomeRealtor = () => {
  const [loader, setLoader] = useState(false);
  const [successBox, setSuccessBox] = useState(false);

  return (
    <div className={`${styles.becomeRealtorPage} sectionContainer`}>
      <div className={styles.becomeRealtorInner}>
        <SectionTitle>Realtor Application Form</SectionTitle>
        {!successBox && (
          <BecomeRealtorForm
            loader={(val) => setLoader(val)}
            success={(val) => setSuccessBox(val)}
          />
        )}
        {loader && (
          <div className={styles.loaderBox}>
            <Loader />
          </div>
        )}
        {successBox && (
          <div className={styles.successBox}>
            <img src={successGif} alt="Success" />
            <h3>
              Thank you
              <br />
              Your response is submitted successfully.
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default BecomeRealtor;
