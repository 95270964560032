import React from "react";
import { routes } from "../../../utils/routes";
import { Container, Row, Col } from "react-bootstrap";
import { Facebook, Linkedin, Instagram } from "react-bootstrap-icons";
import gPlayImg from "../../../assets/gplay.png";
import appleStoreImg from "../../../assets/appleStore.png";
import styles from "./style.module.scss";
import {
  footerBusiness,
  footerInStore,
  footerSiteMap,
} from "../../../utils/constants";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col xs={12} md={6} lg={3}>
            <h2>SITE MAP</h2>
            <ul>
              {footerSiteMap.map((val, i) => (
                <li key={i}>
                  <a href={val.link}>{val.name}</a>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <h2>BUSINESS</h2>
            <ul>
              {footerBusiness.map((val, i) => (
                <li key={i}>
                  <a href={val.link}>{val.name}</a>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <h2>IN-STORE</h2>
            <ul>
              {footerInStore.map((val, i) => (
                <li key={i}>
                  {val.name === "Promotions" ||
                  val.name === "Food 2 Go" ||
                  val.name === "Chillerz" ? (
                    <HashLink elementId="promotions"  to={val.link}>{val.name}</HashLink>
                  ) : (
                    <a href={val.link}>{val.name}</a>
                  )}
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <h2>CONNECT WITH US</h2>
            <ul>
              <li className={styles.footerSocial}>
                <a href={routes.LINKEDIN}>
                  <Linkedin size={20} />
                </a>
                <a href={routes.FACEBOOK}>
                  <Facebook size={20} />
                </a>
                <a href={routes.INSTAGRAM}>
                  <Instagram size={20} />
                </a>
              </li>
              <li>
                <a href={routes.FIND_LOCATION}>Find a location</a>
              </li>
              <li>
                <a href={routes.CONTACT}>Contact us</a>
              </li>
              <li>
                <a href={routes.CAREERS}>Careers</a>
              </li>
              <li className={styles.appDownload}>
                <div>
                  <a href={routes.G_PLAY}>
                    <img src={gPlayImg} alt="Google Play" />
                  </a>
                </div>
                <div>
                  <a href={routes.APPLE_STORE}>
                    <img src={appleStoreImg} alt="Apple Store" />
                  </a>
                </div>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className={styles.bottomFooter}>
        <Row>
          <Col>
            <p>2024 &copy; Canco Petroleum Ltd.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
