import React, { useEffect, useState } from "react";
import { easeInOut, motion } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import { SectionTitle, Text } from "../../components/styledComponent";
import {
  TelephoneFill,
  // EnvelopeFill,
  // PrinterFill,
  BuildingFill,
} from "react-bootstrap-icons";
import ContactForm from "./contactForm";
import { FaqBox } from "../../components/parts";
import { faqData } from "../../utils/constants";
import { Loader } from "../../components/ui";
import successGif from "../../assets/successGif.gif";
import styles from "./style.module.scss";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const [active, setActive] = useState(false);
  const [loader, setLoader] = useState(false);
  const [successBox, setSuccessBox] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  // This array is created for animating contact details
  const contactDetailsArr = [
    <motion.div className={styles.detail} variants={item}>
      <div>
        <TelephoneFill color="var(--primaryColor)" size={35} />
      </div>
      <div>
        <h3>PHONE</h3>
        <Text>+1 (236) 420-0688</Text>
      </div>
      <a href="tel:+12364200688">link</a>
    </motion.div>,
    // <motion.div className={styles.detail} variants={item}>
    //   <div>
    //     <EnvelopeFill color="var(--primaryColor)" size={35} />
    //   </div>
    //   <div>
    //     <h3>MAIL</h3>
    //     <Text>inquiry@cancopetroleum.ca</Text>
    //   </div>
    //   <a href="mailto:inquiry@cancopetroleum.ca">link</a>
    // </motion.div>,
    // <motion.div className={styles.detail} variants={item}>
    //   <div>
    //     <PrinterFill color="var(--primaryColor)" size={35} />
    //   </div>
    //   <div>
    //     <h3>FAX</h3>
    //     <Text>(236) 420 0689</Text>
    //   </div>
    //   <a href="fax:2364200689">link</a>
    // </motion.div>,
    <motion.div className={styles.detail} variants={item}>
      <div>
        <BuildingFill color="var(--primaryColor)" size={35} />
      </div>
      <div>
        <h3>HEAD OFFICE</h3>
        <Text>#200 - 125 Highway 33 E Kelowna, BC V1X 2A1</Text>
      </div>
      <a href="/">link</a>
    </motion.div>,
  ];

  return (
    <div className={styles.contactPage}>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.3, easings: easeInOut }}
        className={styles.bannerSection}
      ></motion.div>
      <Container className={`sectionContainer ${styles.contactDetail}`}>
        <Row>
          <Col>
            <SectionTitle $textAlign="center">
              We’d like to hear from you
            </SectionTitle>
            <motion.div
              className={styles.detailBlock}
              variants={container}
              initial="hidden"
              whileInView="visible"
            >
              {contactDetailsArr.map((item) => item)}
            </motion.div>
          </Col>
        </Row>
      </Container>
      <div className={styles.contactForm}>
        <Container className={`sectionContainer`}>
          <Row>
            <Col
              as={motion.div}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.2, easings: easeInOut }}
            >
              <SectionTitle $textAlign="center">Send us a message</SectionTitle>
              <Text $textAlign="center">
                Fill in our contact form and we’ll get back to you as soon as
                possible.
              </Text>
              <div className={styles.formBlock}>
                {!successBox && (
                  <ContactForm
                    loader={(val) => setLoader(val)}
                    success={(val) => setSuccessBox(val)}
                  />
                )}
                {loader && (
                  <div className={styles.loaderBox}>
                    <Loader />
                  </div>
                )}
                {successBox && (
                  <div className={styles.successBox}>
                    <img src={successGif} alt="Success" />
                    <h3>
                      Thank you
                      <br />
                      Your response is submitted successfully.
                    </h3>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.faqSection} id="faq">
        <Container className={`sectionContainer`}>
          <Row>
            <Col className={styles.faqCol}>
              <SectionTitle>Frequently Asked Questions</SectionTitle>
              <ul>
                {faqData.map((data, i) => (
                  <FaqBox
                    title={data.title}
                    description={data.description}
                    id={data.id}
                    key={i}
                    currentQues={active === data.id ? true : false}
                    currentFaq={(val) => setActive(val)}
                  />
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Contact;
