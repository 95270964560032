import { motion, easeInOut } from "framer-motion";
import styles from "./style.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { SectionTitle, Text } from "../../components/styledComponent";
import contestByLogo from "../../assets/schoolSpiritBwLogo.png";
import { SchoolSpiritForm } from "../../components/parts/forms";
import spiritBanner from "../../assets/schoolSpiritBanner.webp";
import { SchoolSpiritSuccess } from "../../components/parts/popups";
import { useState } from "react";
import SchoolSpiritFailure from "../../components/parts/popups/schoolSpiritFailure";

const SchoolSpirit = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  return (
    <div className={styles.cancoContest}>
      {/* Banner section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.3, easings: easeInOut }}
        className={styles.bannerSection}
      >
        <img src={spiritBanner} alt="School Spirit" />
      </motion.div>
      {/* Contest Description */}
      <Container className={`${styles.contestDescription} sectionContainer`}>
        <Row>
          <Col>
            <SectionTitle>WELCOME TO THE SCHOOL SPIRIT CONTEST!</SectionTitle>
            <Text>
              We at Canco Petroleum are excited to introduce our latest
              initiative - the School Spirit Contest. This contest is a
              celebration of education, community, and the spirit of giving
              back. In partnership with Old Dutch and Coca-Cola, we’re offering
              a $3000 bursary to a nominated school. But here’s the exciting
              part - the winning school will be chosen by you! Every vote
              counts. Each person can vote for their school daily, and each vote
              increases the odds that their school will be randomly selected.
              It’s a great way to support your school and make a difference in
              your community.
              <br />
              <br />
              So, let’s show some school spirit! Nominate your school, cast your
              votes daily, and help your school win this generous bursary.
              Together, we can support education and make a positive impact.
            </Text>
            <img src={contestByLogo} alt="Canco Petroleum and One Stop" />
          </Col>
        </Row>
      </Container>
      {/* School Spirit */}
      <div className={`${styles.schoolSpirit}`}>
        <Container className="sectionContainer">
          <Row>
            <Col lg={12}>
              <Text>
                Ready to make a difference? Fill out the form below to cast your
                vote for the School Spirit Contest. Every vote brings your
                school one step closer to the $3000 bursary. Let’s show our
                school spirit together!
              </Text>
              <div className={styles.formBlock}>
                <SchoolSpiritForm success={setSuccess} error={setError} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className={`${styles.spiritTermsCondition} sectionContainer`}>
        <Row>
          <Col>
            <SectionTitle>TERMS AND CONDITIONS</SectionTitle>
            <Container fluid className={styles.conditionContainer}>
              <Row>
                <Col lg={3}>
                  <h3>ELIGIBILITY</h3>
                </Col>
                <Col lg={9}>
                  <Text>
                    The School Spirit contest ("The Contest") is open to all
                    Canco Cash Loyalty Card holders who are legal residents of
                    Canada. Participants must be of the age of majority in their
                    province or territory of residence at the time of entry.
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <h3>SPONSOR</h3>
                </Col>
                <Col lg={9}>
                  <Text>
                    The Contest is generously sponsored by Canco Petroleum & One
                    Stop Convenience. We are proud to collaborate with our
                    esteemed partners, Old Dutch and Coca-Cola, who have made
                    significant contributions to the grand prize. This
                    collective effort underscores our shared commitment to
                    supporting education and fostering school spirit across
                    Canada.
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <h3>AGREEMENT TO TERMS</h3>
                </Col>
                <Col lg={9}>
                  <Text>
                    By participating in The Contest, participants agree to abide
                    by these Terms and Conditions, which are binding in all
                    respects and are final on all matters related to The
                    Contest.
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <h3>CONTEST PERIOD</h3>
                </Col>
                <Col lg={9}>
                  <Text>
                    The Contest begins on September 1, 2024, at 12:00 AM in the
                    participant's respective time zone and ends on October 31,
                    2024, at 11:59 PM in the participant's respective time zone.
                    All nominations must be received during this period to be
                    eligible for the prize.
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <h3>HOW TO ENTER</h3>
                </Col>
                <Col lg={9}>
                  <Text>
                    Participants can nominate their school for The Contest by
                    following the instructions on the campaign webpage. Multiple
                    nominations per day are not permitted.
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <h3>PRIZE</h3>
                </Col>
                <Col lg={9}>
                  <Text>
                    The winning school will receive a $3,000 bursary from Canco
                    Petroleum & One Stop Convenience. The prize is
                    non-transferable and no substitution will be made except as
                    provided herein at the Sponsor's sole discretion.
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <h3>WINNER SELECTION</h3>
                </Col>
                <Col lg={9}>
                  <Text>
                    The winning school will be determined through a random
                    selection process. At the end of the promotion, all entries
                    will be exported, and each entry will be assigned a unique
                    number. A random number selector will then be used to
                    determine the winning entry. Please note that the more
                    nominations a school receives, the more entries it will have
                    in the final draw, thereby increasing its chances of being
                    selected. The winner will be notified using the contact
                    information provided during the nomination process.
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <h3>GENERAL CONDITIONS</h3>
                </Col>
                <Col lg={9}>
                  <Text>
                    Canco Petroleum reserves the right to cancel, suspend and/or
                    modify The Contest if any fraud, technical failures, or any
                    other factor beyond Canco Petroleum’s reasonable control
                    impairs the integrity or proper functioning of The Contest.
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <h3>LIMITATIONS OF LIABILITY</h3>
                </Col>
                <Col lg={9}>
                  <Text>
                    Canco Petroleum is not responsible for: (1) any incorrect or
                    inaccurate information, whether caused by participants; (2)
                    technical failures of any kind; (3) unauthorized human
                    intervention in any part of the entry process or The
                    Contest.
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <h3>DISPUTES</h3>
                </Col>
                <Col lg={9}>
                  <Text>
                    Any disputes relating to The Contest will be resolved under
                    the laws of Canada.
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <h3>PRIVACY</h3>
                </Col>
                <Col lg={9}>
                  <Text>
                    All personal information collected from participants as part
                    of The Contest will be used only for the purposes of
                    conducting The Contest and will be treated in accordance
                    with our privacy practices. We respect your privacy and are
                    committed to protecting it.
                  </Text>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      {success && <SchoolSpiritSuccess onClose={setSuccess} />}
      {error && <SchoolSpiritFailure onClose={setError} />}
    </div>
  );
};

export default SchoolSpirit;
