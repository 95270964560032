import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import { CancoPointerSection, FindCancoSection } from "../../components/parts";
import { Timeline, Loader, BlogCard } from "../../components/ui";
import { SectionTitle, Text, Anchor } from "../../components/styledComponent";
import { commonGetApiHandler } from "../../api/commonApiCall";
import { GET_API_URLS } from "../../utils/apiConstant";
import prLogoImg from "../../assets/prLogo.png";
import cancoSingleLogo from "../../assets/cancoSingleLogo.png";
import caresLogo from "../../assets/caresLogo.png";
import styles from "./style.module.scss";
import { routes } from "../../utils/routes";

const About = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);
  const [dataArr, setDataArr] = useState(null);
  const [dataError, setDataError] = useState(false);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  function sortBlogHandler(data) {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.created_date);
      const dateB = new Date(b.created_date);
      return dateB - dateA;
      // return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
    });
    // console.log("data", sortedData);
    setBlogData(sortedData);
  }

  useEffect(() => {
    async function blogDataFetcher() {
      setIsLoading(true);
      try {
        const repsonse = await commonGetApiHandler(GET_API_URLS.GET_BLOG);
        repsonse && sortBlogHandler(repsonse.data);
        setIsLoading(false);
      } catch (error) {
        setDataError(true);
        setIsLoading(false);
      }
    }
    blogDataFetcher();
  }, []);

  useEffect(() => {
    if (blogData) {
      const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1,
        },
      };
      const data = blogData.slice(0, 3).map((data, i) => (
        <Col xs={12} md={6} lg={4} key={i} as={motion.div} variants={item}>
          <BlogCard
            id={data.id}
            img={data.image}
            title={data.title}
            description={data.content}
          />
        </Col>
      ));
      setDataArr(data);
    }
  }, [blogData]);

  return (
    <div className={styles.aboutPage}>
      {/* Banner section */}
      <div className={styles.bannerSection}></div>

      {/* About PR Petroleum */}
      <Container className={`sectionContainer ${styles.aboutPr}`}>
        <Row>
          <Col xs={12} md={5} lg={4} className={styles.prLogoContainer}>
            <div>
              <img src={prLogoImg} alt={"PR Petroleum"} />
            </div>
            <div>
              <img src={cancoSingleLogo} alt={"PR Petroleum"} />
            </div>
          </Col>
          <Col xs={12} md={7} lg={8}>
            <Text>
              PR Petroleum Ltd. (“PR”) is a Kelowna, BC based company founded in
              2014. PR owns and operates over 160 gas station, convenience
              store, liquor, and quick service restaurant locations across
              Canada from East to West Coast. PR was founded by Parmjeet Singh
              Sehgal and Ravinder Nijjar with the vision to become a leading
              brand in Convenience, Gas and Beyond.
              <br />
              <br />
              Proudly local, we put people first and believe that honesty,
              integrity, and excellence truly mean something. Our mission is to
              provide great service to our customers at extremely competitive
              prices without compromising quality.
              <br />
              <br />
              Backed by more than 15 years of experience, Parmjeet and Ravinder,
              under the PR umbrella, has worked with all national fuel brands.
              Over the years, they have also developed a great relationship with
              all the major tobacco and convenience store vendors and suppliers.
              PR Petroleum Ltd. is a reputed company in the fuel and convenience
              store industry.
              <br />
              <br />
              PR network has a strong presence in British Columbia and has
              expanded operations into the Alberta, Saskatchewan, Manitoba, New
              Brunswick and Ontario markets. PR’s in house construction team
              oversees all elements of their renovations and “brand-new” gas and
              convenience store builds. They use the highest standard of design
              and construction at all their locations. The stations use the
              latest technology, state-of-the-art fuel storage tanks,
              dispensers, and automated fuel monitoring systems. They aim to not
              only meet but exceed industry standards to protect our
              environment.
              <br />
              <br />
              PR works closely, under contract with First Nations in Alberta and
              British Columbia. Through effective management and customer
              service, PR has achieved great success in improving store
              operations in the First Nation communities. PR is backed by an
              incredible team of more than 400 people across the country; they
              help the company to provide exceptional customer service and help
              nurture our communities.
              <br />
              <br />
              In 2017, after careful analysis of the fuel industry and fuel
              supply chain, PR decided to launch their own fuel retail brand –
              CANCO. A bold alternative to big-box retailers, Canco was born
              from a vision to become a major gas retail brand. In a short
              period of time, Canco has achieved great success. There is
              currently 100+ Canco branded sites in Canada with more planned for
              2023 and future. Canco purchases fuel directly from major fuel
              refineries and terminals in Canada to ensure premium products are
              offered at a competitive price.
            </Text>
          </Col>
        </Row>
      </Container>

      {/* Timeline */}
      <div className={styles.timelineContainer}>
        <Timeline />
      </div>

      {/* Pointer section */}
      <CancoPointerSection />

      {/* Search Canco location */}
      <FindCancoSection />

      {/* Canco care section */}
      <div className={styles.cancoCareSection}>
        <Container className={`sectionContainer ${styles.cancoCareInner}`}>
          <Row>
            <Col xs={12} md={8} lg={9}>
              <SectionTitle>
                CANCO{" "}
                <span style={{ color: "var(--primaryColor)" }}>CARES</span>
              </SectionTitle>
              <h3>Caring is Canadian</h3>
              <Text>
                Caring for one another is what truly makes us all Canadian.
                Canco Community Cares embodies Canco's purpose of caring for
                each other by connecting business and community strengths to
                make a positive impact in society.
                <br />
                <br />
                Canco Community Cares encourages our employees and customers to
                give back to the communities where we all live and work. Through
                partnerships and relationships with those who are actively
                seeking solutions, we help spark change for the generations to
                come. We believe we have the opportunity – and responsibility –
                to help build a better future for us all.
              </Text>
            </Col>
            <Col xs={12} md={4} lg={3}>
              <img src={caresLogo} alt="cares" />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Blog section  */}
      <div className={styles.blogSection}>
        <Container className={`sectionContainer ${styles.blogPageInner}`}>
          <Row className={styles.blogSectionTitle}>
            <Col>
              <SectionTitle $textAlign="center">
                Canco Community Cares
              </SectionTitle>
            </Col>
          </Row>
          {dataArr ? (
            <>
              <Row
                as={motion.div}
                variants={container}
                initial="hidden"
                animate="visible"
              >
                {dataArr.length > 0 ? (
                  dataArr.map((data) => data)
                ) : (
                  <Col>
                    <Text>No Blogs Posted</Text>
                  </Col>
                )}
              </Row>
              <Row>
                <Col className={styles.seeMoreBlog}>
                  <Anchor href={routes.BLOGS}>See more</Anchor>
                </Col>
              </Row>
            </>
          ) : isLoading ? (
            <Row
              as={motion.div}
              variants={container}
              initial="hidden"
              whileInView="visible"
            >
              <Col style={{ textAlign: "center" }}>
                <Loader />
              </Col>
            </Row>
          ) : dataError ? (
            <Row
              as={motion.div}
              variants={container}
              initial="hidden"
              whileInView="visible"
            >
              <Col>
                <Text>Oops! Problem while fetching the data</Text>
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </div>
  );
};

export default About;
