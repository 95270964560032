import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import {
  Button,
  ErrorText,
  Input,
  Label,
  // Select,
} from "../../../styledComponent";
import { useFormik } from "formik";
import { schoolSpiritInitialValues } from "../../../../utils/formik/initialvalue";
import { schoolSpiritValidationSchema } from "../../../../utils/formik/validationSchema";
import { normalizeInput } from "../../../../utils/helpers";
import { commonGetApiHandler } from "../../../../api/commonApiCall";
import {
  BASE_URL,
  GET_API_URLS,
  POST_API_URLS,
} from "../../../../utils/apiConstant";
import WindowedSelect from "react-windowed-select";
import axios from "axios";
import { SecondaryLoader } from "../../../ui";
import { InfoCircleFill } from "react-bootstrap-icons";
import { toast, ToastContainer } from "react-toastify";

const SchoolSpiritForm = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const formikRef = useRef(null);
  const formik = useFormik({
    initialValues: schoolSpiritInitialValues,
    enableReinitialize: true,
    validationSchema: schoolSpiritValidationSchema,
    onSubmit: (values) => {
      formSubmitHandler(values);
    },
  });

  async function formSubmitHandler(data) {
    setIsLoading(true);
    const updatedDataObj = {
      ...data,
      school_city: data?.school_city?.value,
      school_province: data?.school_province?.value,
    };
    // console.log("data", updatedDataObj);
    // props.success(true);

    axios
      .post(`${BASE_URL}${POST_API_URLS.SPIRIT_FORM}`, {
        ...updatedDataObj,
      })
      .then((response) => {
        setIsLoading(false);
        props.success(true);
        formik.resetForm();
        setPhoneNumber("");
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("form error", error);
        if (error?.response?.status === 400) {
          if (
            error?.response?.data?.message ===
            "You have already submitted today. Please try again tomorrow."
          ) {
            props.error(true);
          } else {
            toast.error(`${error?.response?.data?.message}`, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              style: { fontSize: "1.4rem", fontWeight: 500 },
            });
          }
        } else {
          toast.error("Form Submission Failed!", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            style: { fontSize: "1.4rem", fontWeight: 500 },
          });
        }
      });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("phone_number", phoneNumber);
    }, 1000);

    return () => clearTimeout(timer);
  }, [phoneNumber, formik]);

  useEffect(() => {
    async function cityDataFetcher() {
      try {
        const repsonse = await commonGetApiHandler(GET_API_URLS.GET_CITIES);
        repsonse &&
          setCityData(
            repsonse?.data?.map((item) => ({
              value: item?.id,
              label: item?.name,
            }))
          );
      } catch (error) {
        console.log("city error", error);
      }
    }
    async function provinceDataFetcher() {
      try {
        const repsonse = await commonGetApiHandler(GET_API_URLS.GET_PROVINCE);
        repsonse &&
          setProvinceData(
            repsonse?.data?.map((item) => ({
              value: item?.id,
              label: item?.name,
            }))
          );
      } catch (error) {
        console.log("city error", error);
      }
    }
    provinceDataFetcher();
    cityDataFetcher();
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px", // Adjust the font size for the control (input area)
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "16px", // Adjust the font size for the dropdown menu
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "16px", // Adjust the font size for each option
    }),
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={styles.schoolSpiritForm}>
        <Container fluid>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <Label htmlFor="first_name">First Name*</Label>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Input
                type="text"
                placeholder="First Name"
                {...formik.getFieldProps("first_name")}
                style={
                  formik.touched.first_name &&
                  formik.errors.first_name && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.first_name}
                </ErrorText>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <Label htmlFor="last_name">Last Name*</Label>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Input
                type="text"
                placeholder="Last Name"
                {...formik.getFieldProps("last_name")}
                style={
                  formik.touched.last_name &&
                  formik.errors.last_name && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
              />
              {formik.touched.last_name && formik.errors.last_name && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.last_name}
                </ErrorText>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <Label htmlFor="phone_number">Phone Number*</Label>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Input
                type="text"
                name="phone_number"
                id="phone"
                placeholder="(xxx) xxx-xxxx"
                onBlur={formik.handleBlur("phone_number")}
                onChange={(e) => {
                  setPhoneNumber((prev) =>
                    normalizeInput(e.target.value, prev)
                  );
                }}
                value={phoneNumber}
                style={
                  formik.touched.phone_number &&
                  formik.errors.phone_number && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
              />
              {formik.touched.phone_number && formik.errors.phone_number && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.phone_number}
                </ErrorText>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <Label htmlFor="email">Email*</Label>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Input
                type="email"
                placeholder="Email"
                {...formik.getFieldProps("email")}
                style={
                  formik.touched.email &&
                  formik.errors.email && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
              />
              {formik.touched.email && formik.errors.email && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.email}
                </ErrorText>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <span className={styles.toolTipContainer}>
                <InfoCircleFill color="#000" size={18} />
                <span className={styles.toolTipBox}>
                  Find this info on the back of your Canco Cash Loyalty Card, in
                  the "Savings Begin Here" tab of our app, or on our website
                  under Account after logging in.
                </span>
              </span>
              <Label htmlFor="card_number">Canco Cash Card Number*</Label>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Input
                type="text"
                placeholder="XXXXXXXXXXXXXXXXXX"
                {...formik.getFieldProps("card_number")}
                style={
                  formik.touched.card_number &&
                  formik.errors.card_number && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
              />
              {formik.touched.card_number && formik.errors.card_number && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.card_number}
                </ErrorText>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <Label htmlFor="school_name">
                School/College/University Name*
              </Label>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Input
                type="text"
                placeholder="Entity Name"
                {...formik.getFieldProps("school_name")}
                style={
                  formik.touched.school_name &&
                  formik.errors.school_name && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
              />
              {formik.touched.school_name && formik.errors.school_name && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.school_name}
                </ErrorText>
              )}
            </Col>
          </Row>
          <Row className={styles.locationData}>
            <Col xs={12} md={4} lg={4}>
              <Label htmlFor="first_name">
                School/College/University Location*
              </Label>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <div className={styles.cityprovinceData}>
                <div>
                  <WindowedSelect
                    options={provinceData}
                    styles={customStyles}
                    placeholder="Province"
                    name="school_province"
                    value={formik.values.school_province}
                    onBlur={formik.handleBlur("school_province")}
                    onChange={(selectedOption) => {
                      formik.setFieldValue("school_province", selectedOption);
                    }}
                  />
                  {formik.touched.school_province &&
                    formik.errors.school_province && (
                      <ErrorText className={styles.errorText}>
                        {formik.errors.school_province}
                      </ErrorText>
                    )}
                </div>
                <div>
                  <WindowedSelect
                    options={cityData}
                    styles={customStyles}
                    placeholder="City"
                    name="school_city"
                    value={formik.values.school_city}
                    onBlur={formik.handleBlur("school_city")}
                    onChange={(selectedOption) => {
                      formik.setFieldValue("school_city", selectedOption);
                    }}
                  />
                  {formik.touched.school_city && formik.errors.school_city && (
                    <ErrorText className={styles.errorText}>
                      {formik.errors.school_city}
                    </ErrorText>
                  )}
                </div>
              </div>
              {/* <span className={styles.requireNote}>*Required fields</span> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                className={
                  !(formik.isValid && formik.dirty) || isLoading
                    ? "disabledBtn"
                    : "submitBtn"
                }
              >
                {isLoading ? (
                  <SecondaryLoader borderColor={"var(--whiteColor)"} />
                ) : (
                  <span>SUBMIT</span>
                )}
              </Button>
            </Col>
          </Row>
        </Container>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
    </>
  );
};

export default SchoolSpiritForm;
