// Calculting nearest canco location distance
export const nearestLocationsHandler = (userLocation, storeLocation) => {
  const minDistance = 40;
  const lon1 = (userLocation.lng * Math.PI) / 180;
  const lon2 = (storeLocation.lng * Math.PI) / 180;
  const lat1 = (userLocation.lat * Math.PI) / 180;
  const lat2 = (storeLocation.lat * Math.PI) / 180;

  // Haversine formula
  let dlon = lon2 - lon1;
  let dlat = lat2 - lat1;
  let a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

  let c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in miles. Use 3956
  // for km
  let r = 6371;

  let d = c * r;

  return {
    position: d < minDistance ? storeLocation : null,
    distance: parseInt(d),
  };
};

// Amenities search filter handler
export const amenitiesFilterHandler = (data, filterArray) => {
  return (
    data &&
    data.filter((node) => {
      if (filterArray.id.length === 0 && filterArray.province === "") {
        return data;
      } else if (filterArray.id.length === 0) {
        const provinceFilter =
          node.city?.name.toLowerCase() === filterArray.province.toLowerCase();

        return provinceFilter;
      } else if (filterArray.province === "") {
        const filterAmenities =
          filterArray.id.length > 0
            ? filterArray.id.every((filterTag) =>
                node.amenities.map((tag) => tag.id).includes(filterTag)
              )
            : false;

        return filterAmenities;
      } else {
        const filterAmenitiesCity =
          filterArray.id.length > 0
            ? filterArray.id.every(
                (filterTag) =>
                  node.amenities.map((tag) => tag.id).includes(filterTag) &&
                  node.city.name.toLowerCase() ===
                    filterArray.province.toLowerCase()
              )
            : false;
        return filterAmenitiesCity;
      }
    })
  );
};

// Table search filter handler
export const tableSearchFilterHandler = (data, filterArray) => {
  // console.log("loc dat", data);
  return (
    data &&
    data.filter((node) => {
      return filterArray === "" || filterArray === null
        ? data
        : node.name.toLowerCase().includes(filterArray.toLowerCase()) ||
            node.address.toLowerCase().includes(filterArray.toLowerCase());
    })
  );
};

// Format input in canada phone number format
export const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};
