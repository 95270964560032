import React from "react";
import successGif from "../../../../assets/successGif.gif";
import styles from "./style.module.scss";
import { Button } from "../../../styledComponent";

const SchoolSpiritSuccess = (props) => {
  return (
    <div className={styles.confirmDeletionContainer}>
      <div className={styles.confirmDeletionInner}>
        <div className={styles.successBox}>
          <img src={successGif} alt="Success" />
          <h3>
            Thank you for your submission. Be sure to enter again in 24 hours to
            increase your school's chances of winning.
          </h3>
          <Button onClick={() => props.onClose(false)}>Close</Button>
        </div>
      </div>
    </div>
  );
};

export default SchoolSpiritSuccess;
