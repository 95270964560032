import React from "react";
import { motion } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import { Text } from "../../styledComponent";
import fuelIcon from "../../../assets/fuel.png";
import loyaltyIcon from "../../../assets/loyalty.png";
import negotiateIcon from "../../../assets/negotiate.png";
import supportIcon from "../../../assets/support.png";
import styles from "./style.module.scss";

const CancoPointerSection = () => {
  // Pointer section animation settings
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <Container className={`sectionContainer ${styles.pointerDetail}`}>
      <Row>
        <Col>
          <motion.div
            className={styles.detailBlock}
            variants={container}
            initial="hidden"
            whileInView="visible"
          >
            {[
              <motion.div className={styles.detail} variants={item}>
                <div>
                  <img src={fuelIcon} alt="Canco Fuel" />
                </div>
                <div>
                  <h3>HI-QUALITY FUEL</h3>
                  <Text>
                    Purchased from the same refineries and terminals as major
                    national brands.
                  </Text>
                </div>
              </motion.div>,
              <motion.div className={styles.detail} variants={item}>
                <div>
                  <img src={loyaltyIcon} alt="Canco Loyalty" />
                </div>
                <div>
                  <h3>CREATE LOYAL CUSTOMERS</h3>
                  <Text>
                    Canco Cash is a fantastic loyalty program that helps
                    maximize customer retention.
                  </Text>
                </div>
              </motion.div>,
              <motion.div className={styles.detail} variants={item}>
                <div>
                  <img src={negotiateIcon} alt="Canco Negotiate" />
                </div>
                <div>
                  <h3>WE NEGOTIATE WITH VENDORS</h3>
                  <Text>
                    Passing on that Buyin Power to you! Lowering your costs on
                    everyday retail products.
                  </Text>
                </div>
              </motion.div>,
              <motion.div className={styles.detail} variants={item}>
                <div>
                  <img src={supportIcon} alt="Canco Support" />
                </div>
                <div>
                  <h3>SUPPORT & TRAINING</h3>
                  <Text>
                    We accompany you on the way through an experienced field
                    level team.
                  </Text>
                </div>
              </motion.div>,
            ].map((item) => item)}
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export default CancoPointerSection;
