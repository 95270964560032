import React from 'react';
import styles from "./style.module.scss";
import { Anchor, SectionTitle, Text } from '../../styledComponent';
import { ChevronRight } from 'react-bootstrap-icons';
import { routes } from '../../../utils/routes';

const BlogCard = (props) => {
  return (
    <div className={styles.blogCard}>
        <div className={styles.blogImage} style={{backgroundImage: `url(${props.img})`}}>
        </div>
        <div className={styles.blogContent}>
            <SectionTitle>{props.title}</SectionTitle>
            <Text>{props.description.slice(0, 150).replace( /(<([^>]+)>)/ig, '')}</Text>
            <Anchor href={`${routes.BLOGS}/${props.id}`}> Read more <ChevronRight /></Anchor>
        </div>
    </div>
  )
}

export default BlogCard;