import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider";
import { routes } from "../../../utils/routes";
import { BurgerMenu } from "../../ui";
import MenuSlider from "../menuSlider";
import { Button, NavText } from "../../styledComponent";
import { navMenuItems } from "../../../utils/constants";
import { Container, Row, Col } from "react-bootstrap";
import {
  Facebook,
  Instagram,
  Linkedin,
  GeoAltFill,
  PersonFill,
} from "react-bootstrap-icons";
import Logo from "../../../assets/logo.png";
import styles from "./style.module.scss";

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { userName, user, setUser, setUserName } = useAuth();

  const logoutHandler = () => {
    localStorage.removeItem("tokens");
    setUser({
      id: null,
      isLoggedIn: false,
      email: null,
    });
    setUserName("");

    navigate(routes.LOGIN);
  };

  return (
    <header>
      <div className={styles.topHeader}>
        <Container>
          <Row>
            <Col className={styles.topHeaderInner}>
              <ul>
                <li>
                  <Link to={routes.CONTACT}>Contact Us</Link>
                </li>
                <li>
                  <a href={routes.FACEBOOK}>
                    <Facebook />
                  </a>
                </li>
                <li>
                  <a href={routes.INSTAGRAM}>
                    <Instagram />
                  </a>
                </li>
                <li>
                  <a href={routes.LINKEDIN}>
                    <Linkedin />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.bottomHeader}>
        <Container>
          <Row>
            <Col md={3} lg={3} className={styles.brandLogo}>
              <Link to={routes.HOME}>
                <img src={Logo} alt="Canco Petroleum" />
              </Link>
            </Col>
            <Col lg={6} className={styles.headerColTwo}>
              <ul className={styles.mainMenu}>
                {navMenuItems.map((item, i) => (
                  <NavText key={i}>
                    <NavLink to={item.link}>{item.name}</NavLink>
                  </NavText>
                ))}
              </ul>
            </Col>
            <Col md={9} lg={3} className={styles.headerColThree}>
              <ul>
                <li>
                  <Link to={routes.FIND_LOCATION}>
                    <GeoAltFill size={20} /> FIND A CANCO
                  </Link>
                </li>
                <li className={styles.userBlock}>
                  <Link to={routes.LOGIN}>
                    <PersonFill size={20} />{" "}
                    {userName !== "" && userName ? userName : "ACCOUNT"}
                  </Link>
                  {user.isLoggedIn && (
                    <div className={styles.userMenu}>
                      <ul>
                      <li>
                          <Button onClick={() => navigate(routes.EDIT_USER_PROFILE)}>
                            Edit Profile
                          </Button>
                        </li>
                        <li>
                          <Button onClick={() => logoutHandler()}>
                            Logout
                          </Button>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
                {/* <li>
                  <HeaderSearchBar />
                </li> */}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.mobileHeader}>
        <div></div>
        <div className={styles.mobileLogo}>
          <img src={Logo} alt="Canco Petroleum" />
        </div>
        <div className={styles.burgerMenuContainer}>
          <BurgerMenu open={(val) => setIsOpen(val)} isOpen={isOpen} />
        </div>
        <MenuSlider
          closeMenu={(val) => {
            document.body.classList.remove("modalOpen");
            setIsOpen(val);
          }}
          isOpen={isOpen}
        />
      </div>
    </header>
  );
};

export default Header;
