import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import {
  Button,
  ErrorText,
  Label,
  Select,
  Textarea,
} from "../../../styledComponent";
import styles from "./style.module.scss";
import { accountDeletionValidationSchema } from "../../../../utils/formik/validationSchema";
import { POST_API_URLS } from "../../../../utils/apiConstant";
import { postApiHandler } from "../../../../api/commonApiCall";
import { SecondaryLoader } from "../../../ui";
import { routes } from "../../../../utils/routes";
import { ConfirmAccountDeletion } from "../../popups";

const AccountDeletionForm = (props) => {
  const [loader, setLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // console.log("check user data", props.userData);

  const formik = useFormik({
    initialValues: {
      first_name: props?.userData?.first_name,
      last_name: props?.userData?.last_name,
      email: props?.userData?.email,
      phone_number: props?.userData?.phone,
      reason: "",
      comment: "",
      is_agree: false,
    },
    enableReinitialize: true,
    validationSchema: accountDeletionValidationSchema,
    onSubmit: () => {
      setShowPopup(true);
    },
  });

  const accountDeletionHandler = async (values) => {
    // console.log("confirmed values", values);
    setLoader(true);
    const res = await postApiHandler(POST_API_URLS.UNREGISTER_USER, values);
    res &&
      res.response &&
      (window.location.href = routes.SUCCESS_ACOOUNT_DEACTIVATION);

    // res && res.error && console.log("dele error", res.error);
    // // Trigger Toast when card number or ackroo id is wrong
    res && res.error && typeof res.error === "object"
      ? Object.keys(res.error.errorData).map((k) =>
          toast.error(res.error.errorData[k], {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            style: { fontSize: "1.4rem", fontWeight: 500 },
          })
        )
      : toast.error(res.error, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          style: { fontSize: "1.4rem", fontWeight: 500 },
        });

    setLoader(false);
  };

  // console.log("delete user data", props.userData);

  // useEffect(() => {
  //   setShowPopup(true);
  // }, []);

  return (
    <div className={styles.accountDeletionBlock}>
      <form onSubmit={formik.handleSubmit} className={styles.becomeRealtorForm}>
        <div>
          <div>
            <label className="material-checkbox">
              <input
                type="checkbox"
                name={"is_agree"}
                id={"is_agree"}
                onChange={formik.handleChange("is_agree")}
                onBlur={formik.handleBlur("is_agree")}
              />
              <span className="checkmark"></span>I {}
              {props.userData?.first_name} {props.userData?.last_name},
              understand the condition and wish to proceed.
            </label>
            {formik.touched.is_agree && formik.errors.is_agree && (
              <ErrorText className={styles.errorText}>
                {formik.errors.is_agree}
              </ErrorText>
            )}
          </div>
        </div>
        {formik.values?.is_agree && (
          <div>
            <div>
              <Label htmlFor="reason">Reason For Leaving</Label>
              <Select {...formik.getFieldProps("reason")}>
                <option value="">Select Reason</option>
                <option value="Privacy concerns">Privacy concerns</option>
                <option value="No longer use the service">
                  No longer use the service{" "}
                </option>
                <option value="Lack of use">Lack of use</option>
                <option value="Others">Others</option>
              </Select>
            </div>
          </div>
        )}
        {formik.values?.reason === "Others" && (
          <div>
            <div>
              <Label htmlFor="comment">Comments</Label>
              <Textarea {...formik.getFieldProps("comment")} rows={6} />
            </div>
          </div>
        )}

        <Button
          $width="100%"
          className={
            !(formik.isValid && formik.dirty) || loader
              ? "disabledBtn"
              : "submitBtn"
          }
        >
          {loader ? (
            <SecondaryLoader borderColor={"var(--whiteColor)"} />
          ) : (
            <span>Delete Account</span>
          )}
        </Button>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {showPopup && (
        <ConfirmAccountDeletion
          userData={props.userData}
          onClose={() => setShowPopup(false)}
          onConfirm={() => {
            setShowPopup(false);
            accountDeletionHandler(formik.values);
          }}
        />
      )}
    </div>
  );
};

export default AccountDeletionForm;
