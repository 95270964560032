import React from "react";
import styles from "./style.module.scss";
import { QuestionCircleFill } from "react-bootstrap-icons";
// import { Tooltip } from "react-bootstrap";

const ToolTip = (props) => {
  return (
    <span className={styles.toolTipContainer}>
      <QuestionCircleFill size={18} />
      <span className={styles.toolTipBox}>
        Please contact Canco support to update your email
      </span>
    </span>
  );
};

export default ToolTip;
