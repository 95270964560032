import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SectionTitle, Text } from "../../components/styledComponent";
import connectPointerOne from "../../assets/connectPointerOne.png";
import connectPointerTwo from "../../assets/connectPointerTwo.png";
import connectPointerThree from "../../assets/connectPointerThree.png";
import connectPointerFour from "../../assets/connectPointerFour.png";
import connectCard from "../../assets/connectCard.png";
import styles from "./style.module.scss";
import { EnvelopeFill, TelephoneFill } from "react-bootstrap-icons";

const CancoConnect = () => {
  return (
    <div className={styles.cancoConnectPage}>
      {/* Banner section */}
      <div className={styles.bannerSection}></div>

      {/* Canco connect cardlock */}
      <Container className={`sectionContainer ${styles.aboutConnectSection}`}>
        <Row className={styles.aboutConnectInfo}>
          <Col>
            <SectionTitle>A Cardlock that works for you</SectionTitle>
            <div className={styles.pointerSection}>
              <div className={styles.pointerInfo}>
                <img src={connectPointerOne} alt="Pointer" />
                <Text>No annual fee</Text>
              </div>
              <div className={styles.pointerInfo}>
                <img src={connectPointerTwo} alt="Pointer" />
                <Text>Marked Fuels</Text>
              </div>
              <div className={styles.pointerInfo}>
                <img src={connectPointerThree} alt="Pointer" />
                <Text>Access 24 hours a day</Text>
              </div>
              <div className={styles.pointerInfo}>
                <img src={connectPointerFour} alt="Pointer" />
                <Text>Tiered volume discounts</Text>
              </div>
            </div>
            <div className={styles.cardImg}>
              <img src={connectCard} alt="canco connect cardlock" />
            </div>
          </Col>
        </Row>
      </Container>

      {/* Apply today */}
      <div className={styles.applySection}>
        <Container className={`sectionContainer`}>
            <Row>
                <Col>
                    <SectionTitle>Apply Today</SectionTitle>
                    <div className={styles.contactInfo}>
                        <div>
                            <div>
                                <TelephoneFill color="var(--primaryColor)" size={40}/>
                            </div>
                            <div>
                                <SectionTitle>PHONE</SectionTitle>
                                <Text>+1 (236) 420-0688</Text>
                            </div>
                        </div>
                        <div>
                            <div>
                                <EnvelopeFill color="var(--primaryColor)" size={40}/>
                            </div>
                            <div>
                                <SectionTitle>MAIL</SectionTitle>
                                <Text>cardlock@cancopetroleum.ca</Text>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
      </div>
    </div>
  );
};

export default CancoConnect;
