import React from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../utils/routes";
import styles from "./style.module.scss";
import { Button, SectionTitle, Text } from "../../styledComponent";
import ProfilePlaceholderImg from "../../../assets/profilePlaceholder.png";
import { PencilSquare } from "react-bootstrap-icons";

const ProfileBox = (props) => {
  const navigate = useNavigate();
  return (
    <div class={styles.profileCard}>
      <div class={styles.profileBg}>
        <img src={ProfilePlaceholderImg} alt="Profile Placeholder" />
        <div>
          <Text>Welcome</Text>
          <SectionTitle>{props.user}</SectionTitle>
        </div>
        <Button onClick={() => navigate(routes.CANCO_CASH_LOYALTY)}>
          View Card Details
        </Button>
        <Button
          onClick={() => navigate(routes.EDIT_USER_PROFILE)}
          className={styles.editProfileBtn}
        >
          <PencilSquare />
        </Button>
      </div>
      <div class={styles.profileBlob}></div>
    </div>
  );
};

export default ProfileBox;
