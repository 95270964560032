import React, { useState } from "react";
import { BecomeDealerForm } from "../../components/parts/forms";
import { SectionTitle } from "../../components/styledComponent";
import { Loader } from "../../components/ui";
import successGif from "../../assets/successGif.gif";
import styles from "./style.module.scss";

const BecomeDealer = () => {
  const [loader, setLoader] = useState(false);
  const [successBox, setSuccessBox] = useState(false);
  return (
    <div className={`${styles.becomeDealerPage} sectionContainer`}>
      <div className={styles.becomeDealerInner}>
        <SectionTitle>Dealer Application Form</SectionTitle>
        {!successBox && (
          <BecomeDealerForm
            loader={(val) => setLoader(val)}
            success={(val) => setSuccessBox(val)}
          />
        )}
        {loader && (
          <div className={styles.loaderBox}>
            <Loader />
          </div>
        )}
        {successBox && (
          <div className={styles.successBox}>
            <img src={successGif} alt="Success" />
            <h3>
              Thank you
              <br />
              Your response is submitted successfully.
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default BecomeDealer;
