import React from 'react';
import styles from "./style.module.scss";

const StepPointer = (props) => {
  return (
    <div className={`${styles.stepPointerBlock} ${props.className}`}>
        <span className={props.currentStep === 1 && styles.active}>1</span>
        <span className={props.currentStep === 2 && styles.active}>2</span>
    </div>
  )
}

export default StepPointer;