import React from 'react';
import { routes } from '../../../utils/routes';
import { motion, cubicBezier } from 'framer-motion';
import { Container, Row, Col } from 'react-bootstrap';
import { SectionTitle, Text } from '../../styledComponent';
import HomeMobileImg from "../../../assets/homeMobileImg.png";
import GPlayImg from "../../../assets/gplay.png";
import appleStoreImg from "../../../assets/appleStore.png";
import styles from "./style.module.scss";

const animationTransitionValues = [0.95, 0.05, 0.795, 0.035];

const DownloadAppSection = () => {
  return (
    <Container className={`sectionContainer ${styles.sixthSection}`}>
        <Row>
          <Col className={styles.sixthSectionCol}>
            <div>
              <motion.img
                src={HomeMobileImg}
                alt="Mobile App"
                initial={{ y: "-100%" }}
                whileInView={{ y: 0 }}
                transition={{
                  delay: 0.2,
                  duration: 5,
                  easings: cubicBezier({ ...animationTransitionValues }),
                  type: "spring",
                  stiffness: 100,
                }}
              />
            </div>
            <div className={styles.sixthDescription}>
              <SectionTitle $color="var(--whiteColor)">
                Keep track of your Canco Cash and check our latest promotions
                with our app
              </SectionTitle>
              <Text $color="var(--whiteColor)">
                Go digital with our Canco app. Keep track of your Canco cash,
                see promotions and more!
                <br />
                <br />
                Available on IOS and Android. Download today!
              </Text>
              <div className={styles.downloadApp}>
                <a href={routes.G_PLAY}>
                  <img src={GPlayImg} alt="Download From Google Play" />
                </a>
                <a href={routes.APPLE_STORE}>
                  <img src={appleStoreImg} alt="Download From Apple Store" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
  )
}

export default DownloadAppSection;