import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { ToastContainer, toast } from "react-toastify";
import { SignupForm } from "../../components/parts/forms";
import { signupApiHandler } from "../../api/commonApiCall";
import styles from "./style.module.scss";

const Register = () => {
  const [formData, setFormData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errResponse, setErrResponse] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("Submitted data", formData);
    const registerHandler = async () => {
      setLoader(true);
      const registerResponse = await signupApiHandler(formData);

      registerResponse &&
        registerResponse.error &&
        setErrResponse(registerResponse.error);
      setLoader(false);

      registerResponse &&
        registerResponse.error?.errorData &&
        Object.keys(registerResponse.error.errorData).map((k) =>
          registerResponse.error.errorData[k].map((val) =>
            toast.error(val, {
              position: "bottom-left",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              style: { fontSize: "1.4rem", fontWeight: 500 },
            })
          )
        );

      // Trigger success tost when response is recieved in api response
      // registerResponse &&
      //   registerResponse.response &&
      //   toast.success("Registration Successfull", {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "colored",
      //   });

      // Navigate to Email confirmation when registration is successfull
      setTimeout(() => {
        registerResponse &&
          registerResponse.response &&
          navigate(routes.EMAIL_CONFIRMATION);
      }, 1000);

      // Trigger error tost when error is recieved in api response
      registerResponse &&
        registerResponse.error &&
        toast.error(registerResponse.error, {
          position: "bottom-left",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          style: { fontSize: "1.6rem", fontWeight: 500 },
        });
    };
    if (formData) {
      registerHandler();
    }
  }, [formData, navigate]);

  return (
    <div className={styles.registerPage}>
      <div className={styles.registerCol}>
        <SignupForm
          formData={(data) => {
            delete data.confirmPassword;
            setFormData(data);
          }}
          loader={loader}
          error={errResponse}
        />
      </div>
      <div className={styles.registerCol}></div>
      <ToastContainer
        position="bottom-left"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Register;
