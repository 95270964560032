import React from "react";
import { createPortal } from 'react-dom';
import { NavLink, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { routes } from "../../../utils/routes";
import { useAuth } from "../../../context/AuthProvider";
import styles from "./style.module.scss";
import { mobileNavMenuItems } from "../../../utils/constants";
import { Button, Text } from "../../styledComponent";
import { Person } from "react-bootstrap-icons";

const MenuSlider = (props) => {
  const { userName, user, setUser, setUserName } = useAuth();
  const navigate = useNavigate();
  const show = {
    maxWidth: window.innerWidth <= 590 ? "100%" : "50%",
  };

  const hide = {
    maxWidth: 0,
    transitionEnd: {
      maxWidth: 0,
    },
  };

  const logoutHandler = () => {
    localStorage.removeItem("tokens");
    setUser({
      id: null,
      isLoggedIn: false,
      email: null,
    });
    setUserName("");

    navigate(routes.LOGIN);
  };

  return (
    <>
      {props.isOpen && createPortal(
          <div className="popupBgLayer"></div>,
          document.getElementById('modalOverlay')
        )
        }
      <motion.div
        animate={props.isOpen ? show : hide}
        className={`${styles.menuSliderContainer} mobileMenuBlock`}
      >
        <motion.div className={styles.mobileUserBlock}>
          <Person size={20} />
          <Text>{userName ? userName : "ACCOUNT"}</Text>
        </motion.div>
        <ul>
          {mobileNavMenuItems.map((item, i) => (
            <motion.li
              key={i}
              animate={props.isOpen ? { opacity: 1 } : { opacity: 0 }}
            >
              <NavLink to={item.link} onClick={() => props.closeMenu(false)}>
                {item.name}
              </NavLink>
            </motion.li>
          ))}
        </ul>
        {user && user.isLoggedIn && (
          <div className={styles.mobileLogout}>
            <Button
              as={motion.button}
              animate={props.isOpen ? { opacity: 1 } : { opacity: 0 }}
              onClick={() => {
                props.closeMenu(false)
                logoutHandler()
              }}
            >
              LOGOUT
            </Button>
          </div>
        )}
      </motion.div>
    </>
  );
};

export default MenuSlider;
