import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import {
  Button,
  Form,
  Input,
  Label,
  ErrorText,
} from "../../../../../styledComponent";
import { vendorStepOneInitialValue } from "../../../../../../utils/formik/initialvalue";
import { vendorStepOneValidationSchema } from "../../../../../../utils/formik/validationSchema";
import { normalizeInput } from "../../../../../../utils/helpers";
import styles from "../../style.module.scss";

const StepOne = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const formikRef = useRef(null);

  const formik = useFormik({
    initialValues: props.savedFormValues || vendorStepOneInitialValue,
    validationSchema: vendorStepOneValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      props.data(values);
      props.next();
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("phone", phoneNumber);
    }, 1000);

    return () => clearTimeout(timer);
  }, [phoneNumber, formik]);

  useEffect(() => {
    if (props.savedFormValues) {
      setPhoneNumber(props.savedFormValues.phone);
    }
  }, [props.savedFormValues]);

  // console.log("saved values", formik.dirty);
  return (
    <Form onSubmit={formik.handleSubmit} className={styles.becomeVendorForm}>
      <div>
        <div>
          <Label htmlFor="first_name">First Name*</Label>
          <Input
            type="text"
            id="first_name"
            {...formik.getFieldProps("first_name")}
            style={
              formik.touched.first_name &&
              formik.errors.first_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.first_name && formik.errors.first_name && (
            <ErrorText className={styles.errorText}>
              {formik.errors.first_name}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="last_name">Last Name*</Label>
          <Input
            type="text"
            id="last_name"
            {...formik.getFieldProps("last_name")}
            style={
              formik.touched.last_name &&
              formik.errors.last_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.last_name && formik.errors.last_name && (
            <ErrorText className={styles.errorText}>
              {formik.errors.last_name}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="email">Email*</Label>
          <Input
            type="email"
            id="email"
            {...formik.getFieldProps("email")}
            style={
              formik.touched.email &&
              formik.errors.email && { boxShadow: "0px 0px 0px 2px red" }
            }
          />
          {formik.touched.email && formik.errors.email && (
            <ErrorText className={styles.errorText}>
              {formik.errors.email}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="lastName">Phone Number*</Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            placeholder="(xxx) xxx-xxxx"
            onBlur={formik.handleBlur("phone")}
            onChange={(e) => {
              setPhoneNumber((prev) => normalizeInput(e.target.value, prev));
            }}
            value={phoneNumber}
            style={
              formik.touched.phone &&
              formik.errors.phone && { boxShadow: "0px 0px 0px 2px red" }
            }
          />
          {formik.touched.phone && formik.errors.phone && (
            <ErrorText className={styles.errorText}>
              {formik.errors.phone}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="company_name">Company Name*</Label>
          <Input
            type="text"
            id="company_name"
            {...formik.getFieldProps("company_name")}
            style={
              formik.touched.company_name &&
              formik.errors.company_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="focused_product">
            What is your focus products/ category?*
          </Label>
          <Input
            type="text"
            id="focused_product"
            {...formik.getFieldProps("focused_product")}
            style={
              formik.touched.focused_product &&
              formik.errors.focused_product && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.focused_product && formik.errors.focused_product && (
            <ErrorText className={styles.errorText}>
              {formik.errors.focused_product}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="distribution_model">
            What is your distribution model?*
          </Label>
          <Input
            type="text"
            id="distribution_model"
            {...formik.getFieldProps("distribution_model")}
            style={
              formik.touched.distribution_model &&
              formik.errors.distribution_model && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.distribution_model &&
            formik.errors.distribution_model && (
              <ErrorText className={styles.errorText}>
                {formik.errors.distribution_model}
              </ErrorText>
            )}
        </div>
      </div>
      <Button
        $width="100%"
        type="submit"
        className={!formik.isValid ? "disabledBtn" : "submitBtn"}
      >
        Next
      </Button>
    </Form>
  );
};

export default StepOne;
