import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import { commonGetApiHandler } from "../../api/commonApiCall";
import { GET_API_URLS } from "../../utils/apiConstant";
import { SectionTitle, Text } from "../../components/styledComponent";
import { Loader, BlogCard } from "../../components/ui";
import styles from "./style.module.scss";

const Blogs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);
  const [dataArr, setDataArr] = useState(null);
  const [dataError, setDataError] = useState(false);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  function sortBlogHandler(data) {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.created_date);
      const dateB = new Date(b.created_date);
      return dateB - dateA;
      // return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
    });
    // console.log("data", sortedData);
    setBlogData(sortedData);
  }

  useEffect(() => {
    async function blogDataFetcher() {
      setIsLoading(true);
      try {
        const repsonse = await commonGetApiHandler(GET_API_URLS.GET_BLOG);
        repsonse && sortBlogHandler(repsonse.data);
        setIsLoading(false);
      } catch (error) {
        setDataError(true);
        setIsLoading(false);
      }
    }
    blogDataFetcher();
  }, []);

  useEffect(() => {
    if (blogData) {
      const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1,
        },
      };
      const data = blogData.map((data, i) => (
        <Col xs={12} md={6} lg={4} key={i} as={motion.div} variants={item}>
          <BlogCard
            id={data.id}
            img={data.image}
            title={data.title}
            description={data.content}
          />
        </Col>
      ));
      setDataArr(data);
    }
  }, [blogData]);
  return (
    <div className={styles.blogPage}>
      <Container className={`sectionContainer ${styles.blogPageInner}`}>
        <Row className={styles.blogPageTitle}>
          <Col>
            <SectionTitle>Canco Community Cares</SectionTitle>
          </Col>
        </Row>
        {dataArr ? (
          <Row
            as={motion.div}
            variants={container}
            initial="hidden"
            animate="visible"
          >
            {dataArr.length > 0 ? (
              dataArr.map((data) => data)
            ) : (
              <Col>
                <Text>No Blogs Posted</Text>
              </Col>
            )}
          </Row>
        ) : isLoading ? (
          <Row
            as={motion.div}
            variants={container}
            initial="hidden"
            whileInView="visible"
          >
            <Col style={{ textAlign: "center" }}>
              <Loader />
            </Col>
          </Row>
        ) : dataError ? (
          <Row
            as={motion.div}
            variants={container}
            initial="hidden"
            whileInView="visible"
          >
            <Col>
              <Text>Oops! Problem while fetching the data</Text>
            </Col>
          </Row>
        ) : null}
      </Container>
    </div>
  );
};

export default Blogs;
