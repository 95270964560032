import React from "react";
import styles from "./style.module.scss";

const ScheduledBox = (props) => {
  return (
    <div className={styles.timingData}>
      <div>
        <p>Day</p>
        <p>Open Time</p>
        <p>Close Time</p>
      </div>
      {props.timings.map((timeData, i) => {
        if (timeData.day === 1) {
          return (
            <div key={i}>
              <p>Monday</p>
              <p>{timeData.open_time}</p>
              <p>{timeData.close_time}</p>
            </div>
          );
        } else if (timeData.day === 2) {
          return (
            <div key={i}>
              <p>Tuesday</p>
              <p>{timeData.open_time}</p>
              <p>{timeData.close_time}</p>
            </div>
          );
        } else if (timeData.day === 3) {
          return (
            <div key={i}>
              <p>Wednesday</p>
              <p>{timeData.open_time}</p>
              <p>{timeData.close_time}</p>
            </div>
          );
        } else if (timeData.day === 4) {
          return (
            <div key={i}>
              <p>Thursday</p>
              <p>{timeData.open_time}</p>
              <p>{timeData.close_time}</p>
            </div>
          );
        } else if (timeData.day === 5) {
          return (
            <div key={i}>
              <p>Friday</p>
              <p>{timeData.open_time}</p>
              <p>{timeData.close_time}</p>
            </div>
          );
        } else if (timeData.day === 6) {
          return (
            <div key={i}>
              <p>Saturday</p>
              <p>{timeData.open_time}</p>
              <p>{timeData.close_time}</p>
            </div>
          );
        } else {
          return (
            <div key={i}>
              <p>Sunday</p>
              <p>{timeData.open_time}</p>
              <p>{timeData.close_time}</p>
            </div>
          );
        }
      })}
    </div>
  );
};

export default ScheduledBox;
