import React from "react";
import { routes } from "../../utils/routes";
import { motion, easeInOut } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import {
  Anchor,
  Button,
  SectionTitle,
  Text,
} from "../../components/styledComponent";
import CanadaLeaf from "../../assets/canadaLeaf.png";
import DealerImg from "../../assets/dealers.png";
import RealtorImg from "../../assets/realtor.png";
import VendorImg from "../../assets/vendor.png";
import CancoConnect from "../../assets/cancoConnect.png";
import CancoXpress from "../../assets/cancoXpress.png";
import firstGalleryImg from "../../assets/CA-gallery-01.png";
import secondGalleryImg from "../../assets/CA-gallery-02.png";
import thirdGalleryImg from "../../assets/CA-gallery-03.png";
import fourGalleryImg from "../../assets/CA-gallery-04.png";
import fiveGalleryImg from "../../assets/CA-gallery-05.png";
import sixGalleryImg from "../../assets/CA-gallery-06.png";
import sevenGalleryImg from "../../assets/CA-gallery-07.png";
import eightGalleryImg from "../../assets/CA-gallery-08.png";
import nineGalleryImg from "../../assets/CA-gallery-09.png";
import styles from "./style.module.scss";
import { CancoPointerSection } from "../../components/parts";

const Business = () => {
  return (
    <div className={styles.businessPage}>
      {/* Banner section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.3, easings: easeInOut }}
        className={styles.bannerSection}
      ></motion.div>

      {/* Lets do business section */}
      <Container className={`sectionContainer ${styles.businessSection}`}>
        <Row>
          <Col>
            <SectionTitle $textAlign="center">Let’s do business!</SectionTitle>
            <Container fluid className={styles.businessPointer}>
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <img src={DealerImg} alt="Dealer" />
                  <SectionTitle>BECOME A DEALER</SectionTitle>
                  <Text>
                    Canco creates opportunities for Canadians to drive their
                    dreams of business ownership. We’re committed to high
                    quality, and we support every Canco owner in growing their
                    overall business.
                  </Text>
                  <Anchor href="/become-dealer">DEALER APPLICATION</Anchor>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <img src={VendorImg} alt="Vendor" />
                  <SectionTitle>BECOME A VENDOR</SectionTitle>
                  <Text>
                    Interested in becoming a vendor at our convenience stores?
                    Simply download the Vendor Application, fill it out, and
                    upload it along with a few basic details below. Looking
                    forward to meeting you!
                  </Text>
                  <Anchor href="/become-vendor">VENDOR APPLICATION</Anchor>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <img src={RealtorImg} alt="Realtor" />
                  <SectionTitle>REALTOR</SectionTitle>
                  <Text>
                    Canco creates opportunities for Canadians to drive their
                    dreams of business ownership. We’re committed to high
                    quality, and we support every Canco owner in growing their
                    overall business.
                  </Text>
                  <Anchor href="/become-realtor">REALTOR APPLICATION</Anchor>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      {/* Why canco section*/}
      <div className={styles.whyCancoSection}>
        <Container className={`sectionContainer`}>
          <Row>
            <Col>
              <SectionTitle>WHY CANCO?</SectionTitle>
              <Text $textAlign="center">
                Canco is an independent chain of gas stations that launched in
                2016. A bold alternative to big-box retailers, Canco was born
                from a vision to become a leading gas and reatil brand. Backed
                by more than 15 years of combined experience; Canco has a
                dedicated team to support every step of the way! We are there to
                provide the quality and support in growing your business and
                community.
              </Text>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Pointer section */}
      <CancoPointerSection />

      {/* Gallery section */}
      <Container className={`sectionContainer ${styles.gallerySection}`}>
        <Row className={styles.firstGalleryRow}>
          <Col xs={12} md={6} lg={6}>
            <img src={firstGalleryImg} alt="Gallery" />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <img src={secondGalleryImg} alt="Gallery" />
          </Col>
          <Col xs={12} md={2} lg={2}>
            <img src={thirdGalleryImg} alt="Gallery" />
          </Col>
        </Row>
        <Row className={styles.secondGalleryRow}>
          <Col xs={12} md={4} lg={4}>
            <img src={fourGalleryImg} alt="Gallery" />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <img src={fiveGalleryImg} alt="Gallery" />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <img src={sixGalleryImg} alt="Gallery" />
          </Col>
        </Row>
        <Row className={styles.thirdGalleryRow}>
          <Col xs={12} md={2} lg={2}>
            <img src={sevenGalleryImg} alt="Gallery" />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <img src={eightGalleryImg} alt="Gallery" />
          </Col>
          <Col xs={12} md={6} lg={6}>
            <img src={nineGalleryImg} alt="Gallery" />
          </Col>
        </Row>
      </Container>

      {/* Canco connect and xpress card section  */}
      <div className={styles.connectAndExpressCard}>
        <Container className={`sectionContainer`}>
          <Row>
            <Col xs={12} md={6} lg={6} className={styles.connectExpressCol}>
              <div>
                <img src={CancoConnect} alt="Canco connect" />
                <SectionTitle>A Cardlock that works for you</SectionTitle>
                <Text>
                  Enjoy the benefits of our Canco Connect Program, no annual
                  fee, access 24 hours a day, tiered volume discounts, marked
                  fuels and more.
                </Text>
                <Anchor href={routes.CANCO_CONNECT}>Learn more</Anchor>
              </div>
            </Col>
            <Col xs={12} md={6} lg={6} className={styles.connectExpressCol}>
              <div>
                <img src={CancoXpress} alt="Canco xpress" />
                <SectionTitle>A Fleet Card that works for you</SectionTitle>
                <Text>
                  Enjoy the benefits of our Xpress Fleet Card, no annual fee,
                  dedicated customer service, tiered volume discounts, pin
                  protected, spending control and more.
                </Text>
                {/* <Anchor href={routes.CANCO_FLEET_CARD}>Learn more</Anchor> */}
                <Button>Coming Soon</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className={`sectionContainer ${styles.canadaSection}`}>
        <Row>
          <Col>
            <img src={CanadaLeaf} alt="Canada Leaf" />
            <SectionTitle>Canadian born and raised</SectionTitle>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Business;
