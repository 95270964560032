import React, { useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Form,
  Input,
  Label,
  ErrorText,
  Select,
} from "../../../../../styledComponent";
import { vendorStepTwoInitialValue } from "../../../../../../utils/formik/initialvalue";
import { vendorStepTwoValidationSchema } from "../../../../../../utils/formik/validationSchema";
import { commonPostApiHandler } from "../../../../../../api/commonApiCall";
import { POST_API_URLS } from "../../../../../../utils/apiConstant";
// import { UploadFile } from "../../../../../ui";
import styles from "../../style.module.scss";

const StepTwo = (props) => {
  const [uploadName, setUploadName] = useState("Upload File");
  const formik = useFormik({
    initialValues: vendorStepTwoInitialValue,
    validationSchema: vendorStepTwoValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      formSubmitHandler(values);
      // console.log("vendor form values", values);
    },
  });

  async function formSubmitHandler(data) {
    props.isSubmitting(true);
    const collectiveValues = {
      ...data,
      ...props.prevData,
    };

    let formData = new FormData();
    for (let k in collectiveValues) {
      formData.append(k, collectiveValues[k]);
    }
    const formRes = await commonPostApiHandler(
      POST_API_URLS.VENDOR_FORM,
      formData
    );
    formRes && formRes.response && props.isSuccess(true);
    props.isSubmitting(false);
  }

  // console.log("formik valid", formik.isValid);

  return (
    <Form onSubmit={formik.handleSubmit} className={styles.becomeVendorForm}>
      <div>
        <div>
          <Label htmlFor="edi_capabilities">
            What is your company’s EDI capabilities?*
          </Label>
          <Input
            type="text"
            id="edi_capabilities"
            {...formik.getFieldProps("edi_capabilities")}
            style={
              formik.touched.edi_capabilities &&
              formik.errors.edi_capabilities && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.edi_capabilities &&
            formik.errors.edi_capabilities && (
              <ErrorText className={styles.errorText}>
                {formik.errors.edi_capabilities}
              </ErrorText>
            )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="minimum_order_levels">
            What are your minimum order levels?*
          </Label>
          <Input
            type="text"
            id="minimum_order_levels"
            {...formik.getFieldProps("minimum_order_levels")}
            style={
              formik.touched.minimum_order_levels &&
              formik.errors.minimum_order_levels && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.minimum_order_levels &&
            formik.errors.minimum_order_levels && (
              <ErrorText className={styles.errorText}>
                {formik.errors.minimum_order_levels}
              </ErrorText>
            )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="how_to_order">
            How do stores order your products?*
          </Label>
          <Input
            type="text"
            {...formik.getFieldProps("how_to_order")}
            id="how_to_order"
            style={
              formik.touched.how_to_order &&
              formik.errors.how_to_order && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.how_to_order && formik.errors.how_to_order && (
            <ErrorText className={styles.errorText}>
              {formik.errors.how_to_order}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="product_guarantee">
            Do you offer a product guarantee?*
          </Label>
          <Input
            type="text"
            {...formik.getFieldProps("product_guarantee")}
            id="product_guarantee"
            style={
              formik.touched.product_guarantee &&
              formik.errors.product_guarantee && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.product_guarantee &&
            formik.errors.product_guarantee && (
              <ErrorText className={styles.errorText}>
                {formik.errors.product_guarantee}
              </ErrorText>
            )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="distribution_reach">Distribution reach*</Label>
          <Select
            {...formik.getFieldProps("distribution_reach")}
            id="distribution_reach"
            style={
              formik.touched.distribution_reach &&
              formik.errors.distribution_reach && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
            defaultValue={""}
          >
            <option value={""}>Select</option>
            <option value={"regional"}>Regional</option>
            <option value={"provincial"}>Provincial</option>
            <option value={"national"}>National</option>
          </Select>
          {formik.touched.distribution_reach &&
            formik.errors.distribution_reach && (
              <ErrorText className={styles.errorText}>
                {formik.errors.distribution_reach}
              </ErrorText>
            )}
        </div>
      </div>
      <div>
        <div>
          <div className="uploadLogoContainer uploadVendorFile">
            <div className="uploadContainer">
              <input
                type="file"
                name="attachment"
                // hidden
                onChange={(e) => {
                  setUploadName(e.target.files[0].name);
                  formik.setFieldValue("attachment", e.currentTarget.files[0]);
                }}
                onBlur={formik.handleBlur("attachment")}
              />

              <label htmlFor="actual-btn">UPLOAD</label>
              <span id={`fileChosen choosenFile`}>{uploadName}</span>
            </div>
          </div>
          {formik.touched.attachment && formik.errors.attachment && (
            <ErrorText className={styles.errorText}>
              {formik.errors.attachment}
            </ErrorText>
          )}
        </div>
      </div>
      <div className={styles.formBtnContainer}>
        <Button
          $width="100%"
          type="button"
          onClick={() => props.previousStep()}
        >
          Back
        </Button>
        <Button
          $width="100%"
          type="submit"
          className={
            !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
          }
        >
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default StepTwo;
