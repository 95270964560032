import React, { useState } from "react";
import { routes } from "../../utils/routes";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import jwtDecode from "jwt-decode";
import { LoginForm, ProfileBox } from "../../components/parts";
import { loginApiHandler } from "../../api/commonApiCall";
import styles from "./style.module.scss";

const Login = () => {
  const [msg, setMsg] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(null);
  const navigate = useNavigate();
  const { setUser, user, userName } = useAuth();

  const loginSubmitHandler = async (data) => {
    setIsSubmitting(true);
    const loginResult = await loginApiHandler(data);
    if (loginResult && loginResult.isLoggedIn) {
      const tokens = JSON.parse(localStorage.getItem("tokens"));
      const userData = jwtDecode(tokens.access);
      setUser((prev) => ({
        ...prev,
        id: userData.user_id,
        email: userData.email,
        isLoggedIn: true,
      }));
      navigate(routes.CANCO_CASH_LOYALTY);
    }
    if (loginResult && !loginResult.isLoggedIn) {
      // console.log(loginResult.msg);
      if(loginResult.msg.non_field_errors) {
      setMsg(loginResult.msg.non_field_errors[0]);
      } else {
        setMsg(loginResult.msg);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <div className={styles.loginPage}>
      <div className={user.isLoggedIn && styles.activeProfile}>
      {/* <ProfileBox /> */}
        {user.isLoggedIn ? (
          <>
            <ProfileBox user={userName}/>
          </>
        ) : (
          <LoginForm
            onSubmit={(val) => loginSubmitHandler(val)}
            msg={msg}
            isSubmitting={isSubmitting}
          />
        )}
      </div>
      <div></div>
    </div>
  );
};

export default Login;
