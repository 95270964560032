import React from "react";
import crossIcon from "../../../../assets/crossIcon.png";
import styles from "./style.module.scss";
import { Button } from "../../../styledComponent";

const SchoolSpiritFailure = (props) => {
  return (
    <div className={styles.confirmDeletionContainer}>
      <div className={styles.confirmDeletionInner}>
        <div className={styles.successBox}>
          <img src={crossIcon} alt="Success" />
          <h3>
            Your form has already been submitted today. Come back tomorrow and
            submit another entry to improve your school’s chances of winning!
          </h3>
          <Button onClick={() => props.onClose(false)}>Close</Button>
        </div>
      </div>
    </div>
  );
};

export default SchoolSpiritFailure;
