import React from "react";
import { motion, easeInOut } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import { SectionTitle, Text } from "../../components/styledComponent";
import StopTwo from "../../assets/stop_2.png";
import StopFive from "../../assets/stop_5.png";
import StopEight from "../../assets/stop_8.png";
import StopTen from "../../assets/stop_10.png";
import GrandPrize from "../../assets/grandPrize.png";
import RoadTripCar from "../../assets/roadTripCar.png";
import GPlayImg from "../../assets/gplay.png";
import appleStoreImg from "../../assets/appleStore.png";
import styles from "./style.module.scss";
import { routes } from "../../utils/routes";

const RoadTripRules = () => {
  return (
    <div className={styles.roadTripPage}>
      {/* Banner section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.3, easings: easeInOut }}
        className={styles.bannerSection}
      ></motion.div>

      {/* Road Trip Rules */}
      <Container className={`sectionContainer ${styles.rulesContent}`}>
        <Row>
          <Col>
            <SectionTitle $textAlign="center">
              <img src={RoadTripCar} alt="Road Trip" />
              <span>Summer Road Trip Game Rules & Regulations</span>
            </SectionTitle>
          </Col>
        </Row>
        <Row>
          <Container>
            <Row>
              <Col lg={12}>
                <ol>
                  <li>
                    <b>Eligibility</b>
                    <br />
                    The game is open to anyone who has a valid and registered
                    Canco Cash Loyalty Account.
                  </li>
                  <li>
                    <b>Duration</b> <br />
                    The Road Trip game starts on June 1, 2024, and ends ot
                    August 31, 2024, in respective time zones.
                  </li>
                  <li>
                    <b>Participation</b> <br />
                    Participants can enter the game by tapping on the bar at the
                    top of our App.
                  </li>
                  <li>
                    <b>Prize Redemption</b> <br />
                    Prizes at each stop are redeemed in the store. Participants
                    must show their mobile phone to the cashier and ask for the
                    store code to redeem the prizes. Once you exit the code
                    screen, you won't be able to reopen the code.
                  </li>
                  <li>
                    <b>Travel Pack & Grand Prize</b> <br />
                    The Winners will be notified using the information provided
                    at the time of entry. The Winners must be a current Canco
                    Cash Loyalty card holder. In the event any Winner does not
                    respond to such notification within three (3) business days,
                    declines a prize for any reason, or does not meet the
                    requirements set forth, as determined by Canco, then the
                    potential Winner will be disqualified, and the prize may be
                    cancelled. Once a potential winner is confirmed as a Winner,
                    prize distribution will be promptly coordinated. Except as
                    otherwise indicated by Canco, a Winner must personally take
                    delivery of the prize as instructed within thirty (30) days
                    of being notified that such prize is available. If a Winner
                    fails to take delivery of the prize as instructed, the prize
                    will be deemed forfeited. The Winners authorize Canco to use
                    their name, photograph, place of residence, and image for
                    publicity purposes without any compensation beyond the prize
                    awarded. They agree to have this information posted on
                    Canco’s websites and social media platforms. All content
                    submitted via tagging or messaging on social media may be
                    used by Canco Petroleum. Grand Prize of $4,000 travel
                    voucher + $1,000 prepaid Visa. Contest runs from June 1st,
                    2024, to August 31st, 2024. One winner will be randomly
                    selected. The winner will be drawn and receive $4,000 in
                    travel vouchers plus a $1,000 prepaid Visa. The winner will
                    be announced on Friday, September 6th.
                  </li>
                  <li>
                    <b>Disqualification</b> <br />
                    Participants may be disqualified for hacking or tampering
                    with the App in any way.
                  </li>
                  <li>
                    <b>Privacy</b>
                    <br /> Personal information collected from the participants
                    will be handled as per our terms and conditions and privacy
                    policy on the Canco Petroleum website.
                  </li>
                  <li>
                    <b>Sponsor Details</b> <br />
                    The game is sponsored by Canco Gas & One Stop Convenience.
                  </li>
                  <li>
                    <b>Changes to Terms</b> <br />
                    Canco reserves the right to modify these Terms and
                    Conditions at any time. We recommend that you periodically
                    check these Terms and Conditions for changes.
                  </li>
                </ol>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>

      {/* Road Trip Rules */}
      <Container className={`sectionContainer ${styles.howDoesItWork}`}>
        <Row>
          <Col>
            <SectionTitle $textAlign="center">How does it work?</SectionTitle>
            <Text $textAlign="center">
              Participants are limited to one stop per day, per site, in the
              Road Trip Game on the App. Scan your registered Canco Cash Card
              and watch the automobile move around the track. At Stop 2, 5, 8
              and 10 you will be able to claim a prize.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className={styles.stopList}>
              <img src={StopTwo} alt="stop 2" />
              <img src={StopFive} alt="stop 5" />
              <img src={StopEight} alt="stop 8" />
              <img src={StopTen} alt="stop 10" />
            </div>
            <div className={styles.grandPrize}>
              <img src={GrandPrize} alt="Grand Prize" />
            </div>
          </Col>
        </Row>
      </Container>
      <div className={styles.downloadApp}>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle>Download our latest Canco Cash App</SectionTitle>
              <div className={styles.downloadAppLink}>
                <a href={routes.G_PLAY}>
                  <img src={GPlayImg} alt="Download From Google Play" />
                </a>
                <a href={routes.APPLE_STORE}>
                  <img src={appleStoreImg} alt="Download From Apple Store" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default RoadTripRules;
