import React, { useEffect, useState } from "react";
import TableAccordion from "../tableAccordion";
import { Button, SectionTitle, Text } from "../../styledComponent";
import TablePagination from "../tablePagination";
import { Loader, ScheduledBox } from "../../ui";
import { Clock } from "react-bootstrap-icons";
import styles from "./style.module.scss";

const LocationTable = (props) => {
  const [storeFiltered, setStoreFiltered] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //Table search filter handler
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setStoreFiltered(props.locationData);
      setIsLoading(false);
    }, 800);

    return () => {
      // console.log("CLEARED!");
      clearTimeout(timer);
    };
  }, [props.locationData]);

  // console.log('location table data', props.locationData);

  return (
    <div className={styles.tableBlock}>
      <div className={styles.tableFilter}>
        <div className={styles.displayData}>
          <p>Show</p>
          <input
            type="number"
            step={1}
            defaultValue={props.pageSize}
            onChange={(e) => props.onChangeLimit(parseInt(e.target.value))}
            min={1}
          />
          <p>entries</p>
        </div>
        <div className={styles.searchTable}>
          <p>Search</p>
          <input
            type="search"
            onChange={(e) => props.onSearchTable(e.target.value)}
            placeholder="Enter Store Name/Address"
          />
        </div>
      </div>
      <div className={styles.locTable}>
        <table>
          <tbody>
            {storeFiltered && !isLoading ? (
              storeFiltered.length === 0 ? (
                <tr>
                  <td>
                    <Text style={{ textAlign: "center", margin: "0" }}>
                      No Location Found.
                    </Text>
                  </td>
                </tr>
              ) : (
                storeFiltered
                  // .sort((a, b) => a.distance - b.distance)
                  .map((data, i) => (
                    <tr key={i}>
                      <td>
                        <SectionTitle $size="1.4rem">
                          {data.store_name}
                        </SectionTitle>
                        <Text $size="1.4rem">{data.address}</Text>
                      </td>
                      <td>
                        <SectionTitle $size="1.4rem">Amenities</SectionTitle>
                        <Text $size="1.4rem">
                          {data.amenities &&
                            data.amenities.map((item, i) => (
                              <span key={i}>
                                {item.name}, {""}
                              </span>
                            ))}
                        </Text>
                      </td>
                      <td>
                        <div
                          style={{ position: "relative" }}
                          className={styles.storeHours}
                        >
                          <Button $size="1.4rem" $weight="500">
                            <Clock />
                            <span>
                              {data.is24by7
                                ? "Open 24/7"
                                : "Click for store hours"}
                            </span>
                          </Button>
                          {!data.is24by7 && (
                            <ScheduledBox timings={data.timings} />
                          )}
                        </div>
                      </td>
                      <td>
                        <Text $size="1.4rem">{data.distance} km</Text>
                      </td>
                      <td>
                        <Text $size="1.4rem">
                          <a
                            href={`http://maps.google.com/maps?q=${data.lat},${data.lng}&ll=${data.lat},${data.lng}&z=17`}
                          >
                            Directions
                          </a>
                        </Text>
                      </td>
                    </tr>
                  ))
              )
            ) : props.dataError ? (
              <tr>
                <td>
                  <p style={{ textAlign: "center", fontSize: "1.8rem" }}>
                    There was an error while fetching the data
                  </p>
                </td>
              </tr>
            ) : (
              <tr>
                <td style={{ textAlign: "center" }}>
                  <Loader />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className={styles.tableAccord}>
          {storeFiltered && !isLoading ? (
            storeFiltered.length === 0 ? (
              <tr>
                <td>
                  <Text style={{ textAlign: "center", margin: "0" }}>
                    No Location Found.
                  </Text>
                </td>
              </tr>
            ) : (
              storeFiltered.map((data, i) => (
                <TableAccordion
                  key={i}
                  storeName={data.store_name}
                  address={data.address}
                  amenities={data.amenities}
                  timings={data.timings}
                  distance={data.distance}
                  lat={data.lat}
                  lng={data.lng}
                />
              ))
            )
          ) : props.dataError ? (
            <Text style={{ textAlign: "center", fontSize: "1.8rem" }}>
              There was an error while fetching the data
            </Text>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Loader />
            </div>
          )}
        </div>
      </div>
      <TablePagination
        currentPage={props.currentPage}
        totalCount={props.totalCount}
        pageSize={props.pageSize}
        onPageChange={props.onPageChange}
        // onPrevPageChange={props.onPrevPageChange}
        // onNextPageChange={props.onNextPageChange}
        totalNumberOfData={
          props.locationData ? props.locationData.length : null
        }
      />
    </div>
  );
};

export default LocationTable;
