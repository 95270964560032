import { useState } from "react";

const UploadFile = (props) => {
  const [uploadName, setUploadName] = useState("Upload Pamphlet or Document*");
  return (
    <div className="uploadLogoContainer">
      <div className="uploadContainer">
        <input
          type="file"
          id="actual-btn"
          name={props.uploadName}
          hidden
          onChange={(e) => {
            setUploadName(e.target.files[0].name);
            props.onChange(e);
          }}
          onBlur={props.onBlur}
        />

        <label htmlFor="actual-btn">UPLOAD</label>
        <span id={`fileChosen choosenFile`}>{uploadName}</span>
      </div>
    </div>
  );
};

export default UploadFile;
