import apiInterceptor from "./apiCall";
import axios from "axios";
import { POST_API_URLS } from "../utils/apiConstant";
import { BASE_URL } from "../utils/apiConstant";

export const advGetApiHandler = async (url) => {
  try {
    const apiResponse = await apiInterceptor.get(url);
    return { response: apiResponse.data, error: false };
  } catch (error) {
    return { response: false, error: true };
  }
};

export const postApiHandler = async (url, data) => {
  try {
    const apiResponse = await apiInterceptor.post(url, data);
    // console.log("register api response", apiResponse);
    return { response: apiResponse.data, error: false };
  } catch (error) {
    // console.log("error", error);
    let errorMsg;
    if (!error?.response) {
      errorMsg = "No Server Response";
    } else if (error.response?.status === 400) {
      // console.log("400", error.response.data);
      errorMsg = { status: 400, errorData: error.response.data };
    } else {
      errorMsg = "Oops! An Error occured";
    }
    return { response: false, error: errorMsg };
  }
};

// Login API Handler
export const loginApiHandler = async (data) => {
  try {
    const apiResponse = await axios.post(
      `${BASE_URL + POST_API_URLS.ACKROO_LOGIN}`,
      data
    );
    localStorage.setItem("tokens", JSON.stringify(apiResponse.data));
    return { msg: "", isLoggedIn: true };
  } catch (error) {
    // console.log("login error", error);
    let errorMsg;
    if (!error?.response) {
      errorMsg = "No Server Response";
    } else if (error.response?.status === 400) {
      errorMsg = error.response?.data;
    } else if (error.response?.status === 401) {
      errorMsg = "Unauthorized Access";
    } else {
      errorMsg = "Login Failed!";
    }
    return { msg: errorMsg, isLoggedIn: false };
  }
};

// Sign Up API Handler
export const signupApiHandler = async (data) => {
  try {
    const apiResponse = await axios.post(
      `${BASE_URL + POST_API_URLS.REGISTER_USER}`,
      data
    );
    // console.log("signup api response", apiResponse?.data);
    return { response: apiResponse.data, error: false };
  } catch (error) {
    let errorMsg;
    if (!error?.response) {
      errorMsg = "No Server Response";
    } else if (error.response?.status === 400) {
      // console.log("400", error.response.data);
      errorMsg = { status: 400, errorData: error.response.data };
    } else {
      errorMsg = "Signup Failed!";
    }
    return { response: false, error: errorMsg };
  }
};

// Reset Password API Handler
export const resetPasswordApiHandler = async (data) => {
  try {
    const apiResponse = await axios.post(
      `${BASE_URL + POST_API_URLS.RESET_PASSWORD}`,
      data
    );
    // console.log("reset api response", apiResponse?.data);
    return { response: apiResponse.data, error: false };
  } catch (error) {
    let errorMsg;
    if (error.response?.status === 400) {
      errorMsg = { status: 400, errorData: error.response.data };
    }
    return { response: false, error: errorMsg };
  }
};

// New Password API Handler
export const newPasswordApiHandler = async (data) => {
  try {
    const apiResponse = await axios.post(
      `${BASE_URL + POST_API_URLS.NEW_PASSWORD}`,
      data
    );
    return { response: apiResponse.data, error: false };
  } catch (error) {
    let errorMsg;
    if (error.response?.status === 400) {
      errorMsg = { status: 400, errorData: error.response.data };
    }
    return { response: false, error: errorMsg };
  }
};

export const commonGetApiHandler = async (url) => {
  try {
    const apiResponse = await axios.get(`${BASE_URL + url}`);
    return apiResponse;
  } catch (error) {
    return error;
  }
};

export const commonPostApiHandler = async (url, data) => {
  try {
    const apiResponse = await axios.post(`${BASE_URL + url}`, data);
    // console.log("reset api response", apiResponse?.data);
    return { response: apiResponse.data, error: false };
  } catch (error) {
    return { response: false, error: true };
  }
};
