/* global google */

import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import { GOOGLE_INFO } from "../../../utils/constants";
import CancoMarker from "../../../assets/cancoMarkerSmall.png";
import userLocationMarker from "../../../assets/userLocation.png";
import { MapInfoBox } from "../../ui";
// import { Text } from "../../styledComponent";

const Map = ({ coordinates, userLocation, nearCoordinates }) => {
  const [markedLocations, setMarkedLocations] = useState(null);
  const [mapRef, setMapRef] = useState();
  const [infoWindowData, setInfoWindowData] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const { isLoaded } = useJsApiLoader(GOOGLE_INFO);

  const onLoad = (map) => {
    setMapRef(map);
    const markers = [userLocation];
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < nearCoordinates.length; i++) {
      if (nearCoordinates[i]) {
        markers.push(nearCoordinates[i]);
      }
    }
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  useEffect(() => {
    const locArray = coordinates.reduce(function (result, data) {
      if (data) {
        result.push(data);
      }
      return result;
    }, []);
    setMarkedLocations(locArray);
  }, [coordinates]);

  const handleMarkerClick = (id, lat, lng, address, name, city, province) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address, name, city, province });
    setIsOpen(true);
  };

  // console.log("info window data", infoWindowData);

  return (
    <div style={{ width: "100%", height: "60rem" }}>
      {isLoaded && (
        <GoogleMap mapContainerClassName="map-container" onLoad={onLoad}>
          {markedLocations &&
            markedLocations.map((data, i) => (
              <Marker
                position={{ lat: data.lat, lng: data.lng }}
                icon={CancoMarker}
                key={i}
                onClick={() => {
                  handleMarkerClick(
                    data.id,
                    data.lat,
                    data.lng,
                    data.address,
                    data.name,
                    data.city.name,
                    data.province.name
                  );
                }}
              >
                {isOpen && infoWindowData?.id === data.id && (
                  <InfoWindow
                    onCloseClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <MapInfoBox data={infoWindowData} />
                  </InfoWindow>
                )}
              </Marker>
            ))}
          {userLocation && (
            <Marker position={userLocation} icon={userLocationMarker} />
          )}
        </GoogleMap>
      )}
    </div>
  );
};

export default Map;
