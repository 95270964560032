import React, { useState } from "react";
import { StepOne, StepTwo } from "./steps";
import { StepPointer } from "../../../ui";

const BecomeVendorForm = ({ loader, success }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [stepOneFormData, setStepOneFormData] = useState(null);
  const [formData, setFormData] = useState(null);

  const nextHandler = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const backHandler = () => {
    setCurrentStep((prev) => prev - 1);
  };

  return (
    <>
      <StepPointer currentStep={currentStep} />
      {(currentStep === 1 && (
        <StepOne
          data={(data) => {
            setFormData(data);
            setStepOneFormData(data);
          }}
          next={nextHandler}
          savedFormValues={stepOneFormData}
        />
      )) ||
        (currentStep === 2 && (
          <StepTwo
            prevData={formData}
            isSubmitting={(val) => loader(val)}
            isSuccess={(val) => success(val)}
            previousStep={backHandler}
          />
        ))}
      {/* <StepOne />
      <StepTwo /> */}
    </>
  );
};

export default BecomeVendorForm;
