import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { commonGetApiHandler } from "../../api/commonApiCall";
import { GET_API_URLS } from "../../utils/apiConstant";
import styles from "./style.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { SectionTitle, Text } from "../../components/styledComponent";
import {
  nearestLocationsHandler,
  amenitiesFilterHandler,
  tableSearchFilterHandler,
} from "../../utils/helpers";
import { LocationTable, Map, CitySearch } from "../../components/parts";
import { Checkbox, Loader } from "../../components/ui";
import CancoMarker from "../../assets/cancoMarker.png";
import { amenitiesFilterData } from "../../utils/constants";

const LIMIT = 10;

const FindLocation = () => {
  const [allLocations, setAllLocations] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userLocation, setUserLocation] = useState(false);
  const [dataError, setDataError] = useState(null);
  const [limit, setLimit] = useState(LIMIT);
  const [currentPage, setCurrentPage] = useState(1);
  const [amenitiesFilter, setAmenitiesFilter] = useState({
    id: [],
    province: "",
  });
  const [cityFilter, setCityFilter] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [filteredLoc, setFilteredLoc] = useState(null);
  const mapRef = useRef();
  const location = useLocation();

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * limit;
    const lastPageIndex = firstPageIndex + limit;
    const sortLocData = filteredLoc?.map((data) => {
      const locdistance = nearestLocationsHandler(
        selectedCity
          ? {
              lat: selectedCity.lat,
              lng: selectedCity.lng,
            }
          : userLocation,
        {
          lat: parseFloat(data.latitude),
          lng: parseFloat(data.longitude),
        }
      );
      return {
        store_name: data.name,
        address: data.address,
        amenities: data.amenities,
        distance: locdistance.distance,
        lat: data.latitude,
        lng: data.longitude,
        timings: data.timings,
        is24by7: data.is24by7,
      };
    });
    // console.log("sorted data", { sortLocData, firstPageIndex, lastPageIndex });
    return sortLocData
      ?.sort((a, b) => a.distance - b.distance)
      .slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredLoc, limit, selectedCity, userLocation]);

  // Getting user location
  useEffect(() => {
    if (location.state !== null) {
      setAmenitiesFilter((prev) => ({
        id: prev.id,
        province: location.state.value !== null ? location.state.value : null,
      }));
      setSelectedCity(location.state.cityData);
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        function (error) {
          console.log("userloc error", error);
        }
      );
    }
  }, [location]);

  //Fetching all location data
  useEffect(() => {
    async function allLocationFetcher() {
      try {
        const allLocData = await commonGetApiHandler(GET_API_URLS.GET_LOCATION);
        allLocData && setAllLocations(allLocData.data);
      } catch (error) {
        setDataError(error);
      }
    }

    allLocationFetcher();
  }, []);

  // Filter Handler
  const filterHandler = (event) => {
    if (event.target.checked) {
      setAmenitiesFilter((prev) => ({
        id: [...prev.id, parseInt(event.target.value)],
        province: prev.province,
      }));
    } else {
      setAmenitiesFilter((prev) => ({
        id: prev.id.filter(
          (filterTag) => filterTag !== parseInt(event.target.value)
        ),
        province: prev.province,
      }));
    }
  };

  //Filter data when any amenities is checked
  useEffect(() => {
    setIsLoading(true);
    const filterTimer = setTimeout(() => {
      const filteredData = amenitiesFilterHandler(
        allLocations,
        amenitiesFilter
      );
      // console.log("filter location", filteredData);
      setFilteredLoc(filteredData);
      setIsLoading(false);
      mapRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 700);

    return () => clearTimeout(filterTimer);
  }, [amenitiesFilter, allLocations]);

  //Filter data user search city
  function searchFilterHandler(filter) {
    setAmenitiesFilter((prev) => ({
      id: prev.id,
      province: filter ? filter : cityFilter,
    }));
  }

  // console.log("filter location", filteredLoc);
  // console.log("prev --", prev);
  // console.log("next --", next);
  // console.log("userLoc --", userLocation);
  // console.log("loading --", count);

  return (
    <div className={styles.findLocationPage}>
      <Container className={`sectionContainer`}>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <div className={styles.sectionImg}>
              <img src={CancoMarker} alt="Canco Map Marker" />
            </div>
            <SectionTitle $textAlign={"center"}>
              Find your nearest Canco
            </SectionTitle>
            <div className={styles.secDescriptionBlock}>
              <Text $textAlign="center">
                Find the nearest Canco service station. Use the search filters
                to find exactly what you need. From fuelling up, having a snack
                or washing your car. At Canco, we are committed to providing an
                excellent five star experience for all of our customers
              </Text>
              <CitySearch
                onChange={(val) => {
                  setCityFilter(val.value);
                  setSelectedCity(val);
                }}
                onSearch={() => searchFilterHandler()}
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={12}
            lg={12}
            style={{ marginTop: "6rem" }}
            className={styles.mapFilterSection}
          >
            <div className={styles.filterLocation}>
              {amenitiesFilterData &&
                amenitiesFilterData.map((data, i) => (
                  <div className={styles.filterBlock} key={i}>
                    <Checkbox
                      label={data.name}
                      name={data.name}
                      value={data.id}
                      onChange={(e) => filterHandler(e)}
                    />
                  </div>
                ))}
            </div>
            <div ref={mapRef}>
              {userLocation && filteredLoc && !isLoading ? (
                <Map
                  nearCoordinates={filteredLoc.map((data) => {
                    const locCoord = nearestLocationsHandler(
                      selectedCity
                        ? {
                            lat: selectedCity.lat,
                            lng: selectedCity.lng,
                          }
                        : userLocation,
                      {
                        lat: parseFloat(data.latitude),
                        lng: parseFloat(data.longitude),
                      }
                    );
                    return locCoord.position;
                  })}
                  coordinates={filteredLoc.map((data) => {
                    return {
                      lat: parseFloat(data.latitude),
                      lng: parseFloat(data.longitude),
                      name: data.name,
                      id: data.id,
                      address: data.address,
                      city: data.city,
                      province: data.province,
                    };
                  })}
                  userLocation={
                    selectedCity
                      ? {
                          lat: selectedCity.lat,
                          lng: selectedCity.lng,
                        }
                      : userLocation
                  }
                  // userLocation={userLocation}
                />
              ) : dataError ? (
                <div className={styles.mapError}>
                  <Text>There was an error while fetching the data</Text>
                </div>
              ) : (
                <div className={styles.mapLoaderContainer}>
                  <Loader />
                </div>
              )}
            </div>
            <div className={styles.locationTable}>
              <LocationTable
                locationData={
                  userLocation && filteredLoc && !isLoading
                    ? currentTableData
                    : null
                }
                dataError={dataError}
                totalCount={filteredLoc ? filteredLoc.length : 0}
                onPageChange={(page) => setCurrentPage(page)}
                onChangeLimit={(val) => setLimit(val)}
                pageSize={limit}
                currentPage={currentPage}
                onSearchTable={(val) => {
                  const data = tableSearchFilterHandler(allLocations, val);
                  setFilteredLoc(data);
                  setCurrentPage(1);
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FindLocation;
