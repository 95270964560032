import React, { useState, useRef, useEffect } from "react";
import { motion, cubicBezier } from "framer-motion";
import Slider from "react-slick";
import { GET_API_URLS } from "../../utils/apiConstant";
import { commonGetApiHandler } from "../../api/commonApiCall";
import { Container, Row, Col } from "react-bootstrap";
import { FindCancoSection } from "../../components/parts";
import { SectionTitle, Text, Anchor } from "../../components/styledComponent";
import {
  aboutSliderImg,
  homeSliderSettings,
  homePromoSliderSettings,
} from "../../utils/constants";
import oneStopLogo from "../../assets/oneStopLogo.png";
import CancoCardImg from "../../assets/cancoCard.png";
import styles from "./style.module.scss";
import { routes } from "../../utils/routes";
import { RegisterButton } from "../../components/ui";
import { useAuth } from "../../context/AuthProvider";

const animationTransitionValues = [0.95, 0.05, 0.795, 0.035];

const InStore = () => {
  const [isPromo, setIsPromo] = useState(null);
  const [promotionData, setPromotionData] = useState(false);
  const [activePromotionData, setActivePromotionData] = useState(null);
  const [promotionList, setPromotionList] = useState(null);
  const [chillerzList, setChillerzList] = useState(null);
  const [foodToGoList, setFoodToGoList] = useState(null);
  const [bannerSliderData, setBannerSliderData] = useState(null);
  const promoSliderRef = useRef(null);

  const { user } = useAuth();

  const showPromo = {
    transform: "translate(0, 0)",
    opacity: 1,
    display: "block",
  };

  const hidePromo = {
    transform: "translate(0, -100%)",
    opacity: 0,
    display: "none",
  };

  // Getting list of promotions
  useEffect(() => {
    async function promotionFetcher() {
      try {
        const repsonse = await commonGetApiHandler(GET_API_URLS.GET_PROMOTIONS);
        repsonse && setPromotionData(repsonse.data);
      } catch (error) {
        console.log("web promotion api error", error);
      }
    }
    promotionFetcher();
  }, []);

  useEffect(() => {
    async function bannerFetcher() {
      try {
        const repsonse = await commonGetApiHandler(
          GET_API_URLS.GET_BANNER + "?module=STORE"
        );
        repsonse && setBannerSliderData(repsonse.data);
      } catch (error) {
        console.log("banner api error", error);
      }
    }
    bannerFetcher();
  }, []);

  // Dividing promotion data into categories
  useEffect(() => {
    if (promotionData) {
      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let currentDate = new Date(`${year}/${month}/${day}`);
      setActivePromotionData(
        promotionData.filter((val) => {
          let effectiveDate = new Date(val.effective_date.replace(/-/g, "/"));
          let expirationDate = new Date(val.expiration_date.replace(/-/g, "/"));
          // console.log("date", { effectiveDate, expirationDate, currentDate });
          // console.log("exp date", expirationDate >= currentDate);
          return effectiveDate <= currentDate && expirationDate >= currentDate;
        })
      );
    }
  }, [promotionData]);

  useEffect(() => {
    if (activePromotionData) {
      setPromotionList(
        activePromotionData
          ?.sort((a, b) => a.sequence_number - b.sequence_number)
          .filter((val) => val.category.id === 3)
      );
      setChillerzList(
        activePromotionData
          ?.sort((a, b) => a.sequence_number - b.sequence_number)
          .filter((val) => val.category.id === 2)
      );
      setFoodToGoList(
        activePromotionData
          ?.sort((a, b) => a.sequence_number - b.sequence_number)
          .filter((val) => val.category.id === 1)
      );
    }
  }, [activePromotionData]);
  // console.log("Promotion List", { activePromotionData });
  return (
    <div className={styles.inStorePage}>
      {/* Banner section start */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          // delay: 0.3,
          duration: 3,
          easings: cubicBezier({ ...animationTransitionValues }),
        }}
        className={styles.bannerContainer}
      >
        {bannerSliderData && (
          <Slider {...homeSliderSettings}>
            {bannerSliderData
              .sort((a, b) => a.sequence - b.sequence)
              .map((data, i) => {
                return (
                  <div key={i} className={styles.promoImgContainer}>
                    <img src={data.banner} alt="Promotion Slider" />
                  </div>
                );
              })}
          </Slider>
        )}
      </motion.div>

      {/* One Stop Section */}
      <Container className={`sectionContainer ${styles.oneStopSection}`}>
        <Row>
          <Col xs={12} md={12} lg={6} className={styles.oneStopInfo}>
            <div>
              <img src={oneStopLogo} alt="One Stop" />
              <Text
                $textAlign="center"
                style={{
                  fontFamily: "frutigerBold",
                }}
              >
                by Canco
              </Text>
            </div>
            <Text $textAlign="center">
              One Stop by Canco is our convenience store with a huge stock of
              quality products. An easy way to find your everyday items such as
              coffee, groceries, snack foods, confectionery, soft drinks, ice
              creams, tobacco products, lottery tickets and many more. Find your
              nearest One Stop and come check out our incredible promotions.
            </Text>
          </Col>
          <Col xs={12} md={12} lg={6} className={styles.oneStopSlider}>
            <Slider {...homeSliderSettings} className="aboutSlider">
              {aboutSliderImg.map((imgUrl, i) => {
                return (
                  <div key={i} className={styles.oneStopImgContainer}>
                    <img src={imgUrl} alt="Promotion Slider" />
                  </div>
                );
              })}
            </Slider>
          </Col>
        </Row>
      </Container>

      {/* Promotion, Chillerz, Food-2-go Section */}
      <Container className={`sectionContainer ${styles.promotionSection}`}>
        <Row id="promotions">
          <Col xs={12} md={6} lg={4}>
            <div
              className={`${styles.promotionBox} ${styles.promotionsBlock} ${
                isPromo === "promotions" ? styles.activePromotion : null
              }`}
              onClick={() => {
                setIsPromo("promotions");
                setTimeout(() => {
                  promoSliderRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }, 1000);
              }}
            >
              <div className={styles.promotionLabel}>
                <SectionTitle $color="var(--whiteColor)">
                  Promotions
                </SectionTitle>
              </div>
            </div>
            <div
              className={`sectionContainer ${styles.mobilePromoSlider} ${
                styles.promotionSliderSection
              } ${isPromo === "promotions" && styles.activePromotionSlider}`}
              ref={promoSliderRef}
            >
              {isPromo === "promotions" && promotionList && (
                <motion.div
                  animate={isPromo ? showPromo : hidePromo}
                  // initial={{ transform: "translate(0, -100%)" }}
                  // whileInView={{ transform: "translate(0, 0)" }}
                  transition={{
                    duration: 1,
                    easings: cubicBezier(0.17, 0.67, 0.83, 0.67),
                  }}
                >
                  {promotionList.length > 0 ? (
                    <Slider
                      {...homePromoSliderSettings}
                      className="promotionSlider"
                    >
                      {/* {food2GoSliderImg.map((imgUrl, i) => {
                return (
                  <div key={i} className={styles.promoImgContainer}>
                    <img src={imgUrl} alt="Promotion Slider" />
                  </div>
                );
              })} */}
                      {promotionList.map((data, i) => {
                        return (
                          <div key={i} className={styles.promoImgContainer}>
                            <img src={data.image} alt="Promotion Slider" />
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <Text
                      $textAlign="center"
                      style={{ margin: "0", fontSize: "2rem" }}
                    >
                      No Promotions Available!
                    </Text>
                  )}
                </motion.div>
              )}
            </div>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <div
              className={`${styles.promotionBox} ${styles.chillerzBlock} ${
                isPromo === "chillerz" ? styles.activePromotion : null
              }`}
              onClick={() => {
                setIsPromo("chillerz");
                setTimeout(() => {
                  promoSliderRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }, 1000);
              }}
            >
              <div className={styles.promotionLabel}>
                <SectionTitle $color="var(--whiteColor)">Chillerz</SectionTitle>
              </div>
            </div>
            <div
              className={`sectionContainer ${styles.mobilePromoSlider} ${
                styles.promotionSliderSection
              } ${isPromo === "chillerz" && styles.activePromotionSlider}`}
              ref={promoSliderRef}
            >
              {isPromo === "chillerz" && chillerzList && (
                <motion.div
                  animate={isPromo ? showPromo : hidePromo}
                  transition={{
                    duration: 1,
                    easings: cubicBezier(0.17, 0.67, 0.83, 0.67),
                  }}
                >
                  {chillerzList.length > 0 ? (
                    <Slider
                      {...homePromoSliderSettings}
                      className="promotionSlider"
                    >
                      {/* {food2GoSliderImg.map((imgUrl, i) => {
                return (
                  <div key={i} className={styles.promoImgContainer}>
                    <img src={imgUrl} alt="Promotion Slider" />
                  </div>
                );
              })} */}
                      {chillerzList.map((data, i) => {
                        return (
                          <div key={i} className={styles.promoImgContainer}>
                            <img src={data.image} alt="Promotion Slider" />
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <Text
                      $textAlign="center"
                      style={{ margin: "0", fontSize: "2rem" }}
                    >
                      No Chillerz Available!
                    </Text>
                  )}
                </motion.div>
              )}
            </div>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <div
              className={`${styles.promotionBox} ${styles.foodBlock} ${
                isPromo === "food2Go" ? styles.activePromotion : null
              }`}
              onClick={() => {
                setIsPromo("food2Go");
                setTimeout(() => {
                  promoSliderRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }, 1000);
              }}
            >
              <div className={styles.promotionLabel}>
                <SectionTitle $color="var(--whiteColor)">
                  Food 2 Go
                </SectionTitle>
              </div>
            </div>
            <div
              className={`sectionContainer ${styles.mobilePromoSlider} ${
                styles.promotionSliderSection
              } ${isPromo === "food2Go" && styles.activePromotionSlider}`}
              ref={promoSliderRef}
            >
              {isPromo === "food2Go" && foodToGoList && (
                <motion.div
                  animate={isPromo ? showPromo : hidePromo}
                  transition={{
                    duration: 1,
                    easings: cubicBezier(0.17, 0.67, 0.83, 0.67),
                  }}
                >
                  {foodToGoList.length > 0 ? (
                    <Slider
                      {...homePromoSliderSettings}
                      className="promotionSlider"
                    >
                      {/* {food2GoSliderImg.map((imgUrl, i) => {
                return (
                  <div key={i} className={styles.promoImgContainer}>
                    <img src={imgUrl} alt="Promotion Slider" />
                  </div>
                );
              })} */}
                      {foodToGoList.map((data, i) => {
                        return (
                          <div key={i} className={styles.promoImgContainer}>
                            <img src={data.image} alt="Promotion Slider" />
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <Text
                      $textAlign="center"
                      style={{ margin: "0", fontSize: "2rem" }}
                    >
                      No Food 2 Go Available!
                    </Text>
                  )}
                </motion.div>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <div
        className={`sectionContainer ${styles.promotionSliderSection} ${
          isPromo && styles.activePromotionSlider
        }`}
        ref={promoSliderRef}
      >
        {isPromo === "promotions" && promotionList && (
          <motion.div
            animate={isPromo ? showPromo : hidePromo}
            // initial={{ transform: "translate(0, -100%)" }}
            // whileInView={{ transform: "translate(0, 0)" }}
            transition={{
              duration: 1,
              easings: cubicBezier(0.17, 0.67, 0.83, 0.67),
            }}
          >
            {promotionList.length > 0 ? (
              <Slider {...homePromoSliderSettings} className="promotionSlider">
                {/* {food2GoSliderImg.map((imgUrl, i) => {
                return (
                  <div key={i} className={styles.promoImgContainer}>
                    <img src={imgUrl} alt="Promotion Slider" />
                  </div>
                );
              })} */}
                {promotionList.map((data, i) => {
                  return (
                    <div key={i} className={styles.promoImgContainer}>
                      <img src={data.image} alt="Promotion Slider" />
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <Text
                $textAlign="center"
                style={{ margin: "0", fontSize: "2rem" }}
              >
                No Promotions Available!
              </Text>
            )}
          </motion.div>
        )}

        {isPromo === "chillerz" && chillerzList && (
          <motion.div
            animate={isPromo ? showPromo : hidePromo}
            transition={{
              duration: 1,
              easings: cubicBezier(0.17, 0.67, 0.83, 0.67),
            }}
          >
            {chillerzList.length > 0 ? (
              <Slider {...homePromoSliderSettings} className="promotionSlider">
                {/* {food2GoSliderImg.map((imgUrl, i) => {
                return (
                  <div key={i} className={styles.promoImgContainer}>
                    <img src={imgUrl} alt="Promotion Slider" />
                  </div>
                );
              })} */}
                {chillerzList.map((data, i) => {
                  return (
                    <div key={i} className={styles.promoImgContainer}>
                      <img src={data.image} alt="Promotion Slider" />
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <Text
                $textAlign="center"
                style={{ margin: "0", fontSize: "2rem" }}
              >
                No Chillerz Available!
              </Text>
            )}
          </motion.div>
        )}

        {isPromo === "food2Go" && foodToGoList && (
          <motion.div
            animate={isPromo ? showPromo : hidePromo}
            transition={{
              duration: 1,
              easings: cubicBezier(0.17, 0.67, 0.83, 0.67),
            }}
          >
            {foodToGoList.length > 0 ? (
              <Slider {...homePromoSliderSettings} className="promotionSlider">
                {/* {food2GoSliderImg.map((imgUrl, i) => {
                return (
                  <div key={i} className={styles.promoImgContainer}>
                    <img src={imgUrl} alt="Promotion Slider" />
                  </div>
                );
              })} */}
                {foodToGoList.map((data, i) => {
                  return (
                    <div key={i} className={styles.promoImgContainer}>
                      <img src={data.image} alt="Promotion Slider" />
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <Text
                $textAlign="center"
                style={{ margin: "0", fontSize: "2rem" }}
              >
                No Food 2 Go Available!
              </Text>
            )}
          </motion.div>
        )}
      </div>

      {/* Second section start */}
      <FindCancoSection />

      {/* Third section start */}
      <div className={`${styles.thirdSection}`}>
        <Container>
          <Row className={styles.thirdSectionRow}>
            <Col
              lg={6}
              className={styles.thirdDescription}
              as={motion.div}
              initial={{ y: 200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                duration: 1,
                easings: cubicBezier({ ...animationTransitionValues }),
              }}
            >
              <SectionTitle $color={"var(--whiteColor)"}>
                Earn 2¢ per litre on fuel.
              </SectionTitle>
              <SectionTitle $color={"var(--whiteColor)"}>
                +2% Cashback on in-store purchases.*
              </SectionTitle>
              <Text $fontWeight={600} $color={"var(--whiteColor)"}>
                *Some restrictions may apply.
              </Text>
              <Anchor href={routes.CANCO_CASH_LOYALTY}>LEARN MORE</Anchor>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Fourth section start */}
      <Container className={`sectionContainer ${styles.fourthSection}`}>
        <Row>
          <Col lg={6} className={styles.fourthDescription}>
            <SectionTitle>
              It pays to be loyal,
              <br />
              the card that spends like real cash
            </SectionTitle>
            <Text>
              We appreciate every person that walks into Canco Gas + One Stop
              Convenience and we want to help you get the best bang for your
              buck. Canco Cash is our loyalty program where customers earn and
              redeem.
            </Text>
            {!user.isLoggedIn && <RegisterButton />}
          </Col>
          <Col lg={6} className={styles.fourthImg}>
            <motion.img
              src={CancoCardImg}
              alt="Canco Card"
              initial={{ x: 200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.5,
                duration: 2,
                easings: cubicBezier({ ...animationTransitionValues }),
                type: "spring",
                stiffness: 100,
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InStore;
