import React from "react";
import { motion, cubicBezier } from "framer-motion";
import { Button } from "../../styledComponent";
import { Search } from "react-bootstrap-icons";
import { CitySelect } from "../../ui";
import styles from "./style.module.scss";

const CitySearch = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ delay: 0.2, easings: cubicBezier(0.17, 0.67, 0.83, 0.67) }}
      className={styles.searchContainer}
    >
      <CitySelect onChange={(val) => props.onChange(val)} />

      {/* <input
        type="text"
        placeholder="Search location using city"
        onChange={(e) => props.onChange(e)}
      /> */}
      <Button $borderRadius={"50%"} onClick={props.onSearch}>
        <Search />
      </Button>
    </motion.div>
  );
};

export default CitySearch;
