import React, { useState } from "react";
import { SurveyForm } from "../../components/parts/forms";
import { SectionTitle } from "../../components/styledComponent";
import { Loader } from "../../components/ui";
import successGif from "../../assets/successGif.gif";
import styles from "./styles.module.scss";
import { ToastContainer, toast } from "react-toastify";

const Survey = () => {
  const [loader, setLoader] = useState(false);
  const [successBox, setSuccessBox] = useState(false);

  return (
    <div className={`${styles.becomeRealtorPage} sectionContainer`}>
      <div className={styles.becomeRealtorInner}>
        <SectionTitle>Survey Form</SectionTitle>
        {!successBox && (
          <SurveyForm
            loader={(val) => setLoader(val)}
            success={(val) => setSuccessBox(val)}
            error={() =>
              toast.error("Form Submission Failed!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "colored",
                style: { fontSize: "1.6rem", fontWeight: 500 },
              })
            }
          />
        )}
        {loader && (
          <div className={styles.loaderBox}>
            <Loader />
          </div>
        )}
        {successBox && (
          <div className={styles.successBox}>
            <img src={successGif} alt="Success" />
            <h3>
              Thank you
              <br />
              Your response is submitted successfully.
            </h3>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
    </div>
  );
};

export default Survey;
