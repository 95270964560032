import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, cubicBezier } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";
import CitySearch from "../citySearch";
import { SectionTitle, Text } from "../../styledComponent";
import CancoMarker from "../../../assets/cancoMarker.png";
import styles from "./style.module.scss";

const animationTransitionValues = [0.95, 0.05, 0.795, 0.035];

const FindCancoSection = () => {
  const [citySearch, setCitySearch] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const navigate = useNavigate();

  const citySearchHandler = () => {
    citySearch === ""
      ? toast.error("Please enter city", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
        })
      : navigate("/find-location", {
          state: { value: citySearch, cityData: selectedCity },
          replace: true,
        });
  };

  return (
    <Container className={`sectionContainer ${styles.findCancoSection}`}>
      <Row>
        <Col as={motion.div}>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              delay: 0.2,
              easings: cubicBezier({ ...animationTransitionValues }),
            }}
            className={styles.sectionImg}
          >
            <img src={CancoMarker} alt="Canco Map Marker" />
          </motion.div>
          <SectionTitle
            $textAlign={"center"}
            as={motion.h2}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              delay: 0.4,
              easings: cubicBezier({ ...animationTransitionValues }),
            }}
          >
            Find your nearest Canco
          </SectionTitle>
          <div className={styles.descriptionBlock}>
            <Text
              as={motion.p}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                delay: 0.6,
                easings: cubicBezier({ ...animationTransitionValues }),
              }}
              $textAlign="center"
            >
              Find the nearest Canco service station. Use the search filters to
              find exactly what you need. From fuelling up, having a snack or
              washing your car. At Canco, we are committed to providing an
              excellent five star experience for all of our customers
            </Text>
            <CitySearch
              onChange={(e) => {
                setCitySearch(e.value);
                setSelectedCity(e);
              }}
              onSearch={() => citySearchHandler()}
            />
            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnFocusLoss={false}
              draggable={false}
              pauseOnHover={false}
              theme="colored"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FindCancoSection;
