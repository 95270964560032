import styles from "./style.module.scss";
import {
  Button,
  ErrorText,
  Input,
  Label,
  SectionTitle,
  Text,
} from "../../../styledComponent";
import DeleteUserImg from "../../../../assets/deleteUserIcon.png";
import { useFormik } from "formik";
import {
  typeDeleteValidationSchema,
} from "../../../../utils/formik/validationSchema";

const ConfirmAccountDeletion = (props) => {
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: typeDeleteValidationSchema,
    onSubmit: () => {
      props.onConfirm();
    },
  });

  return (
    <div className={styles.confirmDeletionContainer}>
      <div className={styles.confirmDeletionInner}>
        <div>
          <div>
            <img src={DeleteUserImg} alt="Unregistration" />
          </div>
          <div className={styles.confirmDeletionBody}>
            <SectionTitle> Profile Deletion Confirmation</SectionTitle>
            <div className={styles.userDetailsBlock}>
              <Text>
                <b>Name:</b> {props.userData?.first_name}{" "}
                {props.userData?.last_name}
              </Text>
              <Text>
                <b>Email:</b> {props.userData?.email}
              </Text>
              <Text style={{ fontSize: "12px" }}>
                Keep in mind that if you choose to deactivate your account, all
                data related to your Canco Cash and Points will be deleted and
                unrecoverable.
              </Text>
            </div>
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className={styles.confirmDeleteForm}
        >
          <div>
            <Label>Type DELETE to confirm</Label>
            <Input
              type="text"
              onChange={(e) => {
                // Convert the value to uppercase and update the state
                const uppercaseValue = e.target.value.toUpperCase();
                formik.setFieldValue("name", uppercaseValue);
              }}
              onBlur={formik.handleBlur("name")}
              style={{ textTransform: "uppercase" }}
            />
            {formik.touched.name && formik.errors.name && (
              <ErrorText className={styles.errorText}>
                {formik.errors.name}
              </ErrorText>
            )}
          </div>
          <div className={styles.confirmDeletionBtn}>
            <Button
              type="submit"
              className={
                !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
              }
            >
              Confirm Deletion
            </Button>
            <Button onClick={props.onClose}>Cancel Deletion</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmAccountDeletion;
