import React from "react";
import emailGif from "../../assets/emailConfirm.png";
import styles from "./style.module.scss";

const SuccessAccountDeletionRequest = () => {
  return (
    <div className={`${styles.emailConfirmPage} sectionContainer`}>
      <div className={styles.emailConfirmInner}>
        <div className={styles.successBox}>
          <img src={emailGif} alt="Success" />
          <h3>Your request is submitted successfully! </h3>
        </div>
      </div>
    </div>
  );
};

export default SuccessAccountDeletionRequest;
