import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { motion, easeInOut, cubicBezier } from "framer-motion";
import DownloadAppSection from "../../components/parts/downloadAppSection";
import { advGetApiHandler, commonGetApiHandler } from "../../api/commonApiCall";
import { GET_API_URLS } from "../../utils/apiConstant";
import { useAuth } from "../../context/AuthProvider";
import { Col, Container, Row } from "react-bootstrap";
import { Button, SectionTitle, Text } from "../../components/styledComponent";
import CancoCard from "../../assets/cancoCard.png";
import CancoCardImg from "../../assets/cardPlaceholder.png";
import CancoGiftCard from "../../assets/giftCard.png";
import { Loader, RegisterButton } from "../../components/ui";
import {
  AddCard,
  BalanceTransfer,
  FeaturedPopup,
} from "../../components/parts/popups";
import {
  Person,
  CheckCircleFill,
  ChevronDoubleRight,
  ChevronDoubleLeft,
  ChevronDoubleDown,
  PencilSquare,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";

const animationTransitionValues = [0.95, 0.05, 0.795, 0.035];

const CancoCashLoyalty = () => {
  const { userName, user, setUser, setUserName } = useAuth();
  const navigate = useNavigate();
  const [cards, setCards] = useState(null);
  const [activeCards, setActiveCards] = useState(null);
  const [cardNumber, setCardNumber] = useState(null);
  const [cardTransactions, setCardTransactions] = useState(null);
  const [cardBalance, setCardBalance] = useState(null);
  const [transLoader, setTransLoader] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [balanceTransfer, setBalanceTransfer] = useState(false);
  const [numberOfTransaction, setNumberOfTransaction] = useState(5);
  const [promotionData, setPromotionData] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [featuredBanner, setFeaturedBanner] = useState(null);

  // Getting Featured Banner
  useEffect(() => {
    async function promotionFetcher() {
      try {
        const repsonse = await commonGetApiHandler(GET_API_URLS.GET_PROMOTIONS);
        repsonse && setPromotionData(repsonse.data);
      } catch (error) {
        console.log("web promotion api error", error);
      }
    }
    async function storebannerFetcher() {
      try {
        const repsonse = await commonGetApiHandler(
          GET_API_URLS.GET_BANNER + "?module=STORE"
        );
        repsonse && setStoreData(repsonse.data);
      } catch (error) {
        console.log("banner api error", error);
      }
    }
    async function homebannerFetcher() {
      try {
        const repsonse = await commonGetApiHandler(
          GET_API_URLS.GET_BANNER + "?module=DASHBOARD"
        );
        repsonse && setDashboardData(repsonse.data);
      } catch (error) {
        console.log("banner api error", error);
      }
    }
    homebannerFetcher();
    storebannerFetcher();
    promotionFetcher();
  }, []);

  useEffect(() => {
    if (promotionData && dashboardData && storeData) {
      const allData = [...promotionData, ...dashboardData, ...storeData];
      const filterFeatureBanner = allData.filter((val) =>
        val.featured_banner ? true : false
      );
      setFeaturedBanner(filterFeatureBanner);
    }
  }, [promotionData, dashboardData, storeData]);

  // Getting list of card asscoiated with user
  useEffect(() => {
    const token = localStorage.getItem("tokens");
    if (token) {
      async function cardFetcher() {
        const cardData = await advGetApiHandler(GET_API_URLS.GET_CARDS);
        cardData &&
          cardData.response &&
          !cardData.response.error &&
          setCards(cardData.response);
      }
      cardFetcher();
    }
  }, []);

  // Getting details of specific card
  useEffect(() => {
    if (cardNumber) {
      setTransLoader(true);
      async function transactionFetcher() {
        const cardData = await advGetApiHandler(
          GET_API_URLS.GET_CARD_TRANSACTION + `${cardNumber}/transactions/`
        );
        cardData && cardData.response && setCardTransactions(cardData.response);
        // cardData && cardData.error && setError(true);
        setTransLoader(false);
      }
      async function balanceFetcher() {
        const cardData = await advGetApiHandler(
          GET_API_URLS.GET_CARD_TRANSACTION + `${cardNumber}/balance/`
        );
        cardData && cardData?.response && setCardBalance(cardData.response);
        cardData && cardData?.error && setCardBalance(null);
      }
      transactionFetcher();
      balanceFetcher();
      setNumberOfTransaction(5);
    }
  }, [cardNumber]);

  // Dont allow user to scroll when popup is active
  useEffect(() => {
    if (addCard || balanceTransfer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [addCard, balanceTransfer]);

  useEffect(() => {
    if (cards?.length > 0) {
      setActiveCards(cards?.filter((item) => item.active && item));
    }
  }, [cards]);

  useEffect(() => {
    if (activeCards) {
      setCardNumber(activeCards[0].cardnumber);
    }
  }, [activeCards]);

  // Logout user
  const logoutHandler = () => {
    localStorage.removeItem("tokens");
    setUser({
      id: null,
      isLoggedIn: false,
      email: null,
    });
    setUserName("");

    navigate(routes.LOGIN);
  };

  // Slider settings for card slider
  const cardSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    autoplay: false,
    slidesToShow: 1,
    nextArrow: (
      <div className={"cardSliderArrow"}>
        <ChevronDoubleRight size={50} color="var(--primaryColor)" />
      </div>
    ),
    prevArrow: (
      <div className={"cardSliderArrow"}>
        <ChevronDoubleLeft size={50} color="var(--primaryColor)" />
      </div>
    ),
    afterChange: (currentSlide) =>
      setCardNumber(activeCards[currentSlide].cardnumber),
  };

  // console.log("-->", featuredBanner);

  return (
    <div className={styles.cancoCashPage}>
      {/* Banner section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, easings: easeInOut }}
        className={styles.bannerSection}
      ></motion.div>

      {/* My account section */}
      <div className={styles.myAccountSection}>
        <Container className={`sectionContainer`}>
          <Row>
            <Col xs={12} md={4} lg={6}>
              <div className={styles.userBlock}>
                <Person size={40} color="var(--whiteColor)" />
                <SectionTitle $color="var(--whiteColor)">
                  {user?.isLoggedIn ? userName : "My Account"}
                </SectionTitle>
                {user?.isLoggedIn && (
                  <span
                    onClick={() => navigate(routes.EDIT_USER_PROFILE)}
                    className={styles.userEditIcon}
                  >
                    <PencilSquare size={20} />
                  </span>
                )}
              </div>
            </Col>
            <Col xs={12} md={8} lg={6} className={styles.myaccountBtn}>
              {user?.isLoggedIn ? (
                <div className={styles.afterLoginBtn}>
                  <Button onClick={() => setAddCard(true)}>Add Card</Button>
                  <Button onClick={() => setBalanceTransfer(true)}>
                    Balance Transfer
                  </Button>
                  <Button onClick={() => logoutHandler()}>Logout</Button>
                </div>
              ) : (
                <>
                  <Button onClick={() => navigate(routes.LOGIN)}>Login</Button>
                  <Text>
                    or <Link to={routes.REGISTER}>Register</Link> to view
                    details.
                  </Text>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>

      {/* Card Slider */}
      {user.isLoggedIn && (
        <Container className={styles.cardContainer}>
          <Row>
            {cards ? (
              cards.length > 0 ? (
                <>
                  <Col lg={5}>
                    <div className="cardSlider">
                      <Slider {...cardSliderSettings}>
                        {activeCards?.map(
                          (item) =>
                            item &&
                            item.active && (
                              <div className="cardBox">
                                <img src={CancoCardImg} alt="Canco card" />
                                <div className={styles.cardInfo}>
                                  <Text>{item.cardnumber}</Text>
                                  <Text>{item.ackroo_id.split("-")[0]}</Text>
                                </div>
                              </div>
                            )
                        )}
                      </Slider>
                    </div>
                  </Col>
                  <Col className={styles.cardDetailsContainer}>
                    <ul>
                      {activeCards?.map((item) => {
                        if (item && item?.cardnumber === cardNumber) {
                          const rewardBalance =
                            parseFloat(cardBalance?.loyalty) +
                            parseFloat(cardBalance?.promo);
                          return (
                            <>
                              <li>
                                <Text>
                                  <b>Card Number</b>:<br />
                                  <span>{item?.cardnumber}</span>
                                </Text>
                              </li>
                              <li>
                                <Text>
                                  <b>Access Code</b>:<br />
                                  <span>{item?.ackroo_id?.split("-")[0]}</span>
                                </Text>
                              </li>
                              <li>
                                <Text>
                                  <b>Reward Balance</b>:<br />
                                  <span>
                                    ${rewardBalance?.toFixed(2) || 0.0}
                                  </span>
                                </Text>
                              </li>
                              <li>
                                <Text>
                                  <b>Loyalty Balance</b>:<br />
                                  <span>${cardBalance?.loyalty || 0.0}</span>
                                </Text>
                              </li>
                              <li>
                                <Text>
                                  <b>Promotion Balance</b>:<br />
                                  <span>${cardBalance?.promo || 0.0}</span>
                                </Text>
                              </li>
                            </>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </ul>
                    {transLoader && (
                      <div className={styles.loaderBox}>
                        <Loader />
                      </div>
                    )}
                  </Col>
                </>
              ) : (
                <Col>
                  <Text $textAlign="center">No cards!</Text>
                </Col>
              )
            ) : null}
          </Row>
        </Container>
      )}

      {/* Card Transaction */}
      {cards && cards?.length > 0 && (
        <Container>
          <Row>
            <Col>
              <div className={styles.transactionTable}>
                <table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Transaction</th>
                      <th>Location</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transLoader ? (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={4}>
                          <Loader />
                        </td>
                      </tr>
                    ) : cardTransactions && cardTransactions.length > 0 ? (
                      cardTransactions
                        .slice(0, numberOfTransaction)
                        .map((item, i) => (
                          <tr key={i}>
                            <td>
                              <Text className={styles.transactionDate}>
                                {item.created_at.split(" ")[0]}
                              </Text>
                            </td>
                            <td>
                              <h3>{item.transaction_type}</h3>
                            </td>
                            <td>
                              <Text className={styles.transactionLoc}>
                                {item.location}
                              </Text>
                            </td>
                            <td>
                              <Text>$ {item.transaction_amount}</Text>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan={4}>
                          <Text $textAlign="center">No Transactions!</Text>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {!(numberOfTransaction === cardTransactions?.length) &&
                !(cardTransactions?.length < numberOfTransaction) && (
                  <Text
                    className={styles.seeMore}
                    onClick={() => setNumberOfTransaction((prev) => prev + 5)}
                  >
                    <span>See more transaction</span>
                    <span>
                      <ChevronDoubleDown size={30} />
                    </span>
                  </Text>
                )}
            </Col>
          </Row>
        </Container>
      )}

      {/* About canco cash card section */}
      <Container className={`sectionContainer ${styles.aboutCancocard}`}>
        <Row>
          <Col xs={12} md={12} lg={6} className={styles.aboutDescription}>
            <SectionTitle>
              It pays to be loyal, the card that spends like real cash
            </SectionTitle>
            <Text>
              We appreciate every person that walks into Canco Gas + One Stop
              Convenience and we want to help you get the best bang for your
              buck. Canco Cash is our loyalty program where customers earn and
              redeem.
            </Text>
            <ul>
              <li>
                <CheckCircleFill size={35} color="var(--primaryColor)" />
                <Text>Earn 2 ¢ per litre</Text>
              </li>
              <li>
                <CheckCircleFill size={35} color="var(--primaryColor)" />
                <div>
                  <Text>Earn 2% cash back on in-store purchases</Text>
                  <p className={styles.noteTxt}>*some restrictions may apply</p>
                </div>
              </li>
              <li>
                <CheckCircleFill size={35} color="var(--primaryColor)" />
                <div>
                  <Text>Earn 1% cash back on lottery tickets</Text>
                  <p className={styles.noteTxt}>
                    *available at select locations only
                  </p>
                </div>
              </li>
            </ul>
            {!user.isLoggedIn && <RegisterButton />}
          </Col>
          <Col xs={12} md={12} lg={6}>
            <motion.img
              src={CancoCard}
              alt="Canco Card"
              initial={{ x: 200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.5,
                duration: 2,
                easings: cubicBezier({ ...animationTransitionValues }),
                type: "spring",
                stiffness: 100,
              }}
            />
          </Col>
        </Row>
      </Container>

      {/* How does this work section */}
      <Container className={`sectionContainer ${styles.howWorkContainer}`}>
        <Row>
          <Col>
            <SectionTitle $size="2.5rem">How does it work?</SectionTitle>
            <ul>
              <li>
                <SectionTitle>VISIT</SectionTitle>
                <Text>
                  Earn Canco Cash every time you visit any of our Canco
                  locations
                </Text>
              </li>
              <li>
                <SectionTitle>SCAN</SectionTitle>
                <Text>
                  Scan your Canco Cash Card in-store when you make a purchase or
                  swipe it at the pumps
                </Text>
              </li>
              <li>
                <SectionTitle>EARN</SectionTitle>
                <Text>
                  Earn Canco Cash on your account every time you make a fuel or
                  an in-store purchase. *
                </Text>
              </li>
              <li>
                <SectionTitle>ENJOY</SectionTitle>
                <Text>
                  Redeem your Canco Cash on fuel and in-store purchases. *
                </Text>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>

      {/* Gift Card section */}
      <div className={styles.giftCardContainer}>
        <Container className={`sectionContainer`}>
          <Row>
            <Col xs={12} md={12} lg={6} className={styles.giftDescription}>
              <SectionTitle $color="var(--whiteColor)" $size="2.6rem">
                Find the card you’ll love
              </SectionTitle>
              <Text $color="var(--whiteColor)">
                Meet our Canco Gift Card. A convenient card that you can easily
                use for things such as; gas, car washes, snacks, and almost
                anything else you could need for the drive. *
              </Text>
              <Text $color="var(--whiteColor)" $size="1.2rem">
                *Some restrictions may apply.
              </Text>
              <SectionTitle $color="var(--whiteColor)">
                Get one in-store today!
              </SectionTitle>
            </Col>
            <Col xs={12} md={12} lg={6} className={styles.giftimg}>
              <motion.img
                src={CancoGiftCard}
                alt="Canco Card"
                initial={{ x: 200, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  delay: 0.5,
                  duration: 2,
                  easings: cubicBezier({ ...animationTransitionValues }),
                  type: "spring",
                  stiffness: 100,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Download App section */}
      <DownloadAppSection />

      {addCard && <AddCard onClose={() => setAddCard(false)} />}
      {balanceTransfer && (
        <BalanceTransfer
          onClose={() => setBalanceTransfer(false)}
          cards={activeCards}
        />
      )}
      {featuredBanner && featuredBanner?.length > 0 && (
        <FeaturedPopup
          src={featuredBanner[0]?.image || featuredBanner[0]?.banner}
          onClose={() => setFeaturedBanner(false)}
        />
      )}
    </div>
  );
};

export default CancoCashLoyalty;
