import React from "react";
import { useFormik } from "formik";
import { Button, ErrorText, Form, Label } from "../../../styledComponent";
import { surveyFormInitialValues } from "../../../../utils/formik/initialvalue";
import styles from "./style.module.scss";
import { StarRating } from "../../../ui";
import { commonPostApiHandler } from "../../../../api/commonApiCall";
import { POST_API_URLS } from "../../../../utils/apiConstant";
import { surveyValidationSchema } from "../../../../utils/formik/validationSchema";

const SurveyForm = (props) => {
  const formik = useFormik({
    initialValues: surveyFormInitialValues,
    validationSchema: surveyValidationSchema,
    onSubmit: (values) => formSubmitHandler(values),
  });

  async function formSubmitHandler(val) {
    props.loader(true);
    const emailData = { ...val };
    delete emailData.comment;

    const requestedData = {
      email_data: emailData,
      comment: val.comment,
    };
    // console.log("formik", val);
    // console.log("requested data", requestedData);
    const formRes = await commonPostApiHandler(
      POST_API_URLS.SURVEY_FORM,
      requestedData
    );
    // console.log("form res", formRes);
    props.loader(false);
    formRes && formRes.response && props.success(true);
    formRes && formRes.error && props.error();
  }
  console.log("formik", formik.errors);
  return (
    <Form onSubmit={formik.handleSubmit} className={styles.becomeRealtorForm}>
      <div>
        <div>
          <Label htmlFor="firstName">
            <b>Overall Experience:</b> How likely would you recommend Canco One
            Stop to your friends and family?{" "}
          </Label>
          <StarRating
            onSelect={(val) =>
              formik.setFieldValue("overall_experience", val.toString())
            }
          />
          {formik.errors.overall_experience && (
            <ErrorText className={styles.errorText}>
              {formik.errors.overall_experience}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="firstName">
            <b>Environment:</b> Was the environment clean and well maintained?{" "}
          </Label>
          <StarRating
            onSelect={(val) =>
              formik.setFieldValue("enviornment", val.toString())
            }
          />
          {formik.touched.enviornment && formik.errors.enviornment && (
            <ErrorText className={styles.errorText}>
              {formik.errors.enviornment}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="firstName">
            <b>Merchandise:</b> Did you find what you needed in store or at the
            pumps?{" "}
          </Label>
          <StarRating
            onSelect={(val) =>
              formik.setFieldValue("merchandise", val.toString())
            }
          />
          {formik.touched.merchandise && formik.errors.merchandise && (
            <ErrorText className={styles.errorText}>
              {formik.errors.merchandise}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="firstName">
            <b>Service Experience:</b> Was our team friendly and approachable?
          </Label>
          <StarRating
            onSelect={(val) =>
              formik.setFieldValue("service_experience", val.toString())
            }
          />
          {formik.touched.service_experience &&
            formik.errors.service_experience && (
              <ErrorText className={styles.errorText}>
                {formik.errors.service_experience}
              </ErrorText>
            )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="firstName">
            <b>Loyalty Card:</b> Did you find our Canco Cash Card beneficial?
          </Label>
          <StarRating
            onSelect={(val) =>
              formik.setFieldValue("loyalty_card", val.toString())
            }
          />
          {formik.touched.loyalty_card && formik.errors.loyalty_card && (
            <ErrorText className={styles.errorText}>
              {formik.errors.loyalty_card}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="firstName">Comments</Label>
          <textarea
            id="firstName"
            {...formik.getFieldProps("comment")}
            rows="8"
          />
        </div>
      </div>
      <Button
        $width="100%"
        className={
          formik.values.overall_experience.toString() === "0" ||
          formik.values.enviornment.toString() === "0" ||
          formik.values.merchandise.toString() === "0" ||
          formik.values.service_experience.toString() === "0" ||
          formik.values.loyalty_card.toString() === "0"
            ? "disabledBtn"
            : "submitBtn"
        }
      >
        Submit
      </Button>
    </Form>
  );
};

export default SurveyForm;
