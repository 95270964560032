import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import {
  Button,
  Form,
  Input,
  Label,
  Select,
  Textarea,
  ErrorText,
} from "../../../styledComponent";
import styles from "./style.module.scss";
import { dealerApplicationInitialValues } from "../../../../utils/formik/initialvalue";
import { dealerFormValidationSchema } from "../../../../utils/formik/validationSchema";
import { normalizeInput } from "../../../../utils/helpers";

const BecomeDealerForm = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const formikRef = useRef(null);
  const formik = useFormik({
    initialValues: dealerApplicationInitialValues,
    validationSchema: dealerFormValidationSchema,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("phone", phoneNumber);
    }, 1000);

    return () => clearTimeout(timer);
  }, [phoneNumber, formik]);

  const handleSubmit = (event) => {
    props.loader(true);
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        props.loader(false);
        props.success(true);
      })
      .catch((error) => {
        props.loader(false);
        // console.log(error);
      });
  };

  return (
    <Form
      method="POST"
      name="dealerForm"
      data-netlify="true"
      className={styles.becomeDealerForm}
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="dealerForm" />
      <div>
        <div>
          <Label htmlFor="firstName">First Name*</Label>
          <Input
            type="text"
            id="firstName"
            {...formik.getFieldProps("firstName")}
            style={
              formik.touched.firstName &&
              formik.errors.firstName && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <ErrorText className={styles.errorText}>
              {formik.errors.firstName}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="lastName">Last Name*</Label>
          <Input
            type="text"
            id="lastName"
            {...formik.getFieldProps("lastName")}
            style={
              formik.touched.lastName &&
              formik.errors.lastName && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <ErrorText className={styles.errorText}>
              {formik.errors.lastName}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="email">Email*</Label>
          <Input
            type="email"
            id="email"
            {...formik.getFieldProps("email")}
            style={
              formik.touched.email &&
              formik.errors.email && { boxShadow: "0px 0px 0px 2px red" }
            }
          />
          {formik.touched.email && formik.errors.email && (
            <ErrorText className={styles.errorText}>
              {formik.errors.email}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="lastName">Phone Number*</Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            placeholder="(xxx) xxx-xxxx"
            onBlur={formik.handleBlur("phone")}
            onChange={(e) => {
              setPhoneNumber((prev) => normalizeInput(e.target.value, prev));
            }}
            value={phoneNumber}
            style={
              formik.touched.phone &&
              formik.errors.phone && { boxShadow: "0px 0px 0px 2px red" }
            }
          />
          {formik.touched.phone && formik.errors.phone && (
            <ErrorText className={styles.errorText}>
              {formik.errors.phone}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="owner">
            Are you currently an owner/operator of a gas station?*
          </Label>
          <Select
            id="owner"
            {...formik.getFieldProps("owner")}
            style={
              formik.touched.owner &&
              formik.errors.owner && { boxShadow: "0px 0px 0px 2px red" }
            }
          >
            <option value={"select"}>Select</option>
            <option value={"yes"}>Yes</option>
            <option value={"no"}>No</option>
          </Select>
          {formik.touched.owner && formik.errors.owner && (
            <ErrorText className={styles.errorText}>
              {formik.errors.owner}
            </ErrorText>
          )}
        </div>
      </div>
      {formik.values.owner === "yes" && (
        <div>
          <div>
            <Label htmlFor="locationAddress">
              If yes, please include the address of the location?*
            </Label>
            <Input
              type="text"
              name="locationAddress"
              id="locationAddress"
              {...formik.getFieldProps("locationAddress")}
              style={
                formik.touched.locationAddress &&
                formik.errors.locationAddress && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.locationAddress &&
              formik.errors.locationAddress && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.locationAddress}
                </ErrorText>
              )}
          </div>
        </div>
      )}
      <div>
        <div>
          <Label htmlFor="message">Message*</Label>
          <Textarea
            id="message"
            rows={4}
            cols={50}
            {...formik.getFieldProps("message")}
            style={
              formik.touched.message &&
              formik.errors.message && { boxShadow: "0px 0px 0px 2px red" }
            }
          ></Textarea>
          {formik.touched.message && formik.errors.message && (
            <ErrorText className={styles.errorText}>
              {formik.errors.message}
            </ErrorText>
          )}
        </div>
      </div>
      <Button
        $width="100%"
        type="submit"
        className={
          !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
        }
      >
        Submit
      </Button>
    </Form>
  );
};

export default BecomeDealerForm;
