import React from "react";
import styles from "./style.module.scss";
import { PlusLg } from "react-bootstrap-icons";

const FeaturedPopup = (props) => {
  return (
    <div className={styles.addCardContainer}>
      <div className={styles.addCardInner}>
        <PlusLg size={20} onClick={props.onClose} />
        <img src={props.src} alt="Feature Promotion" />
      </div>
    </div>
  );
};

export default FeaturedPopup;
