import { motion, easeInOut } from "framer-motion";
import styles from "./style.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { SectionTitle, Text } from "../../components/styledComponent";
import cancoContestLogo from "../../assets/cancoContestLogo.webp";
import contestByLogo from "../../assets/contestByLogo.webp";
import schoolSpirit from "../../assets/schoolSpiritCard.webp";
// import { routes } from "../../utils/routes";

const CancoContest = () => {
  return (
    <div className={styles.cancoContest}>
      {/* Banner section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.3, easings: easeInOut }}
        className={styles.bannerSection}
      >
        <Container>
          <Row>
            <Col>
              <img src={cancoContestLogo} alt="Canco Contest" />
            </Col>
          </Row>
        </Container>
      </motion.div>
      {/* Contest Description */}
      <Container className={`${styles.contestDescription} sectionContainer`}>
        <Row>
          <Col>
            <Text>
              At Canco Petroleum, we believe in rewarding our loyal customers
              and making their experience with us more exciting. That’s why
              we’ve created Canco Contests - a platform dedicated to bringing
              you a variety of contests as part of our special promotions.
              <br />
              <br />
              Our contests are designed to add a dash of fun to your day while
              giving you the chance to win some amazing prizes. Each contest is
              unique and offers a different reward, ensuring there’s always
              something new and exciting to look forward to!
              <br />
              <br />
              But it’s not just about the prizes. By participating in Canco
              Contests, you’re joining a community of Canco customers across
              Canada. It’s our way of saying thank you for choosing Canco Gas &
              One Stop Convenience
            </Text>
            <img src={contestByLogo} alt="Canco Petroleum and One Stop" />
          </Col>
        </Row>
      </Container>
      {/* School Spirit */}
      <div className={`${styles.schoolSpirit}`}>
        <Container className="sectionContainer">
          <Row>
            <Col xs={12} md={7} lg={6}>
              <img src={schoolSpirit} alt="School Spirit" />
            </Col>
            <Col xs={12} md={5} lg={6}>
              <SectionTitle>School Spirit</SectionTitle>
              <Text>
                We’re thrilled to announce our latest contest, School Spirit,
                brought to you by Canco Petroleum in partnership with Old Dutch
                and Coca-Cola. This contest is all about giving back to our
                community and supporting education.
                <br />
                <br />
                We’re offering a $3000 bursary to a nominated school. But here’s
                the twist - the winning school will be chosen by you! Yes, you
                heard it right. Each person can vote for their school daily, and
                each vote increases the odds that their school will be randomly
                selected.
              </Text>
              {/* <Anchor href={routes.SCHOOL_SPIRIT}>LEARN MORE</Anchor> */}
              <Text className={styles.validDate}>
                <b>VALID DATE:</b> September, 01, 2024 - October, 31, 2024
              </Text>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CancoContest;
