import React from "react";
import { motion, easeInOut } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import { SectionTitle, Text, Anchor } from "../../components/styledComponent";
import { CancoPointerSection, FindCancoSection } from "../../components/parts";
import careersImg from "../../assets/careers.png";
import DealerImg from "../../assets/dealers.png";
import jobsImg from "../../assets/jobs.png";
import styles from "./style.module.scss";
import { Timeline } from "../../components/ui";
import { routes } from "../../utils/routes";

const WorkWithUs = () => {
  return (
    <div className={styles.workWithUsPage}>
      {/* Banner section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.3, easings: easeInOut }}
        className={styles.bannerSection}
      ></motion.div>

      {/* Second section */}
      <div className={`sectionContainer ${styles.secondSection}`}>
        <SectionTitle $textAlign="center" $size="3.2rem">
          Join our team and we'll make it work for you!
        </SectionTitle>
        <Container className={styles.careerBlock}>
          <Row>
            <Col xs={12} md={4} lg={4} className={styles.secondCol}>
              <div className={styles.jobHeader}>
                <img src={careersImg} alt="Careers" />
                <SectionTitle>CAREERS AT CANCO H.O.</SectionTitle>
              </div>
              <Text>
                Check out exciting career opportunities with Canco! We are
                always actively seeking talented individuals to fill Head Office
                positions.
              </Text>
              <Anchor href={routes.CAREERS}>APPLY NOW</Anchor>
            </Col>
            <Col xs={12} md={6} lg={4} className={styles.secondCol}>
              <div className={styles.jobHeader}>
                <img src={jobsImg} alt="Careers" />
                <SectionTitle>JOBS AT SITES</SectionTitle>
              </div>
              <Text>
                If you’re looking for Canco jobs, we have various employment
                positions available at our current corporate and dealer sites.
                Please reach out to your nearest location directly.
              </Text>
              <Anchor href={routes.FIND_LOCATION}>FIND US</Anchor>
            </Col>
            <Col xs={12} md={6} lg={4} className={styles.secondCol}>
              <div className={styles.jobHeader}>
                <img src={DealerImg} alt="Dealer" />
                <SectionTitle>BECOME A DEALER</SectionTitle>
              </div>
              <Text>
                Canco creates opportunities for Canadians to drive their dreams
                of business ownership. We’re committed to high quality, and we
                support every Canco owner in growing their overall business.
              </Text>
              <Anchor href={routes.BECOME_DEALER}>DEALER APPLICATION</Anchor>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Why canco section*/}
      <div className={styles.whyCancoSection}>
        <Container className={`sectionContainer`}>
          <Row>
            <Col>
              <SectionTitle>WHY CANCO?</SectionTitle>
              <Text $textAlign="center">
                Canco is an independent chain of gas stations that launched in
                2016. A bold alternative to big-box retailers, Canco was born
                from a vision to become a leading gas and reatil brand. Backed
                by more than 15 years of combined experience; Canco has a
                dedicated team to support every step of the way! We are there to
                provide the quality and support in growing your business and
                community.
              </Text>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Timeline section */}
      <div className={styles.timelineSection}>
        <Container className="sectionContainer">
          <Row>
            <Col>
              <Timeline />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Pointer section */}
      <CancoPointerSection />

      {/* Find canco section */}
      <FindCancoSection />
    </div>
  );
};

export default WorkWithUs;
