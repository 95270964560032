import axios from "axios";
import { routes } from "../utils/routes";
import { BASE_URL } from "../utils/apiConstant";

const apiInterceptor = axios.create({
  baseURL: BASE_URL,
});

apiInterceptor.interceptors.request.use((config) => {
  let tokensData = JSON.parse(localStorage.getItem("tokens"));
  config.headers["Authorization"] = `Bearer ${tokensData.access}`;
  return config;
});

apiInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("tokens");
      window.location.href = routes.LOGIN;
      // const authData = JSON.parse(localStorage.getItem("tokens"));
      // const payload = {
      //   refresh: authData.refresh
      // };
      // let apiResponse = await axios.post(
      //   'https://api.services.dev.cancopetroleum.ca/api/admin/accounts/login/refresh/',
      //   payload
      // );
      // localStorage.setItem("tokens", JSON.stringify({...apiResponse.data, refresh: authData.refresh}));
      // error.config.headers[
      //   "Authorization"
      // ] = `Bearer ${apiResponse.data.access}`;
      return axios(error.config);
    } else {
      return Promise.reject(error);
    }
    // return Promise.reject(error);
  }
);

export default apiInterceptor;
