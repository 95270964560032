import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import { PlusLg } from "react-bootstrap-icons";
import { Button, Text, ErrorText } from "../../../styledComponent";
import styles from "./style.module.scss";
import { addCardInitialValues } from "../../../../utils/formik/initialvalue";
import { addCardValidationSchema } from "../../../../utils/formik/validationSchema";
import { POST_API_URLS } from "../../../../utils/apiConstant";
import { postApiHandler } from "../../../../api/commonApiCall";
import { SecondaryLoader } from "../../../ui";

const AddCard = (props) => {
  const [loader, setLoader] = useState(false);
  const formik = useFormik({
    initialValues: addCardInitialValues,
    validationSchema: addCardValidationSchema,
    onSubmit: (values) => {
      // console.log("add card", values);
      addCardHandler(values);
    },
  });

  const addCardHandler = async (values) => {
    setLoader(true);
    const res = await postApiHandler(POST_API_URLS.ADD_CARD, values);

    //Trigger toast when card is successfully added
    res &&
      res.response &&
      toast.success("Card Added Successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

    // Reload Canco cash loyalty page
    res && res.response && setTimeout(() => window.location.reload(), 1500);

    // Trigger Toast when card number or ackroo id is wrong
    res && res.error && typeof res.error === "object"
      ? Object.keys(res.error.errorData).map((k) =>
          toast.error(res.error.errorData[k], {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            style: { fontSize: "1.4rem", fontWeight: 500 },
          })
        )
      : toast.error(res.error, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          style: { fontSize: "1.4rem", fontWeight: 500 },
        });

    setLoader(false);
  };

  return (
    <div className={styles.addCardContainer}>
      <div className={styles.addCardInner}>
        <div className={styles.addCardHeader}>
          <Text>Add Card</Text>
          <PlusLg size={20} onClick={props.onClose} />
        </div>
        <div className={styles.addCardBody}>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <label htmlFor="cardnumber">Enter Card Number*</label>
              <input
                type="text"
                name="cardnumber"
                {...formik.getFieldProps("cardnumber")}
                style={
                  formik.touched.cardnumber &&
                  formik.errors.cardnumber && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
              />
              {formik.touched.cardnumber && formik.errors.cardnumber && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.cardnumber}
                </ErrorText>
              )}
            </div>
            <div>
              <label htmlFor="ackroo_id">Enter Access Code*</label>
              <input
                type="text"
                name="ackroo_id"
                {...formik.getFieldProps("ackroo_id")}
                style={
                  formik.touched.ackroo_id &&
                  formik.errors.ackroo_id && {
                    boxShadow: "0px 0px 0px 2px red",
                  }
                }
              />
              {formik.touched.ackroo_id && formik.errors.ackroo_id && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.ackroo_id}
                </ErrorText>
              )}
            </div>
            <Button className={!(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"}>
              {loader ? (
                <SecondaryLoader borderColor={"var(--whiteColor)"} />
              ) : (
                "Add"
              )}
            </Button>
          </form>
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            theme="colored"
          />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </div>
      </div>
    </div>
  );
};

export default AddCard;
