import React from "react";
import styles from "./style.module.scss";
import { SectionTitle, Text } from "../../styledComponent";

const Timeline = () => {
  return (
    <div className={styles.timelineBlock}>
      <div className={styles.yearBlock}>
        <div className={styles.yearContent}>
          <SectionTitle>FIRST SITE</SectionTitle>
          <Text>
            Canco One Stop opened its first Gas Station in Kamloops, B.C.
          </Text>
        </div>
        <div className={styles.yearLabel}>
          <SectionTitle>2016</SectionTitle>
        </div>
      </div>
      <div className={styles.yearBlock}>
        <div className={styles.yearContent}>
          <SectionTitle>COMMUNITY CARES</SectionTitle>
          <Text>
            Canco One Stop started Community Cares, where company employees give
            a helping hand to better the community.
          </Text>
        </div>
        <div className={styles.yearLabel}>
          <SectionTitle>2018</SectionTitle>
        </div>
      </div>
      <div className={styles.yearBlock}>
        <div className={styles.yearContent}>
          <SectionTitle>HEAD OFFICE</SectionTitle>
          <Text>
            Canco has officially moved into its head office in Kelowna, B.C.
          </Text>
        </div>
        <div className={styles.yearLabel}>
          <SectionTitle>2020</SectionTitle>
        </div>
      </div>
      <div className={styles.yearBlock}>
        <div className={styles.yearContent}>
          <SectionTitle>HIT ONTARIO</SectionTitle>
          <Text>
            Canco One Stop travels across Canada as Gas stations open in
            Ontario.
          </Text>
        </div>
        <div className={styles.yearLabel}>
          <SectionTitle>2022</SectionTitle>
        </div>
      </div>
      <div className={styles.yearBlock}>
        <div className={styles.yearContent}>
          <SectionTitle>100TH CANCO GAS</SectionTitle>
          <Text>Canco One Stop celebrates its 100th gas location!</Text>
        </div>
        <div className={styles.yearLabel}>
          <SectionTitle>2023</SectionTitle>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
