import React, { useState } from "react";
import { EditUserProfileForm } from "../../components/parts/forms";
import { SectionTitle } from "../../components/styledComponent";
import { Loader } from "../../components/ui";
import styles from "./style.module.scss";

const UserProfile = () => {
  const [loader, setLoader] = useState(false);
  return (
    <div className={`${styles.becomeDealerPage} sectionContainer`}>
      <div className={styles.becomeDealerInner}>
        <SectionTitle>Edit User Profile</SectionTitle>
        <EditUserProfileForm loader={(val) => setLoader(val)} />
        {loader && (
          <div className={styles.loaderBox}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
