import React from "react";

const Checkbox = (props) => {
  return (
    <label className="material-checkbox">
      <input
        type="checkbox"
        name={props.name}
        id={props.name}
        defaultChecked={props.defaultChecked}
        onChange={props.onChange}
        onBlur={props.onBlur}
        // disabled={props.disabled}
        value={props.value}
      />
      <span className="checkmark"></span>
      {props.label}
    </label>
  );
};

export default Checkbox;
