import React from "react";
import Pagination from "../pagination";
import styles from "./style.module.scss";
import { Text } from "../../styledComponent";

const TablePagination = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
  onPrevPageChange,
  onNextPageChange,
  totalNumberOfData,
}) => {
  return (
    <div className={styles.tableFooter}>
      <div className={styles.displayingData}>
        <Text $size="1.4rem">
          Showing {totalNumberOfData} of {totalCount} entries
        </Text>
      </div>
      <div className={styles.pagination}>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPrevPageChange={onPrevPageChange}
          onNextPageChange={onNextPageChange}
        />
      </div>
    </div>
  );
};

export default TablePagination;
